import * as React from "react";

import { Viewport } from "./viewport";
import { ViewportContext } from "./ViewportContext";

export function ViewportProvider({ children }: React.PropsWithChildren) {
  const viewportState = React.useState<Viewport>(Viewport.default(0));
  return (
    <ViewportContext.Provider value={viewportState}>
      {children}
    </ViewportContext.Provider>
  );
}
