import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { IconButton, useTheme } from "@mui/material";
import * as React from "react";

import { RobotoTooltip } from "@/shared/components";

interface ClearFieldButtonProps {
  onClick: () => void;
  showIcon: boolean;
  disabled?: boolean;
}

export const ClearFieldButton: React.FC<ClearFieldButtonProps> = ({
  onClick,
  showIcon,
  disabled,
}) => {
  const theme = useTheme();
  return (
    <RobotoTooltip title="Clear">
      <IconButton
        onClick={() => onClick()}
        disabled={disabled}
        sx={{
          p: "5px",
          verticalAlign: "middle",
          ml: theme.spacing(0.25),
          opacity: showIcon ? 1 : 0,
          position: "relative",
          top: "auto",
          right: "auto",
        }}
        aria-label="clear-field"
      >
        <HighlightOffIcon
          color={disabled ? "disabled" : "primary"}
          sx={{
            fontSize: "1.1rem",
            cursor: "pointer",
          }}
        />
      </IconButton>
    </RobotoTooltip>
  );
};
