import { Box, CircularProgress, styled, useTheme } from "@mui/material";

import { RobotoTypography } from "@/shared/components";
import { Value } from "@/shared/components/Metadata";

const TruncatedText = styled("abbr")`
  text-decoration: none;
`;

interface KeyValuePairProps {
  label: string;
  value: unknown;
  isLoading?: boolean;
}

export const KeyValuePair = ({
  label,
  value,
  isLoading,
}: KeyValuePairProps) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        margin: "0.5rem 0",
      }}
    >
      <RobotoTypography
        sx={{
          color: theme.palette.mode === "light" ? "#686868" : "#aaa",
          fontSize: "0.875rem",
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap",
        }}
      >
        <TruncatedText title={label}>{label}</TruncatedText>
      </RobotoTypography>
      <Value value={value} />
      {isLoading && <CircularProgress size={10} sx={{ marginLeft: "4px" }} />}
    </Box>
  );
};
