import * as React from "react";

import { UISimpleCondition } from "@/shared/components/filters";
import { QueryTarget, queryTargetToDisplayName } from "@/shared/domain/query";
import { useQueryRecord } from "@/shared/domain/query/hooks";
import { ConditionsOperator } from "@/types";

import * as conditions from "../conditions";

import { MultiSearchBar } from "./MultiSearchBar";
import { ModeToggleSearchBarMode } from "./types";

interface SearchBarControllerProps {
  resolvedOrgId: string;
  activeQueryId?: string;
  autoLaunchQuery: boolean;
  target: QueryTarget;
  setTarget: (arg: QueryTarget) => void;
  mode: ModeToggleSearchBarMode;
  setMode: (arg: ModeToggleSearchBarMode) => void;
  initialSearchTerm?: string;
  initialRoboql?: string;
  onBasicSearchSubmit: (
    target: QueryTarget,
    searchTerm: string,
    onlySetQueryId?: boolean,
  ) => void;
  onConditionSearchSubmit: (
    conditions: UISimpleCondition[],
    operator: ConditionsOperator,
  ) => void;
  onRoboqlSearchSubmit: (
    target: QueryTarget,
    roboql: string,
    onlySetQueryId?: boolean,
  ) => void;
  selectedDatasets: Set<string>;
  clearSelectedDatasets: () => void;
}

export const SearchBarController: React.FC<SearchBarControllerProps> = ({
  resolvedOrgId,
  activeQueryId,
  autoLaunchQuery,
  target,
  setTarget,
  mode,
  setMode,
  initialSearchTerm,
  initialRoboql,
  onBasicSearchSubmit,
  onConditionSearchSubmit,
  onRoboqlSearchSubmit,
  selectedDatasets,
  clearSelectedDatasets,
}) => {
  const [searchTerm, setSearchTerm] = React.useState<string>(
    initialSearchTerm || "",
  );

  const [roboql, setRoboql] = React.useState<string>(initialRoboql || ``);

  React.useEffect(() => {
    // If searchParams change, respect them visually
    if (initialSearchTerm) {
      setSearchTerm(initialSearchTerm);
    }
  }, [initialSearchTerm]);

  // If there's an activeQueryId, set the target, mode and query
  const queryRecordQuery = useQueryRecord(activeQueryId);
  const queryRecord = queryRecordQuery.data;

  React.useEffect(() => {
    if (!queryRecord || autoLaunchQuery) {
      // Don't add the queryId to the URL on initial page loads
      return;
    }
    setTarget(queryRecord.target);
    if (queryRecord.query_ctx.query.roboql) {
      setMode("roboql");
      setRoboql(queryRecord.query_ctx.query.roboql as string);
    }
  }, [queryRecord, autoLaunchQuery, setMode, setTarget]);

  return (
    <MultiSearchBar
      orgId={resolvedOrgId}
      target={target}
      onTargetChanged={setTarget}
      mode={mode}
      onModeChanged={setMode}
      placeholder={`Search ${queryTargetToDisplayName(target).toLowerCase()} with a single term`}
      searchTerm={searchTerm}
      setSearchTerm={setSearchTerm}
      onBasicSearchSubmit={onBasicSearchSubmit}
      searchFields={conditions.searchFieldsForTarget(target)}
      onConditionSearchSubmit={onConditionSearchSubmit}
      roboql={roboql}
      setRoboql={setRoboql}
      onRoboqlSearchSubmit={onRoboqlSearchSubmit}
      selectedDatasets={selectedDatasets}
      clearSelectedDatasets={clearSelectedDatasets}
    />
  );
};
