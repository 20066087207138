import {
  Alert,
  AlertTitle,
  Box,
  Link,
  Paper,
  Typography,
  useTheme,
} from "@mui/material";
import * as React from "react";

// TODO (ROBO-1292) Refactor FileGrid to be Dataset agnostic and move it to /shared/components
import { DatasetFileGrid } from "@/Datasets/Dataset/DatasetFileBrowser/DatasetFileGrid.tsx";
import { useAuth, useNavigation } from "@/providers";
import {
  AlertDialog,
  RobotoButton,
  SelectionButton,
} from "@/shared/components/index.tsx";
import { DatasetsTable } from "@/shared/components/SearchResultViews/index.tsx";
import { CollectionRecord } from "@/shared/domain/collections";
import { FileRecord } from "@/shared/domain/files";
import { useLazyAPICall } from "@/shared/services/apiHooks/index.ts";
import {
  APIResponse,
  Dataset,
  FileNode,
  FileSystemNode,
  isFile,
} from "@/types";

import { collectionEndpoint } from "../api/index.ts";

import { CollectionAudit } from "./CollectionAudit.tsx";
import { CollectionPermissionDetails } from "./CollectionPermissionDetails.tsx";
import { EditableCollectionMetadata } from "./EditableCollectionMetadata.tsx";

interface CollectionDetailsProps {
  collection: CollectionRecord;
  loading: boolean;
  onUpdate?: (arg: APIResponse<CollectionRecord>) => void;
}

export const CollectionDetails: React.FC<CollectionDetailsProps> = ({
  collection,
  loading,
  onUpdate,
}) => {
  const theme = useTheme();
  const { currentOrganization } = useAuth();
  const [errorText, setErrorText] = React.useState<string>("");
  const [alertDialogOpen, setAlertDialogOpen] = React.useState<boolean>(false);
  const [alertDialogTitle, setAlertDialogTitle] = React.useState<string>("");
  const [alertDialogText, setAlertDialogText] = React.useState<string>("");
  const [alertDialogAction, setAlertDialogAction] =
    React.useState<() => Promise<void>>();

  const [mainContentMode, setMainContentMode] = React.useState<
    "details" | "permissions" | "changes"
  >("details");

  const [selectedItems, setSelectedItems] = React.useState<FileNode[]>([]);
  const [selectedDatasets, setSelectedDatasets] = React.useState<Set<string>>(
    new Set<string>(),
  );

  const { goto } = useNavigation();

  const { initiateRequest: deleteCollectionRequest } = useLazyAPICall();

  function wrapActionWithDialog(
    title: string,
    text: string,
    action: () => Promise<void>,
  ) {
    setAlertDialogTitle(title);
    setAlertDialogText(text);
    setAlertDialogAction(() => action);
    setAlertDialogOpen(true);
  }

  const datasets = React.useMemo(() => {
    return "dataset" in collection.resources
      ? (collection.resources["dataset"] as Dataset[])
      : [];
  }, [collection.resources]);

  const files = React.useMemo(() => {
    if ("file" in collection.resources) {
      return (collection.resources["file"] as FileRecord[]).map((file) => {
        return {
          type: "file",
          name: file.relative_path,
          file,
        } as FileNode;
      });
    } else {
      return [];
    }
  }, [collection.resources]);

  const { initiateRequest: updateCollectionRequest } =
    useLazyAPICall<APIResponse<CollectionRecord>>();

  let mainContent = <></>;
  if (mainContentMode === "details") {
    mainContent = (
      <>
        {datasets.length + files.length === 0 && (
          <Box
            sx={{
              mt: theme.spacing(2),
            }}
          >
            <Alert severity="info">
              <AlertTitle>Empty Collection</AlertTitle>
              There are no items in this collection yet. You can add{" "}
              <Link href="/datasets" underline="hover">
                datasets
              </Link>{" "}
              or individual files that have been uploaded to datasets.
            </Alert>
          </Box>
        )}

        {datasets.length > 0 && (
          <DatasetsTable
            datasets={datasets}
            loading={loading}
            selectedDatasets={selectedDatasets}
            setSelectedDatasets={setSelectedDatasets}
            variant="outlined"
          />
        )}

        {files.length > 0 && (
          <Box
            component={Paper}
            elevation={0}
            variant="outlined"
            py={2}
            px={3}
            mt={2}
            sx={{
              backgroundColor: theme.palette.paper.main,
            }}
          >
            <DatasetFileGrid
              datasetId={""}
              currentPage={files}
              gridItems={3}
              annotationViewFilters={{
                confidenceThreshold: { percent: 0, show: false },
                labels: { show: false },
                boundingBox: { show: false },
                segmentation: { show: false },
              }}
              selectedItems={selectedItems}
              handleSelectRows={(
                rows: FileSystemNode[],
                isSelected: boolean,
              ) => {
                if (isSelected) {
                  const itemsToAdd = [];
                  for (const row of rows) {
                    if (!isFile(row)) {
                      continue;
                    }

                    if (
                      !selectedItems.some(
                        (item) => item.file.file_id === row.file.file_id,
                      )
                    ) {
                      itemsToAdd.push(row);
                    }
                  }
                  const newState = selectedItems.concat(itemsToAdd);
                  setSelectedItems(newState);
                } else {
                  const newState = selectedItems.filter(
                    (item) =>
                      !rows.some((row) => {
                        if (!isFile(row)) {
                          return false;
                        }

                        return row.file.file_id === item.file.file_id;
                      }),
                  );
                  setSelectedItems(newState);
                }
              }}
            />
          </Box>
        )}
      </>
    );
  } else if (mainContentMode === "changes") {
    mainContent = (
      <Box
        sx={{
          mt: theme.spacing(3),
        }}
      >
        <CollectionAudit collection={collection} />
      </Box>
    );
  } else if (mainContentMode === "permissions") {
    mainContent = (
      <Box
        sx={{
          mt: theme.spacing(3),
        }}
      >
        <CollectionPermissionDetails collectionId={collection.collection_id} />
      </Box>
    );
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Box
          sx={{
            width: "100%",
          }}
        >
          {errorText && (
            <Box sx={{ mt: theme.spacing(2) }}>
              <Alert severity="error">{errorText}</Alert>
            </Box>
          )}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mt: theme.spacing(3),
            }}
          >
            <EditableCollectionMetadata
              name={collection.name ?? ""}
              description={collection.description ?? ""}
              collectionId={collection.collection_id}
              setErrorText={setErrorText}
              onUpdate={onUpdate}
            />
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          display: {
            xs: "block",
            sm: "flex",
          },
          gap: theme.spacing(2),
          justifyContent: "space-between",
          alignItems: "center",
          mt: theme.spacing(2),
        }}
      >
        <i>
          <Typography variant="caption" color={theme.palette.text.secondary}>
            Last modified by {collection.updated_by} on{" "}
            {new Date(collection.updated).toLocaleString("en-US", {
              day: "numeric",
              month: "long",
              year: "numeric",
            })}
          </Typography>
        </i>
        <Box
          sx={{
            display: "flex",
            gap: theme.spacing(2),
            mt: {
              xs: theme.spacing(2),
              sm: 0,
            },
          }}
        >
          {selectedDatasets.size + selectedItems.length > 0 && (
            <SelectionButton
              selectedItems={selectedItems}
              handleDeselectAll={() => {
                setSelectedItems([]);
              }}
              selectedDatasets={selectedDatasets}
              setSelectedDatasets={setSelectedDatasets}
              currentOrganization={currentOrganization}
              handleRemoveFromCollection={() =>
                wrapActionWithDialog(
                  "Confirm Removal",
                  `Are you sure you want to remove ${
                    selectedDatasets.size + selectedItems.length
                  } items from this collection?`,
                  async function () {
                    const removeDatasets = Array.from(selectedDatasets).map(
                      (dataset) => {
                        return {
                          resource_type: "dataset",
                          resource_id: dataset,
                        };
                      },
                    );

                    const removeFiles = selectedItems.map((item) => {
                      return {
                        resource_type: "file",
                        resource_id: item.file?.file_id || "",
                      };
                    });

                    const { data, error } = await updateCollectionRequest({
                      endpoint: collectionEndpoint,
                      method: "PUT",
                      orgId: currentOrganization?.org_id,
                      pathParams: { collectionId: collection.collection_id },
                      requestBody: JSON.stringify({
                        remove_resources: removeDatasets.concat(removeFiles),
                      }),
                    });

                    if (!error) {
                      if (data && onUpdate) {
                        onUpdate(data);
                      }
                      setSelectedDatasets(new Set<string>());
                      setSelectedItems([]);
                    } else {
                      setErrorText(error.message);
                    }
                  },
                )
              }
            />
          )}
          <RobotoButton
            eventName={"CollectionChangeHistoryClicked"}
            eventProperties={{ collectionId: collection.collection_id }}
            variant={mainContentMode === "changes" ? "contained" : "outlined"}
            size="small"
            color="warning"
            sx={{
              alignSelf: "flex-start",
              minWidth: 120,
              height: 32,
            }}
            onClick={() =>
              setMainContentMode(
                mainContentMode === "changes" ? "details" : "changes",
              )
            }
          >
            Change History
          </RobotoButton>
          <RobotoButton
            eventName={"CollectionPermissionsClicked"}
            eventProperties={{ collectionId: collection.collection_id }}
            variant={
              mainContentMode === "permissions" ? "contained" : "outlined"
            }
            size="small"
            color="info"
            sx={{
              alignSelf: "flex-start",
              minWidth: 120,
              height: 32,
            }}
            onClick={() =>
              setMainContentMode(
                mainContentMode === "permissions" ? "details" : "permissions",
              )
            }
          >
            Permissions
          </RobotoButton>
          <RobotoButton
            eventName={"DeleteCollectionClicked"}
            eventProperties={{ collectionId: collection.collection_id }}
            variant={"outlined"}
            size="small"
            color="error"
            sx={{
              alignSelf: "flex-start",
              minWidth: 120,
              height: 32,
            }}
            onClick={() => {
              const collectionIdentifier = collection.name
                ? collection.name + " (" + collection.collection_id + ") "
                : collection.collection_id;
              wrapActionWithDialog(
                "Delete Collection",
                "Are you sure you want to delete " + collectionIdentifier + "?",
                async function () {
                  const { error } = await deleteCollectionRequest({
                    endpoint: collectionEndpoint,
                    method: "DELETE",
                    orgId: currentOrganization?.org_id,
                    pathParams: { collectionId: collection.collection_id },
                  });

                  if (!error) {
                    goto.collections();
                  } else {
                    setErrorText(error.message);
                  }
                },
              );
            }}
          >
            Delete Collection
          </RobotoButton>
        </Box>
      </Box>

      {mainContent}

      <AlertDialog
        dialogOpen={alertDialogOpen}
        handleClose={() => {
          setAlertDialogOpen(false);
        }}
        dialogTitle={alertDialogTitle}
        dialogText={alertDialogText}
        dialogAction={alertDialogAction}
      />
    </>
  );
};
