import {
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import { RobotoTableCell } from "@/shared/components";

import { type EvaluationColumn } from "./tableColumns";
import { TriggerEvaluationsTableBody } from "./TriggerEvaluationsTableBody";

interface TriggerEvaluationsForTriggerProps {
  triggerName?: string;
}

const COLUMNS: Record<string, EvaluationColumn> = {
  Dataset: "dataset_id",
  Cause: "cause",
  "Evaluation Start": "evaluation_start",
  "Evaluation End": "evaluation_end",
  Status: "status",
  "Status Detail": "status_detail",
  Outcome: "outcome",
  Reason: "outcome_reason",
};

/*
 * Render a table of trigger evaluation records for the given trigger.
 */
export function TriggerEvaluationsForTrigger({
  triggerName,
}: TriggerEvaluationsForTriggerProps) {
  const headers = Object.entries(COLUMNS).map(([label, column]) => (
    <RobotoTableCell key={column}>{label}</RobotoTableCell>
  ));

  return (
    <TableContainer component={Paper} variant="outlined">
      <Table size="small">
        <TableHead>
          <TableRow>{headers}</TableRow>
        </TableHead>
        <TriggerEvaluationsTableBody
          columns={Object.values(COLUMNS)}
          filter={{ triggerName }}
        />
      </Table>
    </TableContainer>
  );
}
