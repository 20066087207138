import { Box, CircularProgress, TextField, useTheme } from "@mui/material";
import { useState } from "react";
import * as React from "react";
import { useSearchParams } from "react-router-dom";

import {
  RobotoButton,
  RobotoTypography,
  RobotoLogo,
} from "@/shared/components";
import { AuthCard } from "@/shared/components/auth/AuthCard";
import { AuthCardTitle } from "@/shared/components/auth/AuthCardTitle";
import { LegalFooter } from "@/shared/components/auth/LegalFooter";
import { ResendCodeButton } from "@/shared/components/auth/ResendCodeButton";
import { OrgInviteRecord, OrgRecord } from "@/shared/domain/orgs";

import { CurrentSignUpForm } from "./signUpFormState";
import { useSubmitVerificationAndSignIn } from "./useSubmitVerificationAndSignIn";

const buttonWidth = 215;

interface VerificationCodeFormProps {
  isVisible: boolean;
  onCodeSuccess: (
    nextForm: CurrentSignUpForm,
    organizations: OrgRecord[],
    pendingInvites: OrgInviteRecord[],
    inviteId: string | null,
    emailAddress: string,
    password: string,
  ) => void;
}

/**
 * For use when the user got to the verification code step in a previous sign up attempt, but did not finish
 */
export const InterruptedVerificationCodeForm: React.FC<
  VerificationCodeFormProps
> = ({ onCodeSuccess, isVisible }) => {
  if (!isVisible) {
    return null;
  }

  return (
    <>
      <RobotoLogo sx={{ maxWidth: "250px" }} />

      <AuthCard>
        <AuthCardTitle title={"Verify your account"} />
        <VerificationInput codeSuccess={onCodeSuccess} />
      </AuthCard>
      <LegalFooter />
    </>
  );
};

interface VerificationInputProps {
  codeSuccess: (
    nextForm: CurrentSignUpForm,
    organizations: OrgRecord[],
    pendingInvites: OrgInviteRecord[],
    inviteId: string | null,
    emailAddress: string,
    password: string,
  ) => void;
}

const VerificationInput: React.FC<VerificationInputProps> = ({
  codeSuccess,
}) => {
  const theme = useTheme();

  const [emailAddress, setEmailAddress] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const [code, setCode] = useState<string>("");

  const [searchParams] = useSearchParams();
  const inviteId = searchParams.get("inviteId");

  const { loading, errMsg, submitVerificationAndSignIn, clearErrMsg } =
    useSubmitVerificationAndSignIn();

  let buttonDisabled = true;

  if (code.length === 6) {
    buttonDisabled = false;
  }

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const { organizations, invites, errMsg } =
      await submitVerificationAndSignIn(emailAddress, code, password);

    let nextForm: CurrentSignUpForm = "howDoYouWork";

    if (errMsg) {
      //errMsg is displayed in the UI
      return;
    } else {
      if (inviteId) {
        // user will be pushed to invite page in success handler
        nextForm = "howDoYouWork";
      } else if (invites.length > 0) {
        nextForm = "pendingInvites";
      } else if (organizations.length > 0) {
        nextForm = "selectOrCreateOrganization";
      } else {
        nextForm = "howDoYouWork";
      }

      codeSuccess(
        nextForm,
        organizations,
        invites,
        inviteId,
        emailAddress,
        password,
      );
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
      component="form"
      onSubmit={(e) => {
        void onSubmit(e);
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <TextField
          label="Verification Code"
          type="tel"
          sx={{ marginBottom: theme.spacing(2) }}
          value={code}
          size="small"
          onChange={(e) => {
            setCode(e.target.value);
            clearErrMsg();
          }}
          autoFocus
        />

        <TextField
          data-cy={"email-input"}
          label="Email address"
          placeholder="name@domain.com"
          type={"email"}
          sx={{ marginBottom: theme.spacing(2) }}
          value={emailAddress}
          size="small"
          onChange={(e) => {
            setEmailAddress(e.target.value);
          }}
          inputProps={{
            autoCapitalize: "none",
          }}
        />

        <TextField
          data-cy={"password-input"}
          label="Password"
          type={"password"}
          size="small"
          sx={{ marginBottom: theme.spacing(2) }}
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
          inputProps={{
            autoCapitalize: "none",
          }}
        />
      </Box>

      {loading ? (
        <Box
          sx={{
            width: buttonWidth,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: theme.spacing(2),
          }}
        >
          <CircularProgress size="1.75rem" />
        </Box>
      ) : (
        <>
          <RobotoButton
            eventName={"VerificationCodeSubmitted"}
            variant={"contained"}
            sx={{ width: buttonWidth, marginBottom: theme.spacing(2) }}
            disabled={buttonDisabled}
            type="submit"
          >
            Submit
          </RobotoButton>

          <ResendCodeButton emailAddress={emailAddress} />
        </>
      )}

      {errMsg && (
        <RobotoTypography
          variant={"caption"}
          textAlign={"center"}
          sx={{ color: theme.palette.error.main }}
        >
          {errMsg}
        </RobotoTypography>
      )}
    </Box>
  );
};
