import { Alert, AlertTitle, Box, Paper, useTheme } from "@mui/material";
import * as React from "react";

import { useNavigation } from "@/providers";
import { Dataset, SearchQueryBody } from "@/types";

import { RobotoDataGrid } from "../RobotoDataGrid";

import { ColumnsModal } from "./ColumnsModal";
import {
  TablePageAction,
  TablePageState,
  tablePageReducer,
} from "./stateReducer";
import { createRows, retrieveMetadataColumns } from "./tableLogic";

const initialDatasetsQuery: SearchQueryBody = {
  sort_by: "created",
  sort_direction: "DESC",
};

const possibleStandardDatasetColumns = [
  "dataset_id",
  "created",
  "created_by",
  "tags",
  "administrator",
  "modified",
  "modified_by",
  "name",
  "org_id",
  "storage_location",
  "device_id",
  "source",
];

const sortableColumns = new Set<string>();

interface DatasetsTableProps {
  datasets: Dataset[];
  loading: boolean;
  selectedDatasets?: Set<string>;
  setSelectedDatasets?: (arg: Set<string>) => void;
  onDatasetSingleClick?: (dataset: Dataset) => void;
  variant?: "elevation" | "outlined";
  containerStyle?: React.CSSProperties;
  fetchMoreData?: () => void;
}

export const DatasetsTable: React.FC<DatasetsTableProps> = ({
  datasets,
  loading,
  selectedDatasets,
  setSelectedDatasets = () => {},
  onDatasetSingleClick = () => {},
  variant,
  containerStyle,
  fetchMoreData,
}) => {
  const theme = useTheme();

  const datasetsById = new Map<string, Dataset>(
    datasets.map((dataset) => {
      return [dataset.dataset_id, dataset];
    }),
  );

  const { goto } = useNavigation();
  const [selectedRowsToggle, setSelectedRowsToggle] =
    React.useState<boolean>(false);

  const [state, dispatch] = React.useReducer<
    React.Reducer<TablePageState, TablePageAction>
  >(tablePageReducer, {
    modalOpen: false,
    rightClickedRow: null,
    sidebar: { isOpen: false, dataset: null },
    columns: ["dataset_id", "name", "created", "source", "tags"],
    tableConfig: { page: 0, rowsPerPage: 25 },
    snackbar: { isOpen: false, message: "" },
    sortColumnIndex: 1,
    sortOrder: "descending",
    lastSearchQuery: initialDatasetsQuery,
    selectedRows: new Set<string>(),
  });

  const pageData = React.useMemo(() => {
    return datasets.slice(
      state.tableConfig.page * state.tableConfig.rowsPerPage,
      state.tableConfig.page * state.tableConfig.rowsPerPage +
        state.tableConfig.rowsPerPage,
    );
  }, [datasets, state.tableConfig]);

  const { rows, allPossibleColumns } = React.useMemo(() => {
    const possibleMetadataColumns: string[] =
      retrieveMetadataColumns(pageData) ?? [];

    const rows = createRows(pageData, state.columns, possibleMetadataColumns);

    const allPossibleColumns = possibleStandardDatasetColumns
      .concat(possibleMetadataColumns)
      .sort((a, b) => a.localeCompare(b, undefined, { sensitivity: "base" }));

    return { rows, allPossibleColumns };
  }, [pageData, state.columns]);

  const noSearchResults =
    Object.keys(state.lastSearchQuery).length > 0 && datasets.length === 0;

  React.useEffect(() => {
    if (selectedDatasets && selectedDatasets.size === 0) {
      setSelectedRowsToggle((prevToggle) => !prevToggle);
      dispatch({
        type: "SET_SELECTED_ROWS",
        payload: { selectedRows: new Set<string>() },
      });
    }
  }, [selectedDatasets]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
      }}
    >
      <Box
        sx={{
          flex: 1,
          maxWidth: "100%",
        }}
      >
        <Paper
          sx={{
            mt: theme.spacing(2),
          }}
          variant={variant}
        >
          {noSearchResults && (
            <Alert severity="info">
              <AlertTitle>Datasets</AlertTitle>
              <>
                No datasets match your search criteria. Try changing your search
                terms and try again.
              </>
            </Alert>
          )}

          {!noSearchResults && (
            <>
              <RobotoDataGrid
                sortableColumns={sortableColumns}
                sortingOrder={state.sortOrder}
                sortingColumnIndex={state.sortColumnIndex}
                page={state.tableConfig.page}
                rowsPerPage={state.tableConfig.rowsPerPage}
                nextPageButtonDisabled={
                  (state.tableConfig.page + 1) *
                    state.tableConfig.rowsPerPage >=
                  datasets.length
                }
                currentRowLength={datasets.length}
                containerStyle={containerStyle}
                loading={loading}
                columnNames={state.columns}
                rows={rows}
                onPageChange={(newPage, rowsPerPage) => {
                  if ((newPage + 2) * rowsPerPage >= datasets.length) {
                    // Request more pages of data from the backend
                    // as we approach the last page of cached results.
                    // 2 pages prior seemed to work well experimentally.
                    fetchMoreData?.();
                  }
                  dispatch({
                    type: "SET_TABLE_CONFIG",
                    payload: { page: newPage, rowsPerPage: rowsPerPage },
                  });
                }}
                onRowsPerPageChange={(_page, newRowsPerPage) => {
                  dispatch({
                    type: "SET_TABLE_CONFIG",
                    payload: {
                      page: 0,
                      rowsPerPage: newRowsPerPage as 10 | 25 | 50,
                    },
                  });
                }}
                onColumnSortClicked={() => {
                  // do nothing
                }}
                onAddColumnClick={() => {
                  dispatch({ type: "SET_MODAL_OPEN", payload: true });
                }}
                onRowDoubleClick={(rowId) => {
                  goto.dataset({ datasetId: rowId });
                }}
                onRowSingleClick={(rowId: string) => {
                  const datasetRecord = datasetsById.get(rowId);
                  if (onDatasetSingleClick && datasetRecord) {
                    onDatasetSingleClick(datasetRecord);
                  }
                }}
                onSelectedRowsChange={(selectedRows) => {
                  setSelectedDatasets(selectedRows);
                  dispatch({
                    type: "SET_SELECTED_ROWS",
                    payload: { selectedRows: selectedRows },
                  });
                }}
                isRowSelectable={true}
                isRowExpandable={false}
                clearSelectedRowsToggle={selectedRowsToggle}
              />
              <ColumnsModal
                open={state.modalOpen}
                handleClose={() => {
                  dispatch({ type: "SET_MODAL_OPEN", payload: false });
                }}
                columns={allPossibleColumns}
                currentColumns={state.columns}
                onSave={(updatedColumns) => {
                  dispatch({ type: "SAVE_COLUMNS", payload: updatedColumns });
                }}
              />
            </>
          )}
        </Paper>
      </Box>
    </Box>
  );
};
