import { Box } from "@mui/material";
import * as React from "react";

import { RobotoMarkdown } from "@/shared/components/RobotoMarkdown";

interface ActionMarkdownProps {
  text: string;
}

export const ActionMarkdown: React.FC<ActionMarkdownProps> = ({ text }) => {
  const [loadedText, setLoadedText] = React.useState<string>("");

  React.useEffect(() => {
    const renderText = async () => {
      function isValidHttpUrl(val: string) {
        let url;

        try {
          url = new URL(val);
        } catch {
          return false;
        }

        return url.protocol === "http:" || url.protocol === "https:";
      }

      if (isValidHttpUrl(text)) {
        await fetch(text, {
          method: "GET",
        })
          .then(function (response) {
            return response.text();
          })
          .then(function (data) {
            setLoadedText(data);
          });
      } else {
        setLoadedText(text);
        return text;
      }
    };

    void renderText();
  }, [text]);

  return (
    <Box
      sx={{
        fontSize: "0.8rem",
      }}
    >
      <RobotoMarkdown content={loadedText} />
    </Box>
  );
};
