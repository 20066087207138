import {
  Box,
  ListItem,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import { useState } from "react";

import { EditFieldButton } from "@/shared/components/EditFieldButton";
import { ComputeRequirements } from "@/shared/domain/actions";
import { OrgRecord } from "@/shared/domain/orgs";
import { useLazyAPICall } from "@/shared/services/apiHooks";
import { APIResponse, RobotoAPICall, RobotoEndpoint } from "@/types";

import { ActionComputeReqs } from "./ActionComputeReqs";
import { ActionComputeReqsTable } from "./ActionComputeReqsTable";
import { parseComputeRequirements } from "./actionUtils";
import { ClearFieldButton } from "./ClearFieldButton";

interface EditableComputeReqsProps<T> {
  title: string;
  field: keyof T;
  value: ComputeRequirements;
  pathParams: Record<string, string | number> | undefined;
  endpoint: RobotoEndpoint;
  currentOrg: OrgRecord | null;
  setErrorText: (arg: string) => void;
  overrides?: boolean;
  onUpdate?: (arg: APIResponse<T>) => void;
}

export default function EditableComputeReqs<T>({
  title,
  field,
  value,
  pathParams,
  endpoint,
  currentOrg,
  setErrorText,
  overrides,
  onUpdate,
}: EditableComputeReqsProps<T>) {
  const theme = useTheme();
  const [showEditableIcon, setShowEditableIcon] = useState(false);
  const [editable, setEditable] = useState(false);
  const [cpu, setCpu] = useState<string | undefined>(
    value.vCPU ? value.vCPU.toString() : undefined,
  );
  const [memory, setMemory] = useState<string | undefined>(
    value.memory ? value.memory.toString() : undefined,
  );
  const [gpu, setGpu] = useState<string | undefined>(
    value.gpu ? value.gpu.toString() : undefined,
  );
  const [storage, setStorage] = useState<number | undefined>(value.storage);

  const [hasComputeFormErrors, setHasComputeFormErrors] = useState(false);

  const { initiateRequest } = useLazyAPICall<APIResponse<T>>();

  const updateRecord = async (reset?: boolean) => {
    const computeRequirements = parseComputeRequirements(
      cpu,
      memory,
      gpu,
      storage,
    );

    const updates = {
      [field]: reset ? null : computeRequirements,
    };
    const requestBody = JSON.stringify(updates);

    const updateCall: RobotoAPICall = {
      endpoint: endpoint,
      orgId: currentOrg?.org_id,
      pathParams: pathParams,
      method: "PUT",
      requestBody: requestBody,
    };

    const { error, data } = await initiateRequest(updateCall);

    if (error) {
      setErrorText(error.message);
      return false;
    }

    if (data && onUpdate) {
      onUpdate(data);
    }

    setEditable(false);
    setErrorText("");

    return data;
  };

  return (
    <ListItem
      onMouseOver={() => {
        setShowEditableIcon(true);
      }}
      onMouseLeave={() => {
        setShowEditableIcon(false);
      }}
    >
      <ListItemText
        primaryTypographyProps={{
          component: "span",
        }}
        primary={
          <>
            <span>{title}</span>

            <EditFieldButton
              disabled={
                (!editable && onUpdate === undefined) || hasComputeFormErrors
              }
              editable={editable}
              showEditableIcon={showEditableIcon}
              onClick={() => {
                if (editable) {
                  void updateRecord();
                }
                setEditable(!editable);
              }}
            />

            {overrides && (
              <ClearFieldButton
                disabled={!editable && onUpdate === undefined}
                showIcon={showEditableIcon}
                onClick={() => {
                  void updateRecord(true);
                  setCpu(undefined);
                  setGpu(undefined);
                  setMemory(undefined);
                  setStorage(undefined);
                }}
              />
            )}

            <Box>
              {!editable ? (
                <>
                  {value && Object.keys(value).length > 0 ? (
                    <Box
                      sx={{
                        mt: theme.spacing(1),
                      }}
                    >
                      <ActionComputeReqsTable
                        cpu={cpu}
                        memory={memory}
                        gpu={gpu}
                        storage={storage}
                      />
                    </Box>
                  ) : (
                    <Typography
                      component="span"
                      variant="body2"
                      style={{
                        whiteSpace: "pre-wrap",
                        color: theme.palette.text.secondary,
                      }}
                    >
                      None
                    </Typography>
                  )}
                </>
              ) : (
                <Box sx={{ mt: theme.spacing(0.5) }}>
                  <ActionComputeReqs
                    cpu={cpu}
                    setCpu={setCpu}
                    memory={memory}
                    setMemory={setMemory}
                    gpu={gpu}
                    setGpu={setGpu}
                    storage={storage}
                    setStorage={setStorage}
                    setHasComputeFormErrors={setHasComputeFormErrors}
                  />
                </Box>
              )}
            </Box>
          </>
        }
      />
    </ListItem>
  );
}
