import HighlightAltIcon from "@mui/icons-material/HighlightAlt";

import { RobotoButton } from "@/shared/components";

interface SelectAnnotationsProps {
  isFileAnImage: boolean;
  handlePopover: (
    component: "annotations" | null,
    element: HTMLButtonElement | null,
  ) => void;
}

export function SelectAnnotations(props: SelectAnnotationsProps) {
  const { isFileAnImage, handlePopover } = props;
  return (
    <RobotoButton
      variant="contained"
      eventName={"FileAnnotationsClicked"}
      startIcon={<HighlightAltIcon />}
      disabled={isFileAnImage === false}
      onClick={(e) => handlePopover("annotations", e.currentTarget)}
    >
      Annotations
    </RobotoButton>
  );
}
