import { Box, CircularProgress, TextField, useTheme } from "@mui/material";
import { useState } from "react";
import * as React from "react";
import { useSearchParams } from "react-router-dom";

import {
  RobotoButton,
  RobotoTypography,
  RobotoLogo,
} from "@/shared/components";
import { AuthCard } from "@/shared/components/auth/AuthCard";
import { AuthCardTitle } from "@/shared/components/auth/AuthCardTitle";
import { LegalFooter } from "@/shared/components/auth/LegalFooter";
import { ResendCodeButton } from "@/shared/components/auth/ResendCodeButton";
import { OrgInviteRecord, OrgRecord } from "@/shared/domain/orgs";

import { CurrentSignUpForm } from "./signUpFormState";
import { useSubmitVerificationAndSignIn } from "./useSubmitVerificationAndSignIn";

const buttonWidth = 215;

interface VerificationCodeFormProps {
  isVisible: boolean;
  onCodeSuccess: (
    nextForm: CurrentSignUpForm,
    organizations: OrgRecord[],
    invites: OrgInviteRecord[],
    inviteId: string | null,
  ) => void;
  emailAddress: string;
  password: string;
}

export const VerificationCodeForm: React.FC<VerificationCodeFormProps> = ({
  onCodeSuccess,
  emailAddress,
  isVisible,
  password,
}) => {
  const theme = useTheme();

  if (!isVisible) {
    return null;
  }

  return (
    <>
      <RobotoLogo sx={{ maxWidth: "250px" }} />

      <AuthCard>
        <AuthCardTitle title={"Verify your email"} />

        <RobotoTypography
          sx={{
            marginBottom: theme.spacing(2),
            textAlign: "center",
          }}
          variant="body2"
        >
          Please enter the code we sent to your email address
        </RobotoTypography>

        <CodeInput
          codeSuccess={onCodeSuccess}
          emailAddress={emailAddress}
          password={password}
        />
      </AuthCard>
      <LegalFooter />
    </>
  );
};

interface CodeInputProps {
  emailAddress: string;
  password: string;
  codeSuccess: (
    nextForm: CurrentSignUpForm,
    organizations: OrgRecord[],
    invites: OrgInviteRecord[],
    inviteId: string | null,
  ) => void;
}

const CodeInput: React.FC<CodeInputProps> = ({
  codeSuccess,
  emailAddress,
  password,
}) => {
  const theme = useTheme();

  const [code, setCode] = useState<string>("");

  const [searchParams] = useSearchParams();

  const inviteId = searchParams.get("inviteId");

  const { loading, errMsg, submitVerificationAndSignIn, clearErrMsg } =
    useSubmitVerificationAndSignIn();

  let buttonDisabled = true;

  if (code.length === 6) {
    buttonDisabled = false;
  }

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { organizations, invites, errMsg } =
      await submitVerificationAndSignIn(emailAddress, code, password);

    if (errMsg) {
      //errMsg is displayed in the UI
      return;
    } else {
      let nextForm: CurrentSignUpForm = "howDoYouWork";

      if (inviteId) {
        // user will be pushed to invite page in success handler
        nextForm = "howDoYouWork";
      } else if (invites.length > 0) {
        nextForm = "pendingInvites";
      } else if (organizations.length > 0) {
        nextForm = "selectOrCreateOrganization";
      } else {
        nextForm = "howDoYouWork";
      }

      codeSuccess(nextForm, organizations, invites, inviteId);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
      component="form"
      onSubmit={(e) => {
        void onSubmit(e);
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <TextField
          data-cy="verification-code-input"
          label="Verification Code"
          type="tel"
          sx={{ marginBottom: theme.spacing(2) }}
          value={code}
          size="small"
          onChange={(e) => {
            setCode(e.target.value);
            clearErrMsg();
          }}
          autoFocus
        />
      </Box>

      {loading ? (
        <Box
          sx={{
            width: buttonWidth,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: theme.spacing(2),
          }}
        >
          <CircularProgress size="1.75rem" />
        </Box>
      ) : (
        <RobotoButton
          data-cy="submit-verification-code"
          eventName={"VerificationCodeSubmitted"}
          variant={"contained"}
          sx={{ width: buttonWidth, marginBottom: theme.spacing(2) }}
          disabled={buttonDisabled}
          type="submit"
        >
          Submit
        </RobotoButton>
      )}

      <ResendCodeButton emailAddress={emailAddress} />

      {errMsg && (
        <RobotoTypography
          variant={"caption"}
          textAlign={"center"}
          sx={{ color: theme.palette.error.main }}
        >
          {errMsg}
        </RobotoTypography>
      )}
    </Box>
  );
};
