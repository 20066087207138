import FolderIcon from "@mui/icons-material/Folder";
import {
  Alert,
  AlertTitle,
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Skeleton,
  useTheme,
} from "@mui/material";
import * as React from "react";
import { Link as RouterLink } from "react-router-dom";

import { useAuth } from "@/providers";
import { usePaginatedAPICall } from "@/shared/services/apiHooks";
import { Dataset, datasetsQueryEndpoint, RobotoAPICall } from "@/types";

interface RecentDatasetsProps {
  count: number;
}

export const RecentDatasets: React.FC<RecentDatasetsProps> = ({ count }) => {
  const theme = useTheme();
  const { currentOrganization } = useAuth();

  const { getFirstPage, error, loading, pageData } =
    usePaginatedAPICall<Dataset>();

  React.useEffect(() => {
    const fetchData = async () => {
      if (!currentOrganization?.org_id) {
        return;
      }

      const apiCall: RobotoAPICall = {
        endpoint: datasetsQueryEndpoint,
        method: "POST",
        requestBody: JSON.stringify({
          sort_direction: "DESC",
          sort_by: "created",
        }),
        orgId: currentOrganization?.org_id,
      };

      await getFirstPage(apiCall, count);
    };

    void fetchData();
  }, [currentOrganization?.org_id, getFirstPage, count]);

  let listOrAlertContent;

  if (loading) {
    listOrAlertContent = (
      <Box
        sx={{
          height: "90%",
          pt: theme.spacing(1),
          pb: theme.spacing(2),
          minHeight: "200px",
        }}
      >
        <Skeleton animation="wave" variant="rounded" height={"100%"} />
      </Box>
    );
  } else if (error) {
    listOrAlertContent = (
      <Alert
        severity="error"
        sx={{
          mt: theme.spacing(1),
          mb: theme.spacing(2),
        }}
      >
        <AlertTitle>Error</AlertTitle>
        {error?.message}
      </Alert>
    );
  } else if (pageData.length === 0) {
    listOrAlertContent = (
      <Alert
        severity="warning"
        sx={{
          mt: theme.spacing(1),
          mb: theme.spacing(2),
        }}
      >
        <AlertTitle>Datasets</AlertTitle>
        No datasets have been created yet. Use the Create Dataset button above
        to create one and upload data.
      </Alert>
    );
  } else {
    listOrAlertContent = (
      <List>
        {pageData.map((dataset, idx) => {
          return (
            <ListItem
              key={idx}
              sx={{
                pl: 0,
                pr: 0,
                pt: theme.spacing(0.25),
              }}
            >
              <ListItemButton
                sx={{
                  pt: 0,
                  pb: 0,
                }}
                component={RouterLink}
                to={`/datasets/${dataset.dataset_id}`}
                disableRipple
                disableTouchRipple
              >
                <ListItemIcon>
                  <FolderIcon />
                </ListItemIcon>
                {dataset.dataset_id !== undefined && (
                  <ListItemText
                    primary={
                      dataset.dataset_id +
                      (dataset.name ? ` — ${dataset.name}` : "")
                    }
                    primaryTypographyProps={{
                      color: theme.palette.primary.main,
                      variant: "body2",
                      component: "span",
                    }}
                    secondaryTypographyProps={{
                      component: "span",
                      variant: "caption",
                    }}
                    secondary={
                      <>
                        {dataset.created &&
                          new Date(dataset.created).toLocaleString()}{" "}
                        by {dataset.created_by}
                      </>
                    }
                  />
                )}
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    );
  }

  return <Box sx={{ height: "100%" }}>{listOrAlertContent}</Box>;
};
