import Box from "@mui/material/Box";
import React from "react";

import { MetadataForm, MetadataView } from "@/shared/components";
import { AutocompleteType } from "@/shared/components/TagAndMetadataAutocomplete";
import { useUpdateDataset } from "@/shared/domain/datasets/hooks/useUpdateDataset";
import { OrgRecord } from "@/shared/domain/orgs";

interface UpdateDatasetMetadataProps {
  currentOrg: OrgRecord | null;
  datasetId: string;
  metadata: Record<string, string>;
  onError?: (error: Error) => void;
}

export const UpdateDatasetMetadata: React.FC<UpdateDatasetMetadataProps> = ({
  currentOrg,
  datasetId,
  metadata,
  onError,
}) => {
  const updateDatasetMutation = useUpdateDataset();

  const removeField = (key: string) => {
    updateDatasetMutation.mutate(
      {
        datasetId: datasetId,
        resourceOwnerId: currentOrg?.org_id || "",
        updates: {
          metadata_changeset: {
            remove_fields: [key],
          },
        },
      },
      {
        onError: (error: Error) => {
          if (onError) {
            onError(error);
          }
        },
      },
    );
  };

  const addField = (key: string, value: string) => {
    updateDatasetMutation.mutate(
      {
        datasetId: datasetId,
        resourceOwnerId: currentOrg?.org_id || "",
        updates: {
          metadata_changeset: {
            put_fields: { [key]: value },
          },
        },
      },
      {
        onError: (error: Error) => {
          if (onError) {
            onError(error);
          }
        },
      },
    );
  };

  return (
    <Box>
      <MetadataView
        metadata={metadata}
        onChange={(_: Record<string, unknown>, keyDeleted) => {
          if (keyDeleted !== undefined) {
            removeField(keyDeleted);
          }
        }}
      />
      <MetadataForm
        metadata={metadata}
        autocompleteType={AutocompleteType.ActionMetadataKeys}
        onAddMetadata={addField}
      />
    </Box>
  );
};
