import ShareIcon from "@mui/icons-material/Share";
import * as React from "react";

import { useAuth } from "@/providers";
import { DomainServicesContext } from "@/providers/DomainServices";
import { SharedWorkspaceLinkModal, RobotoButton } from "@/shared/components";
import { WorkspaceRecord } from "@/shared/domain/workspaces";
import { usePopupState } from "@/shared/hooks";
import { LoggerService } from "@/shared/services";
import { useVizState } from "@/shared/state/visualization";

interface ShareWorkspaceProps {
  fileId: string;
}

export function ShareWorkspace({ fileId }: ShareWorkspaceProps) {
  const { currentOrganization } = useAuth();
  const { workspaceService } = React.useContext(DomainServicesContext);
  const state = useVizState();

  const popup = usePopupState();
  const [workspaceRecord, setWorkspaceRecord] =
    React.useState<WorkspaceRecord | null>(null);

  const [error, setError] = React.useState<Error | null>(null);

  const createWorkspace = () => {
    const orgId = currentOrganization?.org_id || "";

    workspaceService
      .createWorkspace(state, orgId)
      .then((record) => {
        setWorkspaceRecord(record);
        setError(null);
      })
      .catch((e: Error) => {
        LoggerService.error(e);
        setError(e);
        setWorkspaceRecord(null);
      })
      .finally(popup.open);
  };

  return (
    <>
      <SharedWorkspaceLinkModal
        open={popup.isOpen}
        record={workspaceRecord}
        handleClose={() => {
          setWorkspaceRecord(null);
          popup.close();
        }}
        error={error}
      />
      <RobotoButton
        eventName={"ShareFileWorkspaceClicked"}
        eventProperties={{ fileId }}
        variant="outlined"
        onClick={createWorkspace}
        startIcon={<ShareIcon />}
      >
        Share
      </RobotoButton>
    </>
  );
}
