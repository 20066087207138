import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";

import { EventScope } from "@/shared/components/EventScope";
import { RobotoTypography } from "@/shared/components/RobotoTypography";
import { EventRecord } from "@/shared/domain/events";

interface EventPopoverProps {
  event: EventRecord;
  anchorPosition: { x: number; y: number };
}

export const EventPopover = (props: EventPopoverProps) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        position: "fixed",
        top: props.anchorPosition.y,
        left: props.anchorPosition.x,
        padding: "8px",
        borderRadius: "4px",
        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
        background: theme.palette.background.paper,
        zIndex: 1000,
      }}
    >
      <RobotoTypography variant="subtitle2">
        {props.event.name}
      </RobotoTypography>
      <div>
        <RobotoTypography variant="body2">
          {props.event.event_id}
        </RobotoTypography>
        <EventScope event={props.event} />
      </div>
    </Box>
  );
};
