import { brewer } from "chroma-js";

/**
 * Cycles through a set of distinct colors, maintaining separate sequences for each panel.
 * Ensures consistent color assignment across panels for the same index.
 */
export default class CyclingColorScale {
  private colors: string[];
  private panelToIndexMap = new Map<string, number>();

  // https://colorbrewer2.org/#type=qualitative&scheme=Set1&n=9
  constructor(colors: string[] = brewer.Set1) {
    if (colors.length < 1) {
      throw new Error(
        "CyclingColorScale requires a non-empty list of colors to initialize",
      );
    }
    this.colors = colors;
  }

  public getNextColorForPanel(panelId: string) {
    const index = this.panelToIndexMap.get(panelId) ?? 0;
    const color = this.colors[index];
    const nextIndex = (index + 1) % this.colors.length;
    this.panelToIndexMap.set(panelId, nextIndex);
    return color;
  }

  public reset() {
    this.panelToIndexMap.clear();
  }

  public resetForPanel(panelId: string) {
    this.panelToIndexMap.delete(panelId);
  }
}

export const cyclingColorScale = new CyclingColorScale();
