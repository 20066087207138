import { type MessageChunk, MessageChunks } from "@/shared/mcap";
import type {
  ImagePanelState,
  ImagePanelClip,
  TopicData,
} from "@/shared/state/visualization";
import { type MessagePayload, WorkerManager } from "@/shared/webworker";
import { compareOptionalBigints } from "@/utils/comparators";

import { Timer } from "../../timer";

import { CascadingAbortController } from "./CascadingAbortController";
import type { ImageMessage } from "./ImageLoader.worker";
import {
  ImageCommand,
  ImageCommandResponse,
  ImageEvent,
  SOURCE,
  isErrorEvent,
  type ImageCommandPayloadMap,
  type ImageCommandResponsePayloadMap,
  type ImageEventPayloadMap,
} from "./messaging";

export type OnErrorCallback = (
  error: ImageEventPayloadMap[ImageEvent.Error],
) => void;
export type OnLoadingCallback = (
  arg0: ImageEventPayloadMap[ImageEvent.LoadingStateChange],
) => void;

const noop = () => {};

const BUFFER_AHEAD_DURATION_NS = BigInt(10 * 1e9); // 10 seconds
const BUFFER_MAINTENANCE_INTERVAL_MS = 2_000; // 2 seconds

const isAbortError = (err: unknown): err is DOMException =>
  err instanceof DOMException && err.name === "AbortError";

export class Renderer {
  #bufferAheadDurationNs: bigint;
  #bufferMaintenanceIntervalMs: number;
  // AbortController received from the ImagePanel component, aborted on unmount
  // or when this Renderer instance is disposed.
  #componentAbortController: CascadingAbortController;
  #config?: ImagePanelState["config"];
  #data?: ImagePanelState["data"];
  #isDisposed = false;
  #height?: number;
  #messageChunks?: MessageChunks<ImageBitmap>;
  #onErrorCallback: OnErrorCallback = noop;
  #onLoadingCallback: OnLoadingCallback = noop;
  // AbortController that is set and reset internally to this Renderer instance.
  // An "operation" would be any async operation that is started and stopped within this Renderer,
  // such as buffering data.
  #perOperationAbortController: AbortController;
  #renderingContext: CanvasRenderingContext2D;
  #scheduledBufferDataTimeoutId?: number | undefined;
  #timer?: Timer;
  #width?: number;
  #workerManager: WorkerManager;

  constructor({
    abortController,
    renderingContext,
    bufferAheadDurationNs,
    bufferMaintenanceIntervalMs,
  }: {
    abortController: AbortController;
    renderingContext: CanvasRenderingContext2D;
    bufferAheadDurationNs?: bigint;
    bufferMaintenanceIntervalMs?: number;
  }) {
    this.#bufferAheadDurationNs =
      bufferAheadDurationNs ?? BUFFER_AHEAD_DURATION_NS;
    this.#bufferMaintenanceIntervalMs =
      bufferMaintenanceIntervalMs ?? BUFFER_MAINTENANCE_INTERVAL_MS;
    this.#componentAbortController = new CascadingAbortController(
      abortController,
    );
    this.#perOperationAbortController = new AbortController();
    this.#componentAbortController.add(this.#perOperationAbortController);

    this.#renderingContext = renderingContext;
    const worker = new Worker(
      new URL("./ImageLoader.worker", import.meta.url),
      {
        type: "module",
      },
    );
    this.#workerManager = new WorkerManager({
      worker,
      onError: this.#onError,
      onMessage: this.#onMessage,
      signal: this.#componentAbortController.signal,
    });
  }

  private get width(): number {
    if (this.#width === undefined) {
      this.#width = this.#renderingContext.canvas.width;
    }
    return this.#width;
  }

  private get height(): number {
    if (this.#height === undefined) {
      this.#height = this.#renderingContext.canvas.height;
    }
    return this.#height;
  }

  public dispose() {
    this.#isDisposed = true;

    // Stop any pending attempt to buffer data
    this.#clearScheduledBufferData();
    this.#perOperationAbortController.abort();

    // Clear reference to timer
    this.#timer = undefined;

    // Free any loaded data
    this.#messageChunks?.dispose();
    this.#messageChunks = undefined;

    // Free worker
    this.#workerManager.dispose();
  }

  public render(currentTime = this.#timer?.currentTime): void {
    const messageChunks = this.#messageChunks;
    if (messageChunks === undefined || currentTime === undefined) {
      this.#clearCanvas();
      return;
    }

    if (currentTime < messageChunks.minTime) {
      // The timeline is shared among all panels, so it's possible that the current time
      // is before the first message in this topic.
      // In that case, there's nothing to render.
      this.#clearCanvas();

      // Ensure that if the spinner was showing (and a hold placed on the timer), it is removed.
      // This may happen if the user seeks quickly beyond the bounds of the data.
      this.#onLoadingCallback({ isLoading: false });
      return;
    }

    const currentChunk = messageChunks.getChunkAsOfTime(currentTime);
    if (currentChunk === undefined) {
      // This should only happen if there are no chunks (yet) loaded.
      // MessageChunks::getChunkAsOfTime makes an effort to return the chunk that contains
      // the message closest in time (at or before) to the current time.
      // Note, though, that there won't always be a chunk that contains a message for the current time:
      // the timer runs (or attempts to run) at 60hz, so if the data was published at a lower frequency,
      // it's likely we'll hit a timepoint that lies between message chunks.
      // In this case, the desired behavior is to show the last image that was published.
      return;
    }

    if (!currentChunk.isLoaded()) {
      if (currentChunk.requiresLoad()) {
        this.#clearScheduledBufferData();
        this.#manageBuffer().catch((err) => {
          if (isAbortError(err)) {
            return;
          }
          const error = new Error("Failed to buffer data", {
            cause: err,
          });
          this.#onError(error);
        });
      }

      this.#onLoadingCallback({ isLoading: true });
      currentChunk
        .waitUntilLoaded()
        .then((loaded) => {
          if (currentChunk.aborted || !loaded) {
            return;
          }
          this.render(currentTime);
        })
        .catch((err) => {
          if (isAbortError(err)) {
            return;
          }
          const error = new Error("Failed to buffer data", {
            cause: err,
          });
          this.#onError(error);
        })
        .finally(() => {
          if (currentChunk.aborted) {
            return;
          }
          this.#onLoadingCallback({ isLoading: false });
        });
      return;
    }

    const imageMessage = currentChunk.getMessageAsOfTime(currentTime);
    if (imageMessage === undefined) {
      // This should only happen if there are no messages in the chunk.
      // In this case, the desired behavior is to show the last image that was published.
      return;
    }
    this.#drawImage(imageMessage);
  }

  public resize({ width, height }: { width: number; height: number }) {
    const scale = window.devicePixelRatio;
    const scaledWidth = Math.floor(width * scale);
    const scaledHeight = Math.floor(height * scale);
    this.#width = scaledWidth;
    this.#height = scaledHeight;

    this.#renderingContext.canvas.width = scaledWidth;
    this.#renderingContext.canvas.height = scaledHeight;

    // Redraw image
    this.render();
  }

  public setConfig(config: ImagePanelState["config"]): void {
    if (this.#isDisposed) {
      return;
    }

    this.#config = config;

    this.render();
  }

  public async setData(
    data: ImagePanelState["data"],
    signal: AbortSignal,
  ): Promise<void> {
    if (this.#isDisposed) {
      return;
    }

    this.#data = data;

    // Initialize renderer with new topic
    // Side-effect:
    // - stop any active operations that might be buffering data
    this.#resetAbortController();
    this.#clearScheduledBufferData();

    // - clear any previously loaded data
    this.#messageChunks = undefined;

    // - ready the ImageLoader in the web worker to load data from the new representation
    // - redraw the canvas
    await this.#init(signal);
  }

  public setEventListener<K extends keyof ImageEventPayloadMap>(
    type: K,
    listener: (arg0: ImageEventPayloadMap[K]) => void,
  ): void {
    switch (type) {
      case ImageEvent.LoadingStateChange:
        this.#onLoadingCallback = listener as OnLoadingCallback;
        break;
      case ImageEvent.Error:
        this.#onErrorCallback = listener as OnErrorCallback;
        break;
      default:
        throw new Error(`Unknown event type: ${type}`);
    }
  }

  public setTimer(timer: Timer, abortController: AbortController): void {
    if (this.#isDisposed) {
      return;
    }

    this.#componentAbortController.add(abortController);
    this.#timer = timer;
    timer.addListener(
      "start",
      () => {
        if (this.#canPlay()) {
          this.render();
          return;
        }

        // Stop any prior operations that might be buffering data
        this.#resetAbortController();
        // Cancel any scheduled buffer data operation
        this.#clearScheduledBufferData();
        // Re-fill buffer ahead of current time
        this.#waitForBufferThenRender().catch(this.#onError);
      },
      { signal: abortController.signal },
    );
    timer.addListener(
      "tick",
      () => {
        this.render();
      },
      { signal: abortController.signal },
    );
    timer.addListener(
      "seek",
      () => {
        if (this.#canPlay()) {
          this.render();
          return;
        }

        // Stop any prior operations that might be buffering data
        this.#resetAbortController();
        // Cancel any scheduled buffer data operation
        this.#clearScheduledBufferData();
        // Re-fill buffer ahead of current time
        this.#waitForBufferThenRender().catch(this.#onError);
      },
      { signal: abortController.signal },
    );
  }

  #canPlay = (currentTime = this.#timer?.currentTime): boolean => {
    if (currentTime === undefined) {
      return false;
    }
    const requiredChunks = this.#getChunksRequiredToPlay(currentTime);
    return requiredChunks.every((chunk) => chunk.isLoaded());
  };

  #clearCanvas = () => {
    const canvas = this.#renderingContext.canvas;

    // Clear any existing transforms so that the whole rect is cleared.
    this.#renderingContext.resetTransform();

    // Clear whatever was on the canvas
    this.#renderingContext.clearRect(0, 0, canvas.width, canvas.height);

    // Draw solid background that will frame scaled image in case the canvas width/height
    // doesn't match aspect ratio of image
    // this.#renderingContext.fillStyle = "#f9f9f9"; // lightgray
    this.#renderingContext.fillRect(0, 0, canvas.width, canvas.height);
  };

  #clearScheduledBufferData = () => {
    clearTimeout(this.#scheduledBufferDataTimeoutId);
    this.#scheduledBufferDataTimeoutId = undefined;
  };

  #drawImage = (imageMessage: ImageMessage) => {
    const { rotation = 0, stretchToFitCanvas } = this.#config ?? {};

    this.#clearCanvas();

    const imageBitmap = imageMessage.data;

    const isVerticallyRotated = rotation === 90 || rotation === 270;
    const horizontalLength = isVerticallyRotated
      ? imageBitmap.height
      : imageBitmap.width;
    const verticalLength = isVerticallyRotated
      ? imageBitmap.width
      : imageBitmap.height;

    // Smallest of ratios between canvas dimension / image dimension
    // Gives scale at which image will fit within canvas while preserving aspect ratio
    const scale = Math.min(
      this.width / horizontalLength,
      this.height / verticalLength,
      stretchToFitCanvas ? Infinity : 1,
    );
    const scaledWidth = horizontalLength * scale;
    const scaledHeight = verticalLength * scale;

    // Translation
    const canvasCenterX = this.width / 2;
    const canvasCenterY = this.height / 2;
    const imageCenterX = scaledWidth / 2;
    const imageCenterY = scaledHeight / 2;
    const dX =
      canvasCenterX - (isVerticallyRotated ? imageCenterY : imageCenterX);
    const dY =
      canvasCenterY - (isVerticallyRotated ? imageCenterX : imageCenterY);

    // Rotation
    // Canvas is stateful, so, to avoid each draw operation incrementing the rotation
    // reset its transform to the identity matrix
    this.#renderingContext.resetTransform();
    // To rotate about the images center, need to first move the matrix's origin to the image's center
    this.#renderingContext.translate(canvasCenterX, canvasCenterY);
    // Apply rotation
    this.#renderingContext.rotate(-(rotation * Math.PI) / 180);
    // Return translation back to start
    this.#renderingContext.translate(-canvasCenterX, -canvasCenterY);

    // Draw
    this.#renderingContext.drawImage(
      imageBitmap,
      0,
      0,
      imageBitmap.width,
      imageBitmap.height,
      dX,
      dY,
      isVerticallyRotated ? scaledHeight : scaledWidth,
      isVerticallyRotated ? scaledWidth : scaledHeight,
    );
  };

  #getChunksRequiredToPlay = (
    currentTimeNs: bigint,
  ): MessageChunk<ImageBitmap>[] => {
    const messageChunks = this.#messageChunks;
    if (messageChunks === undefined) {
      return [];
    }

    // If the current topic shares the workspace with another that starts before it,
    // begin buffering as soon as the playhead + bufferDuration is greater than the first message.
    const start =
      currentTimeNs < messageChunks.minTime
        ? messageChunks.minTime
        : currentTimeNs;
    const required = messageChunks.getChunksInRange({
      start,
      end: currentTimeNs + this.#bufferAheadDurationNs,
    });

    if (
      // The current time may be after the last chunk.
      // In this case:
      // - `getChunkAsOfTime` will return the last chunk,
      // - while `getChunksInRange` will return an empty array.
      required.length === 0 ||
      // The current time may be between two chunks.
      // In this case:
      // - `getChunkAsOfTime` will return the chunk that ends closest to (but before!) the current time,
      // - while `getChunksInRange` will only return chunks that start after the current time.
      // The first chunk in the returned array should be the one that contains the current time.
      (required.length > 0 && required[0].start > currentTimeNs)
    ) {
      const chunkForTime = messageChunks.getChunkAsOfTime(currentTimeNs);
      if (chunkForTime !== undefined) {
        required.unshift(chunkForTime);
      }
    }

    return required;
  };

  #init = async (signal: AbortSignal) => {
    if (!this.#data || this.#data.length === 0) {
      this.#clearCanvas();
      return;
    }

    const sortedClips = this.#data.sort((a, b) =>
      compareOptionalBigints(
        a.data.topic.startTime ? BigInt(a.data.topic.startTime) : null,
        b.data.topic.startTime ? BigInt(b.data.topic.startTime) : null,
      ),
    );

    for (let idx = 0; idx < sortedClips.length - 1; idx++) {
      const firstClip = sortedClips[idx];
      const secondClip = sortedClips[idx + 1];

      if (
        firstClip.data.topic.endTime === undefined ||
        secondClip.data.topic.startTime === undefined
      ) {
        this.#onError(new Error("Clips must have a start and end time"));
        return;
      }

      if (firstClip.data.topic.endTime >= secondClip.data.topic.startTime) {
        this.#onError(new Error("Overlapping clips are not supported"));
        return;
      }
    }

    const fileIds = sortedClips.map(
      (clip: ImagePanelClip) =>
        clip.data.representation.association.association_id,
    );

    try {
      this.#onLoadingCallback({ isLoading: true });
      const response = await this.#workerManager.sendCommandAwaitResponse<
        ImageCommand,
        ImageCommandPayloadMap[ImageCommand.Init],
        ImageCommandResponsePayloadMap[ImageCommandResponse.Initialized]
      >({
        data: { fileIds },
        source: SOURCE,
        signal,
        type: ImageCommand.Init,
      });
      this.#messageChunks = new MessageChunks(response.chunkIndices);
      await this.#waitForBufferThenRender({
        callerAbortSignal: signal,
      });
    } catch (err) {
      if (signal.aborted || isAbortError(err)) {
        return;
      }
      const error = new Error("Failed to initialize the image panel", {
        cause: err,
      });
      this.#onError(error);
    } finally {
      this.#onLoadingCallback({ isLoading: false });
    }
  };

  /**
   * Send command to worker to load image data for a chunk.
   */
  #loadChunk = async (
    chunk: MessageChunk<ImageBitmap>,
    messagePath: TopicData["messagePath"],
    callerAbortSignal: AbortSignal,
  ) => {
    if (callerAbortSignal.aborted) {
      return;
    }

    // A MessageChunk has its own AbortController to allow for specific abort (e.g., when seeking)...
    const abortController = new AbortController();
    // ...but it receives abort events from the ImagePanel's AbortController,
    // to abort all active operations in the case the Panel itself is disposed.
    this.#componentAbortController.add(abortController);

    chunk.beginLoading(abortController);
    const imageFormat = messagePath.metadata?.format;
    try {
      const response = await this.#workerManager.sendCommandAwaitResponse<
        ImageCommand,
        ImageCommandPayloadMap[ImageCommand.LoadImages],
        ImageCommandResponsePayloadMap[ImageCommandResponse.ImagesLoaded]
      >({
        type: ImageCommand.LoadImages,
        data: {
          startTimeNs: chunk.start,
          endTimeNs: chunk.end,
          format: typeof imageFormat === "string" ? imageFormat : "jpeg",
          messagePath: messagePath.parts,
        },
        signal: abortController.signal,
        source: SOURCE,
      });
      chunk.messagesLoaded(response.messages);
    } catch (err) {
      if (!callerAbortSignal.aborted && !isAbortError(err)) {
        chunk.loadingDidError();
      }
    }

    this.#componentAbortController.remove(abortController);
  };

  /**
   * Buffer data ahead of current time and release data outside of buffer window.
   *
   * This is called in three circumstances:
   *  1. when the panel is first initialized;
   *  2. when the image render loop requires an image from a chunk that is not yet loading (e.g., when seeking);
   *  3. after a timeout, scheduled at the beginning of this method.
   *     It is scheduled to run such that it the buffer is full by the time render loop requires an image.
   *
   * N.b.: this uses the cache of image data stored here, in the UI thread, to determine which chunks to load.
   *       While data is loaded, decompressed, and decoded in a worker, it is not stored in the worker--
   *       it is transferred here, to the UI thread.
   */
  #manageBuffer = async (params?: {
    callerAbortSignal?: AbortSignal;
    renderOnceCurrentChunkLoaded?: boolean;
  }): Promise<void> => {
    const {
      callerAbortSignal = this.#perOperationAbortController.signal,
      renderOnceCurrentChunkLoaded = false,
    } = params ?? {};

    const messageChunks = this.#messageChunks;
    const timer = this.#timer;
    if (
      callerAbortSignal.aborted ||
      messageChunks === undefined ||
      timer === undefined
    ) {
      return;
    }

    // Schedule next run ahead of this one to stay on top of buffer management.
    // Schedule more frequently if playing, less frequently if paused.
    const sleepTimeMs = timer.active
      ? this.#bufferMaintenanceIntervalMs
      : this.#bufferMaintenanceIntervalMs * 2;
    this.#clearScheduledBufferData();
    this.#scheduledBufferDataTimeoutId = window.setTimeout(() => {
      this.#manageBuffer().catch(this.#onError);
    }, sleepTimeMs);

    const currentTime = timer.currentTime;

    // Fill buffer ahead of current time
    const msgpathQualifiedChunksToLoad = this.#getChunksRequiredToPlay(
      currentTime,
    ).filter((chunk) => chunk.requiresLoad());

    // Load the first couple chunks in the buffer window...
    const initialChunksOffset = 2;
    const initial = msgpathQualifiedChunksToLoad.slice(0, initialChunksOffset);

    await Promise.allSettled(
      initial.map(async (chunk) => {
        const messagePath = this.#messagePathForChunk(chunk);
        if (messagePath === undefined) {
          throw new Error(
            `MessagePath not found for chunk starting at ${chunk.start}`,
          );
        }

        await this.#loadChunk(chunk, messagePath, callerAbortSignal);
        if (
          renderOnceCurrentChunkLoaded &&
          !callerAbortSignal.aborted &&
          chunk.isLoaded() &&
          chunk.containsTime(currentTime)
        ) {
          this.render();
        }
      }),
    );

    if (callerAbortSignal.aborted) {
      return;
    }

    // ...then the rest
    const remaining = msgpathQualifiedChunksToLoad.slice(initialChunksOffset);

    await Promise.allSettled(
      remaining.map(async (chunk) => {
        const messagePath = this.#messagePathForChunk(chunk);
        if (messagePath === undefined) {
          throw new Error(
            `MessagePath not found for chunk starting at ${chunk.start}`,
          );
        }

        await this.#loadChunk(chunk, messagePath, callerAbortSignal);
      }),
    );

    if (callerAbortSignal.aborted) {
      return;
    }

    // Release buffered data well-outside of buffer window.
    // This avoids accruing too much data in memory.
    const lastMessageTime = messageChunks.maxTime;
    const unloadUpTo = currentTime - this.#bufferAheadDurationNs * 2n;
    const unloadFrom = currentTime + this.#bufferAheadDurationNs * 2n;
    const chunksToUnload = [
      // Unload chunks significantly behind current time
      // (Would be the case if playing the video or seeking forward in time)
      messageChunks.getChunksInRange({
        end(_chunkStart, chunkEnd) {
          // Unload chunks that end before the unloadUpTo
          // but always keep the last chunk loaded, as it may still be needed for rendering
          // the last image in the topic in case the current time is after the last message.
          if (chunkEnd === lastMessageTime) {
            return false;
          }
          return chunkEnd <= unloadUpTo;
        },
      }) ?? [],
      // Unload chunks significantly ahead of current time
      // (Would be the case if seeking back in time)
      messageChunks.getChunksInRange({
        start(chunkStart) {
          // Unload chunks that start after unloadFrom
          return chunkStart >= unloadFrom;
        },
      }) ?? [],
    ]
      .flat()
      .filter((chunk) => chunk.isLoaded());

    chunksToUnload.forEach((chunk) => {
      chunk.unload();
    });
  };

  #messagePathForChunk = (
    chunk: MessageChunk<ImageBitmap>,
  ): TopicData["messagePath"] | undefined => {
    return this.#data?.find((clip) => {
      if (
        clip.data.topic.startTime === undefined ||
        clip.data.topic.endTime === undefined
      ) {
        return false;
      }

      // MessageChunks are expected to be wholly subsumed by one topic extracted from one file.
      return (
        chunk.start >= BigInt(clip.data.topic.startTime) &&
        chunk.end <= BigInt(clip.data.topic.endTime)
      );
    })?.data.messagePath;
  };

  #onMessage = (event: MessageEvent<MessagePayload>) => {
    if (isErrorEvent(event) && event.data.error !== undefined) {
      this.#onErrorCallback(event.data.error);
      return;
    }
  };

  #onError = (error: Error) => {
    this.#onErrorCallback(error);
  };

  #resetAbortController = () => {
    // Side-effect, removes this controller from the "componentAbortController"
    this.#perOperationAbortController.abort();

    this.#perOperationAbortController = new AbortController();
    this.#componentAbortController.add(this.#perOperationAbortController);
  };

  #waitForBufferThenRender = async (params?: {
    callerAbortSignal?: AbortSignal;
  }): Promise<void> => {
    const { callerAbortSignal = this.#perOperationAbortController.signal } =
      params ?? {};

    const messageChunks = this.#messageChunks;
    const timer = this.#timer;
    if (
      callerAbortSignal.aborted ||
      messageChunks === undefined ||
      timer === undefined
    ) {
      return;
    }

    this.#onLoadingCallback({ isLoading: true });

    const chunksToLoad = this.#getChunksRequiredToPlay(timer.currentTime);
    const activelyLoadingChunks = messageChunks.getLoadingChunks();

    // Abort any loading chunks that are no longer needed.
    // This is likely to happen while seeking.
    for (const chunk of activelyLoadingChunks) {
      if (!chunksToLoad.includes(chunk)) {
        chunk.abort();
      }
    }

    try {
      await this.#manageBuffer({
        callerAbortSignal,
        renderOnceCurrentChunkLoaded: true,
      });
    } catch (err) {
      if (!isAbortError(err)) {
        const error = new Error("Failed to buffer data", {
          cause: err,
        });
        this.#onError(error);
      }
    }

    if (callerAbortSignal.aborted) {
      return;
    }

    try {
      await Promise.allSettled(
        chunksToLoad.map((chunk) => chunk.waitUntilLoaded()),
      );
    } catch (err) {
      if (!isAbortError(err)) {
        const error = new Error("Failed to buffer data", {
          cause: err,
        });
        this.#onError(error);
      }
    }

    if (callerAbortSignal.aborted) {
      return;
    }

    this.#onLoadingCallback({ isLoading: false });
    this.render();
  };
}
