import * as React from "react";

import { RobotoMarkdown } from "@/shared/components/RobotoMarkdown";

interface EventDescriptionProps {
  description: string | undefined;
  readonly?: boolean;
}

export const EventDescription: React.FC<EventDescriptionProps> = ({
  description,
  readonly,
}) => {
  if (description) {
    return <RobotoMarkdown content={description} />;
  }

  if (readonly) {
    return <>None</>;
  }

  return <i style={{ color: "#888" }}>Click to add a description</i>;
};
