import AutoAwesomeOutlinedIcon from "@mui/icons-material/AutoAwesomeOutlined";
import DatasetOutlinedIcon from "@mui/icons-material/DatasetOutlined";
import ExploreOutlinedIcon from "@mui/icons-material/ExploreOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import SignalCellularAltRoundedIcon from "@mui/icons-material/SignalCellularAltRounded";
import StorefrontOutlinedIcon from "@mui/icons-material/StorefrontOutlined";
import { Drawer, Hidden, Link as MUILink } from "@mui/material";
import { useState } from "react";
import * as React from "react";

import { useAuth } from "@/providers";
import { PageRoute } from "@/types";

import { LinkButton } from "./LinkButton";
import { NavItem, NavItems } from "./NavItem";
import { RobotoSidebar } from "./RobotoSidebar";
import { RobotoTopBar } from "./RobotoTopBar/RobotoTopBar";
import { Alignment, NavItemData, RobotoNavigationProps, Title } from "./types";

export const RobotoNavigationComponent: React.FC<RobotoNavigationProps> = ({
  sideBarWidth,
  topBarHeight,
}) => {
  const [open, setOpen] = useState(false);
  const { signOut } = useAuth();

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const sideBarItems: NavItemData[] = [
    {
      title: Title.Home,
      link: PageRoute.Home,
      alignment: Alignment.Left,
      Icon: SignalCellularAltRoundedIcon,
    },
    {
      title: Title.Search,
      link: PageRoute.Search,
      alignment: Alignment.Left,
      Icon: ExploreOutlinedIcon,
    },
    {
      title: Title.Actions,
      link: PageRoute.Actions,
      alignment: Alignment.Left,
      Icon: AutoAwesomeOutlinedIcon,
    },
    {
      title: Title.ActionHub,
      link: PageRoute.ActionHub,
      alignment: Alignment.Left,
      Icon: StorefrontOutlinedIcon,
    },
    {
      title: Title.Collections,
      link: PageRoute.Collections,
      alignment: Alignment.Left,
      Icon: DatasetOutlinedIcon,
    },
  ];

  const onClick = async () => {
    await signOut();
  };

  return (
    <>
      <RobotoTopBar
        topBarHeight={topBarHeight}
        handleDrawerToggle={handleDrawerToggle}
      />

      <RobotoSidebar
        sideBarWidth={sideBarWidth}
        topBarHeight={topBarHeight}
        sideBarItems={sideBarItems}
      />

      <Hidden smUp implementation="css">
        <Drawer
          variant="temporary"
          anchor="right"
          open={open}
          onClose={handleDrawerToggle}
        >
          <NavItems>
            {sideBarItems.map((item) => {
              return (
                <NavItem
                  key={item.title}
                  {...item}
                  alignment={Alignment.Left}
                />
              );
            })}
            <MUILink
              onClick={() => {
                void onClick();
              }}
              style={{ textDecoration: "none" }}
            >
              <LinkButton
                title={Title.Logout}
                active={false}
                Icon={LogoutOutlinedIcon}
              />
            </MUILink>
          </NavItems>
        </Drawer>
      </Hidden>
    </>
  );
};
