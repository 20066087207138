import {
  Alert,
  CircularProgress,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
} from "@mui/material";
import * as React from "react";

import { AssociationRecord } from "@/shared/domain/association";
import { useFile } from "@/shared/domain/files/hooks/useFile";
import { useTopicById } from "@/shared/domain/topics/hooks/useTopic";
import { actions, useVizDispatch } from "@/shared/state/visualization";
import { basename } from "@/utils";

import { RobotoLinkHoverUnderline } from "../RobotoLinkHoverUnderline";
import { RobotoTypography } from "../RobotoTypography";
import { constructTree } from "../visualization/TopicTree";

import { Cell } from "./Cell";
import styles from "./Cell.module.css";
import { MessagePathRow } from "./MessagePathRow";

interface MessagePathDetailProps {
  topicId: string;
  topicAssociation?: AssociationRecord;
  messagePathIds?: string[];
  showTitle?: boolean;
}

/**
 * A component used as a sub-table to list message paths accessible from other resources, such as file metadata or topics.
 */
export function MessagePathsDetail({
  topicId,
  topicAssociation,
  messagePathIds,
  showTitle = true,
}: MessagePathDetailProps) {
  const theme = useTheme();
  const vizDispatch = useVizDispatch();

  // Get the complete topic record incl. message paths
  const topicRecordQuery = useTopicById(topicId);
  const topicTree = React.useMemo(() => {
    if (topicRecordQuery.data === undefined) {
      return null;
    }
    return constructTree([topicRecordQuery.data])[0];
  }, [topicRecordQuery.data]);

  // Get the complete file record incl. filename
  const association = topicAssociation ?? topicRecordQuery.data?.association;
  const fileRecordQuery = useFile(association?.association_id);

  if (topicRecordQuery.isLoading || fileRecordQuery.isLoading) {
    return <CircularProgress size="1.5rem" />;
  } else if (topicRecordQuery.error) {
    return (
      <Alert severity="error">Failed to fetch message paths for topic</Alert>
    );
  } else if (topicTree && association && fileRecordQuery.data) {
    const rows = [];
    let nodes = [...topicTree.children];
    while (nodes.length) {
      const node = nodes.shift();
      if (node !== undefined) {
        const shouldInclude = messagePathIds
          ? messagePathIds.includes(node.data.message_path_id)
          : true; // Include if filter is not set

        if (shouldInclude) {
          rows.push(
            <MessagePathRow
              key={node.data.message_path_id}
              messagePathNode={node}
              topicAssociation={association}
            />,
          );
        }

        nodes = [...node.children, ...nodes];
      }
    }
    return (
      <>
        <RobotoTypography
          variant="body2"
          marginBottom={theme.spacing(2)}
          color={"rgb(107, 114, 128)"}
          display={showTitle ? "block" : "none"}
        >
          <span
            style={{
              fontWeight: 500,
            }}
          >
            {topicTree.data.topic_name}
          </span>{" "}
          in{" "}
          <RobotoLinkHoverUnderline
            onClick={(e) => {
              e.stopPropagation();
              vizDispatch(
                actions.putFiles([{ fileId: fileRecordQuery.data.file_id }]),
              );
            }}
            to={`/files/${fileRecordQuery.data.file_id}`}
          >
            {basename(fileRecordQuery.data.relative_path)}
          </RobotoLinkHoverUnderline>
        </RobotoTypography>
        <TableContainer>
          <Table size="small" sx={{ width: "auto" }}>
            <TableHead>
              <TableRow>
                <Cell className={styles.header}>Message Paths</Cell>
                <Cell className={styles.header}>Type</Cell>
                <Cell className={styles.header}>Min</Cell>
                <Cell className={styles.header}>Max</Cell>
                <Cell className={styles.header}>Mean</Cell>
                <Cell className={styles.header}>Median</Cell>
                <Cell className={styles.header}>True</Cell>
                <Cell className={styles.header}>False</Cell>
              </TableRow>
            </TableHead>
            <TableBody>{rows}</TableBody>
          </Table>
        </TableContainer>
      </>
    );
  }
}
