import { ManageTopics } from "@/shared/components/visualization/PanelSettings/ImagePanelSettings/ManageTopics.tsx";
import { type ImagePanelState } from "@/shared/state/visualization";

import { SettingsSection } from "../SettingsSection";

import { RotateImage } from "./RotateImage";
import { StretchImageToFit } from "./StretchImageToFit";

interface ImagePanelSettingsProps {
  state: ImagePanelState;
}

export function ImagePanelSettings({ state }: ImagePanelSettingsProps) {
  return (
    <div>
      <SettingsSection title="Image transforms">
        <StretchImageToFit
          panelId={state.id}
          stretchImageToFit={state.config?.stretchToFitCanvas ?? false}
        />
        <RotateImage
          panelId={state.id}
          rotation={state.config?.rotation ?? 0}
        />
      </SettingsSection>
      <SettingsSection title="Clips">
        <ManageTopics panelId={state.id} clips={state.data ?? []} />
      </SettingsSection>
    </div>
  );
}
