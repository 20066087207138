import classNames from "classnames";
import * as React from "react";

import { useVizState } from "@/shared/state/visualization";
import { selectPanelById } from "@/shared/state/visualization/hooks";
import {
  LayoutItem,
  LayoutType,
  isLayoutItem,
  type Layout,
} from "@/shared/state/visualization/schema/v1";

import { DropZone, DropZones } from "../DnD";
import { Panel } from "../Panel";
import { PanelResizeHandle } from "../resize-handles";

import styles from "./Layout.module.css";

interface NestedLayoutProps {
  layout: Layout;
}

interface LayoutProps {
  layout: Layout | LayoutItem;
}

export function Layout(props: LayoutProps) {
  const { layout } = props;
  const state = useVizState();

  if (isLayoutItem(layout)) {
    if (layout.type === LayoutType.Panel) {
      const panelState = selectPanelById(state, layout.id);

      if (panelState === undefined) {
        // Should never happen. A corresponding panel should exist in state
        return null;
      }

      return (
        <div
          className={styles.layoutContainer}
          style={{ flexBasis: `${layout.relativeSize * 100}%` }}
        >
          <Panel layout={layout} panelState={panelState} />
          <DropZones<LayoutItem>
            idPrefix={layout.id}
            dropZone={DropZone.Layout}
            data={layout}
          />
        </div>
      );
    }

    // We don't support anything other than panels
    return null;
  }

  return (
    <div
      className={styles.layoutContainer}
      style={{ flexBasis: `${layout.relativeSize * 100}%` }}
    >
      <NestedLayout layout={layout} />
      <DropZones<Layout>
        idPrefix={layout.id}
        dropZone={DropZone.Layout}
        axis={layout.axis}
        data={layout}
      />
    </div>
  );
}

/** TODO Opportunity to merge Layout and NestedLayout implementation */
function NestedLayout({ layout }: NestedLayoutProps) {
  const parentRef = React.useRef<HTMLDivElement>(null);

  const layouts = React.useMemo(() => {
    const layouts = [];

    if (layout.children.length > 0) {
      const firstChild = layout.children[0];
      layouts.push(<Layout key={firstChild.id} layout={firstChild} />);
    }

    for (let i = 1; i < layout.children.length; i++) {
      const prevLayout = layout.children[i - 1];
      const currentLayout = layout.children[i];

      layouts.push(
        <PanelResizeHandle
          key={prevLayout.id + currentLayout.id}
          axis={layout.axis}
          containerRef={parentRef}
          layout1={prevLayout}
          layout2={currentLayout}
        />,
      );

      layouts.push(<Layout key={currentLayout.id} layout={currentLayout} />);
    }

    return layouts;
  }, [layout.axis, layout.children]);

  return (
    <div
      ref={parentRef}
      className={classNames(styles.layout, {
        [styles.flexCol]: layout.axis === "y",
      })}
    >
      {layouts}
    </div>
  );
}
