import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  Paper,
  Typography,
  useTheme,
} from "@mui/material";

interface CancelUploadDialogProps {
  isCancelUploadInProgress: boolean;
  open: boolean;
  onCancelUpload: () => Promise<void>;
  onContinueUpload: () => void;
}

export const CancelUploadDialog: React.FC<CancelUploadDialogProps> = ({
  isCancelUploadInProgress,
  open,
  onCancelUpload,
  onContinueUpload,
}) => {
  const theme = useTheme();

  return (
    <Dialog open={open}>
      <Paper sx={{ padding: theme.spacing(2) }}>
        <DialogTitle variant="h6" sx={{ paddingLeft: 0 }}>
          Cancel upload?
        </DialogTitle>
        <Typography>
          Your upload is not complete. Would you like to cancel the upload?
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: theme.spacing(2),
          }}
        >
          {isCancelUploadInProgress ? (
            <Box
              sx={{
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                marginBottom: theme.spacing(2),
              }}
            >
              <CircularProgress size={"1.5rem"} />
            </Box>
          ) : (
            <>
              <Button
                variant="contained"
                disableElevation
                style={{ textTransform: "none" }}
                sx={{ marginRight: theme.spacing(2) }}
                onClick={() => {
                  onContinueUpload();
                }}
              >
                Continue Upload
              </Button>
              <Button
                disableElevation
                style={{ textTransform: "none" }}
                onClick={() => {
                  void onCancelUpload();
                }}
              >
                Cancel Upload
              </Button>
            </>
          )}
        </Box>
      </Paper>
    </Dialog>
  );
};
