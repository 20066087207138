import { Search as SearchIcon } from "@mui/icons-material";
import DatasetOutlinedIcon from "@mui/icons-material/DatasetOutlined";
import PhotoOutlinedIcon from "@mui/icons-material/PhotoOutlined";
import UploadIcon from "@mui/icons-material/Upload";
import VideoCameraBackOutlinedIcon from "@mui/icons-material/VideoCameraBackOutlined";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  Box,
  InputAdornment,
  Popover,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  ToggleButtonProps,
  TooltipProps,
  useTheme,
} from "@mui/material";
import * as React from "react";

import { useAuth } from "@/providers";
import {
  RobotoButton,
  RobotoTooltip,
  SelectionButton,
} from "@/shared/components";
import { AnnotationsMenu } from "@/shared/components/Annotations";
import { AnnotationViewFilters } from "@/shared/components/Annotations/types";
import { AddToCollection } from "@/shared/components/collections";
import { FileNode, FileSystemNode, isFile } from "@/types";
import { imageFileTypes, videoFileTypes } from "@/utils";

import { DatasetFileTypeSelector } from "./DatasetFileTypeSelector";

function TooltipToggleButton(props: ToggleButtonProps & TooltipProps) {
  const { title, ...other } = props;
  return (
    <RobotoTooltip title={title || ""}>
      <ToggleButton {...other} />
    </RobotoTooltip>
  );
}

interface DatasetOperationBarProps {
  datasetId: string;
  extensions: string[];
  display: string;
  fileTypes: string[];
  activeToggle: string;
  handleDeleteItems: (items: FileSystemNode[]) => void;
  handleDeselectAll: () => void;
  handleViewToggle: (
    display: string,
    fileTypes: string[],
    activeToggle: string,
  ) => void;
  handleFileTypeSelection: (fileTypes: string[]) => void;
  handleFileSearch: (searchTerm: string) => void;
  handleShowHiddenFilesToggle: () => void;
  showHiddenFiles: boolean;
  annotationFiles?: FileNode[];
  annotationFile?: FileNode;
  annotationDataLoading?: boolean;
  annotationDataError?: Error;
  onAnnotationFileSelected: (file: FileNode | undefined) => void;
  annotationViewFilters: AnnotationViewFilters;
  onViewFiltersChanged: (filters: AnnotationViewFilters) => void;
  uploadButtonPressed: () => void;
  selectedItems: FileSystemNode[];
}

export const DatasetOperationBar: React.FC<DatasetOperationBarProps> = ({
  datasetId,
  extensions,
  fileTypes,
  display,
  handleDeleteItems,
  handleDeselectAll,
  handleViewToggle,
  handleFileTypeSelection,
  handleFileSearch,
  handleShowHiddenFilesToggle,
  activeToggle,
  showHiddenFiles,
  annotationFiles,
  annotationFile,
  annotationDataLoading,
  annotationDataError,
  onAnnotationFileSelected,
  annotationViewFilters,
  onViewFiltersChanged,
  uploadButtonPressed,
  selectedItems,
}) => {
  const theme = useTheme();
  const { currentOrganization } = useAuth();

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handleCollectionPopoverOpen = (
    event: React.MouseEvent<HTMLElement>,
    overrideAnchorEl?: HTMLElement | null,
  ) => {
    if (overrideAnchorEl) {
      setAnchorEl(overrideAnchorEl);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleCollectionPopoverClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box sx={{ display: "flex", gap: theme.spacing(1) }}>
        {selectedItems.length > 0 && (
          <SelectionButton
            datasetId={datasetId}
            selectedItems={selectedItems}
            handleDeselectAll={handleDeselectAll}
            currentOrganization={currentOrganization}
            handleCollectionPopoverOpen={handleCollectionPopoverOpen}
            handleDeleteItems={() => {
              handleDeleteItems(selectedItems);
            }}
          />
        )}
        <RobotoTooltip title={"Upload files"}>
          <span>
            <RobotoButton
              variant={"outlined"}
              size="small"
              startIcon={<UploadIcon />}
              eventName={"UploadFilesClicked"}
              eventProperties={{
                datasetId,
              }}
              sx={{
                height: "32px",
              }}
              onClick={() => {
                uploadButtonPressed();
              }}
            >
              Upload
            </RobotoButton>
          </span>
        </RobotoTooltip>

        <Box
          sx={{
            gap: theme.spacing(1),
            display: {
              xs: "none",
              sm: "flex",
            },
          }}
        >
          <RobotoTooltip
            title={
              selectedItems.length > 0
                ? "Add files to collection"
                : "Add dataset to collection"
            }
          >
            <span>
              <RobotoButton
                variant={"outlined"}
                size="small"
                startIcon={<DatasetOutlinedIcon />}
                eventName={"AddToCollectionClicked"}
                sx={{
                  height: "32px",
                }}
                onClick={handleCollectionPopoverOpen}
              >
                Add
              </RobotoButton>
            </span>
          </RobotoTooltip>
        </Box>

        <TextField
          id="outlined-basic"
          placeholder="Search files in folder"
          variant="outlined"
          size="small"
          onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.key.toLowerCase().includes("enter")) {
              handleFileSearch((e.target as HTMLInputElement).value);
            }
          }}
          inputProps={{
            autoComplete: "off",
            fontSize: "0.8rem",
          }}
          InputProps={{
            id: "search-bar",
            sx: {
              pl: 0,
              pr: 1,
              fontSize: "0.875rem",
              height: "32px",
              minWidth: "50px",
            },
            startAdornment: (
              <InputAdornment
                position={"start"}
                sx={{
                  ml: theme.spacing(1),
                  mr: theme.spacing(1),
                }}
              >
                <SearchIcon fontSize="small" color="disabled" />
              </InputAdornment>
            ),
          }}
        />

        <ToggleButtonGroup
          exclusive
          aria-label="text alignment"
          size="small"
          sx={{
            height: "32px",
          }}
          value={activeToggle}
          onChange={(_, newValue) => {
            if (newValue === "images") {
              handleViewToggle(
                "grid",
                imageFileTypes.filter((item) => extensions.includes(item)),
                "images",
              );
            } else if (newValue === "videos") {
              handleViewToggle(
                "grid",
                videoFileTypes.filter((item) => extensions.includes(item)),
                "videos",
              );
            } else {
              handleViewToggle("table", [], "all");
            }
          }}
        >
          <TooltipToggleButton
            title="Image Grid"
            value="images"
            aria-label="images"
            color="primary"
            sx={{
              display: {
                xs: "none",
                md: "flex",
              },
            }}
          >
            <PhotoOutlinedIcon fontSize="small" />
          </TooltipToggleButton>
          <TooltipToggleButton
            title="Video Grid"
            value="videos"
            aria-label="video"
            color="primary"
            sx={{
              display: {
                xs: "none",
                md: "flex",
              },
            }}
          >
            <VideoCameraBackOutlinedIcon fontSize="small" />
          </TooltipToggleButton>
        </ToggleButtonGroup>

        <Box
          sx={{
            display: {
              xs: "none",
              lg: "block",
            },
          }}
        >
          <DatasetFileTypeSelector
            extensions={extensions}
            types={fileTypes}
            handleFileTypeSelection={handleFileTypeSelection}
          />
        </Box>

        <Box
          sx={{
            display: {
              xs: "none",
              lg: "block",
            },
          }}
        >
          <TooltipToggleButton
            title={showHiddenFiles ? "Hide dot files" : "Show hidden files"}
            value="check"
            selected={showHiddenFiles}
            color="primary"
            onChange={() => {
              handleShowHiddenFilesToggle();
            }}
            size="small"
            sx={{
              height: "32px",
              display: {
                xs: "none",
                md: "flex",
              },
            }}
          >
            <VisibilityIcon fontSize="small" />
          </TooltipToggleButton>
        </Box>

        {display === "grid" && (
          <>
            <AnnotationsMenu
              annotationFiles={annotationFiles}
              annotationFile={annotationFile}
              annotationDataLoading={annotationDataLoading}
              annotationDataError={annotationDataError}
              annotationViewFilters={annotationViewFilters}
              onViewFiltersChanged={onViewFiltersChanged}
              onAnnotationFileSelected={onAnnotationFileSelected}
              inline={true}
            />
          </>
        )}
      </Box>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleCollectionPopoverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: -8,
          horizontal: "left",
        }}
        marginThreshold={5}
      >
        <AddToCollection
          selectedDatasets={new Set([datasetId])}
          selectedFiles={selectedItems.filter((item) => isFile(item))}
          close={handleCollectionPopoverClose}
        />
      </Popover>
    </>
  );
};
