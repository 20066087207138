import { Alert, useTheme } from "@mui/material";
import * as React from "react";

import { AutocompleteType } from "@/shared/components/TagAndMetadataAutocomplete";
import { useFileRecordTags } from "@/shared/domain/files/hooks/useFileRecordTags";
import { useMutateFileTags } from "@/shared/domain/files/hooks/useMutateFileTags";

import { TagInput, Tags, TagsGroup } from "../tags";

import { TagsSkeleton } from "./TagsSkeleton";

interface UpdateFileTagsProps {
  fileId: string;
  readonly?: boolean;
}

export const UpdateFileTags: React.FC<UpdateFileTagsProps> = ({
  fileId,
  readonly = false,
}) => {
  const theme = useTheme();
  const fileTagsQuery = useFileRecordTags(fileId);
  const fileTagsMutation = useMutateFileTags();

  const tagAdded = (tag: string) => {
    fileTagsMutation.mutate({
      fileId,
      putTags: [tag],
    });
  };

  const tagRemoved = (tag: string) => {
    fileTagsMutation.mutate({
      fileId,
      removeTags: [tag],
    });
  };

  if (fileTagsQuery.isLoading) {
    return <TagsSkeleton />;
  }

  return (
    <>
      {readonly && fileTagsQuery.data?.length === 0 && <>None</>}
      <TagsGroup style={{ marginTop: theme.spacing(1) }}>
        <Tags
          tags={fileTagsQuery.data || []}
          onDeleteTag={readonly ? undefined : tagRemoved}
        />
        {readonly === false && (
          <TagInput
            autocompleteType={AutocompleteType.FileTags}
            onAddTag={tagAdded}
            tags={fileTagsQuery.data || []}
            size="small"
            isLoading={fileTagsMutation.isPending}
          />
        )}
      </TagsGroup>

      {fileTagsQuery.isError && (
        <Alert severity="error" sx={{ marginTop: theme.spacing(1) }}>
          {fileTagsQuery.error.message}
        </Alert>
      )}

      {fileTagsMutation.isError && (
        <Alert
          onClose={() => fileTagsMutation.reset()}
          severity="error"
          sx={{ marginTop: theme.spacing(1) }}
        >
          {fileTagsMutation.error.message}
        </Alert>
      )}
    </>
  );
};
