import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useTheme } from "@mui/material";
import * as React from "react";

import { RobotoButton } from "@/shared/components";
import {
  FiltersBox,
  UISimpleCondition,
  FilterForm,
} from "@/shared/components/filters";
import { AutocompleteType } from "@/shared/components/TagAndMetadataAutocomplete";
import { useElementsForAutocompleteType } from "@/shared/components/TagAndMetadataAutocomplete/hook";
import { ConditionsOperator } from "@/types";

import { searchFields } from "./types";

interface TriggerConditionProps {
  conditions: UISimpleCondition[];
  setConditions?: (arg: UISimpleCondition[]) => void;
  operator: ConditionsOperator;
  setOperator?: (arg: ConditionsOperator) => void;
  editable: boolean;
}

export const TriggerCondition: React.FC<TriggerConditionProps> = ({
  conditions,
  setConditions,
  operator,
  setOperator,
  editable,
}) => {
  const theme = useTheme();

  const { elements: metadataKeys } = useElementsForAutocompleteType(
    AutocompleteType.DatasetMetadataKeys,
  );

  const [anchorEl, setAnchorEl] = React.useState<
    HTMLButtonElement | HTMLDivElement | null
  >(null);

  const [filterToUpdate, setFilterToUpdate] =
    React.useState<UISimpleCondition | null>(null);

  const handleAddFilterClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setFilterToUpdate(null);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setFilterToUpdate(null);
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      {conditions && conditions.length > 0 ? (
        <FiltersBox
          onGroupOperatorChange={(newOperator) => {
            if (setOperator) {
              setOperator(newOperator);
            }
          }}
          conditions={conditions}
          conditionsOperator={operator}
          onDeleteCondition={(condition) => {
            const newConditions = conditions.filter(
              (c) => c.id !== condition.id,
            );
            if (setConditions) {
              setConditions(newConditions);
            }
          }}
          onFilterClick={(event, filter) => {
            setFilterToUpdate(filter);
            setAnchorEl(event.currentTarget);
          }}
          editable={editable}
        />
      ) : null}

      {editable && (
        <RobotoButton
          size="small"
          variant="outlined"
          sx={{
            mt: theme.spacing(1),
          }}
          eventName={"AddFilterToTriggerClicked"}
          startIcon={<AddCircleOutlineIcon fontSize="small" />}
          onClick={(e) => handleAddFilterClick(e)}
        >
          Condition
        </RobotoButton>
      )}

      <FilterForm
        availableMetadataKeys={metadataKeys}
        open={open}
        searchFields={searchFields}
        filterToUpdate={filterToUpdate}
        anchorEl={anchorEl}
        handleClose={handleClose}
        onCreate={(condition) => {
          const newConditions = [...conditions, condition];
          if (setConditions) {
            setConditions(newConditions);
          }
        }}
        onUpdate={(filter) => {
          const newConditions = conditions.map((condition) => {
            if (condition.id === filter.id) {
              return filter;
            }
            return condition;
          });

          if (setConditions) {
            setConditions(newConditions);
          }
          setFilterToUpdate(null);
          setAnchorEl(null);
        }}
      />
    </>
  );
};
