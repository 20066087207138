import { ListItem, ListItemText } from "@mui/material";

import { InvocationStatus } from "@/shared/domain/actions";

import { createMechanicalTimeStr } from "./helpers";

interface InvocationRuntimeText {
  duration: string;
  lastStatus: InvocationStatus;
}

export function InvocationRuntimeText({
  duration,
  lastStatus,
}: InvocationRuntimeText) {
  const mechanicalTimeStr = createMechanicalTimeStr(duration);
  const showRuntime = lastStatus === InvocationStatus.Completed;
  return (
    <div>
      {showRuntime && (
        <ListItem>
          <ListItemText primary={"Duration"} secondary={mechanicalTimeStr} />
        </ListItem>
      )}
    </div>
  );
}
