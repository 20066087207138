import { Autocomplete, TextField, useTheme } from "@mui/material";
import * as React from "react";

import { OrgRecord } from "@/shared/domain/orgs";
import { usePaginatedAPICall } from "@/shared/services/apiHooks";
import { Dataset, RobotoAPICall, datasetsQueryEndpoint } from "@/types";

interface DatasetSelectorProps {
  datasetId: string | null;
  setDatasetId: (arg: string | null) => void;
  currentOrg: OrgRecord | null;
}

export const DatasetSelector: React.FC<DatasetSelectorProps> = ({
  datasetId,
  setDatasetId,
  currentOrg,
}) => {
  const theme = useTheme();
  const { getFirstPage, loading, pageData } = usePaginatedAPICall<Dataset>();

  React.useEffect(() => {
    const fetchData = async () => {
      if (!currentOrg?.org_id) {
        return;
      }

      const apiCall: RobotoAPICall = {
        endpoint: datasetsQueryEndpoint,
        method: "POST",
        requestBody: JSON.stringify({
          sort_direction: "DESC",
          sort_by: "created",
        }),
        orgId: currentOrg?.org_id,
      };

      await getFirstPage(apiCall, 10);
    };

    void fetchData();
  }, [currentOrg?.org_id, getFirstPage]);

  return (
    <Autocomplete
      disablePortal
      id="available-datasets"
      options={pageData}
      fullWidth
      freeSolo
      value={datasetId}
      size={"small"}
      loading={loading}
      groupBy={() => "Recent Datasets"}
      getOptionLabel={(option) => (option as Dataset).dataset_id ?? option}
      renderOption={(props, option: Dataset | string) => (
        <li style={{ display: "block" }} {...props}>
          <>
            <span style={{ display: "block" }}>
              {(option as Dataset).dataset_id}
              {(option as Dataset).name ? ` — ${(option as Dataset).name}` : ""}
            </span>
            <span
              style={{
                fontSize: "0.7rem",
                display: "block",
                color: theme.palette.text.secondary,
              }}
            >
              {(option as Dataset).created_by} on{" "}
              {new Date((option as Dataset).created || "").toLocaleDateString()}
            </span>
          </>
        </li>
      )}
      onChange={(_event, newValue: Dataset | string | null) => {
        if (typeof newValue === "object") {
          setDatasetId(newValue?.dataset_id ?? null);
        } else {
          setDatasetId(newValue);
        }
      }}
      renderInput={(params) => <TextField {...params} placeholder="Dataset" />}
      componentsProps={{
        paper: {
          sx: {
            fontSize: "0.875rem",
          },
        },
      }}
      sx={{ width: "100%", maxWidth: 400 }}
    />
  );
};
