import { Alert, Box, Skeleton, TextField, useTheme } from "@mui/material";
import * as React from "react";

import { useAuth } from "@/providers";
import { DatasetRecord } from "@/shared/domain/datasets";
import { useUpdateDataset } from "@/shared/domain/datasets/hooks";
import { APIServiceError } from "@/types";

interface EditableDatasetNameProps {
  datasetId: string;
  name: string | undefined;
  isLoading: boolean;
}

export const EditableDatasetName: React.FC<EditableDatasetNameProps> = ({
  datasetId,
  name,
  isLoading,
}) => {
  const theme = useTheme();
  const { currentOrganization } = useAuth();
  const [currentValue, setCurrentValue] = React.useState<string | undefined>(
    name,
  );
  const [editable, setEditable] = React.useState(false);
  const [error, setError] = React.useState<APIServiceError | null>(null);

  React.useEffect(() => {
    setCurrentValue(name);
  }, [name, datasetId]);

  const { mutate: updateDatasetMutation } = useUpdateDataset();

  const updateDataset = () => {
    updateDatasetMutation(
      {
        datasetId,
        resourceOwnerId: currentOrganization?.org_id || "",
        updates: {
          name: currentValue,
        },
      },
      {
        onError: (error: Error) => {
          setError(error);
        },
        onSuccess: (record: DatasetRecord) => {
          setCurrentValue(record.name);
        },
      },
    );

    setEditable(false);
  };

  if (isLoading) {
    return <Skeleton width="500px" sx={{ mt: theme.spacing(1) }} />;
  }

  return (
    <Box sx={{ mt: theme.spacing(1.75) }}>
      {error && (
        <Alert
          onClose={() => setError(null)}
          severity="error"
          sx={{ mb: theme.spacing(1), mt: theme.spacing(1) }}
        >
          {error.message}
        </Alert>
      )}
      {!editable ? (
        <Box
          component={Box}
          onClick={() => {
            if (editable) {
              void updateDataset();
            }
            setEditable(!editable);
          }}
        >
          {currentValue === undefined && <Skeleton width="400px" />}
          {currentValue !== undefined && (
            <Box
              sx={{
                maxWidth: "500px",
                ":hover": {
                  backgroundColor: theme.palette.hoverBackground.main,
                  borderRadius: "0.25rem",
                  cursor: "pointer",
                },
              }}
            >
              {currentValue || <i>Click to give this dataset a name.</i>}
            </Box>
          )}
        </Box>
      ) : (
        <TextField
          id="dataset-name"
          placeholder="Enter a name"
          size="small"
          fullWidth
          autoFocus
          value={currentValue}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setCurrentValue(event.target.value);
          }}
          inputProps={{
            maxLength: 120,
          }}
          onBlur={() => {
            void updateDataset();
            setEditable(!editable);
          }}
          sx={{
            maxWidth: "500px",
          }}
        />
      )}
    </Box>
  );
};
