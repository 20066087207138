import { Link } from "@mui/material";
import * as React from "react";

import { useNavigation } from "@/providers";
import {
  constructPanelForMessagePath,
  determineDefaultPanelType,
} from "@/shared/components/visualization/DndContainer/dropHandlers";
import { AssociationRecord } from "@/shared/domain/association";
import { actions, useVizDispatch } from "@/shared/state/visualization";

import { type MessagePathNode } from "../visualization/TopicTree";

interface MessagePathLinkProps {
  messagePathNode: MessagePathNode;
  topicAssociation: AssociationRecord;
  linkPrefix?: string;
  className?: string;
}

export const MessagePathLink: React.FC<MessagePathLinkProps> = ({
  messagePathNode,
  topicAssociation,
  linkPrefix,
  className,
}) => {
  const { goto } = useNavigation();
  const vizDispatch = useVizDispatch();

  const handleClick = () => {
    const panelType = determineDefaultPanelType(messagePathNode);

    goto.file({
      fileId: topicAssociation.association_id,
      beforeNavigation: () => {
        vizDispatch(actions.removeAllPanels());
        vizDispatch(
          actions.putFiles([{ fileId: topicAssociation.association_id }]),
        );
        constructPanelForMessagePath(vizDispatch, messagePathNode, panelType);
      },
    });
  };

  return (
    <Link
      onClick={handleClick}
      component="button"
      underline="hover"
      variant="body2"
      className={className}
      sx={{ userSelect: "text" }}
    >
      {linkPrefix}
      {messagePathNode.data.message_path}
    </Link>
  );
};
