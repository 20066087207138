export const compareOptionalBigints = (
  a: bigint | null | undefined,
  b: bigint | null | undefined,
) => {
  if (a === null || a === undefined) {
    if (b === null || b === undefined) {
      return 0;
    }
    return -1;
  }
  if (b === null || b === undefined) {
    return 1;
  }
  return Number(a - b);
};
