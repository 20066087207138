import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import * as React from "react";

import { useAuth } from "@/providers";
import { OrganizationType, OrgRecord } from "@/shared/domain/orgs";

import { RobotoButton } from "../../RobotoButton";

import { OrgListMenu } from "./OrgListMenu";

interface OrgListButtonProps {
  userEmail: string | null;
}

export const OrgListButton: React.FC<OrgListButtonProps> = ({ userEmail }) => {
  //
  const theme = useTheme();

  const {
    getCurrentOrganization,
    setCurrentOrganization: setCurrentOrgInAuthProvider,
    availableOrganizations,
  } = useAuth();

  const [orgAnchorEl, setOrgAnchorEl] =
    React.useState<HTMLButtonElement | null>(null);

  const [currentOrganization, setCurrentOrganization] =
    useState<OrgRecord | null>(getCurrentOrganization());

  useEffect(() => {
    const currentOrg = getCurrentOrganization();

    setCurrentOrganization(currentOrg);
  }, [getCurrentOrganization]);

  const getOrgToDisplay = () => {
    if (currentOrganization) {
      if (currentOrganization.org_type === OrganizationType.Individual) {
        return userEmail;
      } else {
        return currentOrganization.name;
      }
    }

    if (userEmail) {
      return userEmail;
    }

    return "Roboto AI";
  };

  const userHasMultipleOrgs =
    availableOrganizations?.length && availableOrganizations.length > 0;

  return (
    <>
      <RobotoButton
        eventName={"OrgListClicked"}
        variant={"contained"}
        sx={{
          padding: "2px 10px",
          backgroundColor: theme.palette.buttonHeader.main,
          "&:hover": {
            backgroundColor: theme.palette.buttonHeader.light,
          },
        }}
        endIcon={userHasMultipleOrgs ? <ArrowDropDownIcon /> : null}
        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
          if (userHasMultipleOrgs) {
            setOrgAnchorEl(e.currentTarget);
          }
        }}
      >
        {getOrgToDisplay()}
      </RobotoButton>

      <OrgListMenu
        anchorEl={orgAnchorEl}
        handleClose={() => setOrgAnchorEl(null)}
        availableOrganizations={availableOrganizations}
        currentOrganization={currentOrganization}
        orgClicked={(org) => {
          setCurrentOrganization(org);
          setCurrentOrgInAuthProvider(org);
          setOrgAnchorEl(null);

          // get ref to window href and refresh page
          const currentLocation = window.location.href;
          window.location.replace(currentLocation);
        }}
      />
    </>
  );
};
