import { Chip } from "@mui/material";

import { TriggerEvaluationStatus } from "@/shared/domain/actions";

import { statusToColor, statusToFormattedString } from "./tableColumns";

interface TriggerEvaluationStatusChipProps {
  status: TriggerEvaluationStatus;
}

export function TriggerEvaluationStatusChip({
  status,
}: TriggerEvaluationStatusChipProps) {
  return (
    <Chip
      color={statusToColor(status)}
      label={statusToFormattedString(status)}
      size="small"
      variant="outlined"
    />
  );
}
