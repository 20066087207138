import { SearchField } from "@/shared/components/filters";

export const searchFields = [
  {
    name: "tags",
    type: "tags",
  },
  {
    name: "metadata",
    type: "metadata",
  },
  {
    name: "created",
    type: "date",
  },
  {
    name: "created_by",
    type: "other",
  },
] as SearchField[];
