import {
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link,
  useTheme,
} from "@mui/material";
import * as React from "react";

import { WorkspaceRecord } from "@/shared/domain/workspaces/WorkspaceRecord";

import CopyToClipboardButton from "./CopyToClipboardButton";

interface SharedWorkspaceLinkModal {
  open: boolean;
  record: WorkspaceRecord | null;
  handleClose: () => void;
  error: Error | null;
}

export const SharedWorkspaceLinkModal: React.FC<SharedWorkspaceLinkModal> = ({
  open,
  record,
  handleClose,
  error,
}) => {
  const theme = useTheme();

  const workspaceId = record?.workspace_id || "";

  const url = new URL(window.location.href);

  const pathSegments = url.pathname
    .split("/")
    .filter((segment) => segment !== "");

  const lastSegment = pathSegments[pathSegments.length - 1];
  const isWorkspaceId = lastSegment.startsWith("ws_");

  if (isWorkspaceId) {
    pathSegments[pathSegments.length - 1] = workspaceId;
  } else {
    pathSegments.push(workspaceId);
  }

  url.pathname = pathSegments.join("/");

  const linkText = url.href;

  return (
    <Dialog open={open} fullWidth={true} maxWidth={"md"} onClose={handleClose}>
      <DialogTitle>Share Workspace</DialogTitle>
      <DialogContent>
        {error !== null ? (
          <DialogContentText id="alert-dialog-description">
            Failed to create shared workspace. Try again.
          </DialogContentText>
        ) : (
          <DialogContentText id="alert-dialog-description" component={"span"}>
            Copy the link below to share your current workspace:
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                fontSize: "0.95rem",
                gap: theme.spacing(1),
              }}
            >
              <Link
                href={linkText}
                sx={{
                  textDecoration: "none",
                  ":hover": {
                    textDecoration: "underline",
                  },
                }}
              >
                {linkText}
              </Link>
              <CopyToClipboardButton
                text={linkText}
                eventName={"CopyWorkspaceLinkClicked"}
                eventProps={{ link: linkText }}
              />
            </Box>
          </DialogContentText>
        )}
      </DialogContent>
    </Dialog>
  );
};
