import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

import { EventRecord } from "@/shared/domain/events";
import { useUpdateEvent } from "@/shared/domain/events/hooks";

import { RobotoTypography } from "../../RobotoTypography";
import { AutocompleteType } from "../../TagAndMetadataAutocomplete";
import { TagInput, Tags, TagsGroup } from "../../tags";
import { useEphemeralWorkspaceState, useWorkspaceEvent } from "../WorkspaceCtx";

import styles from "./EventTags.module.css";

interface EventTagsProps {
  eventId: EventRecord["event_id"];
  onError: (error: Error) => void;
}

export function EventTags({ eventId, onError }: EventTagsProps) {
  const eventQuery = useWorkspaceEvent(eventId);
  const updateTagsMutation = useUpdateEvent();
  const { updateEventInWorkspace } = useEphemeralWorkspaceState();

  const onAddTag = (tag: string) => {
    updateTagsMutation
      .mutateAsync({
        id: eventId,
        requestBody: {
          metadata_changeset: {
            put_tags: [tag],
          },
        },
      })
      .then((event: EventRecord) => {
        updateEventInWorkspace(event);
      })
      .catch(onError);
  };

  const onDeleteTag = (tag: string) => {
    updateTagsMutation
      .mutateAsync({
        id: eventId,
        requestBody: {
          metadata_changeset: {
            remove_tags: [tag],
          },
        },
      })
      .then((event: EventRecord) => {
        updateEventInWorkspace(event);
      })
      .catch(onError);
  };

  if (eventQuery.isLoading) {
    return (
      <section>
        <RobotoTypography component={"h3"} className={styles.title}>
          Tags
        </RobotoTypography>
        <TagsGroup>
          <Tags tags={[]} truncateText={false} />
          <TagInput
            autocompleteType={AutocompleteType.EventTags}
            onAddTag={onAddTag}
            tags={[]}
          />
        </TagsGroup>
      </section>
    );
  }

  if (eventQuery.isError) {
    return (
      <section>
        <RobotoTypography component={"h3"} className={styles.title}>
          Tags
        </RobotoTypography>
        <div className={styles.errorContainer}>
          <ErrorOutlineIcon color="error" />
          <RobotoTypography>Failed to load event tags</RobotoTypography>
        </div>
      </section>
    );
  }

  const { tags } = eventQuery.data;

  return (
    <section>
      <RobotoTypography component={"h3"} className={styles.title}>
        Tags
      </RobotoTypography>
      <TagsGroup>
        <Tags tags={tags} truncateText={false} onDeleteTag={onDeleteTag} />
        <TagInput
          autocompleteType={AutocompleteType.EventTags}
          onAddTag={onAddTag}
          tags={tags}
        />
      </TagsGroup>
    </section>
  );
}
