import { Alert, Box, Divider, useTheme } from "@mui/material";
import * as React from "react";

import { useAuth } from "@/providers";
import { NavigationPage, PageHeader } from "@/shared/components";

import { CollectionsView } from "./CollectionsView";

const dividerStyles = {
  margin: "1em 0",
};

export const Collections: React.FC = () => {
  const { currentOrganization } = useAuth();

  const theme = useTheme();

  return (
    <NavigationPage title={"Roboto - Collections"}>
      <PageHeader>Collections</PageHeader>

      <Divider sx={dividerStyles} />

      <Box marginTop={theme.spacing(3)} marginBottom={theme.spacing(3)}>
        <Box marginBottom={theme.spacing(3)}>
          <Alert severity="info">
            Collections are used to group datasets or files together. For
            example, you could create a collection of images for regression
            testing an algorithm.
          </Alert>
        </Box>
        <CollectionsView org={currentOrganization} />
      </Box>
    </NavigationPage>
  );
};
