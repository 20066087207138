import type { McapTypes } from "@mcap/core";

import type { MessagePathPart } from "@/shared/state/visualization";
import type { MessagePayload } from "@/shared/webworker";

import type { ImageMessage } from "../ImageLoader.worker";

export enum ImageCommand {
  Init = "Init",
  LoadImages = "LoadImages",
}

export interface ImageCommandPayloadMap {
  [ImageCommand.Init]: { fileIds: string[] };
  [ImageCommand.LoadImages]: {
    startTimeNs: bigint;
    endTimeNs: bigint;
    format: string;
    messagePath: MessagePathPart[];
  };
}

export enum ImageCommandResponse {
  ImagesLoaded = "ImagesLoaded",
  Initialized = "Initialized",
}

export interface ImageCommandResponsePayloadMap {
  [ImageCommandResponse.Initialized]: {
    chunkIndices: readonly McapTypes.ChunkIndex[];
  };
  [ImageCommandResponse.ImagesLoaded]: { messages: ImageMessage[] };
}

export function isInitCommand(
  event: MessageEvent<MessagePayload>,
): event is MessageEvent<
  MessagePayload<ImageCommand, ImageCommandPayloadMap[ImageCommand.Init]>
> {
  return event.data.type === ImageCommand.Init;
}

export function isLoadImagesCommand(
  event: MessageEvent<MessagePayload>,
): event is MessageEvent<
  MessagePayload<ImageCommand, ImageCommandPayloadMap[ImageCommand.LoadImages]>
> {
  return event.data.type === ImageCommand.LoadImages;
}
