import {
  Alert,
  Box,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  useTheme,
} from "@mui/material";
import * as React from "react";

import { useFileTopics } from "@/shared/domain/topics/hooks/useFileTopics";

import { FileMetadata } from "./FileMetadata";
import { MemoizedTopicsTable } from "./TopicsTable";
import { UpdateFileTags } from "./UpdateFileTags";

interface FileMetadataAndTopicsProps {
  fileId: string;
  fileVersion: number;
  // if set, lets the component know it should prepare for display
  // if not set, the component starts downloading content immediately upon render
  willBeDisplayed?: boolean;
  // an optional callback function that lets the parent know all data has been loaded from the Roboto API
  onDataReady?: () => void;
  readonly?: boolean;
}

export const FileMetadataAndTopics: React.FC<FileMetadataAndTopicsProps> = ({
  fileId,
  fileVersion,
  willBeDisplayed,
  onDataReady,
  readonly = false,
}) => {
  const queriesEnabled = willBeDisplayed === undefined ? true : willBeDisplayed;

  const theme = useTheme();

  const topicQuery = useFileTopics(fileId, fileVersion, {
    isEnabled: queriesEnabled,
  });

  React.useEffect(() => {
    if ((topicQuery.data !== undefined || topicQuery.isError) && onDataReady) {
      onDataReady();
    }
  }, [onDataReady, topicQuery.data, topicQuery.isError]);

  return (
    <Box sx={{ maxWidth: "80vw" }}>
      {topicQuery.isLoading && !onDataReady ? (
        <Box
          sx={{
            display: "flex",
            width: "100%",
            padding: theme.spacing(2),
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress size="1.5rem" />
        </Box>
      ) : (
        <>
          {topicQuery.isError ? (
            <Alert severity="error" sx={{ marginTop: theme.spacing(2) }}>
              Error fetching topics: {topicQuery.error.message}
            </Alert>
          ) : null}

          <List sx={{ width: "100%" }}>
            <ListItem>
              <ListItemText
                primary={"Tags"}
                primaryTypographyProps={{
                  variant: "subtitle2",
                }}
                secondaryTypographyProps={{
                  component: "span",
                }}
                secondary={
                  <UpdateFileTags fileId={fileId} readonly={readonly} />
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={"Metadata"}
                primaryTypographyProps={{
                  variant: "subtitle2",
                }}
                secondaryTypographyProps={{
                  component: "span",
                }}
                secondary={<FileMetadata fileId={fileId} readonly={readonly} />}
              />
            </ListItem>
            {topicQuery.data !== undefined && topicQuery.data.length > 0 && (
              <ListItem>
                <ListItemText
                  primary={"Topics"}
                  primaryTypographyProps={{
                    variant: "subtitle2",
                  }}
                  secondaryTypographyProps={{
                    component: "span",
                  }}
                  secondary={<MemoizedTopicsTable topics={topicQuery.data} />}
                />
              </ListItem>
            )}
          </List>
        </>
      )}
    </Box>
  );
};
