import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

import { InvocationLogRecord } from "@/shared/domain/actions";

interface InvocationLogRecordsProps {
  isLoading: boolean;
  logRecords: InvocationLogRecord[];
}

// https://css-tricks.com/books/greatest-css-tricks/pin-scrolling-to-bottom/
const overflowAnchorStyles = {
  height: "1px",
  overflowAnchor: "auto" as const,
};

const LogLine = styled("div")(() => ({
  display: "flex",
  gap: "0.5em",
  overflowAnchor: "none",
  padding: "0.25em",
  wordBreak: "break-all",
}));

const LogLineNumber = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[500],
  fontFamily: "monospace",
  fontSize: "0.75rem",
  userSelect: "none",
  whiteSpace: "nowrap",
}));

const LogMessage = styled(Typography)(() => ({
  fontFamily: "monospace",
  fontSize: "0.75rem",
  wordBreak: "break-all",
}));

export default function InvocationLogRecords({
  isLoading,
  logRecords,
}: InvocationLogRecordsProps) {
  if (!logRecords.length && !isLoading) {
    return (
      <Typography
        variant="body2"
        sx={{
          p: 0.5,
          fontFamily: "monospace",
          fontSize: "0.8rem",
          wordBreak: "break-all",
        }}
      >
        No logs found.
      </Typography>
    );
  }

  return (
    <Box>
      {logRecords.map((line, index) => {
        return (
          <LogLine key={line.timestamp}>
            <LogLineNumber variant="body2">{index + 1}</LogLineNumber>
            <LogMessage variant="body2">{line.log}</LogMessage>
          </LogLine>
        );
      })}
      <Box key="overflow-anchor" sx={overflowAnchorStyles}></Box>
    </Box>
  );
}
