import StyleOutlinedIcon from "@mui/icons-material/StyleOutlined";
import { MenuItem } from "@mui/material";

import { useAnalytics } from "@/providers";

interface ViewTagsAndMetadataProps {
  onClick: () => void;
}

export function ViewTagsAndMetadata({ onClick }: ViewTagsAndMetadataProps) {
  const { trackEvent } = useAnalytics();

  return (
    <MenuItem
      onClick={function openTagsAndMetadata() {
        trackEvent("FileDetailsClicked");
        onClick();
      }}
    >
      <StyleOutlinedIcon fontSize="small" />
      View file details
    </MenuItem>
  );
}
