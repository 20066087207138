import { useParams } from "react-router-dom";

import { Invocation } from "@/Actions/Invocation";
import { NavigationPage } from "@/shared/components";

const InvocationDetailPage: React.FC = () => {
  const { invocationId } = useParams();
  return (
    <NavigationPage title={`Roboto - Invocation - ${invocationId}`}>
      <Invocation invocationId={invocationId} />
    </NavigationPage>
  );
};

export default InvocationDetailPage;
