import * as React from "react";

import { useFiles } from "@/shared/state/visualization";

import { FileSelector, SelectableFile } from "../FileSelector";
import { TopicTree } from "../TopicTree";

import styles from "./FileAndTopicList.module.css";
import { Header } from "./Header";

export function FileAndTopicList() {
  const files = useFiles();
  const [selectedFile, setSelectedFile] = React.useState<
    SelectableFile | undefined
  >(() => {
    if (files.length === 1) {
      return files[0];
    }
    return undefined;
  });

  React.useEffect(() => {
    setSelectedFile((prev) => {
      const currentSelectionInFiles = files.some(
        (file) => file.fileId === prev?.fileId,
      );

      if (
        // If there is no currently selected file,
        // or the currently selected file is no longer in the list of available files...
        (prev === undefined || !currentSelectionInFiles) &&
        // ...and there is only one file available
        files.length === 1
      ) {
        // ...then select that one file
        return files[0];
      }

      // If the currently selected file is no longer in the list of available files
      // and there is more than one file available (or no files available)...
      if (!currentSelectionInFiles) {
        // ...then clear the selection
        return undefined;
      }

      // Otherwise, keep the current selection
      return prev;
    });
  }, [files]);

  const onFileSelected = React.useCallback((file: SelectableFile) => {
    setSelectedFile(file);
  }, []);

  const clearFileSelection = React.useCallback(() => {
    setSelectedFile(undefined);
  }, []);

  return (
    <>
      <FileSelector
        className={[
          styles.fileSelector,
          {
            [styles.hidden]: selectedFile !== undefined,
          },
        ]}
        files={files}
        onFileSelected={onFileSelected}
      />
      <TopicTree
        className={[
          styles.topicTree,
          {
            [styles.hidden]: selectedFile === undefined,
          },
        ]}
        file={selectedFile}
        renderHeader={() => {
          if (files.length < 2) {
            return null;
          } else {
            return (
              <Header className={styles.header} onBack={clearFileSelection} />
            );
          }
        }}
      />
    </>
  );
}
