import { Box, ListItem, ListItemText, useTheme } from "@mui/material";
import * as React from "react";

import { useAuth } from "@/providers";
import { EditFieldButton } from "@/shared/components";
import { ActionRecord, ActionReference } from "@/shared/domain/actions";
import { TriggerRecord } from "@/shared/domain/actions/TriggerRecord";
import { useLazyAPICall } from "@/shared/services/apiHooks";
import { APIResponse, RobotoAPICall, triggerEndpoint } from "@/types";

import { isActionReference } from "../../model";
import { ActionLink, ActionSelector } from "../action";

interface EditableActionSelectorProps {
  title: string;
  trigger: TriggerRecord;
  setErrorText: (arg: string) => void;
  onUpdate: (arg: APIResponse<TriggerRecord>) => void;
}

export const EditableActionSelector: React.FC<EditableActionSelectorProps> = ({
  title,
  trigger,
  setErrorText,
  onUpdate,
}) => {
  const theme = useTheme();

  const [showEditableIcon, setShowEditableIcon] = React.useState(false);
  const [editable, setEditable] = React.useState(false);
  const [selection, setSelection] = React.useState<
    ActionRecord | ActionReference | null
  >(trigger.action);
  const { currentOrganization } = useAuth();

  const { initiateRequest } = useLazyAPICall<APIResponse<TriggerRecord>>();

  const update = async () => {
    const updateCall: RobotoAPICall = {
      endpoint: triggerEndpoint,
      orgId: currentOrganization?.org_id,
      pathParams: { name: trigger.name },
      method: "PUT",
      // Add action_digest when/if we want to enable pinning triggers to specific action digests
      // For now, associate triggers with "latest"
      requestBody: JSON.stringify({
        action_name: selection?.name,
        action_owner_id: isActionReference(selection)
          ? selection.owner
          : selection?.org_id,
        // Wipe parameter values if the action on a trigger gets changed
        parameter_values: {},
      }),
    };

    const { data, error } = await initiateRequest(updateCall);

    if (error) {
      setErrorText(error.message);
      return false;
    }

    if (data) {
      onUpdate(data);
    }

    setErrorText("");

    return true;
  };

  let content: JSX.Element | string = "None";
  if (editable) {
    content = (
      <Box sx={{ mt: theme.spacing(1) }}>
        <ActionSelector
          action={selection}
          setAction={setSelection}
          currentOrg={currentOrganization}
        />
      </Box>
    );
  } else if (selection) {
    content = <ActionLink action={trigger.action} />;
  }

  const onClick = async () => {
    if (editable) {
      const res = await update();
      if (res) {
        setEditable(false);
      }
    }
    setEditable(!editable);
  };

  return (
    <ListItem
      onMouseOver={() => {
        setShowEditableIcon(true);
      }}
      onMouseLeave={() => {
        setShowEditableIcon(false);
      }}
    >
      <ListItemText
        primaryTypographyProps={{
          component: "span",
        }}
        sx={{
          mt: theme.spacing(0.5),
        }}
        primary={
          <>
            <span>{title}</span>
            <EditFieldButton
              editable={editable}
              showEditableIcon={showEditableIcon}
              onClick={() => {
                void onClick();
              }}
            />
          </>
        }
        secondaryTypographyProps={{
          component: "span",
        }}
        secondary={content}
      />
    </ListItem>
  );
};
