import { Trigger } from "@/Actions/Trigger";
import { NavigationPage } from "@/shared/components";

const TriggerDetailPage: React.FC = () => {
  return (
    <NavigationPage title={"Roboto - Trigger"}>
      <Trigger />
    </NavigationPage>
  );
};

export default TriggerDetailPage;
