import { Box, TextField, Typography, useTheme } from "@mui/material";
import * as React from "react";

import { useAuth } from "@/providers";
import { EditFieldButton } from "@/shared/components";
import { RobotoMarkdown } from "@/shared/components/RobotoMarkdown";
import { CollectionRecord } from "@/shared/domain/collections";
import { useLazyAPICall } from "@/shared/services/apiHooks";
import { APIResponse } from "@/types";

import { collectionEndpoint } from "../api";

interface EditableCollectionMetadataProps {
  name: string | undefined;
  description: string | undefined;
  collectionId: string;
  setErrorText: (arg: string) => void;
  onUpdate?: (arg: APIResponse<CollectionRecord>) => void;
}

export const EditableCollectionMetadata: React.FC<
  EditableCollectionMetadataProps
> = ({ name, description, collectionId, setErrorText, onUpdate }) => {
  const theme = useTheme();
  const { currentOrganization } = useAuth();
  const [showEditableIcon, setShowEditableIcon] = React.useState(false);
  const [editable, setEditable] = React.useState(false);
  const [currentName, setCurrentName] = React.useState<string | undefined>(
    name,
  );
  const [currentDescription, setCurrentDescription] = React.useState<
    string | undefined
  >(description);
  const nameFieldRef = React.createRef<HTMLInputElement>();
  const descFieldRef = React.createRef<HTMLInputElement>();

  const { initiateRequest: updateCollectionReq } =
    useLazyAPICall<APIResponse<CollectionRecord>>();

  const updateCollection = async (
    updatedName: string | undefined,
    updatedDesc: string | undefined,
  ) => {
    // Update the Collection
    const { error, data } = await updateCollectionReq({
      method: "PUT",
      endpoint: collectionEndpoint,
      pathParams: { collectionId: collectionId },
      requestBody: JSON.stringify({
        name: updatedName,
        description: updatedDesc,
      }),
      orgId: currentOrganization?.org_id,
    });

    if (error) {
      setErrorText(error.message);
      return false;
    }

    if (data && onUpdate) {
      onUpdate(data);
    }

    setErrorText("");

    return true;
  };

  const onClick = async () => {
    if (editable) {
      const name = nameFieldRef.current?.value;
      const desc = descFieldRef.current?.value;
      const res = await updateCollection(name, desc);
      if (res) {
        // Update succeeded, change cached state
        setCurrentName(name);
        setCurrentDescription(desc);
        setEditable(false);
      }
    }
    setEditable(!editable);
  };

  return (
    <Box
      onMouseOver={() => {
        if (onUpdate) {
          setShowEditableIcon(true);
        }
      }}
      onMouseLeave={() => {
        setShowEditableIcon(false);
      }}
      width={"100%"}
    >
      <Box width={"100%"}>
        <Box width={"100%"}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: theme.spacing(1),
            }}
          >
            {editable === false ? (
              <Typography variant="h6">
                {currentName || <i>No Name</i>}
              </Typography>
            ) : (
              <TextField
                sx={{ mt: theme.spacing(1), mb: theme.spacing(1), width: 300 }}
                autoFocus
                size="small"
                defaultValue={currentName}
                variant="outlined"
                placeholder="Collection Name"
                inputProps={{
                  ref: nameFieldRef,
                  style: {
                    fontSize: "0.875rem",
                  },
                }}
              />
            )}

            <EditFieldButton
              editable={editable}
              showEditableIcon={showEditableIcon}
              onClick={() => {
                void onClick();
              }}
            />
          </Box>

          {editable === false ? (
            <Box
              sx={{
                mt: theme.spacing(1),
              }}
            >
              <Typography
                variant="body2"
                component={"span"}
                color={theme.palette.text.secondary}
              >
                {currentDescription ? (
                  <RobotoMarkdown content={currentDescription}></RobotoMarkdown>
                ) : (
                  <i>No Description</i>
                )}
              </Typography>
            </Box>
          ) : (
            <TextField
              sx={{ mt: theme.spacing(1), width: "100%" }}
              multiline
              minRows={2}
              maxRows={10}
              size="small"
              placeholder="Collection Description"
              defaultValue={currentDescription}
              variant="outlined"
              inputProps={{
                ref: descFieldRef,
                style: {
                  fontSize: "0.875rem",
                },
              }}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};
