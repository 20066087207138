import { useQuery } from "@tanstack/react-query";
import * as React from "react";

import { DomainServicesContext } from "@/providers/DomainServices";

export function useFileMetadata(
  fileId: string,
  options?: { isEnabled?: boolean },
) {
  const { files } = React.useContext(DomainServicesContext);
  const { isEnabled = true } = options || {};

  return useQuery<Record<string, string>, Error>({
    queryKey: ["fileRecordMetadata", fileId],
    queryFn: async ({ signal }) => {
      if (fileId === undefined) {
        return Promise.reject(new Error("Invalid fileId"));
      }

      const fileRecord = await files.getFileRecord(fileId, {
        abortSignal: signal,
      });

      return fileRecord.metadata;
    },
    enabled: isEnabled,
    staleTime: 60000,
  });
}
