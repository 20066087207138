import type { Dimensions } from "@/shared/hooks";

import type { Extents } from "../PlotRenderer";

import styles from "./Tooltip.module.css";

interface CursorIndicatorProps {
  chartExtents: Extents | null;
  containerDims: Dimensions;
  hidden?: boolean;
  mouseX: number | null;
}

/**
 * A vertical line that follows the cursor to indicate the current position on the plot.
 */
export function CursorIndicator(props: CursorIndicatorProps) {
  const { chartExtents, containerDims, hidden, mouseX } = props;
  if (chartExtents === null || mouseX === null || hidden === true) {
    return null;
  }

  const left = mouseX - containerDims.left;
  return (
    <div
      className={styles.cursorIndicator}
      style={{
        left: `${left}px`,
        height: `${chartExtents.pixel.height}px`,
        top: `${chartExtents.pixel.top}px`,
      }}
    ></div>
  );
}
