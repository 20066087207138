import InfoIcon from "@mui/icons-material/Info";
import {
  Box,
  IconButton,
  ListItem,
  ListItemText,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import * as React from "react";

import { EditFieldButton } from "@/shared/components";
import { ActionRecord } from "@/shared/domain/actions";
import { TriggerRecord } from "@/shared/domain/actions/TriggerRecord";
import { OrgRecord } from "@/shared/domain/orgs";
import { useLazyAPICall } from "@/shared/services/apiHooks";
import { APIResponse, RobotoAPICall, RobotoEndpoint } from "@/types";

import {
  handleTimeoutOnChangeState,
  isActionTimeoutValid,
  parseTimeout,
} from "./actionUtils";

type RecordType = ActionRecord | TriggerRecord;
type UpdateCallback<T extends RecordType> = (arg: APIResponse<T>) => void;
interface EditableTimeoutProps<T extends RecordType> {
  title: string;
  field: string;
  value: number | undefined;
  endpoint: RobotoEndpoint;
  name: string;
  currentOrg: OrgRecord | null;
  setErrorText: (arg: string | null) => void;
  renderMarkdown?: boolean;
  disablePadding?: boolean;
  onHelperClick?: () => void;
  onUpdate?: UpdateCallback<T>;
  maxLength?: number;
}

const HELP_TEXT =
  "Action timeout, if defined, must be a number between 1 and 720 minutes.";

function isNotNil<T>(val: T): val is NonNullable<T> {
  return val !== undefined && val !== null;
}

export function EditableTimeout<T extends RecordType>({
  title,
  field,
  value,
  endpoint,
  name,
  currentOrg,
  setErrorText,
  disablePadding,
  onHelperClick,
  onUpdate,
}: EditableTimeoutProps<T>) {
  const theme = useTheme();
  const [showEditableIcon, setShowEditableIcon] = React.useState(false);
  const [editable, setEditable] = React.useState(false);
  const [currentValue, setCurrentValue] = React.useState<
    number | string | undefined
  >(value);
  const { initiateRequest } = useLazyAPICall<APIResponse<T>>();

  const update = async () => {
    if (!isActionTimeoutValid(currentValue)) {
      throw new Error(HELP_TEXT);
    }

    const timeout = isNotNil(currentValue) ? parseTimeout(currentValue) : null;
    const requestBody: { [key: string]: number | null } = {
      [field]: timeout,
    };

    const request: RobotoAPICall = {
      endpoint: endpoint,
      orgId: currentOrg?.org_id,
      pathParams: { name },
      method: "PUT",
      requestBody: JSON.stringify(requestBody),
    };

    const { data, error } = await initiateRequest(request);

    if (error) {
      throw error;
    }

    if (data && onUpdate) {
      onUpdate(data);
    }
  };

  return (
    <ListItem
      onMouseOver={() => {
        if (onUpdate) {
          setShowEditableIcon(true);
        }
      }}
      onMouseLeave={() => {
        setShowEditableIcon(false);
      }}
      disablePadding={disablePadding}
    >
      <ListItemText
        primaryTypographyProps={{
          component: "span",
        }}
        sx={{
          mt: disablePadding ? 0 : theme.spacing(0.5),
        }}
        primary={
          <>
            <span>{title}</span>

            {onHelperClick && (
              <IconButton
                sx={{
                  p: "5px",
                  verticalAlign: "middle",
                  ml: theme.spacing(0.25),
                }}
                onClick={() => onHelperClick()}
              >
                <InfoIcon
                  color="primary"
                  sx={{
                    fontSize: "1.1rem",
                    cursor: "pointer",
                  }}
                />
              </IconButton>
            )}

            <EditFieldButton
              editable={editable}
              showEditableIcon={showEditableIcon}
              onClick={() => {
                if (editable) {
                  update()
                    .then(() => {
                      setEditable(false);
                      setErrorText(null);
                    })
                    .catch((err) => {
                      if (err instanceof Error) {
                        setErrorText(err.message);
                      } else {
                        setErrorText(String(err));
                      }
                    });
                } else {
                  setEditable(true);
                }
              }}
            />

            <Box>
              {editable === false ? (
                <Typography
                  component="span"
                  variant="body2"
                  style={{
                    whiteSpace: "pre-wrap",
                    color: theme.palette.text.secondary,
                    wordBreak: "break-word",
                  }}
                >
                  <>
                    {isNotNil(currentValue)
                      ? `${currentValue} minutes`
                      : "None"}
                  </>
                </Typography>
              ) : (
                <TextField
                  sx={{ mt: theme.spacing(1) }}
                  size="small"
                  variant="outlined"
                  defaultValue={currentValue}
                  helperText={
                    isActionTimeoutValid(currentValue) ? null : HELP_TEXT
                  }
                  error={!isActionTimeoutValid(currentValue)}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    handleTimeoutOnChangeState(event, setCurrentValue);
                  }}
                />
              )}
            </Box>
          </>
        }
      />
    </ListItem>
  );
}
