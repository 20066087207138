import * as React from "react";

import { Viewport } from "./viewport";

export type ViewportState = [Viewport, (viewport: Viewport) => void];

export const ViewportContext = React.createContext<ViewportState>([
  Viewport.default(0),
  () => {},
]);
