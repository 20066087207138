import { CreateTrigger } from "@/Actions/CreateTrigger";
import { NavigationPage } from "@/shared/components";

const TriggerCreationPage: React.FC = () => {
  return (
    <NavigationPage title={"Roboto - Create Trigger"}>
      <CreateTrigger />
    </NavigationPage>
  );
};

export default TriggerCreationPage;
