import { Box, ListItem, ListItemText } from "@mui/material";
import { useState, useEffect } from "react";

import { EditFieldButton } from "@/shared/components/EditFieldButton";
import { ActionParameter } from "@/shared/domain/actions";
import { OrgRecord } from "@/shared/domain/orgs";
import { useLazyAPICall } from "@/shared/services/apiHooks";
import { APIResponse, RobotoAPICall, RobotoEndpoint } from "@/types";

import { ActionParamsTable } from "./ActionParamsTable";
import { ClearFieldButton } from "./ClearFieldButton";

interface EditableActionParamsProps<T> {
  title: string;
  value: ActionParameter[];
  paramValues?: {
    [key: string]: unknown;
  };
  pathParams: Record<string, string | number> | undefined;
  endpoint: RobotoEndpoint;
  currentOrg: OrgRecord | null;
  setErrorText: (arg: string) => void;
  overrides?: boolean;
  onUpdate?: (arg: APIResponse<T>) => void;
}

export default function EditableActionParams<T>({
  title,
  value,
  paramValues,
  pathParams,
  endpoint,
  currentOrg,
  setErrorText,
  overrides,
  onUpdate,
}: EditableActionParamsProps<T>) {
  const [showEditableIcon, setShowEditableIcon] = useState(false);
  const [editable, setEditable] = useState(false);
  const [parameters, setParameters] = useState<ActionParameter[]>(value);
  const [removeParameters, setRemoveParameters] = useState<string[]>([]);
  const [parameterValues, setParameterValues] = useState<
    | {
        [key: string]: unknown;
      }
    | undefined
  >(paramValues);

  useEffect(() => {
    setParameters(value);
  }, [value]);

  useEffect(() => {
    setParameterValues(paramValues);
  }, [paramValues]);

  const { initiateRequest } = useLazyAPICall<APIResponse<T>>();

  const updateRecord = async () => {
    let requestBody = {} as BodyInit;

    if (paramValues) {
      // for trigger update, only send param values
      requestBody = JSON.stringify({
        parameter_values: parameterValues,
      });
    } else {
      // for action update, send full params
      requestBody = JSON.stringify({
        parameter_changeset: {
          put_parameters: parameters,
          remove_parameters: removeParameters,
        },
      });
    }

    const updateCall: RobotoAPICall = {
      endpoint: endpoint,
      orgId: currentOrg?.org_id,
      pathParams: pathParams,
      method: "PUT",
      requestBody: requestBody,
    };

    const { error, data } = await initiateRequest(updateCall);

    if (error) {
      setErrorText(error.message);
      return false;
    }

    if (data && onUpdate) {
      onUpdate(data);
    }

    setEditable(false);
    setErrorText("");

    return data;
  };

  return (
    <ListItem
      onMouseOver={() => {
        setShowEditableIcon(true);
      }}
      onMouseLeave={() => {
        setShowEditableIcon(false);
      }}
    >
      <ListItemText
        primaryTypographyProps={{
          component: "span",
        }}
        primary={
          <>
            <span>{title}</span>

            <EditFieldButton
              editable={editable}
              disabled={!editable && onUpdate === undefined}
              showEditableIcon={showEditableIcon}
              onClick={() => {
                if (editable) {
                  void updateRecord();
                }
                setEditable(!editable);
              }}
            />

            {overrides && (
              <ClearFieldButton
                disabled={!editable && onUpdate === undefined}
                showIcon={showEditableIcon}
                onClick={() => {
                  void updateRecord();
                }}
              />
            )}

            <Box>
              <>
                <Box>
                  <ActionParamsTable
                    editable={editable}
                    mode={parameterValues ? "values" : "parameters"}
                    params={parameters}
                    setParams={setParameters}
                    removeParams={removeParameters}
                    setRemoveParams={setRemoveParameters}
                    paramValues={parameterValues}
                    setParamValues={setParameterValues}
                  />
                </Box>
              </>
            </Box>
          </>
        }
      />
    </ListItem>
  );
}
