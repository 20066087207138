import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import * as React from "react";

import { actions, useVizDispatch } from "@/shared/state/visualization";

import { useEphemeralWorkspaceState } from "../../WorkspaceCtx";

interface RemovePanelProps {
  panelId: string;
}

/**
 * A button that removes a panel from the panel board.
 */
export function RemovePanel({ panelId }: RemovePanelProps) {
  const dispatch = useVizDispatch();
  const workspace = useEphemeralWorkspaceState();

  const onRemovePanel = React.useCallback(() => {
    workspace.clearPanelSelectionIfMatches(panelId);
    dispatch(actions.removePanel(panelId));
  }, [dispatch, panelId, workspace]);

  return (
    <IconButton
      aria-label="remove panel"
      onClick={onRemovePanel}
      size="small"
      title="Remove panel"
    >
      <CloseIcon fontSize="small" />
    </IconButton>
  );
}
