import { Box } from "@mui/material";

import { RobotoLink } from "@/shared/components";
import { InvocationRecord } from "@/shared/domain/actions";

interface InvocationDebugLinksProps {
  invocation: InvocationRecord | null;
}

export default function InvocationDebugLinks(props: InvocationDebugLinksProps) {
  if (!props.invocation) {
    return null;
  }

  const cloudwatchLogGroupName = `/roboto/executors/${props.invocation.org_id}/action_org#${props.invocation.provenance.action.org_id}_action_name#${props.invocation.provenance.action.name}`;
  const cloudwatchUrl = `https://us-west-2.console.aws.amazon.com/cloudwatch/home?region=us-west-2#logsV2:log-groups/log-group/${encodeURIComponent(cloudwatchLogGroupName)}`;

  return (
    <Box>
      <RobotoLink to={cloudwatchUrl} target={"_blank"}>
        Cloudwatch (Monitor + Fluentbit)
      </RobotoLink>
    </Box>
  );
}
