import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import { Skeleton } from "@mui/material";

import { RobotoTypography } from "@/shared/components";
import { useFile } from "@/shared/domain/files/hooks/useFile";

import styles from "./Scopes.module.css";

interface AssociatedFileProps {
  fileId: string;
}

export function AssociatedFile({ fileId }: AssociatedFileProps) {
  const fileQuery = useFile(fileId);
  if (fileQuery.isPending) {
    return <Skeleton variant="text" width="150px" />;
  }
  if (fileQuery.isError) {
    return (
      <span className={styles.container}>
        <ErrorOutlineIcon color="error" className={styles.icon} />
        <RobotoTypography component={"span"} className={styles.text}>
          Failed to load file
        </RobotoTypography>
      </span>
    );
  }
  return (
    <span className={styles.container}>
      <InsertDriveFileOutlinedIcon className={styles.icon} />
      <RobotoTypography
        component={"span"}
        className={styles.text}
        title={fileQuery.data?.relative_path}
      >
        {fileQuery.data?.relative_path}
      </RobotoTypography>
    </span>
  );
}
