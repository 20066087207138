import { Sensors, SensorsOff } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";

import { usePlotPanelContext } from "../panelContext";

/**
 * A button used to synchronize a plot with all other plots.
 */
export function SyncViewportButton() {
  const { isViewportSynced, setViewportSynced } = usePlotPanelContext();
  const onClick = () => {
    setViewportSynced(!isViewportSynced);
  };

  return (
    <IconButton
      aria-label="sync-chart-viewport"
      onClick={onClick}
      size="small"
      title="Sync viewport with all other charts."
    >
      {isViewportSynced ? (
        <Sensors color="primary" fontSize="small" />
      ) : (
        <SensorsOff color="inherit" fontSize="small" />
      )}
    </IconButton>
  );
}
