import { NS_PER_MS } from "@/utils/time";

import { TimeSpan } from "./TimeSpan";

interface TimerProps {
  spans: [bigint, bigint][];
  active?: boolean;
  elapsed?: number;
  fps?: number;
}

export type TimerCallback = (timer: Timer) => void;
type CallbackConfig = {
  callback: TimerCallback;
  id: symbol;
};
export type ListenerType =
  | "seek"
  | "start"
  | "stop"
  | "tick"
  | "hold"
  | "holdRelease"
  | "timeSpanUpdate";
type Listeners = {
  [key in ListenerType]: CallbackConfig[];
};

/**
 * Provider of single, centralized source of elapsed time over a duration.
 * Intended for synchronization of visualization elements that have a time dimension.
 */
export class Timer {
  // Whether the Timer's run loop is ticking.
  #active = false;

  // Duration of this Timer, in milliseconds.
  #duration: number = 0;

  // Current time according to this Timer, in milliseconds.
  // It is in "duration time", and is therefore relative to the Timer's `timeSpans`.
  // It may move backward or forward via `Timer::seek`.
  #elapsed: number = 0;

  #fps: number;

  // Ids of holds placed on the Timer.
  // While non-empty, the Timer will neither increment elapsed time nor call `onTick` listeners.
  // See docstring of Timer::hold for more information.
  #holds: symbol[] = [];

  // Event listeners
  #listeners: Listeners = {
    hold: [],
    holdRelease: [],
    seek: [],
    start: [],
    stop: [],
    tick: [],
    timeSpanUpdate: [],
  };

  #playbackRate: number = 1;

  #timeSpans: TimeSpan[] = [];

  // Return value of `setTimeout`; used to cancel the next run of this Timer's run loop.
  #timeoutId?: number;

  public static sortAndMergeSpans(
    spans: [bigint, bigint][],
  ): [bigint, bigint][] {
    if (spans.length === 0) {
      return [];
    }

    // Sort spans by start time (direct bigint comparison)
    const sortedSpans = [...spans].sort((a, b) => {
      if (a[0] < b[0]) {
        return -1;
      }
      if (a[0] > b[0]) {
        return 1;
      }
      return 0;
    });

    // Merge overlapping spans
    const mergedSpans: [bigint, bigint][] = [];
    let currentSpan: [bigint, bigint] = sortedSpans[0];

    for (let i = 1; i < sortedSpans.length; i++) {
      const [start, end] = sortedSpans[i];

      // Handle merge: span starts before or when currentSpan ends
      if (start <= currentSpan[1]) {
        // Ensure that end time is always the latest
        currentSpan = [
          currentSpan[0],
          end > currentSpan[1] ? end : currentSpan[1],
        ];
      } else {
        // Non-overlapping span, push the current span and start a new one
        mergedSpans.push(currentSpan);
        currentSpan = sortedSpans[i];
      }
    }

    // Add the last current span
    mergedSpans.push(currentSpan);

    return mergedSpans;
  }

  public static spansAreSortedAndNonOverlapping(spans: [bigint, bigint][]) {
    if (spans.length === 0) {
      return true;
    }

    for (let i = 0; i < spans.length - 1; i++) {
      const [curStart, curEnd] = spans[i];
      const nextStart = spans[i + 1][0];
      if (curStart > curEnd) {
        return false;
      }

      if (curStart >= nextStart) {
        return false;
      }

      if (curEnd > nextStart) {
        return false;
      }
    }

    const lastSpan = spans[spans.length - 1];

    if (lastSpan[0] > lastSpan[1]) {
      return false;
    }

    return true;
  }

  constructor(props: TimerProps) {
    const { spans, active = false, elapsed = 0, fps = 60 } = props;
    this.#active = active;
    this.#fps = fps;

    if (!Timer.spansAreSortedAndNonOverlapping(spans)) {
      throw new Error("Timespans must be sorted and non-overlapping");
    }

    const timeSpans = [];
    let duration = 0;
    for (const [start, end] of spans) {
      const timeSpan = new TimeSpan(start, end, duration);
      timeSpans.push(timeSpan);
      duration += timeSpan.durationMs;
    }
    this.#timeSpans = timeSpans;
    this.#duration = duration;
    this.#elapsed = Math.min(elapsed, duration);

    if (this.#active) {
      this.#timeoutId = window.setTimeout(this.#tick, this.frameDurationMs);
    }
  }

  get active(): boolean {
    return this.#active;
  }

  get currentTime(): bigint {
    // Find the TimeSpan that contains the current time
    const span = this.#timeSpans.find((span) => span.contains(this.#elapsed));
    if (!span) {
      if (this.#timeSpans.length && this.finished) {
        return this.#timeSpans[this.#timeSpans.length - 1].endTime;
      }
      return 0n;
    }
    return span.project(this.#elapsed);
  }

  get duration(): number {
    return this.#duration;
  }

  get elapsed(): number {
    return this.#elapsed;
  }

  get finished(): boolean {
    return this.#elapsed >= this.duration;
  }

  get frameDurationMs(): number {
    return 1000 / this.#fps;
  }

  get isHeld(): boolean {
    return this.#holds.length > 0;
  }

  get playbackRate(): number {
    return this.#playbackRate;
  }

  /**
   * Create a subscription to an event published by this Timer.
   * Returns a unique id associated with the newly attached subscription
   * that can be used to remove the listener.
   */
  addListener(
    type: ListenerType,
    callback: TimerCallback,
    opts?: { id?: symbol; signal?: AbortSignal },
  ): symbol {
    const { id, signal } = opts ?? {};
    const _id = id || Symbol();
    this.#listeners[type].push({ callback, id: _id });
    if (signal !== undefined) {
      signal.addEventListener("abort", () => this.removeListener(_id, type), {
        once: true,
      });
    }
    return _id;
  }

  /**
   * Stop this Timer if it is active and remove all listeners.
   */
  destruct() {
    this.stop();

    for (const event in this.#listeners) {
      const listenerType = event as ListenerType;
      this.#listeners[listenerType].length = 0;
    }
  }

  /**
   * Given an absolute time in nanoseconds, returns an appropriate timeline context offset time in milliseconds.
   */
  durationFromAbsolute(absoluteTimeNs: number | bigint): number {
    const normalizedTimeNs = BigInt(absoluteTimeNs);

    // This assumes that TimeSpans have no overlap, and are sorted by startTime ascending
    for (const timeSpan of this.#timeSpans) {
      // If the timestamp is before the current timespan, that means it exists in a gap between the previous span
      // (or nothing if this is the 0th span), and the current span. We can use offsetMs to represent that.
      if (normalizedTimeNs < timeSpan.startTime) {
        return timeSpan.offsetMs;
      }

      // If the timestamp is within this span, normalize it
      if (normalizedTimeNs <= timeSpan.endTime) {
        return (
          timeSpan.offsetMs +
          Number(normalizedTimeNs - timeSpan.startTime) / NS_PER_MS
        );
      }
    }

    // If we're at the end of the timespans and the timestamp is after the last timespan,
    // use the end of the last timespan
    if (this.#timeSpans.length) {
      return this.#timeSpans[this.#timeSpans.length - 1].endMs;
    }

    // If there are no timespans just return 0
    return 0;
  }

  /**
   * Place a hold on the internal run loop.
   * While held, elapsed time will not increment, and `onTick` listeners will not be called.
   *
   * This amounts to a "soft pause" of the Timer, and is useful when:
   *  - A user is scrubbing along the timeline. Once finished scrubbing,
   *    the playhead should continue marching onward from that point without further user input.
   *  - A panel needs to buffer more data before it can start or continue playing.
   */
  hold(): symbol {
    const hold = Symbol();
    this.#holds.push(hold);

    for (const { callback } of this.#listeners.hold) {
      callback(this);
    }

    return hold;
  }

  /**
   * Release a hold placed via a call to `Timer::hold`.
   */
  holdRelease(holdIdentifier: symbol) {
    this.#holds = this.#holds.filter((hold) => hold !== holdIdentifier);

    for (const { callback } of this.#listeners.holdRelease) {
      callback(this);
    }
  }

  /**
   * Remove subscription made in Timer::addListener, e.g.,
   * if a time-synchronized element is unmounted.
   */
  removeListener(id: symbol, eventType?: ListenerType) {
    for (const type in this.#listeners) {
      const listenerType = type as ListenerType;
      if (eventType && type !== listenerType) {
        continue;
      }

      const listeners = this.#listeners[listenerType].filter(
        ({ id: _id }) => id !== _id,
      );
      this.#listeners[listenerType] = listeners;
    }
  }

  /**
   * Start from the beginning.
   */
  reset() {
    this.#elapsed = 0;
  }

  /**
   * Jump to a specific time within this Timer's timeline.
   * Time is expressed within duration-space.
   */
  seek(toElapsed: number): void {
    this.#elapsed = toElapsed;

    for (const { callback } of this.#listeners.seek) {
      callback(this);
    }
  }

  seekTo(nextTime: bigint): void {
    const span = this.#timeSpans.find(
      (timeSpan) =>
        timeSpan.startTime <= nextTime && nextTime <= timeSpan.endTime,
    );
    if (span === undefined) {
      // One way you can reach here is if you try to seek to an empty space
      return;
    }

    const nextElapsed =
      Number(nextTime - span.startTime) / NS_PER_MS + span.offsetMs;

    this.seek(nextElapsed);
  }

  setPlaybackRate(playbackRate: number) {
    this.#playbackRate = playbackRate;
  }

  /**
   * Begin or resume internal loop and inform all listeners of state change.
   */
  start() {
    if (this.#active || this.duration === 0) {
      return;
    }
    this.#active = true;
    this.#timeoutId = window.setTimeout(this.#tick, this.frameDurationMs);

    for (const { callback } of this.#listeners.start) {
      callback(this);
    }
  }

  /**
   * Stop running internal loop and inform all listeners of state change.
   */
  stop() {
    if (!this.#active) {
      return;
    }

    this.#active = false;
    if (this.#timeoutId) {
      clearTimeout(this.#timeoutId);
    }

    // Clear any holds
    if (this.#holds.length) {
      this.#holds.length = 0;
      for (const { callback } of this.#listeners.holdRelease) {
        callback(this);
      }
    }

    for (const { callback } of this.#listeners.stop) {
      callback(this);
    }
  }

  updateTimeSpans(spans: TimerProps["spans"]): void {
    const timeSpans = [];
    let duration = 0;
    for (const [start, end] of spans) {
      const timeSpan = new TimeSpan(start, end, duration);
      timeSpans.push(timeSpan);
      duration += timeSpan.durationMs;
    }
    this.#timeSpans = timeSpans;
    this.#duration = duration;
    this.#elapsed = Math.min(this.#elapsed, duration);

    for (const { callback } of this.#listeners.timeSpanUpdate) {
      callback(this);
    }
  }

  #tick = (): void => {
    if (!this.#active) {
      return;
    }

    if (this.#holds.length) {
      this.#timeoutId = window.setTimeout(
        this.#tick,
        4, // Minimum delay for nested setTimeouts per HTML standard
      );
      return;
    }

    this.#elapsed += this.frameDurationMs * this.#playbackRate;

    for (const { callback } of this.#listeners.tick) {
      callback(this);
    }

    if (this.finished) {
      this.stop();
      return;
    }

    this.#timeoutId = window.setTimeout(this.#tick, this.frameDurationMs);
  };
}
