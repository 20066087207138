import StartIcon from "@mui/icons-material/StartOutlined";
import { useTheme } from "@mui/material";

import { RobotoIconButton } from "@/shared/components/RobotoIconButton";
import { RobotoTooltip } from "@/shared/components/RobotoTooltip";

import {
  isSidebarClosed,
  SidebarViewType,
  useWorkspaceSidebar,
} from "../../sidebarContext";

export function ToggleSidebar() {
  const theme = useTheme();
  const sidebar = useWorkspaceSidebar();

  if (isSidebarClosed(sidebar.activeView) === false) {
    return null;
  }

  return (
    <RobotoTooltip title="Open Sidebar">
      <span>
        <RobotoIconButton
          eventName="WorkspaceSidebarClosed"
          onClick={() => {
            sidebar.openView({
              type: SidebarViewType.TabbedSidebar,
              data: undefined,
            });
          }}
          sx={{
            backgroundColor: theme.palette.action.hover,
          }}
        >
          <StartIcon
            fontSize="small"
            sx={{
              color: theme.palette.text.secondary,
              ":hover": {
                color: theme.palette.text.primary,
              },
            }}
          />
        </RobotoIconButton>
      </span>
    </RobotoTooltip>
  );
}
