import { CircularProgress, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import * as React from "react";
import { useLocation } from "react-router-dom";

import { useDomainServices } from "@/providers/DomainServices";
import { RobotoTypography, RobotoLogo } from "@/shared/components";
import { OrgInviteRecord, OrgRecord } from "@/shared/domain/orgs";
import { LoggerService } from "@/shared/services";
import { PageRoute } from "@/types";

import { AuthCard } from "./AuthCard";
import { AuthCardTitle } from "./AuthCardTitle";
import { LegalFooter } from "./LegalFooter";

interface LoadOrgsAndInvitesFormProps {
  isVisible: boolean;
  onSuccess: (
    currentInviteId: string | null,
    orgs: OrgRecord[],
    pendingInvites: OrgInviteRecord[],
  ) => void;
}

export const LoadOrgsAndInvitesForm: React.FC<LoadOrgsAndInvitesFormProps> = ({
  isVisible,
  onSuccess,
}) => {
  const theme = useTheme();

  const { users } = useDomainServices();

  const { pathname, search, hash } = useLocation();

  const [errMsg, setErrMsg] = useState<string | null>(null);

  useEffect(() => {
    const getOrgsAndInvites = async () => {
      let inviteId = null;

      // Attempt to get inviteId from Google OAuth redirect, if it exists
      try {
        // Google + Amplify pass back a hex encoded state param, so we need to manually parse it
        const manualParams = new URLSearchParams(
          new URL(`${pathname}${search}${hash}`, "http://doesntmatter").search,
        );
        const state = manualParams.get("state");

        if (state) {
          const encodedInvite = state.split("-")[1];
          if (encodedInvite) {
            const decodedInvite = Buffer.from(encodedInvite, "hex").toString(
              "utf8",
            );
            inviteId = (JSON.parse(decodedInvite) as { inviteId: string })
              .inviteId;
          }
        }
      } catch (e) {
        LoggerService.warn(e);
      }

      if (inviteId !== null) {
        onSuccess(inviteId, [], []);
        return;
      }

      try {
        const [orgs, invites] = await Promise.all([
          users.getOrgsForCurrentUser(),
          users.getInvitesForCurrentUser(),
        ]);

        onSuccess(null, orgs, invites);
      } catch (e: unknown) {
        if (e instanceof Error) {
          setErrMsg(e.message);
          return;
        }
      }
    };

    if (isVisible) {
      void getOrgsAndInvites();
    }
  }, [isVisible, onSuccess, hash, pathname, search, users]);

  if (!isVisible) {
    return null;
  }

  return (
    <>
      <RobotoLogo sx={{ maxWidth: "250px" }} />

      <AuthCard>
        <AuthCardTitle title={"Sign In"} />

        {!errMsg && <CircularProgress size="1.75rem" />}

        {errMsg && (
          <RobotoTypography
            variant={"caption"}
            textAlign={"center"}
            sx={{ color: theme.palette.error.main }}
          >
            Error: {errMsg}. Please try{" "}
            <a href={PageRoute.SignIn}>signing in</a> again
          </RobotoTypography>
        )}
      </AuthCard>

      <LegalFooter />
    </>
  );
};
