import AdjustIcon from "@mui/icons-material/Adjust";
import CloseIcon from "@mui/icons-material/Close";
import { Box, IconButton } from "@mui/material";
import { alpha } from "@mui/material/styles";
import classNames from "classnames";
import * as React from "react";

import { useDebouncedCallback } from "@/shared/hooks";
import {
  actions,
  VizStateDispatchContext,
  type MapPanelState,
} from "@/shared/state/visualization";
import { MapPath } from "@/shared/state/visualization/schema/v1";

import { defaultMarker } from "./colors";
import styles from "./Legend.module.css";

interface LegendProps {
  hide?: boolean;
  centerOnPath?: (pathId: string) => void;
  state: MapPanelState;
}

export function Legend({ hide, centerOnPath, state }: LegendProps) {
  const vizStateDispatch = React.useContext(VizStateDispatchContext);

  const changePathColor = useDebouncedCallback(function setPathColor(
    pathId: string,
    color: string,
  ) {
    vizStateDispatch(
      actions.setPathStyle(state.id, pathId, { lineColor: color }),
    );
  }, 15);

  const removePath = (pathId: string) => {
    vizStateDispatch(actions.removePathFromMapPanel(state.id, pathId));
  };

  const setPathVisibility = (pathId: string, visible: boolean) => {
    vizStateDispatch(actions.setPathVisibility(state.id, pathId, visible));
  };

  const colorForPath = (path: MapPath) => {
    const stateDefinedColor = (path?.style ?? {}).lineColor as
      | string
      | undefined;

    return stateDefinedColor ?? defaultMarker;
  };

  if (hide === true || state.data.length === 0) {
    return null;
  }

  const overflow =
    state.data.length > 5 ? (
      <li key="overflow" className={styles.legendItem}>
        ...and others
      </li>
    ) : null;

  const paths = state.data.slice(0, 5);

  return (
    <Box
      className={styles.container}
      component="ul"
      bgcolor={(theme) => alpha(theme.palette.paper.main, 0.8)}
    >
      {paths.map((pathItem, idx) => {
        const lineColor = colorForPath(pathItem);
        return (
          <li className={styles.legendItem} key={pathItem.id}>
            <div className={styles.legendItemLabel}>
              <input
                className={styles.legendItemColor}
                type="color"
                value={lineColor}
                name={pathItem.id}
                onChange={(event) => {
                  changePathColor(pathItem.id, event.target.value);
                }}
              />
              <p
                role="button"
                tabIndex={0}
                aria-label="Toggle visibility of path"
                className={classNames({
                  [styles.hidden]: !pathItem.visible,
                })}
                onClick={function showOrHidePath(event) {
                  // Prevent triggering any other onclick handlers
                  event.stopPropagation();

                  setPathVisibility(pathItem.id, !pathItem.visible);
                }}
              >
                {`Path ${idx + 1}`}
              </p>
            </div>
            <div>
              <IconButton
                className={styles.iconButton}
                onClick={function center() {
                  centerOnPath?.(pathItem.id);
                }}
              >
                <AdjustIcon fontSize="inherit" />
              </IconButton>
              <IconButton
                className={styles.iconButton}
                onClick={function removePathFromView() {
                  removePath(pathItem.id);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            </div>
          </li>
        );
      })}
      {overflow}
    </Box>
  );
}
