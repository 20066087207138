import { Box, Skeleton, useTheme } from "@mui/material";
import * as React from "react";

export const MetadataSkeleton: React.FC = () => {
  const theme = useTheme();

  const numSkeletons = 3;

  return (
    <Box sx={{ display: "flex", flexDirection: "row", mt: theme.spacing(1) }}>
      {new Array<number>(numSkeletons).fill(0).map((_, index) => {
        return (
          <Skeleton
            variant="rounded"
            width={"112px"}
            height={"57px"}
            sx={{ marginRight: theme.spacing(1) }}
            key={index}
          />
        );
      })}
    </Box>
  );
};
