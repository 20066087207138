import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material";
import classNames from "classnames";
import { ReactNode } from "react";

import { RobotoIconButton } from "@/shared/components/RobotoIconButton";

import styles from "./SidebarItem.module.css";

interface SidebarItemProps {
  containerClassName?: classNames.Argument;
  containerStyle?: React.CSSProperties;
  header: ReactNode;
  body: ReactNode;
  footer?: ReactNode;
  onClose?: () => void;
}

export function SidebarItem(props: SidebarItemProps) {
  const { containerStyle = {}, header, body, footer, onClose } = props;
  const theme = useTheme();
  const showCloseButton = onClose !== undefined;

  return (
    <section
      className={classNames(styles.sidebarContainer, props.containerClassName)}
      style={{ backgroundColor: theme.palette.code.main, ...containerStyle }}
    >
      <header
        className={styles.header}
        style={{ borderColor: theme.border.color }}
      >
        {header}
        {showCloseButton && (
          <RobotoIconButton
            onClick={onClose}
            eventName="WorkspaceSidebarClosed"
            size="small"
            title="Close"
          >
            <CloseIcon fontSize="small" />
          </RobotoIconButton>
        )}
      </header>
      <div className={styles.body}>{body}</div>
      {footer && (
        <footer
          className={styles.footer}
          style={{ borderColor: theme.border.color }}
        >
          {footer}
        </footer>
      )}
    </section>
  );
}
