import { useParams } from "react-router-dom";

import { useNavigation } from "@/providers";
import { NavigationPage, RobotoTypography } from "@/shared/components";
import { AssociationType } from "@/shared/domain/association";

import { CreateDatasetEventForm } from "./CreateDatasetEventForm";

export const CreateDatasetEvent = () => {
  const { datasetPath } = useParams();
  const { goto } = useNavigation();
  return (
    <NavigationPage title={"Roboto - Create Event"}>
      <RobotoTypography variant="h5">Create Event</RobotoTypography>
      <CreateDatasetEventForm
        associationRecord={{
          association_id: datasetPath ?? "",
          association_type: AssociationType.Dataset,
        }}
        onCancel={() => {
          if (datasetPath) {
            goto.datasetEvents({ datasetId: datasetPath });
          }
        }}
        onSuccess={() => {
          if (datasetPath) {
            goto.datasetEvents({ datasetId: datasetPath });
          }
        }}
      />
    </NavigationPage>
  );
};
