import { Routes, Route } from "react-router-dom";

import { Action } from "./Action";
import { ActionsTabs } from "./ActionsTabs";
import { CreateAction } from "./CreateAction";
import { InvokeAction } from "./InvokeAction";

export function ActionsRoutes() {
  return (
    <Routes>
      <Route index element={<ActionsTabs />} />
      <Route path="create" element={<CreateAction />} />
      <Route path="invoke" element={<InvokeAction />} />
      <Route path=":actionRef/*" element={<Action />} />
    </Routes>
  );
}
