import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";

import { useTriggerById } from "@/shared/domain/actions/hooks";

import { RobotoLinkHoverUnderline } from "../RobotoLinkHoverUnderline";

interface TriggerLinkProps {
  triggerId: string;
}

export function TriggerLink({ triggerId }: TriggerLinkProps) {
  const result = useTriggerById(triggerId);

  if (result.isPending) {
    return (
      <Typography variant="body2">
        <Skeleton variant="text" width="200px" />
      </Typography>
    );
  }

  if (result.isError && result.error.name === "RobotoNotFoundException") {
    return (
      <Typography
        color="GrayText"
        sx={{ textDecorationLine: "line-through" }}
        title="This trigger no longer exists"
        variant="body2"
      >
        {triggerId}
      </Typography>
    );
  }

  if (result.isError) {
    return (
      <Typography
        color="error"
        title="Failed to load link to trigger"
        variant="body2"
      >
        {triggerId}
      </Typography>
    );
  }

  const trigger = result.data;

  return (
    <RobotoLinkHoverUnderline to={`/triggers/${trigger.name}`}>
      {trigger.name}
    </RobotoLinkHoverUnderline>
  );
}
