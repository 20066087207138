import CancelIcon from "@mui/icons-material/Cancel";
import { SxProps } from "@mui/material";
import * as React from "react";

import { AlertDialog, RobotoButton } from "@/shared/components";
import { InvocationRecord, InvocationStatus } from "@/shared/domain/actions";
import { OrgRecord } from "@/shared/domain/orgs";

import useCancellationCallback from "./useCancellationCallback";

interface CancelInvocationButtonProps {
  invocation?: InvocationRecord;
  onCancel?: () => void;
  org: OrgRecord | null;
  sx?: SxProps;
}

const terminalStatuses = [
  InvocationStatus.Completed,
  InvocationStatus.Cancelled,
  InvocationStatus.Failed,
  InvocationStatus.Deadly,
];

export default function CancelInvocationButton({
  invocation,
  onCancel,
  org,
  sx,
}: CancelInvocationButtonProps) {
  const cancelInvocation = useCancellationCallback(invocation, org, onCancel);
  const [alertDialogOpen, setAlertDialogOpen] = React.useState<boolean>(false);

  const latestStatusRecord = invocation?.status[invocation?.status.length - 1];

  const isTerminal =
    latestStatusRecord?.status !== undefined &&
    terminalStatuses.includes(latestStatusRecord.status);

  const styles = React.useMemo(() => {
    const base = {};
    if (!sx) {
      return base;
    }

    return {
      ...base,
      ...sx,
    };
  }, [sx]);

  if (isTerminal) {
    return null;
  }

  return (
    <>
      <RobotoButton
        eventName={"CancelInvocationClicked"}
        eventProperties={{
          invocationId: invocation?.invocation_id || "",
        }}
        disabled={isTerminal}
        onClick={() => {
          setAlertDialogOpen(true);
        }}
        startIcon={<CancelIcon />}
        sx={styles}
        variant="outlined"
        size="small"
        color="warning"
      >
        Cancel
      </RobotoButton>
      <AlertDialog
        dialogOpen={alertDialogOpen}
        handleClose={() => {
          setAlertDialogOpen(false);
        }}
        dialogTitle="Cancel"
        dialogText={`Are you sure you want to cancel this invocation of ${invocation?.provenance.action.name}?`}
        dialogAction={cancelInvocation}
      />
    </>
  );
}
