import * as React from "react";

import {
  Annotation,
  AnnotationViewFilters,
  ImageAnnotation,
  ImageSegmentations,
} from "@/shared/components/Annotations";
interface DatasetImageItemProps {
  signedUrl: string;
  loading: boolean;
  setLoading: (arg: boolean) => void;
  annotations: Annotation[];
  annotationViewFilters: AnnotationViewFilters;
  onClick: (e: React.MouseEvent<HTMLElement>) => void;
}

export const DatasetImageItem: React.FC<DatasetImageItemProps> = ({
  signedUrl,
  loading,
  setLoading,
  annotations,
  annotationViewFilters,
  onClick,
}) => {
  return (
    <>
      <img
        style={{
          width: "100%",
          height: loading ? 0 : "auto",
          opacity: loading ? 0 : 1,
          transition: "opacity 0.5s",
          display: "block",
        }}
        src={signedUrl}
        alt={""}
        loading="lazy"
        onLoad={() => setLoading(false)}
        onClick={onClick}
      />

      {!loading &&
        Array.isArray(annotations) &&
        annotations?.map((annotation, idx) => {
          if (
            annotation.confidence !== undefined &&
            annotation.confidence >=
              annotationViewFilters.confidenceThreshold.percent / 100
          ) {
            return (
              <ImageAnnotation
                key={idx}
                annotation={annotation}
                annotationViewFilters={annotationViewFilters}
                onClick={onClick}
              />
            );
          }
        })}

      {!loading &&
        annotations &&
        Array.isArray(annotations) &&
        annotationViewFilters.segmentation.show && (
          <ImageSegmentations
            annotations={annotations}
            confidenceThreshold={
              annotationViewFilters.confidenceThreshold.percent
            }
            onClick={onClick}
          />
        )}
    </>
  );
};
