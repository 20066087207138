import { Box, List, ListItem, Modal, useTheme } from "@mui/material";
import { useState } from "react";
import * as React from "react";

import {
  RobotoButton,
  RobotoCheckbox,
  RobotoTypography,
} from "@/shared/components";
import { formatFieldName } from "@/utils";

interface ColumnsModalProps {
  open: boolean;
  handleClose: () => void;
  currentColumns: string[];
  columns: string[];
  onSave: (selectedColumns: string[]) => void;
}

export const ColumnsModal: React.FC<ColumnsModalProps> = ({
  open,
  handleClose,
  currentColumns,
  columns,
  onSave,
}) => {
  const theme = useTheme();

  const [selectedColumns, setSelectedColumns] = useState<Set<string>>(
    new Set(currentColumns),
  );

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="columns-modal-title"
      aria-describedby="columns-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: {
            xs: "90%",
            sm: "80%",
            md: "60%",
            lg: "50%",
            xl: "40%",
          },
          maxWidth: "500px",
          bgcolor: "background.paper",
          border: theme.border.thin,
          borderRadius: theme.border.radius,
          boxShadow: 24,
          padding: theme.spacing(3),
          display: "flex",
          flexDirection: "column",
          overflowY: "auto",
          maxHeight: "80vh",
        }}
      >
        <RobotoTypography variant={"h6"}>Columns</RobotoTypography>

        <List>
          {columns.map((column) => {
            return (
              <ListItem key={column} dense sx={{ padding: 0 }}>
                <RobotoCheckbox
                  size="small"
                  checked={selectedColumns.has(column)}
                  onChange={(e) => {
                    if (e.target.checked) {
                      const newColumns = new Set(selectedColumns);
                      newColumns.add(column);
                      setSelectedColumns(newColumns);
                    } else {
                      const newColumns = new Set(selectedColumns);
                      newColumns.delete(column);
                      setSelectedColumns(newColumns);
                    }
                  }}
                />
                <RobotoTypography variant={"body2"}>
                  {formatFieldName(column)}
                </RobotoTypography>
              </ListItem>
            );
          })}
        </List>

        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: {
              xs: "column",
              sm: "row",
            },
            mt: theme.spacing(1),
          }}
        >
          <RobotoButton
            eventName={"UpdateDatasetTableColumnsFormSubmitted"}
            variant={"contained"}
            sx={{
              marginRight: {
                xs: "0px",
                sm: theme.spacing(2),
              },
              marginBottom: {
                xs: theme.spacing(2),
                sm: "0px",
              },
              width: "100px",
            }}
            onClick={() => {
              const columnsToSave = Array.from(selectedColumns.keys());
              onSave(columnsToSave);
            }}
          >
            Update
          </RobotoButton>
          <RobotoButton
            eventName={"UpdateDatasetTableColumnsFormCanceled"}
            variant={"outlined"}
            onClick={() => {
              handleClose();
            }}
            sx={{ width: "100px" }}
          >
            Cancel
          </RobotoButton>
        </Box>
      </Box>
    </Modal>
  );
};
