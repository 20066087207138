import { EventRecord } from "@/shared/domain/events";

import { CreateDatasetEvent } from "./CreateDatasetEvent";
import { DeleteDatasetEvents } from "./DeleteDatasetEvents";

interface DatasetEventTableControlsProps {
  datasetId: string;
  selectedEvents: EventRecord["event_id"][];
  onDeleteEvents: (events: EventRecord["event_id"][]) => void;
}

export function DatasetEventTableControls({
  datasetId,
  selectedEvents,
  onDeleteEvents,
}: DatasetEventTableControlsProps) {
  return (
    <div style={{ display: "flex", gap: "0.5rem", marginBottom: "1rem" }}>
      <CreateDatasetEvent datasetId={datasetId} />
      <DeleteDatasetEvents
        datasetId={datasetId}
        selectedEvents={selectedEvents}
        onDeleteEvents={onDeleteEvents}
      />
    </div>
  );
}
