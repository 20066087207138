import { nanoSecToSec } from "@/utils/time";

import { TopicRecord } from "./TopicRecord";

export class TopicSequence {
  public readonly topic_name: string;
  public readonly schema_name: string;
  public readonly topics: TopicRecord[];

  constructor(topic_name: string, schema_name: string, topics: TopicRecord[]) {
    this.topic_name = topic_name;
    this.schema_name = schema_name;
    this.topics = topics.sort((a, b) => {
      if (a.start_time === null && b.start_time !== null) {
        return 1;
      }
      if (a.start_time !== null && b.start_time === null) {
        return -1;
      }
      if (a.start_time !== null && b.start_time !== null) {
        return Number(a.start_time - b.start_time);
      }
      return 0;
    });
  }

  public get active_duration_s(): number {
    return this.topics.reduce((totalDurationInSec, topic) => {
      if (topic.start_time && topic.end_time) {
        return (
          totalDurationInSec + nanoSecToSec(topic.end_time - topic.start_time)
        );
      }
      return totalDurationInSec;
    }, 0);
  }

  public get active_frequency_hz(): number {
    const activeDuration = this.active_duration_s;
    return activeDuration > 0 ? this.message_count / activeDuration : 0;
  }

  public get end_time(): bigint | null {
    return this.topics[this.topics.length - 1].end_time;
  }

  public get start_time(): bigint | null {
    return this.topics[0].start_time;
  }

  public get message_count(): number {
    return this.topics.reduce(
      (acc, topic) => acc + (topic.message_count ?? 0),
      0,
    );
  }

  static fromTopics(topics: TopicRecord[]): TopicSequence[] {
    const groupedTopics = topics.reduce<Record<string, TopicRecord[]>>(
      (acc, topic) => {
        const key = `${topic.topic_name}|${topic.schema_name}`;
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(topic);
        return acc;
      },
      {},
    );

    return Object.entries(groupedTopics).map(([key, topicGroup]) => {
      const [topic_name, schema_name] = key.split("|");
      return new TopicSequence(topic_name, schema_name, topicGroup);
    });
  }
}
