import { LoggerService } from "@/shared/services";

import { HttpClient, robotoHeaders } from "../../http";

import {
  type NotificationRecord,
  NotificationBatchResponse,
  ReadStatus,
} from "./NotificationRecord";

export class NotificationService {
  #httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.#httpClient = httpClient;
  }

  markNotificationsAcknowledged = async (
    notifications: NotificationRecord[],
    orgId: string,
  ): Promise<NotificationRecord[]> => {
    const notificationsToUpdate = notifications.map((notification) => {
      return {
        notification_id: notification.notification_id,
        lifecycle_status: {
          web_ui: "Acknowledged",
        },
      };
    });

    const requestBodyObject = {
      requests: notificationsToUpdate,
    };

    const url = this.#httpClient.constructUrl(`v1/notifications/batch`);
    const response = await this.#httpClient.put(url, {
      body: JSON.stringify(requestBodyObject),
      headers: robotoHeaders({ orgId }),
    });

    const batchResponse = await response.json<NotificationBatchResponse>();

    const updatedNotifications = batchResponse.responses
      .map((response) => {
        if (response.error) {
          LoggerService.error(
            "Error marking notification as last viewed",
            response.error,
          );
          return null;
        }
        return response.data;
      })
      .filter((notification) => notification !== null);

    return updatedNotifications;
  };

  markNotificationsAsRead = async (
    notifications: NotificationRecord[],
    orgId: string,
  ): Promise<NotificationRecord[]> => {
    const notificationsToUpdate = notifications
      .map((notification) => {
        if (notification.read_status === ReadStatus.Read) {
          return null;
        }

        return {
          notification_id: notification.notification_id,
          read_status: "read",
        };
      })
      .filter((notification) => notification !== null);

    const requestBodyObject = {
      requests: notificationsToUpdate,
    };

    const url = this.#httpClient.constructUrl(`v1/notifications/batch`);
    const response = await this.#httpClient.put(url, {
      body: JSON.stringify(requestBodyObject),
      headers: robotoHeaders({ orgId }),
    });

    const batchResponse = await response.json<NotificationBatchResponse>();

    const updatedNotifications = batchResponse.responses
      .map((response) => {
        if (response.error) {
          LoggerService.error(
            "Error marking notification as read",
            response.error,
          );
          return null;
        }
        return response.data;
      })
      .filter((notification) => notification !== null);

    return updatedNotifications;
  };
}
