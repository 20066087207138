import { ActionHub } from "@/Actions/ActionHub";
import { NavigationPage } from "@/shared/components";

export default function ActionHubLandingPage() {
  return (
    <NavigationPage title="Roboto - Action Hub">
      <ActionHub />
    </NavigationPage>
  );
}
