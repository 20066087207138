import { Alert, List, ListItem, Skeleton } from "@mui/material";
import * as React from "react";

import { VisibilityToggle } from "@/shared/components/VisibilityToggle";
import { actions, useVizDispatch } from "@/shared/state/visualization";

import { SidebarViewType, useWorkspaceSidebar } from "../../sidebarContext";
import {
  useAllWorkspaceEvents,
  useEphemeralWorkspaceStateLoading,
} from "../../WorkspaceCtx";

import { Event } from "./Event";
import { EventFilterInput } from "./EventFilterInput";
import styles from "./EventList.module.css";

export function EventList() {
  const events = useAllWorkspaceEvents();
  const loading = useEphemeralWorkspaceStateLoading();
  const dispatch = useVizDispatch();

  const [filter, setFilter] = React.useState<string>("");
  const [allEventsVisible, setAllEventsVisible] = React.useState<boolean>(true);

  const sidebar = useWorkspaceSidebar();

  // Compute filtered events
  const filteredEvents = React.useMemo(
    () =>
      events.filter((event) =>
        event.name.toLowerCase().includes(filter.toLowerCase()),
      ),
    [events, filter],
  );

  return (
    <div className={styles.eventListContainer}>
      <div className={styles.eventListControls}>
        <EventFilterInput
          onFilter={(filterInput: string) => {
            setFilter(filterInput);
          }}
        />

        <VisibilityToggle
          on={allEventsVisible}
          onToggle={(on) => {
            setAllEventsVisible(on);
            dispatch(
              actions.setEventsVisibility(
                on,
                filteredEvents.map((event) => event.event_id),
              ),
            );
          }}
        />
      </div>

      <div className={styles.eventListContent}>
        <List dense disablePadding>
          {!loading &&
            filteredEvents.map((event) => {
              return (
                <Event
                  key={event.event_id}
                  event={event}
                  onClick={function openEventDetails() {
                    sidebar.openView({
                      type: SidebarViewType.EventDetails,
                      data: {
                        eventId: event.event_id,
                      },
                    });
                  }}
                />
              );
            })}

          {loading && (
            <>
              {Array.from({ length: 5 }).map((_, index) => (
                <ListItem key={index}>
                  <Skeleton width="100%" height="30px" />
                </ListItem>
              ))}
            </>
          )}
        </List>

        {!loading && filteredEvents.length === 0 && (
          <Alert severity="info" className={styles.eventListAlert}>
            No matching events to show
          </Alert>
        )}
      </div>
    </div>
  );
}
