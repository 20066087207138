import { Grid, useTheme } from "@mui/material";
import * as React from "react";

import { useNavigation } from "@/providers";
import { ExpandableCopyText, PageHeader } from "@/shared/components";
import { TagsGroup, Tags } from "@/shared/components/tags";
import { useDataset } from "@/shared/domain/datasets/hooks";

import { EditableDatasetDescription } from "./EditableDatasetDescription";
import { EditableDatasetName } from "./EditableDatasetName";

interface DatasetHeaderProps {
  datasetId: string;
  showTags?: boolean;
}

export const DatasetHeader: React.FC<DatasetHeaderProps> = ({
  datasetId,
  showTags,
}) => {
  const theme = useTheme();
  const { goto } = useNavigation();

  const datasetQuery = useDataset(datasetId, { throwOnError: true });

  const goToDatasetsByTag = (tag: string) => {
    const queryParams = new URLSearchParams();
    queryParams.set("query", tag);
    goto.search(queryParams);
  };

  return (
    <Grid container spacing={1}>
      <Grid container item>
        <Grid item sm={5} xs={12}>
          <PageHeader component={"div"}>
            <ExpandableCopyText
              title="Dataset"
              text={datasetId}
              trimLength={15}
            />
          </PageHeader>
          <EditableDatasetName
            datasetId={datasetId}
            name={datasetQuery.data?.name}
            isLoading={datasetQuery.isLoading}
          />
        </Grid>
        <Grid
          item
          sm={7}
          xs={0}
          sx={{
            display: {
              xs: "none",
              sm: "block",
            },
          }}
        >
          <TagsGroup style={{ justifyContent: "flex-end" }}>
            <Tags
              tags={datasetQuery.data?.tags || []}
              onClickTag={goToDatasetsByTag}
            />
          </TagsGroup>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <EditableDatasetDescription
          datasetId={datasetId}
          description={datasetQuery.data?.description}
          isLoading={datasetQuery.isLoading}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          mt: theme.spacing(1),
          display: {
            xs: "block",
            sm: "none",
          },
        }}
      >
        {/** Special case for mobile. */}
        {showTags && (
          <TagsGroup>
            <Tags
              tags={datasetQuery.data?.tags ?? []}
              onClickTag={goToDatasetsByTag}
            />
          </TagsGroup>
        )}
      </Grid>
    </Grid>
  );
};
