import { Message } from "@/shared/mcap";

import { RenderingError } from "../RenderingError";

import styles from "./FormattedMessage.module.css";

interface FormattedMessageProps {
  error: Error | null;
  message: Message | null;
}

export function FormattedMessage({ error, message }: FormattedMessageProps) {
  if (error !== null) {
    return <RenderingError error={error} />;
  }

  if (message === null) {
    return <pre className={styles.message}>No message</pre>;
  }

  const stringified = JSON.stringify(
    message,
    (_, v: unknown): unknown => (typeof v === "bigint" ? v.toString() : v),
    2,
  );
  return <pre className={styles.message}>{stringified}</pre>;
}
