import { Box, CircularProgress } from "@mui/material";
import React from "react";

import { FileRecord } from "@/shared/domain/files";
import { basename } from "@/utils";

interface VideoContentProps {
  fileRecord: FileRecord;
  signedUrl: URL | undefined;
}

export const VideoContent: React.FC<VideoContentProps> = ({
  fileRecord,
  signedUrl,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "100%",
        justifyContent: "center",
      }}
    >
      {!signedUrl && <CircularProgress />}
      {signedUrl && (
        <Box
          sx={{
            maxHeight: "95%",
          }}
        >
          <video
            controls
            src={signedUrl.toString()}
            title={basename(fileRecord.relative_path)}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "contain",
            }}
          />
        </Box>
      )}
    </Box>
  );
};
