import {
  type TopicData,
  messagePathPartIsMessagePathAttr,
} from "@/shared/state/visualization";

import styles from "./MessagePath.module.css";
import { MessagePathSliceEditor } from "./MessagePathSliceEditor";

interface MessagePathProps {
  messagePath: TopicData["messagePath"];
  onChange: (messagePath: TopicData["messagePath"]) => void;
}

/**
 * Render each segment of a message path. Slice segments are editable.
 */
export function MessagePath({ messagePath, onChange }: MessagePathProps) {
  const parts = messagePath.parts.map((part, index) => {
    if (messagePathPartIsMessagePathAttr(part)) {
      const sep = index === 0 ? "" : ".";
      return (
        <span className={styles.part} key={index}>
          {sep}
          {part.attribute}
        </span>
      );
    }
    return (
      <MessagePathSliceEditor
        key={index}
        onChange={(slice) => {
          const updated = [...messagePath.parts];
          updated[index] = slice;
          onChange({
            ...messagePath,
            parts: updated,
          });
        }}
        slice={part}
      />
    );
  });
  return <div className={styles.messagePathEditor}>{parts}</div>;
}
