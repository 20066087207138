import { TextField, Button, CircularProgress } from "@mui/material";
import { SubmitHandler, useForm } from "react-hook-form";

import { EventRecord } from "@/shared/domain/events";
import { useUpdateEvent } from "@/shared/domain/events/hooks";

interface EventDescriptionFormProps {
  id: EventRecord["event_id"];
  description: EventRecord["description"];
  onSubmit: (event: EventRecord) => void;
  onCancel: () => void;
  inputClassName?: CSSModuleClasses[string];
}

export function EventDescriptionForm({
  id,
  description,
  onSubmit,
  onCancel,
  inputClassName,
}: EventDescriptionFormProps) {
  const form = useForm({
    defaultValues: {
      description,
    },
  });

  const updateEvent = useUpdateEvent();

  const submissionErrors = form.formState.errors.root?.serverErrors;

  const onSubmitForm: SubmitHandler<{
    description: EventRecord["description"];
  }> = (data) => {
    // Return early if the description didn't change
    if (data.description === description) {
      onCancel();
      return;
    }
    return updateEvent
      .mutateAsync({
        id,
        requestBody: {
          description: data.description,
        },
      })
      .then((event) => {
        onSubmit(event);
      });
  };

  return (
    <form
      onSubmit={(event) => {
        form
          .handleSubmit(onSubmitForm)(event)
          .catch((err) => {
            const message =
              err instanceof Error
                ? err.message
                : "Failed to save description.";
            form.setError("root.serverErrors", {
              message,
            });
          });
      }}
    >
      <TextField
        {...form.register("description")}
        autoFocus
        defaultValue={description}
        error={form.formState.errors.description !== undefined}
        fullWidth
        helperText={form.formState.errors.description?.message}
        multiline
        size="small"
        minRows={2}
        inputProps={{
          className: inputClassName,
        }}
      />
      {submissionErrors && (
        <p style={{ color: "red", margin: "0.5rem 0" }}>
          {submissionErrors.message}
        </p>
      )}
      <div
        style={{
          display: "flex",
          gap: "0.5rem",
          margin: "0.5rem 0",
          justifyContent: "flex-end",
        }}
      >
        <Button
          type="button"
          size="small"
          style={{ textTransform: "none" }}
          onClick={() => onCancel()}
        >
          Cancel
        </Button>
        <Button
          size="small"
          disabled={form.formState.isSubmitting}
          style={{ textTransform: "none" }}
          variant="contained"
          type="submit"
          disableElevation
          endIcon={updateEvent.isPending && <CircularProgress size="1.2rem" />}
        >
          Save
        </Button>
      </div>
    </form>
  );
}
