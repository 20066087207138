import { LayoutOrientation } from "@/shared/state/visualization/actions";

import { DropZone } from "./constants";
import { Droppable } from "./Droppable";

interface DropZonesProps<T> {
  idPrefix: string;
  dropZone: DropZone;
  data?: T;
  axis?: "x" | "y";
}

export function DropZones<T>(props: DropZonesProps<T>) {
  const { idPrefix, dropZone, data, axis } = props;

  const baseStyles: React.CSSProperties = {
    pointerEvents: "none",
    position: "absolute",
    zIndex: "1000",
  };

  if (axis === "x") {
    return (
      <>
        <Droppable
          dropZone={dropZone}
          droppableId={`${idPrefix}-top`}
          data={{ layout: data, orientation: LayoutOrientation.TOP }}
          style={{
            ...baseStyles,
            top: 0,
            left: 0,
            right: 0,
            height: "50%",
          }}
        />
        <Droppable
          dropZone={dropZone}
          droppableId={`${idPrefix}-bottom`}
          data={{ layout: data, orientation: LayoutOrientation.BOTTOM }}
          style={{
            ...baseStyles,
            bottom: 0,
            left: 0,
            right: 0,
            height: "50%",
          }}
        />
      </>
    );
  } else if (axis === "y") {
    return (
      <>
        <Droppable
          dropZone={dropZone}
          droppableId={`${idPrefix}-right`}
          data={{ layout: data, orientation: LayoutOrientation.RIGHT }}
          style={{
            ...baseStyles,
            right: 0,
            top: 0,
            bottom: 0,
            width: "50%",
          }}
        />
        <Droppable
          dropZone={dropZone}
          droppableId={`${idPrefix}-left`}
          data={{ layout: data, orientation: LayoutOrientation.LEFT }}
          style={{
            ...baseStyles,
            left: 0,
            top: 0,
            bottom: 0,
            width: "50%",
          }}
        />
      </>
    );
  }

  return (
    <>
      <Droppable
        dropZone={dropZone}
        droppableId={`${idPrefix}-top`}
        data={{ layout: data, orientation: LayoutOrientation.TOP }}
        style={{
          ...baseStyles,
          top: 0,
          left: 0,
          right: 0,
          height: "50%",
        }}
      />
      <Droppable
        dropZone={dropZone}
        droppableId={`${idPrefix}-right`}
        data={{ layout: data, orientation: LayoutOrientation.RIGHT }}
        style={{
          ...baseStyles,
          right: 0,
          top: 0,
          bottom: 0,
          width: "50%",
        }}
      />
      <Droppable
        dropZone={dropZone}
        droppableId={`${idPrefix}-bottom`}
        data={{ layout: data, orientation: LayoutOrientation.BOTTOM }}
        style={{
          ...baseStyles,
          bottom: 0,
          left: 0,
          right: 0,
          height: "50%",
        }}
      />
      <Droppable
        dropZone={dropZone}
        droppableId={`${idPrefix}-left`}
        data={{ layout: data, orientation: LayoutOrientation.LEFT }}
        style={{
          ...baseStyles,
          left: 0,
          top: 0,
          bottom: 0,
          width: "50%",
        }}
      />
    </>
  );
}
