import { Navigate, Route, Routes } from "react-router-dom";

import { File } from "./File";

export function FilesRoutes() {
  return (
    <Routes>
      <Route index element={<Navigate to="/search?target=files" replace />} />
      <Route path=":fileId/:workspaceId?" element={<File />} />
    </Routes>
  );
}
