import {
  Alert,
  Box,
  Link,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  useTheme,
} from "@mui/material";
import * as React from "react";

import {
  RobotoLinkHoverUnderline,
  RobotoTableCell,
  RobotoTableRow,
} from "@/shared/components";
import { ActionRecord, InvocationRecord } from "@/shared/domain/actions";
import {
  OrganizationTier,
  OrganizationType,
  OrgRecord,
} from "@/shared/domain/orgs";
import { OrganizationStatus } from "@/shared/domain/orgs/OrgRecord";
import { usePaginatedAPICall } from "@/shared/services/apiHooks";
import {
  Condition,
  RobotoAPICall,
  SearchQueryBody,
  searchInvocationsEndpoint,
} from "@/types";
import {
  invocationActionCondition,
  invocationDatasetCondition,
  invocationInitiatorCondition,
} from "@/utils/commonConditions";

import InvokeActionButton from "./action/InvokeActionButton";
import InvocationRow from "./invocation/InvocationRow";

type RowsPerPage = 10 | 25 | 50;
const initialRowsPerPage = 10;

interface InvocationsProps {
  currentOrg: OrgRecord | null;
  datasetId?: string;
  action?: ActionRecord;
  triggerId?: string;
  condition?: Condition;
}

const kindaSketchyCurrentOrgFromJustId = (orgId: string): OrgRecord => {
  return {
    org_id: orgId,
    org_type: OrganizationType.Team,
    created: "2024-01-01T00:00Z",
    name: "FAKE_ORG",
    tier: OrganizationTier.Free,
    status: OrganizationStatus.Active,
  };
};

export const Invocations: React.FC<InvocationsProps> = ({
  currentOrg,
  datasetId,
  action,
  triggerId,
  condition,
}) => {
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [page, setPage] = React.useState<number>(0);
  const [rowsPerPage, setRowsPerPage] =
    React.useState<number>(initialRowsPerPage);

  const {
    getFirstPage,
    fetchNextPage,
    fetchPreviousPage,
    onRowsPerPageChange,
    pageData,
    isNextPageAvailable,
    cacheLength,
    updateItemsInCache,
  } = usePaginatedAPICall<InvocationRecord>();

  React.useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      const apiCall = {} as RobotoAPICall;

      apiCall.endpoint = searchInvocationsEndpoint;
      apiCall.method = "POST";
      apiCall.orgId = currentOrg?.org_id;

      const requestBody = {} as SearchQueryBody;
      requestBody.sort_direction = "DESC";
      requestBody.sort_by = "created";
      requestBody.limit = 100;

      if (condition) {
        requestBody.condition = condition;
      } else if (datasetId) {
        requestBody.condition = invocationDatasetCondition(datasetId);
      } else if (action) {
        requestBody.condition = invocationActionCondition(
          action?.org_id ?? currentOrg?.org_id,
          action.name,
        );
      } else if (triggerId) {
        requestBody.condition = invocationInitiatorCondition(
          triggerId,
          "Trigger",
        );
      }

      apiCall.requestBody = JSON.stringify(requestBody);

      await getFirstPage(apiCall, initialRowsPerPage);

      setIsLoading(false);
    };

    void fetchData();
  }, [
    getFirstPage,
    currentOrg?.org_id,
    datasetId,
    action,
    triggerId,
    condition,
  ]);

  const theme = useTheme();

  const onUpdate = React.useCallback(
    (record: InvocationRecord) => {
      updateItemsInCache({ [record.invocation_id]: record }, "invocation_id");
    },
    [updateItemsInCache],
  );

  const onPageChangeHandler = async (newPage: number) => {
    //
    if (newPage > page) {
      setPage(page + 1);
      await fetchNextPage(newPage, rowsPerPage, "body");
    } else {
      setPage(page - 1);
      fetchPreviousPage(newPage, rowsPerPage);
    }
  };

  const onRowsPerPageChangeHandler = async (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const val = parseInt(e?.target?.value) ?? 10;
    setPage(0);
    setRowsPerPage(val);
    await onRowsPerPageChange(val as RowsPerPage);
  };

  return (
    <div>
      {!datasetId && !condition && (
        <Box sx={{ mb: theme.spacing(3) }}>
          <Alert severity="info">
            Invocations are runs of an action on files in a dataset.{" "}
            {currentOrg?.tier === OrganizationTier.Free && (
              <>
                In Roboto&apos;s free tier, invocations are run serially.
                Upgrade to{" "}
                <RobotoLinkHoverUnderline
                  to={"https://www.roboto.ai/pricing"}
                  target="_blank"
                >
                  premium
                </RobotoLinkHoverUnderline>{" "}
                to run them faster in parallel.
              </>
            )}
          </Alert>
        </Box>
      )}
      <Box sx={{ mb: theme.spacing(2) }}>
        <InvokeActionButton
          action={action}
          datasetId={datasetId}
          sx={{ height: theme.actionButtonHeight }}
        />
      </Box>
      <TableContainer component={Paper} variant="outlined">
        <Table size="small">
          <TableHead>
            <TableRow>
              <RobotoTableCell>Invocation</RobotoTableCell>
              <RobotoTableCell>Status</RobotoTableCell>
              <RobotoTableCell>Action</RobotoTableCell>
              {!datasetId && <RobotoTableCell>Dataset</RobotoTableCell>}
              <RobotoTableCell>Duration</RobotoTableCell>
              <RobotoTableCell>Date</RobotoTableCell>
              <RobotoTableCell>Run By</RobotoTableCell>
              <RobotoTableCell>Logs</RobotoTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <>
                {Array.from({ length: 3 }, (_, i) => (
                  <TableRow key={`row-${i}`}>
                    {Array.from({ length: datasetId ? 7 : 8 }, (_, j) => (
                      <RobotoTableCell key={`cell-${j}`}>
                        <Skeleton
                          animation="wave"
                          variant="rounded"
                          height={30}
                          sx={{ mt: 1, mb: 1 }}
                        />
                      </RobotoTableCell>
                    ))}
                  </TableRow>
                ))}
              </>
            ) : (
              <>
                {pageData?.length > 0 ? (
                  <>
                    {pageData.map((invocation) => {
                      return (
                        <InvocationRow
                          key={invocation.invocation_id}
                          invocation={invocation}
                          currentOrg={
                            currentOrg ||
                            kindaSketchyCurrentOrgFromJustId(invocation.org_id)
                          }
                          onUpdate={onUpdate}
                          showDatasetId={datasetId ? false : true}
                        />
                      );
                    })}
                  </>
                ) : (
                  <RobotoTableRow>
                    <RobotoTableCell
                      style={{
                        paddingBottom: theme.spacing(2),
                        paddingTop: theme.spacing(2),
                      }}
                      colSpan={datasetId ? 7 : 8}
                    >
                      <Alert severity="warning">
                        No actions have been invoked yet. You can invoke one
                        using the button above, or with the{" "}
                        <Link
                          underline="hover"
                          target="_blank"
                          href="https://docs.roboto.ai/getting-started/programmatic-access.html#python-sdk-and-cli"
                        >
                          Roboto CLI
                        </Link>
                        :
                        <br />
                        <br />
                        <span
                          style={{
                            fontFamily: "monospace",
                            fontSize: "0.8rem",
                          }}
                        >
                          $ roboto actions invoke {"<actionReference>"}
                          --dataset-id {datasetId
                            ? datasetId
                            : "<datasetId>"}{" "}
                          --input-data {"<filePattern>"}
                        </span>
                      </Alert>
                    </RobotoTableCell>
                  </RobotoTableRow>
                )}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={isNextPageAvailable ? -1 : cacheLength}
        nextIconButtonProps={{
          disabled: !isNextPageAvailable,
        }}
        rowsPerPageOptions={[10, 25, 50]}
        page={page}
        onPageChange={(_, newPage) => {
          void onPageChangeHandler(newPage);
        }}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={(e) => {
          void onRowsPerPageChangeHandler(e);
        }}
        labelRowsPerPage={"Invocations per page:"}
      />
    </div>
  );
};
