import { QueryTarget } from "@/shared/domain/query";

export enum SearchResultInteraction {
  SingleClick,
}

export type OnResultInteraction = (
  result: unknown,
  resultType: QueryTarget,
  interaction: SearchResultInteraction,
) => void;
