import AddIcon from "@mui/icons-material/Add";
import { Box, useTheme } from "@mui/material";
import TablePagination from "@mui/material/TablePagination";
import * as React from "react";

import { useNavigation } from "@/providers";
import { RobotoButton } from "@/shared/components";
import { Accessibility, ActionRecord } from "@/shared/domain/actions";
import { OrgRecord } from "@/shared/domain/orgs";
import { usePaginatedAPICall } from "@/shared/services/apiHooks";
import { Condition, RobotoAPICall, SearchQueryBody } from "@/types";

import ActionCardGrid from "./ActionCardGrid";
import ActionsViewToggle from "./ActionsViewToggle";
import ActionTable from "./ActionTable";
import ActionTagFilter from "./ActionTagFilter";
import InvokeActionButton from "./InvokeActionButton";
import { Capability, View } from "./types";

interface ActionsViewProps {
  accessibility: Accessibility;
  capabilities: Capability[];
  defaultView?: View;
  org: OrgRecord | null;
}

const ROWS_PER_PAGE_OPTIONS = [20, 30, 50];
const INITIAL_ROWS_PER_PAGE = ROWS_PER_PAGE_OPTIONS[0];

const tagFilterStyles = { flex: "1 1 auto" };
const viewToggleStyles = { flex: "0 0 auto", alignSelf: "flex-start" };

/**
 * A paginated list of actions, fetched from a parameterized endpoint.
 * The list can be displayed as a compact table or a card grid.
 * The capabilities prop controls what operations are available to the user
 * on each action.
 */
export default function ActionsView({
  accessibility,
  capabilities,
  defaultView = View.CardGrid,
  org,
}: ActionsViewProps) {
  const theme = useTheme();
  const [view, setView] = React.useState<View>(defaultView);
  const [page, setPage] = React.useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(
    INITIAL_ROWS_PER_PAGE,
  );
  const { goto } = useNavigation();

  const {
    cacheLength,
    getFirstPage,
    fetchNextPage,
    fetchPreviousPage,
    isNextPageAvailable,
    loading,
    onRowsPerPageChange,
    pageData,
    removeItemFromCache,
  } = usePaginatedAPICall<ActionRecord>();

  const fetchActions = React.useCallback(
    (tagFilters?: string[]) => {
      const baseEndpoint = "/actions/query";
      const requestBody: SearchQueryBody = {
        sort_direction: "DESC",
      };
      if (accessibility === Accessibility.Organization) {
        requestBody.sort_by = "created";
      }
      if (tagFilters?.length) {
        const conditions: Condition[] = tagFilters.map((tag) => {
          return {
            field: "tags",
            comparator: "CONTAINS",
            value: tag,
          };
        });
        requestBody.condition = {
          operator: "OR",
          conditions,
        };
      }
      const request: RobotoAPICall = {
        endpoint: () => {
          if (accessibility === Accessibility.Organization) {
            return baseEndpoint;
          }
          return `${baseEndpoint}/actionhub`;
        },
        method: "POST",
        requestBody: JSON.stringify(requestBody),
        orgId: org?.org_id,
      };

      void getFirstPage(request, INITIAL_ROWS_PER_PAGE);
    },
    [getFirstPage, org?.org_id, accessibility],
  );

  // On mount, fetch the first page of actions
  React.useEffect(() => {
    fetchActions();
  }, [fetchActions]);

  let onDeleteCallback = undefined;
  if (capabilities.includes(Capability.Delete)) {
    onDeleteCallback = (action: ActionRecord) =>
      removeItemFromCache(action.name, "name");
  }

  const actionsView =
    view === View.CardGrid ? (
      <ActionCardGrid
        actions={pageData}
        isLoading={loading}
        onDelete={onDeleteCallback}
        org={org}
        accessibility={accessibility}
      />
    ) : (
      <ActionTable
        actions={pageData}
        isLoading={loading}
        onDelete={onDeleteCallback}
        org={org}
      />
    );

  const onPageChangeHandler = async (newPage: number) => {
    if (newPage > page) {
      setPage(page + 1);
      await fetchNextPage(newPage, rowsPerPage);
    } else {
      setPage(page - 1);
      fetchPreviousPage(newPage, rowsPerPage);
    }
  };

  const onRowsPerPageChangeHandler = async (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    let val = parseInt(e?.target?.value);
    if (!Number.isFinite(val)) {
      val = ROWS_PER_PAGE_OPTIONS[0];
    }
    setPage(0);
    setRowsPerPage(val);
    await onRowsPerPageChange(val);
  };

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        marginBottom={theme.spacing(2)}
      >
        <Box
          sx={{
            display: "flex",
            gap: theme.spacing(2),
            overflow: "hidden",
          }}
        >
          {accessibility === Accessibility.Organization && (
            <>
              <RobotoButton
                eventName={"CreateActionClicked"}
                variant="outlined"
                size="small"
                color="primary"
                startIcon={<AddIcon />}
                onClick={() => goto.createAction()}
                sx={{
                  height: theme.actionButtonHeight,
                  minWidth: 80,
                }}
              >
                Create
              </RobotoButton>

              <InvokeActionButton sx={{ height: theme.actionButtonHeight }} />
            </>
          )}

          <Box
            sx={{
              display: {
                xs: "none",
                sm: "block",
              },
              overflow: "hidden",
            }}
          >
            <ActionTagFilter
              accessibility={accessibility}
              onFilterChange={fetchActions}
              sx={tagFilterStyles}
            />
          </Box>
        </Box>
        <ActionsViewToggle
          setView={setView}
          sx={viewToggleStyles}
          view={view}
        />
      </Box>
      <Box width="100%">
        {actionsView}
        <TablePagination
          component="div"
          count={isNextPageAvailable ? -1 : cacheLength}
          nextIconButtonProps={{
            disabled: !isNextPageAvailable,
          }}
          rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
          page={page}
          onPageChange={(_, newPage) => {
            void onPageChangeHandler(newPage);
          }}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={(e) => {
            void onRowsPerPageChangeHandler(e);
          }}
          labelRowsPerPage="Actions per page:"
        />
      </Box>
    </>
  );
}
