import Ajv from "ajv";

import {
  CURRENT_ANNOTATION_SCHEMA_VERSION,
  schema as annotationSchema,
} from "@/shared/components/Annotations/schema";
import {
  CURRENT_VIZ_SCHEMA_VERSION,
  vizSchema,
} from "@/shared/state/visualization/schema";

export const schemaValidator = new Ajv();

schemaValidator.addSchema(annotationSchema, CURRENT_ANNOTATION_SCHEMA_VERSION);
schemaValidator.addSchema(vizSchema, CURRENT_VIZ_SCHEMA_VERSION);
