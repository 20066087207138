import { Routes, Route, Navigate } from "react-router-dom";

import { CreateDataset } from "./CreateDataset";
import { CreateDatasetEvent } from "./CreateDatasetEvent";
import { Dataset } from "./Dataset";

export function DatasetsRoutes() {
  return (
    <Routes>
      <Route index element={<Navigate to="/search" replace />} />
      <Route path="create" element={<CreateDataset />} />
      <Route
        path=":datasetPath/events/create"
        element={<CreateDatasetEvent />}
      />
      <Route path=":datasetPath/*" element={<Dataset />} />
    </Routes>
  );
}
