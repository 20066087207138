import { type ErrorDetail, type MessagePayload } from "@/shared/webworker";

export enum ImageEvent {
  Error = "Error",
  LoadingStateChange = "LoadingStateChange",
}

export interface ImageEventPayloadMap {
  [ImageEvent.Error]: ErrorDetail | Error;
  [ImageEvent.LoadingStateChange]: { isLoading: boolean };
}

export function isErrorEvent(
  event: MessageEvent<MessagePayload>,
): event is MessageEvent<
  MessagePayload<ImageEvent, ImageEventPayloadMap[ImageEvent.Error]>
> {
  return event.data.type === ImageEvent.Error;
}
