import {
  type MessagePathRecord,
  type RepresentationRecord,
  type TopicRecord,
  CanonicalDataType,
  RepresentationStorageFormat,
} from "@/shared/domain/topics";

interface TreeNode<T> {
  children: MessagePathNode[];
  data: T;
  depth: number;
  label: string;
}

export interface MessagePathNode extends TreeNode<MessagePathRecord> {
  parent: MessagePathNode | TopicNode;
}

export type TopicNode = TreeNode<TopicRecord>;
export function isTopicNode(
  node: MessagePathNode | TopicNode,
): node is TopicNode {
  return !("parent" in node);
}

function toPath(messagePath: string): string[] {
  return messagePath.split(".");
}

/**
 * Given a list of TopicRecords, construct a list of TopicNode trees.
 * MessagePaths have a `message_path` field that is a string of dot-separated keys,
 * which indicate their position in the tree. For example:
 * Given:
 * [
 *  {
 *    topic_name: "topic_name",
 *    message_paths: [
 *     { message_path: "foo", ... },
 *     { message_path: "foo.bar", ... },
 *     { message_path: "foo.bar.baz", ... }
 *    ],
 *    ...
 *  }
 * ]
 *
 * Expect:
 * {
 *   label: "topic_name",
 *   data: TopicRecord,
 *   depth: 0,
 *   children: [
 *     {
 *       label: "foo",
 *       data: MessagePathRecord,
 *       depth: 1,
 *       parent: <reference to the "topic_name" node>,
 *       children: [
 *           {
 *             label: "bar",
 *             data: MessagePathRecord,
 *             depth: 2,
 *             parent: <reference to the "foo" node>,
 *             children: [
 *               {
 *                 label: "baz",
 *                 data: MessagePathRecord,
 *                 depth: 3,
 *                 children: [],
 *                 parent: <reference to the "bar" node>
 *               }
 *           ]
 *         }
 *       ]
 *     }
 *   ]
 * }
 */
export function constructTree(topicRecords: TopicRecord[]): TopicNode[] {
  const topicNodes: TopicNode[] = [];
  for (const topicRecord of topicRecords) {
    const topicNode: TopicNode = {
      label: topicRecord.topic_name,
      data: topicRecord,
      depth: 0,
      children: [],
    };
    topicNodes.push(topicNode);

    // Heuristic: "parent_path" must come before "parent_path.child_path" for the tree construction to work.
    // This could otherwise be solved by explicitly tracking `parent_message_path` in the database.
    topicRecord.message_paths.sort((a, b) =>
      a.message_path.localeCompare(b.message_path, undefined, {
        numeric: true,
      }),
    );

    for (const messagePath of topicRecord.message_paths) {
      const path = toPath(messagePath.message_path);
      const parents = path.slice(0, path.length - 1);
      const current = path[path.length - 1];

      let parent: TopicNode | MessagePathNode = topicNode;
      for (const parentPath of parents) {
        const existing: MessagePathNode | undefined = parent.children.find(
          (child) => child.label === parentPath,
        );
        if (existing !== undefined) {
          parent = existing;
        }
      }

      const messagePathNode = {
        children: [],
        data: messagePath,
        depth: parent.depth + 1,
        label: current,
        parent,
      };
      parent.children.push(messagePathNode);
      parent.children.sort((a, b) =>
        a.label.localeCompare(b.label, undefined, { numeric: true }),
      );
    }
  }
  return topicNodes.sort((a, b) => {
    return a.label.localeCompare(b.label, undefined, { numeric: true });
  });
}

/**
 * Walk up the tree of message paths, from child to parent, to find its corresponding topic node.
 */
export function walkMessagePathToTopic(
  messagePath: MessagePathNode,
): TopicNode {
  let parent: MessagePathNode | TopicNode = messagePath;
  while ("parent" in parent) {
    parent = parent.parent;
  }
  return parent;
}

/**
 * Return a pointer to the MCAP file containing data for the given topic message path,
 * or `null` if the given topic message path is not specifically associated with its own MCAP.
 */
function messagePathSpecificMcapRepresentation(
  messagePath: MessagePathNode,
): RepresentationRecord | undefined {
  return messagePath.data.representations.find((representation) => {
    return representation.storage_format === RepresentationStorageFormat.MCAP;
  });
}

/**
 * Find the MCAP file containing data for the given topic message path.
 *
 * Iteratively walks up the message path tree, from the current node up to the topic record,
 * and returns the first MCAP representation it finds.
 */
export function representationForMessagePath(
  messagePath: MessagePathNode,
): RepresentationRecord | null {
  const representation = messagePathSpecificMcapRepresentation(messagePath);
  if (representation !== undefined) {
    return representation;
  }

  let node: MessagePathNode | TopicNode = messagePath;
  while (!isTopicNode(node) && node.parent) {
    node = node.parent;
    if (isTopicNode(node)) {
      return node.data.default_representation;
    }
    const representation = messagePathSpecificMcapRepresentation(node);
    if (representation) {
      return representation;
    }
  }

  return null;
}

export function topicAndRepresentationForMessagePath(
  messagePath: MessagePathNode,
): {
  topic: TopicNode;
  representation: RepresentationRecord;
} {
  const topic = walkMessagePathToTopic(messagePath);
  const representation = representationForMessagePath(messagePath);
  if (!representation) {
    throw new Error(
      `Cannot determine visualization-specific representation for ${topic.data.topic_name}`,
    );
  }
  return { topic, representation };
}

/**
 * Is data represented by the current MessagePathNode contained in the same
 * MCAP file as another topic message path that contains bytes?
 *
 * Used to prevent loading data in a Raw Message panel if the records contain large binary payloads.
 * As of July 2024, doing that crashes the Raw Message panel.
 * If the data fetching approach of the Raw Message panel is updated to account for/avoid this,
 * this check can be safely removed.
 */
export function sharesRepresentationWithBinaryField(
  messagePath: MessagePathNode,
) {
  // If the given message path is itself a binary field, short-circuit further evaluation.
  if (
    messagePath.data.canonical_data_type === CanonicalDataType.Image ||
    messagePath.data.canonical_data_type === CanonicalDataType.Byte
  ) {
    return true;
  }

  const representation = representationForMessagePath(messagePath);
  if (representation === null) {
    // If we don't have a reference to where the bytes of this path of topic data are stored,
    // then the rest of this doesn't matter, because we can't visualize this path of topic data anyway.
    return false;
  }

  // Walk up the tree to find the TopicNode...
  const topicNode = walkMessagePathToTopic(messagePath);

  // ...then BFS to find if any parents/siblings/cousins/aunts to the current message path represent bytes-like values.
  const queue: MessagePathNode[] = [...topicNode.children];
  while (queue.length > 0) {
    const current = queue.shift();
    if (!current) {
      continue;
    }
    if (
      // The node being evaluated is bytes-like...
      (current.data.canonical_data_type === CanonicalDataType.Image ||
        current.data.canonical_data_type === CanonicalDataType.Byte) &&
      // ...and those bytes are in the same MCAP file as the target message path's
      representationForMessagePath(current)?.representation_id ===
        representation.representation_id
    ) {
      return true;
    }
    queue.push(...current.children);
  }

  return false;
}

/**
 * Find a MessagePathNode by traversing the constructed tree.
 * @param rootNode - The root node of the tree to start from (e.g., a TopicNode)
 * @param messagePath - The dot-separated message path string to search for
 * @returns The corresponding MessagePathNode if found, or null if not
 */
export function findMessagePathNode(
  rootNode: TopicNode,
  messagePath: string,
): MessagePathNode | null {
  const pathParts = toPath(messagePath);

  function traverse(
    currentNode: MessagePathNode | TopicNode,
    index: number,
  ): MessagePathNode | null {
    // Base case: if we've traversed all parts
    if (index === pathParts.length) {
      return isTopicNode(currentNode) ? null : currentNode;
    }

    // Find the child node matching the current part
    const childNode = currentNode.children.find(
      (child) => child.label === pathParts[index],
    );

    // If a matching child is found, continue traversing the tree
    return childNode ? traverse(childNode, index + 1) : null;
  }

  // Start the traversal from the root node
  return traverse(rootNode, 0);
}
