import SourceIcon from "@mui/icons-material/Source";
import { Box, Divider, Skeleton, useTheme } from "@mui/material";
import * as React from "react";
import { useNavigate } from "react-router-dom";

import { RobotoIconButton, RobotoTooltip } from "@/shared/components";
import { ToggleSidebar } from "@/shared/components/visualization/controls/ToggleSidebar";
import { FileRecord, IngestionStatus } from "@/shared/domain/files";
import { FileNode } from "@/types";
import { dirname } from "@/utils";

import {
  FileVisualizationControls,
  ViewFileControls,
  WorkspaceControls,
} from "./controls";
import { FileHeaderDatasetLink } from "./FileHeaderDatasetLink";
import { FileHeaderPath } from "./FileHeaderPath";
import { FileNavigationToggle } from "./FileNavigationToggle";

interface FileHeaderProps {
  datasetId?: string;
  fileRecord?: FileRecord;
  siblingFiles?: FileNode[];
  isContentImage: boolean;
  handlePopover: (
    component: "annotations" | "details" | null,
    element: HTMLButtonElement | null,
  ) => void;
}

export const FileHeader: React.FC<FileHeaderProps> = ({
  datasetId,
  fileRecord,
  siblingFiles,
  isContentImage,
  handlePopover,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const hasTopics = fileRecord?.ingestion_status === IngestionStatus.Ingested;

  return (
    <>
      {fileRecord ? (
        <>
          <Box
            sx={{
              marginBottom: theme.spacing(1.5),
            }}
          >
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: {
                  xs: "repeat(2, minmax(0, 1fr))",
                  md: "repeat(3, minmax(0, 1fr))",
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: theme.spacing(2),
                }}
              >
                <ToggleSidebar />
                <RobotoTooltip title={"Go to dataset"}>
                  <span>
                    <RobotoIconButton
                      eventName={"FileDatasetClicked"}
                      eventProperties={{
                        datasetId: datasetId || "",
                        fileId: fileRecord.file_id,
                      }}
                      onClick={() => {
                        navigate(
                          `/datasets/${datasetId}/${dirname(
                            fileRecord.relative_path,
                          )}`,
                        );
                      }}
                      sx={{
                        backgroundColor: theme.palette.action.hover,
                      }}
                    >
                      <SourceIcon
                        fontSize="small"
                        sx={{
                          color: theme.palette.text.secondary,
                          ":hover": {
                            color: theme.palette.text.primary,
                          },
                        }}
                      />
                    </RobotoIconButton>
                  </span>
                </RobotoTooltip>

                <Box
                  sx={{
                    overflow: "hidden",
                  }}
                >
                  <FileHeaderDatasetLink
                    datasetId={datasetId}
                    fileRecord={fileRecord}
                  />

                  <FileHeaderPath fileRecord={fileRecord} />
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  opacity: fileRecord ? 1 : 0,
                  transition: "opacity 0.3s",
                  justifyContent: {
                    xs: "flex-end",
                    md: "center",
                  },
                }}
              >
                <FileNavigationToggle
                  fileRecord={fileRecord}
                  siblingFiles={siblingFiles}
                />
              </Box>

              <Box
                sx={{
                  display: {
                    xs: "none",
                    md: "flex",
                  },
                  alignItems: "center",
                  justifyContent: "flex-end",
                  opacity: fileRecord ? 1 : 0,
                  transition: "opacity 0.3s",
                }}
              >
                <Box
                  sx={{
                    gap: theme.spacing(2),
                    display: "flex",
                  }}
                >
                  <WorkspaceControls
                    fileRecord={fileRecord}
                    hide={hasTopics === false}
                  />
                  <FileVisualizationControls
                    hide={hasTopics === true}
                    isFileAnImage={isContentImage}
                    handlePopover={handlePopover}
                  />
                  <ViewFileControls
                    fileRecord={fileRecord}
                    handlePopover={handlePopover}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
          <Divider />
        </>
      ) : (
        <Skeleton
          animation="wave"
          variant="rounded"
          height={`calc(64px - ${theme.spacing(1.5)})`}
          sx={{
            mb: theme.spacing(1.5),
          }}
        />
      )}
    </>
  );
};
