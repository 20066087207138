import {
  LayoutItem,
  PanelState,
  PanelType,
  isImagePanelState,
  isLogPanelState,
  isMapPanelState,
  isPlotPanelState,
  isRawMessagePanelState,
} from "@/shared/state/visualization";

import { ImagePanel } from "./ImagePanel";
import { LogPanel } from "./LogPanel";
import { LogPanelProvider } from "./LogPanel/panelContext";
import { MapPanel } from "./MapPanel";
import { PlotPanel } from "./PlotPanel";
import { PlotPanelProvider } from "./PlotPanel/panelContext";
import { RawMessagePanel } from "./RawMessagePanel";

interface PanelProps {
  layout: LayoutItem;
  panelState: PanelState;
}

export function Panel(props: PanelProps) {
  const { layout, panelState } = props;

  switch (panelState.type) {
    case PanelType.Log: {
      if (!isLogPanelState(panelState)) {
        return null;
      }
      return (
        <LogPanelProvider>
          <LogPanel key={panelState.id} layout={layout} state={panelState} />
        </LogPanelProvider>
      );
    }
    case PanelType.Image: {
      if (!isImagePanelState(panelState)) {
        return null;
      }
      return (
        <ImagePanel key={panelState.id} layout={layout} state={panelState} />
      );
    }
    case PanelType.Map: {
      if (!isMapPanelState(panelState)) {
        return null;
      }
      return (
        <MapPanel key={panelState.id} layout={layout} state={panelState} />
      );
    }
    case PanelType.Plot: {
      if (!isPlotPanelState(panelState)) {
        return null;
      }
      return (
        <PlotPanelProvider>
          <PlotPanel key={panelState.id} layout={layout} state={panelState} />
        </PlotPanelProvider>
      );
    }
    case PanelType.RawMessage: {
      if (!isRawMessagePanelState(panelState)) {
        return null;
      }
      return (
        <RawMessagePanel
          key={panelState.id}
          layout={layout}
          state={panelState}
        />
      );
    }
    default:
      return null;
  }
}
