import { usePlotPanelContext } from "../panelContext";

import { MoveTool } from "./MoveTool";
import { PlotTool } from "./PlotTool";
import { SelectRegionTool } from "./SelectRegionTool";
import { SyncViewportButton } from "./SyncViewportButton";

export function Toolbar() {
  const { activeTool, setActiveTool } = usePlotPanelContext();

  return (
    <div style={{ display: "flex", gap: "0.25rem", margin: "0 0.25rem" }}>
      <SyncViewportButton />
      <MoveTool
        onClick={() => setActiveTool(PlotTool.Move)}
        selected={activeTool === PlotTool.Move}
      />
      <SelectRegionTool
        onClick={() => setActiveTool(PlotTool.SelectRegion)}
        selected={activeTool === PlotTool.SelectRegion}
      />
    </div>
  );
}
