import Box from "@mui/material/Box";
import React from "react";

import { MetadataForm, MetadataView } from "@/shared/components";
import { AutocompleteType } from "@/shared/components/TagAndMetadataAutocomplete";
import { ActionRecord } from "@/shared/domain/actions";
import { OrgRecord } from "@/shared/domain/orgs";
import { useLazyAPICall } from "@/shared/services/apiHooks";
import { APIResponse, actionEndpoint } from "@/types";

interface UpdateActionMetadataProps {
  initialMetadata: Record<string, string>;
  actionName: string;
  currentOrg: OrgRecord | null;
  onMetadataUpdated?: (arg: APIResponse<ActionRecord>) => void;
}

export const UpdateActionMetadata: React.FC<UpdateActionMetadataProps> = ({
  initialMetadata,
  actionName,
  currentOrg,
  onMetadataUpdated,
}) => {
  const [metadata, setMetadata] =
    React.useState<Record<string, unknown>>(initialMetadata);

  const { initiateRequest } = useLazyAPICall<{
    data: ActionRecord;
  }>();

  const metadataAdded = (key: string, value: string) => {
    if (key && value) {
      const metadataClone = {
        ...metadata,
        [key]: value,
      };
      setMetadata(metadataClone);
      void updateMetadata({
        metadata_changeset: {
          put_fields: { [key]: value },
        },
      });
    }
  };

  const updateMetadata = async (requestBody: object) => {
    const { data, error } = await initiateRequest({
      method: "PUT",
      endpoint: actionEndpoint,
      pathParams: { name: actionName },
      requestBody: JSON.stringify(requestBody),
      orgId: currentOrg?.org_id,
    });

    if (!error && data && onMetadataUpdated) {
      onMetadataUpdated(data);
    }
  };

  return (
    <Box>
      <MetadataView
        metadata={metadata}
        disabled={!onMetadataUpdated}
        onChange={(metadata: Record<string, unknown>, keyDeleted?: string) => {
          setMetadata(metadata);
          if (keyDeleted) {
            void updateMetadata({
              metadata_changeset: {
                remove_fields: [keyDeleted],
              },
            });
          }
        }}
      />
      <MetadataForm
        metadata={metadata}
        autocompleteType={AutocompleteType.ActionMetadataKeys}
        onAddMetadata={metadataAdded}
        disabled={!onMetadataUpdated}
      />
    </Box>
  );
};
