import ExpandCircleDownOutlinedIcon from "@mui/icons-material/ExpandCircleDownOutlined";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {
  Alert,
  Box,
  Breadcrumbs,
  CircularProgress,
  Collapse,
  IconButton,
  Paper,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import * as React from "react";
import { Link } from "react-router-dom";

import { useAuth, useNavigation } from "@/providers";
import { RobotoButton } from "@/shared/components";
import { UISimpleCondition } from "@/shared/components/filters";
import { ActionParameter, ActionRecord } from "@/shared/domain/actions";
import { TriggerRecord } from "@/shared/domain/actions/TriggerRecord";
import { OrganizationTier } from "@/shared/domain/orgs";
import { useLazyAPICall } from "@/shared/services/apiHooks";
import {
  APIResponse,
  ConditionGroup,
  ConditionsOperator,
  actionEndpoint,
  triggersEndpoint,
} from "@/types";

import {
  ActionComputeReqs,
  ActionContainerParams,
  ActionInputData,
  ActionSelector,
} from "../components/action";
import { ActionParamsTable } from "../components/action/ActionParamsTable";
import { ActionTimeoutField } from "../components/action/ActionTimeoutField";
import {
  actionNameError,
  createInitialTimeoutState,
  isActionNameValid,
  isActionTimeoutValid,
  parseComputeRequirements,
  parseContainerParameters,
} from "../components/action/actionUtils";
import { TriggerCondition } from "../components/trigger/TriggerCondition";
import { TriggerForEachSelector } from "../components/trigger/TriggerForEachSelector";

const formErrors = (
  hasComputeFormErrors: boolean,
  name?: string,
  actionTimeout?: number | string,
) => {
  return (
    actionNameError(name) ||
    hasComputeFormErrors ||
    !isActionTimeoutValid(actionTimeout)
  );
};

export const CreateTrigger: React.FC = () => {
  //
  const theme = useTheme();
  const { currentOrganization } = useAuth();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [errorText, setErrorText] = React.useState<string>("");
  const [name, setName] = React.useState<string | undefined>(undefined);
  const [action, setAction] = React.useState<ActionRecord | null>(null);
  const [requiredInputs, setRequiredInputs] = React.useState<
    string[] | undefined
  >(undefined);
  const [additionalInputs, setAdditionalInputs] = React.useState<
    string[] | undefined
  >(undefined);

  const [cpu, setCpu] = React.useState<string | undefined>(undefined);
  const [memory, setMemory] = React.useState<string | undefined>(undefined);
  const [gpu, setGpu] = React.useState<string | undefined>(undefined);
  const [storage, setStorage] = React.useState<number | undefined>(undefined);
  const [workdir, setWorkdir] = React.useState<string | undefined>(undefined);
  const [command, setCommand] = React.useState<string | undefined>(undefined);
  const [entrypoint, setEntrypoint] = React.useState<string | undefined>(
    undefined,
  );
  const [envVars, setEnvVars] = React.useState<
    Record<string, string> | undefined
  >(undefined);

  const [operator, setOperator] = React.useState<ConditionsOperator>("AND");
  const [conditions, setConditions] = React.useState<UISimpleCondition[]>([]);
  const [forEachVal, setForEachVal] = React.useState<string | null>("dataset");

  const [openAdditionalInputs, setOpenAdditionalInputs] =
    React.useState<boolean>(false);

  const [openForEachVal, setOpenForEachVal] = React.useState<boolean>(false);

  const [hasComputeFormErrors, setHasComputeFormErrors] = React.useState(false);

  const isGated =
    currentOrganization?.tier === OrganizationTier.Free ? true : false;

  const [actionTimeout, setActionTimeout] = React.useState<
    number | string | undefined
  >(createInitialTimeoutState(isGated));

  const [openContainerParams, setOpenContainerParams] =
    React.useState<boolean>(false);

  const [openResourceParams, setOpenResourceParams] =
    React.useState<boolean>(false);

  const [parameters, setParameters] = React.useState<
    ActionParameter[] | undefined
  >(undefined);
  const [parameterValues, setParameterValues] = React.useState<
    | {
        [key: string]: unknown;
      }
    | undefined
  >(undefined);

  const { initiateRequest: createTriggerReq } =
    useLazyAPICall<APIResponse<TriggerRecord>>();

  const { goto } = useNavigation();

  const createTrigger = async () => {
    setLoading(true);

    const computeRequirements = parseComputeRequirements(
      cpu,
      memory,
      gpu,
      storage,
    );

    const containerParameters = parseContainerParameters(
      command,
      entrypoint,
      workdir,
      envVars,
    );

    let group: ConditionGroup | null;
    if (conditions.length === 0) {
      group = null;
    } else {
      group = {
        conditions: conditions,
        operator: operator,
      };
    }

    // Create the Trigger
    const { error, data } = await createTriggerReq({
      method: "POST",
      endpoint: triggersEndpoint,
      requestBody: JSON.stringify({
        name: name,
        // Put action.digest into request when we want to enable pinning triggers to a specific action version
        action_name: action?.name,
        action_owner_id: action?.org_id,
        for_each: forEachVal,
        required_inputs: requiredInputs,
        additional_inputs: additionalInputs,
        compute_requirement_overrides: computeRequirements,
        container_parameter_overrides: containerParameters,
        condition: group,
        parameter_values: parameterValues,
        timeout: actionTimeout,
      }),
      orgId: currentOrganization?.org_id,
    });

    if (!error && data) {
      goto.trigger({
        triggerId: data.data.name,
      });
    }

    if (error) {
      setErrorText(error.message);
    }

    setLoading(false);
  };

  const { initiateRequest: getAction } =
    useLazyAPICall<APIResponse<ActionRecord>>();

  React.useEffect(() => {
    async function fetchAction() {
      if (!action) {
        return;
      }

      const queryParams = action.digest
        ? new URLSearchParams({ digest: action.digest })
        : undefined;

      const headers = action.org_id
        ? { "X-Roboto-Resource-Owner-Id": action.org_id }
        : undefined;

      const { data, error } = await getAction({
        endpoint: actionEndpoint,
        method: "GET",
        orgId: currentOrganization?.org_id,
        pathParams: { name: action.name },
        queryParams,
        headers,
      });

      if (!error) {
        setParameters(data?.data.parameters);
        setActionTimeout(data?.data.timeout);
      }
    }

    void fetchAction();
  }, [action, currentOrganization?.org_id, getAction]);

  return (
    <>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        sx={{
          fontSize: "1.125rem",
          fontWeight: "500",
          borderBottom: "unset",
          "& a": {
            color: theme.palette.text.secondary,
            textDecoration: "none",
          },
          "& a:hover": {
            textDecoration: "underline",
          },
        }}
      >
        <Link to="/actions?tab=1">Triggers</Link>,
        <Typography
          sx={{ fontSize: "1.125rem", fontWeight: "500" }}
          color="text.primary"
        >
          Create Trigger
        </Typography>
      </Breadcrumbs>

      <Box>
        <Box
          component={Paper}
          variant="outlined"
          sx={{ p: theme.spacing(2), mt: theme.spacing(3), background: "none" }}
        >
          <Box
            sx={{
              mb: theme.spacing(2),
            }}
          >
            {errorText ? (
              <Alert severity="error">{errorText}</Alert>
            ) : (
              <Alert severity="info">
                Complete the form below to create a new trigger.
              </Alert>
            )}
          </Box>
          <Stack
            component="form"
            sx={{
              maxWidth: "75ch",
            }}
            spacing={3}
            noValidate
            autoComplete="off"
          >
            <Box>
              <Box sx={{ mb: theme.spacing(1) }}>
                <Typography variant="subtitle2">Trigger Name</Typography>
                <Typography variant="caption">
                  Give your trigger a unique name. Note, this can&apos;t be
                  modified after.
                </Typography>
              </Box>
              <TextField
                id="outlined-trigger-name"
                placeholder="Enter a name"
                size="small"
                sx={{ width: 400 }}
                value={name}
                error={actionNameError(name)}
                helperText={
                  name === undefined || (name && isActionNameValid(name))
                    ? ""
                    : "Names cannot contain spaces or special characters"
                }
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setName(event.target.value);
                }}
              />
            </Box>
            <Box>
              <Box sx={{ mb: theme.spacing(1) }}>
                <Typography variant="subtitle2">Action</Typography>
                <Typography variant="caption">
                  Select the action this trigger will invoke from your org or
                  the Action Hub.
                </Typography>
              </Box>
              <ActionSelector
                action={action}
                setAction={setAction}
                currentOrg={currentOrganization}
              />
            </Box>

            {parameters && parameters.length > 0 && (
              <Box>
                <Box sx={{ mb: theme.spacing(1) }}>
                  <Typography variant="subtitle2">Parameters</Typography>
                  <Typography variant="caption">
                    Provide values for this action&apos;s parameters.
                  </Typography>
                </Box>
                <ActionParamsTable
                  editable={true}
                  mode={"values"}
                  params={parameters}
                  setParams={setParameters}
                  paramValues={parameterValues}
                  setParamValues={setParameterValues}
                />
              </Box>
            )}

            <Box>
              <Box sx={{ mb: theme.spacing(1) }}>
                <Typography variant="subtitle2">Required Inputs</Typography>
                <Typography variant="caption">
                  File patterns of data that must be present in a dataset for
                  this trigger to fire.
                  <br />
                  Example:<strong> **/cam_front/*.jpg</strong>
                </Typography>
              </Box>
              <ActionInputData
                inputData={requiredInputs || [""]}
                setInputData={setRequiredInputs}
                minRequired={1}
              />
            </Box>
            <Box>
              <Box sx={{ mb: theme.spacing(1) }}>
                <Typography variant="subtitle2">
                  Conditions (optional)
                </Typography>
                <Typography variant="caption">
                  Rules that determine whether the trigger should run. <br />
                  Example: only trigger this action if dataset tags contains:{" "}
                  <i>sunny</i>
                </Typography>
              </Box>
              <TriggerCondition
                conditions={conditions}
                setConditions={setConditions}
                operator={operator}
                setOperator={setOperator}
                editable={true}
              />
            </Box>
            <ActionTimeoutField
              isGated={isGated}
              showAlert={true}
              actionTimeout={actionTimeout}
              setActionTimeout={setActionTimeout}
            />
            <Box>
              <Box sx={{ mb: theme.spacing(1.5) }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography variant="subtitle2">
                    Additional Inputs (optional)
                  </Typography>
                  <IconButton
                    aria-label="additional-inputs-open"
                    size="small"
                    sx={{
                      ml: theme.spacing(0.25),
                      transform: openAdditionalInputs
                        ? "rotate(180deg)"
                        : "none",
                    }}
                    onClick={() =>
                      setOpenAdditionalInputs(!openAdditionalInputs)
                    }
                  >
                    <ExpandCircleDownOutlinedIcon
                      color="primary"
                      fontSize="small"
                    />
                  </IconButton>
                </Box>
                <Typography variant="caption">
                  File patterns of additional data that will also be mounted if
                  available.
                </Typography>
              </Box>
              <Collapse in={openAdditionalInputs} timeout="auto" unmountOnExit>
                <ActionInputData
                  inputData={additionalInputs || [""]}
                  setInputData={setAdditionalInputs}
                  minRequired={0}
                />
              </Collapse>
            </Box>

            <Box>
              <Box sx={{ mb: theme.spacing(1.5) }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography variant="subtitle2">
                    Invoke For Each (optional)
                  </Typography>
                  <IconButton
                    aria-label="invoke-for-each"
                    size="small"
                    sx={{
                      ml: theme.spacing(0.25),
                      transform: openForEachVal ? "rotate(180deg)" : "none",
                    }}
                    onClick={() => setOpenForEachVal(!openForEachVal)}
                  >
                    <ExpandCircleDownOutlinedIcon
                      color="primary"
                      fontSize="small"
                    />
                  </IconButton>
                </Box>
                <Typography variant="caption">
                  A trigger can invoke an action once for each matching dataset,
                  or once for each matching file.
                </Typography>
              </Box>
              <Collapse in={openForEachVal} timeout="auto" unmountOnExit>
                <TriggerForEachSelector
                  forEach={forEachVal}
                  setForEach={setForEachVal}
                />
              </Collapse>
            </Box>
            <Box>
              <Box sx={{ mb: theme.spacing(2) }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography variant="subtitle2">
                    Container Parameter Overrides (optional)
                  </Typography>
                  <IconButton
                    aria-label="open-container-params"
                    size="small"
                    sx={{
                      ml: theme.spacing(0.25),
                      transform: openContainerParams
                        ? "rotate(180deg)"
                        : "none",
                    }}
                    onClick={() => setOpenContainerParams(!openContainerParams)}
                  >
                    <ExpandCircleDownOutlinedIcon
                      color="primary"
                      fontSize="small"
                    />
                  </IconButton>
                </Box>
                <Typography variant="caption">
                  Override parameters passed to the action&apos;s container at
                  runtime.
                </Typography>
              </Box>
              <Collapse in={openContainerParams} timeout="auto" unmountOnExit>
                <ActionContainerParams
                  workdir={workdir}
                  setWorkdir={setWorkdir}
                  command={command}
                  setCommand={setCommand}
                  entrypoint={entrypoint}
                  setEntrypoint={setEntrypoint}
                  envVars={envVars || {}}
                  setEnvVars={setEnvVars}
                />
              </Collapse>
            </Box>
            <Box>
              <Box sx={{ mb: theme.spacing(2) }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography variant="subtitle2">
                    Compute Requirement Overrides (optional)
                  </Typography>
                  <IconButton
                    aria-label="open-compute-params"
                    size="small"
                    sx={{
                      ml: theme.spacing(0.25),
                      transform: openResourceParams ? "rotate(180deg)" : "none",
                    }}
                    onClick={() => setOpenResourceParams(!openResourceParams)}
                  >
                    <ExpandCircleDownOutlinedIcon
                      color="primary"
                      fontSize="small"
                    />
                  </IconButton>
                </Box>
                <Typography variant="caption">
                  Override compute resources required to run the action.
                </Typography>
              </Box>
              <Collapse in={openResourceParams} timeout="auto" unmountOnExit>
                <ActionComputeReqs
                  cpu={cpu}
                  setCpu={setCpu}
                  memory={memory}
                  setMemory={setMemory}
                  gpu={gpu}
                  setGpu={setGpu}
                  storage={storage}
                  setStorage={setStorage}
                  setHasComputeFormErrors={setHasComputeFormErrors}
                />
              </Collapse>
            </Box>
          </Stack>
          <Box sx={{ mt: theme.spacing(3) }}>
            <Box
              sx={{
                mt: theme.spacing(1),
                display: "flex",
                alignItems: "center",
              }}
            >
              <RobotoButton
                eventName={"CreateTriggerFormSubmitted"}
                eventProperties={{ name: name ?? "" }}
                variant={"contained"}
                color="secondary"
                disabled={
                  name === undefined ||
                  name === "" ||
                  action === null ||
                  requiredInputs === undefined ||
                  formErrors(hasComputeFormErrors, name, actionTimeout)
                }
                onClick={() => void createTrigger()}
              >
                Create Trigger
              </RobotoButton>
              {loading && (
                <CircularProgress sx={{ ml: theme.spacing(1.5) }} size="1rem" />
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};
