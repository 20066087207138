import SettingsIcon from "@mui/icons-material/Settings";
import { IconButton } from "@mui/material";
import classNames from "classnames";

import {
  type PanelState,
  isPlotPanelState,
  isImagePanelState,
} from "@/shared/state/visualization";

import { SidebarViewType, useWorkspaceSidebar } from "../../sidebarContext";
import { useEphemeralWorkspaceState } from "../../WorkspaceCtx";

interface OpenPanelSettingsProps {
  className?: classNames.Argument;
  state: PanelState;
}

/**
 * A button that opens the settings for a panel.
 */
export function OpenPanelSettings({
  className,
  state,
}: OpenPanelSettingsProps) {
  const workspace = useEphemeralWorkspaceState();
  const sidebar = useWorkspaceSidebar();

  const selectPanel = () => {
    workspace.selectPanel(state.id);
    sidebar.openView({
      type: SidebarViewType.PanelSettings,
      data: undefined,
    });
  };

  // Enable this button for panel types for which we have settings.
  const enabled = isImagePanelState(state) || isPlotPanelState(state);
  const title = enabled
    ? "Open panel settings"
    : "No settings available for this panel";

  return (
    <IconButton
      aria-label="open-panel-settings"
      className={classNames(className)}
      disabled={!enabled}
      onClick={selectPanel}
      size="small"
      title={title}
    >
      <SettingsIcon fontSize="inherit" />
    </IconButton>
  );
}
