import { Box, Container, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import * as React from "react";
import { useSearchParams } from "react-router-dom";

import { useAuth, useNavigation } from "@/providers";
import { Page } from "@/shared/components";
import {
  ConfigureOrganizationForm,
  HowDoYouWorkForm,
  InviteTeamMembersForm,
  LoadOrgsAndInvitesForm,
  PendingInvitesForm,
  SelectOrCreateOrganizationForm,
} from "@/shared/components/auth";
import { ConfigureDataRegionForm } from "@/shared/components/auth/ConfigureDataRegionForm";
import { ConfigureExperiences } from "@/shared/components/auth/ConfigureExperiences";
import { WorkMode } from "@/shared/components/auth/HowDoYouWorkForm";
import { OrgInviteRecord, OrgRecord } from "@/shared/domain/orgs";
import { RobotoRegion } from "@/shared/domain/orgs/OrgRecord";
import { useLazyAPICall } from "@/shared/services/apiHooks";
import { orgsInvitesEndpoint } from "@/types";

import { ForgotPasswordForm } from "./ForgotPasswordForm";
import { ResetPasswordForm } from "./ResetPasswordForm";
import { SignInForm } from "./SignInForm";

type CurrentSignInForm =
  | "configureDataRegion"
  | "configureExperiences"
  | "configureOrganization"
  | "forgotPassword"
  | "howDoYouWork"
  | "inviteTeamMembers"
  | "loadOrgs"
  | "pendingInvites"
  | "resetPassword"
  | "selectOrCreateOrganization"
  | "signIn";

interface SignInFormState {
  currentSignInForm: CurrentSignInForm;
  emailAddress: string;
  individualAccountIdentifier: string;
  workMode: WorkMode;
  currentOrganizations: OrgRecord[] | null;
  organizationName?: string;
  allowEmailDomainToJoinOrg?: boolean;
  teamMemberEmails?: string[];
  pendingInvites: OrgInviteRecord[] | null;
  robotoRegion: RobotoRegion;
}

export const SignIn: React.FC = () => {
  const theme = useTheme();

  const [searchParams] = useSearchParams();

  const inviteId = searchParams.get("inviteId") ?? undefined;
  const chooseorg = searchParams.get("chooseorg") ?? undefined;
  const oauth = searchParams.get("oauth") ?? undefined;

  const isOAuthFlow = oauth;
  const isChooseOrgFlow = chooseorg;

  const [formState, setFormState] = useState<SignInFormState>({
    currentSignInForm: "signIn",
    individualAccountIdentifier: "",
    emailAddress: "",
    workMode: "individual",
    currentOrganizations: null,
    pendingInvites: null,
    robotoRegion: RobotoRegion.UsWest,
  });

  const { getCurrentOrganization, isAuthenticated } = useAuth();
  const { goto } = useNavigation();

  const { initiateRequest: initiateInviteRequest } = useLazyAPICall();

  useEffect(() => {
    if (
      formState.currentSignInForm === "signIn" &&
      isAuthenticated &&
      getCurrentOrganization() !== null
    ) {
      goto.home();
    } else if (
      formState.currentSignInForm === "signIn" &&
      isAuthenticated &&
      getCurrentOrganization() === null
    ) {
      // We're in an invite flow, let the invite flow handle the redirect
      if (inviteId) {
        return;
      }

      goto.signIn({
        chooseOrg: true,
      });
    }
  }, [
    goto,
    formState.currentSignInForm,
    getCurrentOrganization,
    inviteId,
    isAuthenticated,
  ]);

  useEffect(() => {
    if (isOAuthFlow || isChooseOrgFlow) {
      setFormState((prevState) => {
        return {
          ...prevState,
          currentSignInForm: "loadOrgs",
        };
      });
    } else {
      setFormState((prevState) => {
        return {
          ...prevState,
          currentSignInForm: "signIn",
        };
      });
    }
  }, [isOAuthFlow, isChooseOrgFlow]);

  return (
    <Page title={"Roboto - Sign In"}>
      <Container
        sx={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "grid",
            rowGap: theme.spacing(4),
            minHeight: "100vh",
            padding: theme.spacing(4, 2),
            marginLeft: "auto",
            marginRight: "auto",
            placeItems: "stretch center",
          }}
        >
          <SignInForm
            inviteId={inviteId}
            isVisible={formState.currentSignInForm === "signIn"}
            forgotPasswordClicked={() => {
              const newFormState: SignInFormState = {
                ...formState,
                currentSignInForm: "forgotPassword",
              };

              setFormState(newFormState);
            }}
            emailSignInSuccess={() => {
              if (inviteId) {
                goto.invite({ inviteId });
                return;
              }
              // useEffect moves the user to the next step
            }}
          />

          <LoadOrgsAndInvitesForm
            isVisible={formState.currentSignInForm === "loadOrgs"}
            onSuccess={(
              currentInviteId: string | null,
              orgs: OrgRecord[],
              pendingInvites: OrgInviteRecord[],
            ) => {
              if (currentInviteId) {
                goto.invite({ inviteId: currentInviteId });
                return;
              }

              const newFormState: SignInFormState = {
                ...formState,
                currentSignInForm: "pendingInvites",
                pendingInvites: pendingInvites,
                currentOrganizations: orgs,
              };

              if (pendingInvites.length > 0) {
                setFormState({
                  ...newFormState,
                  currentSignInForm: "pendingInvites",
                });
              } else {
                setFormState({
                  ...newFormState,
                  currentSignInForm: "selectOrCreateOrganization",
                });
              }
            }}
          />

          <PendingInvitesForm
            isVisible={formState.currentSignInForm === "pendingInvites"}
            invites={formState.pendingInvites}
            skipInvites={() => {
              setFormState({
                ...formState,
                currentSignInForm: "selectOrCreateOrganization",
              });
            }}
          />

          <SelectOrCreateOrganizationForm
            isVisible={
              formState.currentSignInForm === "selectOrCreateOrganization"
            }
            currentOrganizations={formState.currentOrganizations}
            createNewOrganizationClicked={(individualAccountIdentifier) => {
              const newFormState: SignInFormState = {
                ...formState,
                currentSignInForm: "howDoYouWork",
                individualAccountIdentifier: individualAccountIdentifier,
              };

              setFormState(newFormState);
            }}
          />

          <HowDoYouWorkForm
            isVisible={formState.currentSignInForm === "howDoYouWork"}
            goBackPressed={() => {
              setFormState({
                ...formState,
                currentSignInForm: "selectOrCreateOrganization",
              });
            }}
            continueClicked={(workMode) => {
              const newFormState: SignInFormState = {
                ...formState,
                currentSignInForm: "configureDataRegion",
                workMode: workMode,
              };

              setFormState(newFormState);
            }}
          />

          <ConfigureDataRegionForm
            isVisible={formState.currentSignInForm === "configureDataRegion"}
            regionChosen={(region) => {
              setFormState({
                ...formState,
                robotoRegion: region,
                currentSignInForm: "configureOrganization",
              });
            }}
            goBackPressed={() => {
              setFormState({ ...formState, currentSignInForm: "howDoYouWork" });
            }}
          />

          <ConfigureOrganizationForm
            isVisible={formState.currentSignInForm === "configureOrganization"}
            onSuccess={(
              allowEmailDomain: boolean,
              organizationName: string,
            ) => {
              const newFormState: SignInFormState = {
                ...formState,
                currentSignInForm:
                  formState.workMode === "individual"
                    ? "configureExperiences"
                    : "inviteTeamMembers",
                allowEmailDomainToJoinOrg: allowEmailDomain,
                organizationName: organizationName,
              };

              setFormState(newFormState);
            }}
            goBackPressed={() =>
              setFormState({
                ...formState,
                currentSignInForm: "configureDataRegion",
              })
            }
            workMode={formState.workMode}
            dataRegion={formState.robotoRegion}
          />

          <InviteTeamMembersForm
            isVisible={formState.currentSignInForm === "inviteTeamMembers"}
            organizationName={formState.organizationName}
            inviteAndCompletePressed={(emailAddresses) => {
              emailAddresses.forEach((emailAddress) => {
                void initiateInviteRequest({
                  endpoint: orgsInvitesEndpoint,
                  method: "POST",
                  requestBody: JSON.stringify({
                    invited_user_id: emailAddress,
                  }),
                  orgId: getCurrentOrganization()?.org_id,
                });
              });

              const newFormState: SignInFormState = {
                ...formState,
                currentSignInForm: "configureExperiences",
              };

              setFormState(newFormState);
            }}
            skipForNowPressed={() => {
              const newFormState: SignInFormState = {
                ...formState,
                currentSignInForm: "configureExperiences",
              };

              setFormState(newFormState);
            }}
          />

          <ConfigureExperiences
            isVisible={formState.currentSignInForm === "configureExperiences"}
            continueClicked={() => {
              goto.home();
            }}
            orgId={getCurrentOrganization()?.org_id}
          />

          <ForgotPasswordForm
            isVisible={formState.currentSignInForm === "forgotPassword"}
            onSuccess={(emailAddress) => {
              const newFormState: SignInFormState = {
                ...formState,
                currentSignInForm: "resetPassword",
                emailAddress: emailAddress,
              };

              setFormState(newFormState);
            }}
            goBackClicked={() => {
              const newFormState: SignInFormState = {
                ...formState,
                currentSignInForm: "signIn",
              };

              setFormState(newFormState);
            }}
          />
          <ResetPasswordForm
            isVisible={formState.currentSignInForm === "resetPassword"}
            emailAddress={formState.emailAddress}
            goBackClicked={() => {
              const newFormState: SignInFormState = {
                ...formState,
                currentSignInForm: "forgotPassword",
              };

              setFormState(newFormState);
            }}
          />
        </Box>
      </Container>
    </Page>
  );
};
