import {
  CreateEventForm,
  EventDataEntityType,
  EventScope,
  EventSourceType,
} from "@/shared/components/events";
import { isThisPanelSource } from "@/shared/components/events/scope";
import {
  AssociationRecord,
  AssociationType,
} from "@/shared/domain/association";
import { CreateEventRequest } from "@/shared/domain/events";
import {
  isPlotPanelState,
  PanelState,
  State,
  TopicData,
} from "@/shared/state/visualization";

import { EphemeralWorkspaceState } from "../WorkspaceCtx/EphemeralWorkspaceState";

export interface CreateWorkspaceEventForm extends CreateEventForm {
  scope: EventScope;
}

export type CreateWorkspaceEventFormDefaults = Partial<
  Omit<CreateWorkspaceEventForm, "start_time" | "end_time"> & {
    start_time: bigint;
    end_time: bigint;
  }
>;

interface TimeRange {
  startTime: bigint;
  endTime: bigint;
}

function getTopicDataForPanel(panel: PanelState): TopicData[] {
  if (isPlotPanelState(panel)) {
    return panel.data.map((series) => series.data);
  }

  // TODO support other panel types
  return [];
}

function getTopicsForSource(
  source: EventScope["source"],
  panels: State["panels"] = {},
): TopicData[] {
  if (isThisPanelSource(source)) {
    return getTopicDataForPanel(panels[source.panelId]);
  }

  if (source.type === EventSourceType.AllPanels) {
    return Object.values(panels).flatMap((panel) =>
      getTopicDataForPanel(panel),
    );
  }

  throw new Error("Unsupported source type");
}

function filterTopicsByTimeRange(topics: TopicData[], timeRange: TimeRange) {
  return topics.filter((topicData) => {
    if (
      topicData.topic.startTime === undefined ||
      topicData.topic.endTime === undefined
    ) {
      return false;
    }

    const startTime = BigInt(topicData.topic.startTime);
    const endTime = BigInt(topicData.topic.endTime);

    return startTime <= timeRange.endTime && endTime >= timeRange.startTime;
  });
}

function getAssociationsFromTopics(
  topics: TopicData[],
  dataEntity: EventScope["dataEntity"],
  files: EphemeralWorkspaceState["files"],
): AssociationRecord[] {
  switch (dataEntity.type) {
    case EventDataEntityType.AllFiles:
      return Array.from(
        new Map(
          topics.map((topicData) => [
            topicData.topic.association.association_id,
            topicData.topic.association,
          ]),
        ).values(),
      );
    case EventDataEntityType.AllTopics:
      return Array.from(
        new Map(
          topics.map((topicData) => [
            topicData.topic.id,
            {
              association_id: topicData.topic.id,
              association_type: AssociationType.Topic,
            },
          ]),
        ).values(),
      );
    case EventDataEntityType.AllMessagePaths:
      return topics.map((topicData) => ({
        association_id: topicData.messagePath.id,
        association_type: AssociationType.MessagePath,
      }));
    case EventDataEntityType.AllDatasets: {
      const fileIds = Array.from(
        new Set(
          topics.map((topicData) => topicData.topic.association.association_id),
        ),
      );
      const fileRecords = files.filter((file) =>
        fileIds.includes(file.file_id),
      );
      return fileRecords.map((fileRecord) => ({
        association_id: fileRecord.association_id,
        association_type: AssociationType.Dataset,
      }));
    }
    default:
      return [];
  }
}

function scopeToAssociations({
  files,
  panels,
  scope,
  timeRange,
}: {
  files: EphemeralWorkspaceState["files"];
  panels: State["panels"];
  scope: EventScope;
  timeRange: TimeRange;
}): AssociationRecord[] {
  const topics = getTopicsForSource(scope.source, panels);
  const filteredTopics = filterTopicsByTimeRange(topics, timeRange);
  return getAssociationsFromTopics(filteredTopics, scope.dataEntity, files);
}

export function makeCreateEventRequest(
  formData: CreateWorkspaceEventForm,
  vizState: State,
  workspaceState: EphemeralWorkspaceState,
): CreateEventRequest {
  const timeRange = {
    startTime: BigInt(formData.start_time),
    endTime: BigInt(formData.end_time),
  };
  return {
    ...formData,
    start_time: timeRange.startTime,
    end_time: timeRange.endTime,
    associations: scopeToAssociations({
      files: workspaceState.files,
      panels: vizState.panels,
      scope: formData.scope,
      timeRange,
    }),
  };
}
