import { Alert, Box, useTheme } from "@mui/material";

import { Accessibility } from "@/shared/domain/actions";
import { OrgRecord } from "@/shared/domain/orgs";

import ActionsView from "./action/ActionsView";
import { Capability, View } from "./action/types";

interface OrgActionsProps {
  currentOrg: OrgRecord | null;
}

const ACTION_VIEW_CAPABILITIES = [Capability.Delete, Capability.Invoke];

/**
 * Lists Actions accessible within the current org.
 */
export function OrgActions({ currentOrg }: OrgActionsProps) {
  const theme = useTheme();
  const verticalSpacing = theme.spacing(3);

  return (
    <>
      <Box marginBottom={verticalSpacing}>
        <Alert severity="info">
          Actions run code on datasets uploaded to Roboto. You can use them to
          process data and programmatically add tags or metadata.
        </Alert>
      </Box>
      <ActionsView
        accessibility={Accessibility.Organization}
        capabilities={ACTION_VIEW_CAPABILITIES}
        defaultView={View.CardGrid}
        org={currentOrg}
      />
    </>
  );
}
