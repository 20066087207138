import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import * as React from "react";

import { DatasetRecord } from "@/shared/domain/datasets";
import { useDataset } from "@/shared/domain/datasets/hooks/useDataset.ts";
import { LoggerService } from "@/shared/services";

import { RobotoLinkHoverUnderline } from "../RobotoLinkHoverUnderline";

function defaultLinkName(datasetRecord: DatasetRecord) {
  return datasetRecord.dataset_id;
}

interface DatasetLinkProps {
  datasetId: string;
  getLinkName?: (datasetRecord: DatasetRecord) => string;
}

export const DatasetLink: React.FC<DatasetLinkProps> = ({
  datasetId,
  getLinkName = defaultLinkName,
}) => {
  const datasetQuery = useDataset(datasetId);

  if (datasetQuery.isLoading) {
    return (
      <Typography variant="body2">
        <Skeleton variant="text" width="200px" />
      </Typography>
    );
  }

  if (
    datasetQuery.isError &&
    datasetQuery.error.name === "RobotoNotFoundException"
  ) {
    return (
      <Typography
        color="GrayText"
        sx={{ textDecorationLine: "line-through" }}
        title="This dataset no longer exists"
        variant="body2"
      >
        {datasetId}
      </Typography>
    );
  }

  if (datasetQuery.isError || datasetQuery.data === undefined) {
    LoggerService.error(datasetQuery.error?.message, datasetQuery.error);
    return (
      <Typography
        color="error"
        title="Failed to load link to dataset"
        variant="body2"
      >
        {datasetId}
      </Typography>
    );
  }

  return (
    <RobotoLinkHoverUnderline to={`/datasets/${datasetId}`} variant="body2">
      {getLinkName(datasetQuery.data)}
    </RobotoLinkHoverUnderline>
  );
};
