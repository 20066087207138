import * as React from "react";

import { RobotoTypography } from "@/shared/components/RobotoTypography";

import styles from "./ConfigItem.module.css";

interface ConfigItemProps {
  label: React.ReactNode;
}

/**
 * A reusable layout component for displaying a labeled configuration option.
 * It provides a consistent structure for plot series settings.
 */
export function ConfigItem({
  children,
  label,
}: React.PropsWithChildren<ConfigItemProps>) {
  return (
    <div className={styles.container}>
      <RobotoTypography className={styles.label}>{label}</RobotoTypography>
      {children}
    </div>
  );
}
