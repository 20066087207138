import { Box, Divider, useTheme } from "@mui/material";
import { useSearchParams } from "react-router-dom";

import { useAuth } from "@/providers";
import {
  NavigationPage,
  PageHeader,
  RobotoTypography,
} from "@/shared/components";
import {
  RobotoStyledTab,
  RobotoStyledTabs,
  RobotoTabPanel,
} from "@/shared/components/RobotoStyledTabs";

import { OrganizationSettings } from "./OrganizationSettings";
import { Tokens } from "./Tokens";
import { Usage } from "./Usage";

export function Settings() {
  const theme = useTheme();

  const { currentOrganization, currentUser } = useAuth();

  const [searchParams, setSearchParams] = useSearchParams();

  const tabSearchParam = searchParams.get("tab");
  const tabValue = tabSearchParam !== null ? parseInt(tabSearchParam) : 0;

  return (
    <NavigationPage title={"Roboto - Settings"}>
      <PageHeader>Settings</PageHeader>

      <Box
        sx={{
          paddingTop: theme.spacing(3),
        }}
      >
        <RobotoStyledTabs
          value={tabValue}
          onChange={(_event, newValue) => {
            setSearchParams({ tab: String(newValue) });
          }}
          aria-label="settings tabs"
          variant="scrollable"
        >
          <RobotoStyledTab label="Overview" />
          <RobotoStyledTab data-cy="organization" label="Organization" />
          <RobotoStyledTab label="Tokens" />
          <RobotoStyledTab label="Usage" />
        </RobotoStyledTabs>

        <Divider
          sx={{
            borderColor: theme.border.color,
          }}
        />
      </Box>

      <Box
        sx={{
          padding: theme.spacing(2, 0),
          paddingBottom: theme.spacing(1),
        }}
      >
        <RobotoTabPanel
          index={0}
          value={tabValue}
          sx={{
            padding: theme.spacing(2),
            paddingBottom: theme.spacing(1),
          }}
        >
          <RobotoTypography variant="body2">
            Welcome to the Roboto settings panel!
            <br />
            <br />
            Use the tabs above to review your organization, tokens and usage.
          </RobotoTypography>
        </RobotoTabPanel>

        <RobotoTabPanel
          index={1}
          value={tabValue}
          sx={{
            padding: theme.spacing(2),
            paddingBottom: theme.spacing(1),
          }}
        >
          <OrganizationSettings
            currentOrg={currentOrganization}
            currentUser={currentUser}
          />
        </RobotoTabPanel>

        <RobotoTabPanel index={2} value={tabValue}>
          <Tokens />
        </RobotoTabPanel>

        <RobotoTabPanel
          index={3}
          value={tabValue}
          sx={{
            padding: theme.spacing(2),
            paddingBottom: theme.spacing(3),
          }}
        >
          <Usage showGraphs={true} />
        </RobotoTabPanel>
      </Box>
    </NavigationPage>
  );
}
