import VisibilityIcon from "@mui/icons-material/Visibility";
import { Box, Button, useTheme } from "@mui/material";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import * as React from "react";
import { useParams } from "react-router-dom";

import { useAuth } from "@/providers";
// TODO break up OrganizationSettings and put the pieces into /shared
import { useDomainServices } from "@/providers/DomainServices";
import { OrganizationSettings } from "@/Settings/OrganizationSettings";
import { NavigationPage } from "@/shared/components";
import { SystemUserOnly } from "@/shared/components/SystemUserOnly";
import { OrgRecord } from "@/shared/domain/orgs";

import { OrgUpdateTierButton } from "./OrgUpdateTierButton";

function useOrg(orgId: string | undefined) {
  const { orgService } = useDomainServices();

  return useQuery({
    queryKey: ["org", orgId],
    queryFn: ({ signal }) => {
      if (orgId === undefined) {
        return Promise.reject(new Error("Invalid orgId"));
      }

      return orgService.getOrg(orgId, {
        abortSignal: signal,
      });
    },
    enabled: orgId !== undefined,
    // 5 minutes until stale. Chosen arbitrarily.
    staleTime: 60000,
  });
}

function useSetOrg() {
  const queryClient = useQueryClient();

  return React.useCallback(
    function setOrg(org: OrgRecord) {
      queryClient.setQueryData(["org", org.org_id], org);
    },
    [queryClient],
  );
}

export function Org() {
  const theme = useTheme();
  const { currentUser, setCurrentOrganization } = useAuth();

  const { orgId } = useParams();
  const orgQuery = useOrg(orgId);
  const setOrg = useSetOrg();

  return (
    <NavigationPage title={`Roboto - Orgs - ${orgId}`}>
      <SystemUserOnly>
        {orgQuery.data && (
          <>
            <Box
              sx={{
                display: "flex",
                gap: theme.spacing(2),
              }}
            >
              <Button
                variant="contained"
                disableElevation
                onClick={() => setCurrentOrganization(orgQuery.data)}
                style={{
                  textTransform: "none",
                }}
                startIcon={<VisibilityIcon fontSize="small" />}
              >
                View As
              </Button>
              <OrgUpdateTierButton
                orgId={orgQuery.data.org_id}
                currentOrgTier={orgQuery.data.tier}
                onSuccess={setOrg}
              />
            </Box>
            <Box mt={theme.spacing(2)}>
              <OrganizationSettings
                currentOrg={orgQuery.data}
                currentUser={currentUser}
              />
            </Box>
          </>
        )}
      </SystemUserOnly>
    </NavigationPage>
  );
}
