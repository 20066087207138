import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {
  Alert,
  Box,
  Breadcrumbs,
  Divider,
  List,
  ListItem,
  ListItemText,
  Skeleton,
  Typography,
  useTheme,
} from "@mui/material";
import * as React from "react";
import { Link, useParams } from "react-router-dom";

import { NavigationPage, UserLink } from "@/shared/components";
import { TokensTable } from "@/shared/components/tokens";
import {
  useDevice,
  useDeviceTokens,
} from "@/shared/domain/devices/hooks/useDevice";

/**
 * Details page for a single Device.
 */
export const Device: React.FC = () => {
  const theme = useTheme();
  const { deviceId } = useParams<{ deviceId: string | undefined }>();

  const deviceQuery = useDevice(deviceId);
  const deviceTokensQuery = useDeviceTokens(deviceId);

  const [errorText, setErrorText] = React.useState<string>();

  const breadcrumbs = [
    <Link key="list" to="#">
      Devices
    </Link>,
    <Typography
      key="collection-id"
      sx={{ fontSize: "1.125rem", fontWeight: "500" }}
      color="text.secondary"
    >
      {deviceId}
    </Typography>,
  ];

  const device = deviceQuery.data;
  const deviceTokens = deviceTokensQuery.data;

  const dataLoading = deviceQuery.isLoading || deviceTokensQuery.isLoading;

  return (
    <NavigationPage title={`Roboto - Device - ${deviceId}`}>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        sx={{
          fontSize: "1.125rem",
          fontWeight: "500",
          borderBottom: "unset",
          "& a": {
            color: theme.palette.text.secondary,
            textDecoration: "none",
          },
          "& a:hover": {
            textDecoration: "underline",
          },
        }}
      >
        {breadcrumbs}
      </Breadcrumbs>

      <Box
        sx={{
          paddingTop: theme.spacing(2),
        }}
      >
        <Divider
          sx={{
            borderColor: theme.border.color,
          }}
        />
      </Box>

      {!device && dataLoading && (
        <Box marginTop={theme.spacing(3)}>
          <Skeleton variant="rounded" height="300px" />
        </Box>
      )}

      {(deviceQuery.error || errorText) && (
        <Box
          sx={{
            mt: theme.spacing(2),
            mb: theme.spacing(1),
          }}
        >
          <Alert severity="error">
            {deviceQuery.error && deviceQuery?.error?.message}
            {errorText && errorText}
          </Alert>
        </Box>
      )}

      {device && !dataLoading && (
        <Box>
          <List sx={{ width: "100%" }}>
            <ListItem>
              <ListItemText
                primary={"Device ID"}
                secondary={device.device_id}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={"Created"}
                secondary={new Date(device.created || "").toLocaleString()}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={"Created By"}
                secondary={<UserLink userId={device.created_by ?? ""} />}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={"Modified"}
                secondary={new Date(device.modified || "").toLocaleString()}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={"Modified By"}
                secondary={<UserLink userId={device.modified_by ?? ""} />}
              />
            </ListItem>
          </List>
        </Box>
      )}

      {deviceTokens && !dataLoading && (
        <Box padding={theme.spacing(2)}>
          <TokensTable
            data={deviceTokens}
            loading={deviceTokensQuery.isLoading}
            refetch={() => {
              void deviceTokensQuery.refetch();
            }}
            setErrorText={setErrorText}
          />
        </Box>
      )}
    </NavigationPage>
  );
};
