import { useQuery } from "@tanstack/react-query";
import * as React from "react";

import { DomainServicesContext } from "@/providers/DomainServices";
import { AssociationType } from "@/shared/domain/association";
import { TopicRecord } from "@/shared/domain/topics";

/**
 * Fetch TopicRecords associated with the given file.
 */
export function useFileTopics(
  fileId: string,
  fileVersion: number,
  options?: { isEnabled?: boolean },
) {
  const { topics: topicService } = React.useContext(DomainServicesContext);
  const { isEnabled = true } = options || {};

  return useQuery<TopicRecord[], Error>({
    queryKey: ["fileTopics", fileId, fileVersion],
    queryFn: ({ signal }) => {
      return topicService.getTopicsByAssociation(
        {
          association_id: fileId,
          association_type: AssociationType.File,
          association_version: fileVersion,
        },
        {
          abortSignal: signal,
        },
      );
    },
    enabled: isEnabled,
    // Post-ingestion, neither topic nor message path records are expected to be fast-changing.
    staleTime: 300000, // 5 minutes, in milliseconds
  });
}
