import KeyboardArrowLeftRoundedIcon from "@mui/icons-material/KeyboardArrowLeftRounded";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import {
  Box,
  CircularProgress,
  IconButton,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import * as React from "react";

import { useNavigation } from "@/providers";
import { FileRecord } from "@/shared/domain/files";
import { useFile } from "@/shared/domain/files/hooks";
import { actions, useVizDispatch } from "@/shared/state/visualization";
import { FileNode } from "@/types";

/*
In JavaScript, '%' is a remainder operator, not modulo.
This makes no difference for positive values, but when the
dividend and divisor are of different signs, they give different
results.
*/
function mod(n: number, m: number) {
  return ((n % m) + m) % m;
}

function getIndexOfFile(
  file: FileRecord | undefined,
  files: FileNode[] | undefined,
) {
  if (!file || !files) {
    return 0;
  }

  for (let i = 0; i < files.length; i++) {
    if (file.file_id === files[i].file?.file_id) {
      return i;
    }
  }

  return -1;
}

interface FileNavigationToggleProps {
  fileRecord: FileRecord;
  siblingFiles?: FileNode[];
}

export const FileNavigationToggle: React.FC<FileNavigationToggleProps> = ({
  fileRecord,
  siblingFiles,
}) => {
  const theme = useTheme();
  const { goto } = useNavigation();
  const vizDispatch = useVizDispatch();

  const [indexEditable, setIndexEditable] = React.useState<boolean>(false);
  const [displayedIndex, setDisplayedIndex] = React.useState<string>();

  // Index of active file in directory
  const currentIndex = React.useMemo(() => {
    return getIndexOfFile(fileRecord, siblingFiles);
  }, [fileRecord, siblingFiles]);

  const nextFile = React.useMemo(() => {
    if (siblingFiles && siblingFiles.length > 0) {
      const nextIndex = mod(currentIndex + 1, siblingFiles.length);
      return siblingFiles[nextIndex].file?.file_id;
    }
  }, [siblingFiles, currentIndex]);

  const prevFile = React.useMemo(() => {
    if (siblingFiles && siblingFiles.length > 0) {
      const prevIndex = mod(currentIndex - 1, siblingFiles.length);
      return siblingFiles[prevIndex].file?.file_id;
    }
  }, [siblingFiles, currentIndex]);

  // Optimistically pre-fetches the next and previous file in the list
  useFile(nextFile);
  useFile(prevFile);

  // If the currentIndex changes, update the displayed indicator
  React.useEffect(() => {
    setDisplayedIndex((currentIndex + 1).toString());
  }, [currentIndex]);

  function goToFile(file: FileRecord) {
    goto.file({
      fileId: file.file_id,
      beforeNavigation: () =>
        vizDispatch(
          actions.putFiles([
            { fileId: file.file_id, relativePath: file.relative_path },
          ]),
        ),
    });
  }

  // If the user clicks the current index it becomes editable
  function updateIndex() {
    if (!siblingFiles) {
      return;
    }
    setIndexEditable(false);
    if (displayedIndex === undefined) {
      return;
    }

    let reqIndex = parseInt(displayedIndex);

    // Ignore values beyond max items
    if (reqIndex > siblingFiles?.length) {
      return;
    }

    // Account for the visual index starting at 1 vs. 0
    reqIndex = mod(reqIndex - 1, siblingFiles.length);
    const file = siblingFiles[reqIndex].file;
    if (!file) {
      return;
    }
    goToFile(file);
  }

  return (
    <Box
      sx={{
        borderRadius: "10px",
        backgroundColor: theme.palette.action.hover,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        paddingLeft: theme.spacing(0.5),
        paddingRight: theme.spacing(0.5),
        gap: theme.spacing(0.25),
        minHeight: "36px",
      }}
    >
      <IconButton
        sx={{
          padding: theme.spacing(0.25),
        }}
        onClick={() => {
          if (!siblingFiles) {
            return;
          }
          const priorIndex = mod(currentIndex - 1, siblingFiles.length);
          const file = siblingFiles[priorIndex].file;
          if (!file) {
            return;
          }
          goToFile(file);
        }}
        // Disabled if it's the only file in the directory
        disabled={siblingFiles === undefined || siblingFiles.length === 1}
      >
        <KeyboardArrowLeftRoundedIcon />
      </IconButton>
      <Typography
        component={"span"}
        variant="body2"
        sx={{
          color: theme.palette.text.primary,
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(2),
          display: "flex",
          cursor: "pointer",
        }}
        onClick={() => {
          setIndexEditable(true);
        }}
      >
        {!siblingFiles ? (
          <CircularProgress size={"1.1rem"} />
        ) : (
          <EditableIndexField
            indexEditable={indexEditable}
            currentIndex={currentIndex}
            displayedIndex={displayedIndex}
            siblingFiles={siblingFiles}
            setDisplayedIndex={setDisplayedIndex}
            updateIndex={updateIndex}
          />
        )}
      </Typography>
      <IconButton
        sx={{
          padding: theme.spacing(0.25),
        }}
        onClick={() => {
          if (!siblingFiles) {
            return;
          }
          const nextIndex = mod(currentIndex + 1, siblingFiles.length);
          const file = siblingFiles[nextIndex].file;
          if (!file) {
            return;
          }
          goToFile(file);
        }}
        // Disabled if it's the only file in the directory
        disabled={siblingFiles === undefined || siblingFiles.length === 1}
      >
        <KeyboardArrowRightRoundedIcon />
      </IconButton>
    </Box>
  );
};

interface EditableIndexFieldProps {
  indexEditable: boolean;
  currentIndex: number;
  displayedIndex: string | undefined;
  siblingFiles: FileNode[];
  setDisplayedIndex: (arg: string) => void;
  updateIndex: () => void;
}

export const EditableIndexField: React.FC<EditableIndexFieldProps> = ({
  indexEditable,
  currentIndex,
  displayedIndex,
  siblingFiles,
  setDisplayedIndex,
  updateIndex,
}) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: theme.spacing(1),
      }}
    >
      {indexEditable ? (
        <TextField
          id="outlined-index-size-small"
          autoFocus
          variant="outlined"
          size="small"
          error={
            // Show red outline on textfield if value exceeds
            // num. of items in directory, or is <= 0
            displayedIndex !== undefined &&
            (parseInt(displayedIndex) > siblingFiles.length ||
              parseInt(displayedIndex) <= 0)
          }
          value={displayedIndex}
          InputProps={{
            sx: {
              fontSize: "0.875rem",
            },
          }}
          inputProps={{
            sx: {
              textAlign: "center",
              p: theme.spacing(0.5),
              pl: theme.spacing(1.5),
              pr: theme.spacing(1.5),
            },
          }}
          sx={{
            width: "60px",
            p: 0,
          }}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setDisplayedIndex(event.target.value);
          }}
          onKeyDown={(ev) => {
            if (ev.key === "Enter") {
              updateIndex();
              ev.preventDefault();
            }
          }}
          onBlur={() => {
            updateIndex();
          }}
        />
      ) : (
        <>
          {currentIndex + 1} {siblingFiles && `/ ${siblingFiles.length}`}
        </>
      )}
    </Box>
  );
};
