import { isDetachPortCommand } from "./messaging";

/**
 * Forward an AbortSignal from one JS realm to another.
 */
export class AbortSignalForwarder {
  #signal: AbortSignal;
  #localPort: MessagePort;
  #forwardedPort: MessagePort;
  #isOpen = false;

  constructor(signal: AbortSignal) {
    this.#signal = signal;

    const messageChannel = new MessageChannel();
    this.#localPort = messageChannel.port1;
    this.#forwardedPort = messageChannel.port2;

    this.#signal.addEventListener("abort", this.#forwardAbortEvent);

    this.#localPort.addEventListener("message", this.#listenForDetachCommand);
    this.#localPort.start();
    this.#isOpen = true;
  }

  public get forwardedPort() {
    return this.#forwardedPort;
  }

  /**
   * This getter exists for testing purposes.
   */
  public get isOpen() {
    return this.#isOpen;
  }

  close = () => {
    this.#signal.removeEventListener("abort", this.#forwardAbortEvent);
    this.#localPort.removeEventListener(
      "message",
      this.#listenForDetachCommand,
    );
    this.#localPort.close();
    this.#isOpen = false;
  };

  #forwardAbortEvent = () => {
    this.#localPort.postMessage({ reason: this.#signal.reason as unknown });
    this.close();
  };

  #listenForDetachCommand = (event: MessageEvent) => {
    if (isDetachPortCommand(event)) {
      this.close();
    }
  };
}

/**
 * Receive an AbortSignal from another JS realm via a MessagePort,
 * exposing an AbortController for the signal.
 */
export class AbortSignalReceiver {
  public static REASON = "AbortError";

  #abortController: AbortController;
  #port: MessagePort;

  constructor(port: MessagePort) {
    this.#abortController = new AbortController();
    this.#port = port;

    this.#port.addEventListener("message", this.#receivedForwardedAbortEvent, {
      once: true,
    });
    this.#port.start();
  }

  public get signal() {
    return this.#abortController.signal;
  }

  detachAbortSignalPort = () => {
    // Post back to the UI thread to detach eventlistener registered
    // on source AbortSignal
    this.#port.postMessage("detach");

    // Remove eventlistener registered on signalPort
    this.#port.removeEventListener(
      "message",
      this.#receivedForwardedAbortEvent,
    );

    // Shutdown message channel for this abort signal
    this.#port.close();
  };

  #receivedForwardedAbortEvent = (event: MessageEvent) => {
    if (event.data && "reason" in event.data) {
      // The abort controller on the other side of this port has been aborted
      this.#abortController.abort(AbortSignalReceiver.REASON);
    }
    this.#port.close();
  };
}
