import { Box, CircularProgress, useTheme } from "@mui/material";
import * as React from "react";

import { DomainServicesContext } from "@/providers/DomainServices";
import {
  RobotoButton,
  RobotoLogo,
  RobotoTypography,
} from "@/shared/components";
import {
  humanReadableRobotoRegion,
  RobotoRegion,
} from "@/shared/domain/orgs/OrgRecord";

import { AuthCard } from "./AuthCard";
import styles from "./AuthFlowButton.module.css";
import { DataRegionCard } from "./DataRegionCard";
import { LegalFooter } from "./LegalFooter";

interface ConfigureDataRegionFormProps {
  isVisible: boolean;
  regionChosen: (robotoRegion: RobotoRegion) => void;
  goBackPressed: () => void;
}

export const ConfigureDataRegionForm: React.FC<
  ConfigureDataRegionFormProps
> = ({ isVisible, regionChosen, goBackPressed }) => {
  const theme = useTheme();

  const [availableRegions, setAvailableRegions] = React.useState<
    RobotoRegion[]
  >([]);
  const [selectedRegion, setSelectedRegion] =
    React.useState<RobotoRegion | null>(null);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<Error | null>(null);

  const { orgService } = React.useContext(DomainServicesContext);

  React.useEffect(() => {
    const fetchRegions = async () => {
      setLoading(true);
      setError(null);
      try {
        const regions = await orgService.getAvailableDataRegions();
        setAvailableRegions(regions);

        if (regions.length > 0) {
          if (regions.includes(RobotoRegion.UsWest)) {
            setSelectedRegion(RobotoRegion.UsWest);
          } else if (regions.includes(RobotoRegion.UsGovWest)) {
            setSelectedRegion(RobotoRegion.UsGovWest);
          } else {
            setSelectedRegion(regions[0]);
          }
        }
      } catch (err) {
        setError(err instanceof Error ? err : new Error("An error occurred"));
      } finally {
        setLoading(false);
      }
    };

    void fetchRegions();
  }, [orgService]);

  if (!isVisible) {
    return null;
  }

  return (
    <>
      <RobotoLogo sx={{ maxWidth: "250px" }} />

      <AuthCard>
        <Box>
          <RobotoTypography
            variant="h5"
            textAlign={"center"}
            sx={{ marginBottom: theme.spacing(2) }}
          >
            Data Region
          </RobotoTypography>
          <RobotoTypography variant="body2" textAlign={"center"}>
            Select a region where your data should be stored
          </RobotoTypography>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            mt: theme.spacing(3),
            gap: theme.spacing(2),
            width: "100%",
          }}
        >
          {error && (
            <RobotoTypography
              variant={"caption"}
              textAlign={"center"}
              sx={{ color: theme.palette.error.main }}
            >
              {error.message}
            </RobotoTypography>
          )}
          {loading ? (
            <Box>
              <RobotoTypography>Loading available regions...</RobotoTypography>
              <CircularProgress size="1.75rem" />
            </Box>
          ) : (
            availableRegions
              .sort((a, b) => {
                return -a.localeCompare(b);
              })
              .map((dataRegion) => {
                return (
                  <DataRegionCard
                    key={dataRegion}
                    title={humanReadableRobotoRegion(dataRegion)}
                    emoji={dataRegion.startsWith("us") ? "🇺🇸" : "🇪🇺"}
                    selected={selectedRegion === dataRegion}
                    regionSelected={() => {
                      setSelectedRegion(dataRegion);
                    }}
                  />
                );
              })
          )}

          <RobotoTypography variant="body2" textAlign={"center"}>
            You can also connect to your own bucket with a premium account.
            Contact us after sign up to learn more.
          </RobotoTypography>
        </Box>
        <Box
          className={styles.authFlowSmallButton}
          sx={{
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            mt: theme.spacing(2),
          }}
        >
          <RobotoButton
            className={styles.authFlowSmallButton}
            data-cy={"continue-button"}
            eventName={"GoToNextStep"}
            eventProperties={{ currentStep: "ConfigureDataRegion" }}
            variant="contained"
            disabled={selectedRegion === null}
            sx={{
              marginBottom: theme.spacing(2),
            }}
            onClick={() => {
              if (selectedRegion) {
                regionChosen(selectedRegion);
              }
            }}
          >
            Continue
          </RobotoButton>

          <RobotoButton
            eventName={"GoToPreviousStep"}
            eventProperties={{ currentStep: "ConfigureDataRegion" }}
            onClick={goBackPressed}
            className={styles.authFlowSmallButton}
          >
            Go back a step
          </RobotoButton>
        </Box>
      </AuthCard>
      <LegalFooter />
    </>
  );
};
