import * as React from "react";

import { EventRecord } from "@/shared/domain/events";
import { FileRecord } from "@/shared/domain/files";
import { TopicRecord, MessagePathRecord } from "@/shared/domain/topics";

import { Timer } from "../timer";

const noop = () => {};

export interface EphemeralWorkspaceState {
  // Server state
  events: EventRecord[];
  files: FileRecord[];
  topics: TopicRecord[];
  messagePaths: MessagePathRecord[];
  loading: boolean;
  error?: Error | null;

  // Events CRUD
  addEventToWorkspace: (event: EventRecord) => void;
  removeEventFromWorkspace: (eventId: EventRecord["event_id"]) => void;
  updateEventInWorkspace: (event: EventRecord) => void;

  // Panel selection
  selectedPanelId: string | null;
  selectPanel: (panelId: string) => void;
  clearPanelSelection: () => void;
  clearPanelSelectionIfMatches: (panelId: string) => void; // compare-and-set

  // Timer
  timer: Timer;
}

export const EphemeralWorkspaceStateContext =
  React.createContext<EphemeralWorkspaceState>({
    events: [],
    files: [],
    topics: [],
    messagePaths: [],
    loading: false,
    error: null,
    selectedPanelId: null,
    selectPanel: noop,
    clearPanelSelection: noop,
    clearPanelSelectionIfMatches: noop,
    addEventToWorkspace: noop,
    removeEventFromWorkspace: noop,
    updateEventInWorkspace: noop,
    timer: new Timer({ spans: [] }),
  });
