import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";

import { useAuth } from "@/providers";
import { RobotoButton } from "@/shared/components";
import { Accessibility, ActionRecord } from "@/shared/domain/actions";
import { useLazyAPICall } from "@/shared/services/apiHooks";
import { APIServiceError, actionAccessibilityEndpoint } from "@/types";

interface ChangeActionAccessibilityButtonProps {
  action: ActionRecord;
  onSuccess?: () => void;
  onError?: (error: APIServiceError) => void;
}

export default function ChangeActionAccessibilityButton({
  action,
  onSuccess,
  onError,
}: ChangeActionAccessibilityButtonProps) {
  const { currentOrganization } = useAuth();

  const isPublic = action?.accessibility === Accessibility.ActionHub;
  const { initiateRequest: changeAvailabilityRequest } = useLazyAPICall();

  const onClick = async () => {
    const { error } = await changeAvailabilityRequest({
      endpoint: actionAccessibilityEndpoint,
      method: "PUT",
      orgId: currentOrganization?.org_id,
      pathParams: { actionName: action.name },
      requestBody: JSON.stringify({
        accessibility: isPublic
          ? Accessibility.Organization
          : Accessibility.ActionHub,
      }),
    });

    if (error && onError) {
      onError(error);
      return;
    }

    if (onSuccess) {
      onSuccess();
    }
  };

  return (
    <RobotoButton
      disabled={isPublic} // TODO Public->Private is currently broken https://roboto.atlassian.net/browse/ROBO-529
      eventName={"ChangeActionAccessibilityClicked"}
      eventProperties={{
        accessibility: isPublic ? "public" : "private",
      }}
      variant={"outlined"}
      color="warning"
      size="small"
      startIcon={isPublic ? <LockIcon /> : <LockOpenIcon />}
      onClick={() => {
        void onClick();
      }}
    >
      {isPublic ? "Unpublish" : "Publish"}
    </RobotoButton>
  );
}
