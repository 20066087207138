import ArticleIcon from "@mui/icons-material/Article";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import { Box, Collapse, useTheme } from "@mui/material";
import { useState, useCallback, Fragment } from "react";

import {
  RobotoTableCell,
  RobotoTableRow,
  RobotoLinkHoverUnderline,
  TooltipIconButton,
} from "@/shared/components";
import { TriggerLink } from "@/shared/components/links";
import { UserLink } from "@/shared/components/UserLink";
import { InvocationRecord, InvocationSource } from "@/shared/domain/actions";
import { OrgRecord } from "@/shared/domain/orgs";

import { ActionLink } from "../action/ActionLink";

import CancelInvocationIconButton from "./CancelInvocationIconButton";
import CloneInvocationIconButton from "./CloneInvocationIconButton";
import InvocationLogs from "./InvocationLogs";
import { createMechanicalTimeStr } from "./InvocationRuntimeText/helpers";
import InvocationStatusChip from "./InvocationStatusChip";
import { getInvocation } from "./service";

interface InvocationRowProps {
  invocation: InvocationRecord;
  currentOrg: OrgRecord | null;
  onUpdate: (record: InvocationRecord) => void;
  showDatasetId: boolean;
}

const unsetBorder = { borderBottom: "unset" };

const consistentStatusWidth = { width: "85px", mr: "8px" };

export default function InvocationRow({
  invocation,
  currentOrg,
  onUpdate,
  showDatasetId,
}: InvocationRowProps) {
  const [openLogs, setOpenLogs] = useState(false);
  const theme = useTheme();

  const refetchRecord = useCallback(() => {
    if (!currentOrg?.org_id) {
      return;
    }
    getInvocation(invocation.invocation_id, currentOrg?.org_id)
      .then((record) => onUpdate(record))
      .catch(() => {
        // swallow
      });
  }, [invocation, currentOrg?.org_id, onUpdate]);

  const lastStatusRecord = invocation.status[invocation.status.length - 1];

  let dataSource = null;
  if (showDatasetId) {
    dataSource = (
      <RobotoTableCell sx={unsetBorder}>
        <RobotoLinkHoverUnderline
          variant="body2"
          to={`/datasets/${invocation.data_source.data_source_id}`}
        >
          {invocation.data_source.data_source_id}
        </RobotoLinkHoverUnderline>
      </RobotoTableCell>
    );
  }

  let provenanceSource: JSX.Element | string =
    invocation.provenance.source.source_id;
  if (invocation.provenance.source.source_type === InvocationSource.Trigger) {
    provenanceSource = (
      <TriggerLink triggerId={invocation.provenance.source.source_id} />
    );
  } else if (
    invocation.provenance.source.source_type === InvocationSource.Manual
  ) {
    provenanceSource = (
      <UserLink userId={invocation.provenance.source.source_id} />
    );
  }

  return (
    <Fragment>
      <RobotoTableRow>
        <RobotoTableCell
          sx={{
            ...unsetBorder,
            "& a": {
              color: theme.palette.primary.main,
              textDecoration: "none",
            },
            "& a:hover": {
              textDecoration: "underline",
            },
          }}
        >
          <RobotoLinkHoverUnderline
            variant="body2"
            to={`/invocations/${invocation.invocation_id}`}
          >
            {invocation.invocation_id}
          </RobotoLinkHoverUnderline>
        </RobotoTableCell>
        <RobotoTableCell
          sx={{
            ...unsetBorder,
            whiteSpace: "nowrap",
          }}
        >
          <InvocationStatusChip
            onPollForStatus={refetchRecord}
            size="small"
            status={lastStatusRecord.status}
            variant="outlined"
            sx={consistentStatusWidth}
          />
          <CloneInvocationIconButton invocation={invocation} org={currentOrg} />
          <CancelInvocationIconButton
            invocation={invocation}
            onCancel={refetchRecord}
            org={currentOrg}
          />
        </RobotoTableCell>
        <RobotoTableCell sx={unsetBorder}>
          <ActionLink action={invocation.provenance.action} />
        </RobotoTableCell>
        {dataSource}
        <RobotoTableCell sx={unsetBorder}>
          {createMechanicalTimeStr(invocation.duration)}
        </RobotoTableCell>
        <RobotoTableCell sx={unsetBorder}>
          {new Date(invocation.created).toLocaleString()}
        </RobotoTableCell>
        <RobotoTableCell sx={unsetBorder}>{provenanceSource}</RobotoTableCell>
        <RobotoTableCell sx={unsetBorder}>
          <TooltipIconButton
            title="Open logs"
            size="small"
            onClick={() => setOpenLogs(!openLogs)}
          >
            {openLogs ? <ArticleIcon /> : <ArticleOutlinedIcon />}
          </TooltipIconButton>
        </RobotoTableCell>
      </RobotoTableRow>

      <RobotoTableRow>
        <RobotoTableCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={showDatasetId ? 8 : 7}
        >
          <Collapse in={openLogs} timeout="auto" unmountOnExit>
            <Box
              sx={{
                mt: 0.5,
                mb: 3,
                maxWidth: "1300px",
              }}
            >
              <InvocationLogs invocation={invocation} currentOrg={currentOrg} />
            </Box>
          </Collapse>
        </RobotoTableCell>
      </RobotoTableRow>
    </Fragment>
  );
}
