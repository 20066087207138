import { RobotoLinkHoverUnderline } from "@/shared/components";
import { useTriggerById } from "@/shared/domain/actions/hooks";

interface TriggerLinkProps {
  triggerId: string;
}

export function TriggerLink({ triggerId }: TriggerLinkProps) {
  const result = useTriggerById(triggerId);

  if (result.isPending || result.isError) {
    return triggerId;
  }

  const trigger = result.data;

  return (
    <RobotoLinkHoverUnderline to={`/triggers/${trigger.name}`}>
      {trigger.name}
    </RobotoLinkHoverUnderline>
  );
}
