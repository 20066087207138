import { Box, Typography, useTheme } from "@mui/material";
import * as React from "react";

import { FileSource } from "@/shared/components";
import { FileMetadata } from "@/shared/components/FileMetadataAndTopics/FileMetadata";
import { UpdateFileTags } from "@/shared/components/FileMetadataAndTopics/UpdateFileTags";
import { FileRecord } from "@/shared/domain/files";
import { humanFileSize } from "@/utils";

interface FileDetailsProps {
  fileRecord: FileRecord;
}

export const FileDetails: React.FC<FileDetailsProps> = ({ fileRecord }) => {
  const theme = useTheme();

  const SIDEBAR_HEADER_STYLE = {
    fontWeight: 500,
    color: theme.palette.text.secondary,
  };

  return (
    <Box>
      <Box mb={theme.spacing(3)}>
        <Typography variant="body2">
          <div style={SIDEBAR_HEADER_STYLE}>Size</div>
          {fileRecord?.size !== undefined && humanFileSize(fileRecord.size)}
        </Typography>
      </Box>

      <Box mb={theme.spacing(3)}>
        <Typography variant="body2">
          <div style={SIDEBAR_HEADER_STYLE}>Last Modified</div>
          {fileRecord?.modified !== undefined && (
            <>{new Date(fileRecord?.modified).toLocaleString()}</>
          )}
        </Typography>
      </Box>

      <Box mb={theme.spacing(3)}>
        <Typography variant="body2">
          <div style={SIDEBAR_HEADER_STYLE}>Source</div>
          {<FileSource fileRecord={fileRecord} />}
        </Typography>
      </Box>

      <Box mb={theme.spacing(3)}>
        <Typography variant="body2" component="span">
          <div style={SIDEBAR_HEADER_STYLE}>Tags</div>
          <Box sx={{ mt: 1 }}>
            <UpdateFileTags fileId={fileRecord.file_id} />
          </Box>
        </Typography>
      </Box>

      <Box>
        <Typography variant="body2" component="span">
          <div style={SIDEBAR_HEADER_STYLE}>Metadata</div>
          <Box>
            <FileMetadata fileId={fileRecord.file_id} />
          </Box>
        </Typography>
      </Box>
    </Box>
  );
};
