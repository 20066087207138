import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {
  Alert,
  Box,
  Breadcrumbs,
  Divider,
  Typography,
  useTheme,
} from "@mui/material";
import * as React from "react";
import { Link, useParams } from "react-router-dom";

import { useAuth } from "@/providers";
import { CommentsBox, NavigationPage } from "@/shared/components";
import { CollectionRecord } from "@/shared/domain/collections";
import { useLazyAPICall } from "@/shared/services/apiHooks";
import { APIResponse } from "@/types";

import { collectionEndpoint } from "../api";

import { CollectionDetails } from "./CollectionDetails";

/**
 * Details page for a single Collection.
 *
 * The collection to detail is determined from the page's URL.
 * The URL can contain the collection's ID.
 */
export const Collection: React.FC = () => {
  const { collectionId } = useParams<{ collectionId: string | undefined }>();
  const theme = useTheme();
  const { currentOrganization } = useAuth();

  const {
    data: response,
    error,
    loading,
    initiateRequest,
    updateCache,
  } = useLazyAPICall<APIResponse<CollectionRecord>>();

  React.useEffect(() => {
    async function fetchCollection() {
      if (!currentOrganization?.org_id || !collectionId) {
        return;
      }

      const queryParams = new URLSearchParams();
      queryParams.set("content_mode", "full");

      await initiateRequest({
        endpoint: collectionEndpoint,
        method: "GET",
        orgId: currentOrganization.org_id,
        pathParams: { collectionId: collectionId },
        queryParams,
      });
    }

    void fetchCollection();
  }, [currentOrganization?.org_id, initiateRequest, collectionId]);

  const breadcrumbs = [
    <Link key="list" to="/collections">
      Collections
    </Link>,
    <Typography
      key="collection-id"
      sx={{ fontSize: "1.125rem", fontWeight: "500" }}
      color="text.secondary"
    >
      {response?.data.collection_id}
    </Typography>,
  ];

  let errorContent = null;
  let pageContent = null;
  if (error) {
    errorContent = (
      <Box
        sx={{
          mt: theme.spacing(2),
          mb: theme.spacing(1),
        }}
      >
        <Alert severity="error">{error.message}</Alert>
      </Box>
    );
  } else if (response?.data) {
    pageContent = (
      <CollectionDetails
        collection={response?.data}
        loading={loading}
        onUpdate={updateCache}
      />
    );
  }

  return (
    <NavigationPage title={`Roboto - Collection - ${collectionId}`}>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        sx={{
          fontSize: "1.125rem",
          fontWeight: "500",
          borderBottom: "unset",
          "& a": {
            color: theme.palette.text.secondary,
            textDecoration: "none",
          },
          "& a:hover": {
            textDecoration: "underline",
          },
        }}
      >
        {breadcrumbs}
      </Breadcrumbs>

      {errorContent}

      <Box
        sx={{
          paddingTop: theme.spacing(2),
        }}
      >
        <Divider
          sx={{
            borderColor: theme.border.color,
          }}
        />
      </Box>

      {pageContent}

      <Box
        sx={{
          paddingTop: theme.spacing(3),
        }}
      >
        {!loading && response && (
          <CommentsBox
            entityType={"collection"}
            entityId={response?.data.collection_id}
          />
        )}
      </Box>
    </NavigationPage>
  );
};
