import { Box, SvgIconProps, useTheme } from "@mui/material";
import { ComponentType, ReactElement } from "react";

import { RobotoTypography } from "@/shared/components";

interface TitleRowProps {
  title: string;
  IconComponent: ComponentType<SvgIconProps>;
  ModifyComponent?: ReactElement;
}

export const TitleRow = ({
  title,
  IconComponent,
  ModifyComponent,
}: TitleRowProps) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginBottom: theme.spacing(2),
        width: "100%",
        justifyContent: "space-between",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <IconComponent
          fontSize="small"
          sx={{
            marginRight: theme.spacing(1),
            color: theme.palette.text.primary,
          }}
        />
        <RobotoTypography variant="subtitle2">{title}</RobotoTypography>
      </Box>
      {ModifyComponent}
    </Box>
  );
};
