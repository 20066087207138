import CloseIcon from "@mui/icons-material/Close";

import { RobotoIconButton } from "@/shared/components/RobotoIconButton";
import { RobotoTypography } from "@/shared/components/RobotoTypography";

import { CreateWorkspaceEventForm } from "../CreateWorkspaceEventForm";
import { isCreateEventView, useWorkspaceSidebar } from "../sidebarContext";

import styles from "./CreateEventSidebar.module.css";

interface CreateEventSidebarProps {
  onClose: () => void;
}

export function CreateEventSidebar(props: CreateEventSidebarProps) {
  const sidebar = useWorkspaceSidebar();

  if (!isCreateEventView(sidebar.activeDrawer)) {
    return null;
  }

  const { defaultFields, onSuccess, sourceOptions } = sidebar.activeDrawer.data;

  return (
    <section>
      <header className={styles.header}>
        <RobotoTypography component="h1" className={styles.title}>
          New Event
        </RobotoTypography>
        <RobotoIconButton
          onClick={props.onClose}
          eventName="WorkspaceSidebarClosed"
        >
          <CloseIcon />
        </RobotoIconButton>
      </header>
      <div className={styles.body}>
        <CreateWorkspaceEventForm
          defaultFields={defaultFields}
          onSuccess={(event) => {
            onSuccess(event);
            props.onClose();
          }}
          onCancel={props.onClose}
          sourceOptions={sourceOptions}
        />
      </div>
    </section>
  );
}
