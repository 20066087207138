import * as React from "react";

import { AutocompleteType } from "@/shared/components/TagAndMetadataAutocomplete";
import { TagInput, TagsGroup, Tags } from "@/shared/components/tags";
import { ActionRecord } from "@/shared/domain/actions";
import { OrgRecord } from "@/shared/domain/orgs";
import { useLazyAPICall } from "@/shared/services/apiHooks";
import { APIResponse, actionEndpoint } from "@/types";

interface UpdateActionTagsProps {
  initialTags: string[];
  actionName: string;
  currentOrg: OrgRecord | null;
  onTagsUpdated?: (arg: APIResponse<ActionRecord>) => void;
  enableUpdates?: boolean;
}

export const UpdateActionTags: React.FC<UpdateActionTagsProps> = ({
  initialTags,
  actionName,
  currentOrg,
  onTagsUpdated,
  enableUpdates,
}) => {
  const [tags, setTags] = React.useState<string[]>(initialTags);

  const { initiateRequest } = useLazyAPICall<{
    data: ActionRecord;
  }>();

  const tagAdded = (tag: string) => {
    const newTags = tags.concat([tag]);
    setTags(newTags);
    void updateTags({
      metadata_changeset: {
        put_tags: [tag],
      },
    });
  };

  const tagRemoved = (tag: string) => {
    const newTags = tags.filter((e) => e !== tag);
    setTags(newTags);
    void updateTags({
      metadata_changeset: {
        remove_tags: [tag],
      },
    });
  };

  const updateTags = async (requestBody: object) => {
    const { data, error } = await initiateRequest({
      method: "PUT",
      endpoint: actionEndpoint,
      pathParams: { name: actionName },
      requestBody: JSON.stringify(requestBody),
      orgId: currentOrg?.org_id,
    });

    if (!error && data && onTagsUpdated) {
      onTagsUpdated(data);
    }
  };

  return (
    <TagsGroup>
      <Tags
        tags={tags}
        onDeleteTag={enableUpdates === true ? tagRemoved : undefined}
        truncateText={false}
      />
      <TagInput
        autocompleteType={AutocompleteType.ActionTags}
        onAddTag={tagAdded}
        tags={tags}
      />
    </TagsGroup>
  );
};
