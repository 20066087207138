import { S3Client } from "@aws-sdk/client-s3";
import { Upload } from "@aws-sdk/lib-storage";

import { APIService, LoggerService } from "@/shared/services";
import {
  APIResponse,
  DatasetsCredentialsResponse,
  datasetsManifestTransactionBeginEndpoint,
  datasetsManifestTransactionCompleteEndpoint,
} from "@/types";

import { RobotoBucketCredentials } from "./RobotoBucketCredentials";
import { UploadableFile } from "./UploadableFile";

export function fileSystemEntryIsFile(
  entry: FileSystemEntry,
): entry is FileSystemFileEntry {
  return entry.isFile;
}

export function fileSystemEntryIsDirectory(
  entry: FileSystemEntry,
): entry is FileSystemDirectoryEntry {
  return entry.isDirectory;
}

/**
 * Iteratively yield FileSystemEntries from a directory.
 *
 * Slightly modified from a sample in the W3C File and Directory Entries API spec.
 */
export async function* getFileEntries(
  dirEntry: FileSystemDirectoryEntry,
): AsyncGenerator<FileSystemFileEntry> {
  const reader = dirEntry.createReader();
  const getNextBatch = () => {
    return new Promise<FileSystemEntry[]>((resolve, reject) => {
      reader.readEntries(resolve, reject);
    });
  };

  let entries;
  do {
    entries = await getNextBatch();
    for (const entry of entries) {
      if (fileSystemEntryIsFile(entry)) {
        yield entry;
      } else if (fileSystemEntryIsDirectory(entry)) {
        for await (const nestedEntry of getFileEntries(entry)) {
          yield nestedEntry;
        }
      } else {
        throw new Error("Impossible code path");
      }
    }
  } while (entries.length > 0);
}

export function fileFromFileSystemFileEntry(
  fileEntry: FileSystemFileEntry,
  uploadItemKey?: string,
): Promise<UploadableFile> {
  return new Promise<UploadableFile>((resolve, reject) => {
    fileEntry.file((file: File) => {
      let relativePath = file.webkitRelativePath;
      if (relativePath === "") {
        if (fileEntry.fullPath.startsWith("/")) {
          relativePath = fileEntry.fullPath.slice(1);
        } else {
          relativePath = fileEntry.fullPath;
        }
      }
      resolve(
        new UploadableFile(
          file,
          relativePath,
          uploadItemKey ? uploadItemKey : fileEntry.fullPath,
        ),
      );
    }, reject);
  });
}

export const uploadManifestFile = async (
  file: UploadableFile,
  destUri: string,
  datasetId: string,
  orgId: string,
  trackUploadProgress: (arg0: number) => void,
  credentials: DatasetsCredentialsResponse,
  transactionId: string,
  { signal }: { signal?: AbortSignal },
): Promise<void> => {
  const { access_key_id, secret_access_key, session_token } = credentials.data;

  const robotoBucketCredentials = new RobotoBucketCredentials(
    access_key_id,
    secret_access_key,
    session_token,
    datasetId,
    orgId,
    transactionId,
  );

  const s3Client = new S3Client({
    region: credentials.data.region,
    credentials: robotoBucketCredentials,
  });

  let keyUri = destUri;
  if (destUri.startsWith("s3://")) {
    keyUri = destUri.slice("s3://".length);
  }

  const keyParts = keyUri.split("/");
  const bucket = keyParts.shift();
  const key = keyParts.join("/");

  // https://docs.aws.amazon.com/AWSJavaScriptSDK/v3/latest/Package/-aws-sdk-lib-storage/Class/Upload/
  const upload = new Upload({
    client: s3Client,
    params: {
      Bucket: bucket,
      Key: key,
      Body: file.file,
    },
  });

  let prevLoaded = 0;
  upload.on("httpUploadProgress", (progress) => {
    const loaded = progress.loaded ?? 0;
    const chunkSize = loaded - prevLoaded;
    prevLoaded = loaded;
    trackUploadProgress(chunkSize);

    if (signal?.aborted) {
      void upload.abort();
    }
  });

  try {
    await upload.done();
  } catch (e) {
    if (e instanceof Error) {
      if (e.name === "AbortError") {
        return;
      }
    } else {
      throw e;
    }
  }
};

// File path -> file size in bytes
type ResourceManifest = { [key: string]: number };

export const beginManifestTransaction = async (
  datasetId: string,
  resourceManifest: ResourceManifest,
  orgId: string,
): Promise<{
  transaction_id: string | null;
  uploadMappings: { [key: string]: string } | null;
  error: Error | null;
}> => {
  const { response, error } = await APIService.authorizedRequest<
    APIResponse<{
      transaction_id: string;
      upload_mappings: { [key: string]: string };
    }>
  >({
    method: "POST",
    endpoint: datasetsManifestTransactionBeginEndpoint,
    apiVersion: "v2",
    requestBody: JSON.stringify({
      resource_manifest: resourceManifest,
      origination: "Roboto Web App",
    }),
    pathParams: {
      datasetId,
    },
    orgId,
  });

  if (error) {
    LoggerService.error("Error beginning transaction", error);
    return {
      transaction_id: null,
      uploadMappings: null,
      error,
    };
  }

  if (response?.data === undefined) {
    const error = new Error("No data returned from transaction endpoint");
    LoggerService.error("No data returned from transaction endpoint", error);
    return {
      transaction_id: null,
      uploadMappings: null,
      error,
    };
  }

  return {
    transaction_id: response.data.transaction_id,
    uploadMappings: response.data.upload_mappings,
    error,
  };
};

export const completeManifestFileUpload = async (
  datasetId: string,
  orgId: string,
  uploadId: string,
) => {
  const { error: errResp } = await APIService.authorizedRequest({
    method: "PUT",
    endpoint: datasetsManifestTransactionCompleteEndpoint,
    apiVersion: "v2",
    orgId: orgId,
    pathParams: {
      datasetId,
      uploadId,
    },
  });

  if (errResp) {
    throw errResp;
  }
};
