import * as React from "react";

import { useNavigation } from "@/providers";
import { AutocompleteType } from "@/shared/components/TagAndMetadataAutocomplete";
import { TagInput, Tags, TagsGroup } from "@/shared/components/tags";
import { DatasetRecord } from "@/shared/domain/datasets";
import { useUpdateDataset } from "@/shared/domain/datasets/hooks/useUpdateDataset";
import { OrgRecord } from "@/shared/domain/orgs";

interface UpdateDatasetTagsProps {
  initialTags: string[];
  datasetId: string;
  currentOrg: OrgRecord | null;
  onTagsUpdated?: (record: DatasetRecord) => void;
  onError?: (error: Error) => void;
  size?: "small" | "medium";
}

export const UpdateDatasetTags: React.FC<UpdateDatasetTagsProps> = ({
  initialTags,
  datasetId,
  currentOrg,
  onTagsUpdated,
  onError,
  size = "medium",
}) => {
  const { goto } = useNavigation();
  const [tags, setTags] = React.useState<string[]>(initialTags);

  const { mutate: updateDataset } = useUpdateDataset();

  const tagAdded = (tag: string) => {
    updateDataset(
      {
        datasetId: datasetId,
        resourceOwnerId: currentOrg?.org_id || "",
        updates: {
          metadata_changeset: {
            put_tags: [tag],
          },
        },
      },
      {
        onSuccess: (record) => {
          setTags(record.tags);
          if (onTagsUpdated) {
            onTagsUpdated(record);
          }
        },
        onError: (error: Error) => {
          if (onError) {
            onError(error);
          }
        },
      },
    );
  };

  const tagRemoved = (tag: string) => {
    updateDataset(
      {
        datasetId: datasetId,
        resourceOwnerId: currentOrg?.org_id || "",
        updates: {
          metadata_changeset: {
            remove_tags: [tag],
          },
        },
      },
      {
        onSuccess: (record) => {
          setTags(record.tags);
          if (onTagsUpdated) {
            onTagsUpdated(record);
          }
        },
        onError: (error: Error) => {
          if (onError) {
            onError(error);
          }
        },
      },
    );
  };

  React.useEffect(() => {
    setTags(initialTags);
  }, [initialTags]);

  return (
    <TagsGroup>
      <Tags
        tags={tags}
        onDeleteTag={tagRemoved}
        onClickTag={(tag) => {
          const queryParams = new URLSearchParams();
          queryParams.set("query", tag);
          goto.search(queryParams);
        }}
        truncateText={size === "small"}
      />
      <TagInput
        autocompleteType={AutocompleteType.FileTags}
        onAddTag={tagAdded}
        tags={tags}
        size={size}
      />
    </TagsGroup>
  );
};
