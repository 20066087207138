import {
  Box,
  Divider,
  MenuItem,
  Popover,
  Select,
  useTheme,
} from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useState } from "react";
import * as React from "react";

import { RobotoButton, RobotoTypography } from "@/shared/components";
import { Comparator, MetadataKey } from "@/types";
import { formatFieldName } from "@/utils";

import { UISimpleCondition } from "./conditions";
import { SearchField } from "./fields";
import { filterComparatorsAndValues } from "./helpers";
import { InnerContent } from "./InnerContent";

const comparators: Comparator[] = [
  "EQUALS",
  "NOT_EQUALS",
  "CONTAINS",
  "NOT_CONTAINS",
  "GREATER_THAN",
  "LESS_THAN",
  "GREATER_THAN_OR_EQUAL",
  "LESS_THAN_OR_EQUAL",
  "IS_NULL",
  "IS_NOT_NULL",
  "BEGINS_WITH",
  "EXISTS",
  "NOT_EXISTS",
];

const emptyComparators: Comparator[] = [
  "EXISTS",
  "NOT_EXISTS",
  "IS_NULL",
  "IS_NOT_NULL",
];

type MetadataType = "string" | "number" | "boolean";

type HandleChangeFunc = (event: {
  target: { name: string; value: string };
}) => void;
interface FilterFormProps {
  open: boolean;
  filterToUpdate: UISimpleCondition | null;
  anchorEl: HTMLButtonElement | HTMLDivElement | null;
  handleClose: () => void;
  onCreate: (condition: UISimpleCondition) => void;
  onUpdate: (condition: UISimpleCondition) => void;
  searchFields: SearchField[];
  availableMetadataKeys: string[];
}

interface FilterFormState {
  condition: UISimpleCondition;
  metadataType: "string" | "boolean" | "number";
  metadataKey: string;
  metadataValue: string;
  dateValue: Dayjs | null;
  currentSearchField: SearchField;
}

export const FilterForm: React.FC<FilterFormProps> = ({
  open,
  filterToUpdate,
  anchorEl,
  handleClose,
  onCreate,
  onUpdate,
  searchFields,
  availableMetadataKeys,
}) => {
  const theme = useTheme();

  const currentTimezone = dayjs.tz.guess();
  const todayAt8AMLocal = dayjs()
    .tz(currentTimezone)
    .startOf("day")
    .add(8, "hour");

  const [state, setState] = useState<FilterFormState>({
    condition: {
      id: new Date().getTime().toString(),
      field: searchFields[0].name,
      comparator: "EQUALS",
      value: "",
      searchFieldType: searchFields[0].type,
    },
    currentSearchField: searchFields[0],
    metadataType: "string",
    metadataKey: "",
    metadataValue: "",
    dateValue: todayAt8AMLocal,
  });

  useEffect(() => {
    const currentTimezone = dayjs.tz.guess();
    const todayAt8AMLocal = dayjs()
      .tz(currentTimezone)
      .startOf("day")
      .add(8, "hour");

    if (filterToUpdate) {
      const updatedSearchField = searchFields.find(
        (field) => field.name === filterToUpdate.field,
      ) as SearchField;

      if (filterToUpdate.field.startsWith("metadata")) {
        const metadataSearchField = searchFields.find(
          (field) => field.type === "metadata",
        ) as SearchField;

        const newState: FilterFormState = {
          condition: { ...filterToUpdate },
          metadataType: "string",
          metadataKey: "",
          metadataValue: "",
          dateValue: todayAt8AMLocal,
          currentSearchField: metadataSearchField,
        };

        const index = filterToUpdate.field.lastIndexOf("metadata.");
        const key = filterToUpdate.field.slice(index + 9);

        newState.condition.field = "metadata";
        newState.metadataKey = key;
        newState.metadataValue = `${filterToUpdate.value}`;

        newState.metadataType = typeof filterToUpdate.value as
          | "string"
          | "number"
          | "boolean";

        setState(newState);
        return;
      } else if (updatedSearchField.type === "date") {
        const dayJSDate = dayjs(`${filterToUpdate.value}`);

        const newState: FilterFormState = {
          condition: { ...filterToUpdate },
          metadataType: "string",
          metadataKey: "",
          metadataValue: "",
          dateValue: dayJSDate,
          currentSearchField: updatedSearchField,
        };

        setState(newState);

        return;
      }

      setState({
        condition: filterToUpdate,
        metadataType: "string",
        metadataKey: "",
        metadataValue: "",
        dateValue: todayAt8AMLocal,
        currentSearchField: updatedSearchField,
      });
    } else {
      setState({
        condition: {
          id: new Date().getTime().toString(),
          field: searchFields[0].name,
          comparator: "EQUALS",
          value: "",
          searchFieldType: searchFields[0].type,
        },
        metadataType: "string",
        metadataKey: "",
        metadataValue: "",
        dateValue: todayAt8AMLocal,
        currentSearchField: searchFields[0],
      });
    }
  }, [filterToUpdate, searchFields]);

  const resetState = () => {
    setState({
      condition: {
        id: new Date().getTime().toString(),
        field: searchFields[0].name,
        comparator: "EQUALS",
        value: "",
        searchFieldType: searchFields[0].type,
      },
      metadataType: "string",
      metadataKey: "",
      metadataValue: "",
      dateValue: todayAt8AMLocal,
      currentSearchField: searchFields[0],
    });
  };

  const handleChange: HandleChangeFunc = ({ target: { name, value } }) => {
    switch (name) {
      case "metadataKey":
        setState((prevState) => {
          const newState = { ...prevState };
          newState.metadataKey = value;
          return newState;
        });

        break;

      case "metadataValue":
        setState((prevState) => {
          const newState = { ...prevState };
          newState.metadataValue = value;
          newState.condition.value = `${prevState.metadataKey}.${value}`;
          return newState;
        });

        break;

      case "selectMetadataType":
        setState((prevState) => {
          const newState = { ...prevState };
          newState.metadataType = value as MetadataType;
          return newState;
        });

        break;

      case "valueInput":
        setState((prevState) => {
          const newState = { ...prevState };
          newState.condition.value = value;
          return newState;
        });

        break;

      case "fieldSelect":
        setState((prevState) => {
          const newState = { ...prevState };
          newState.condition.field = value;
          newState.condition.value = "";

          const newField = searchFields.find(
            (field) => field.name === value,
          ) as SearchField;

          newState.condition.searchFieldType = newField.type;

          newState.currentSearchField = newField;

          if (newState.currentSearchField.type === "date") {
            newState.condition.comparator = "GREATER_THAN_OR_EQUAL";
          } else if (newState.currentSearchField.type === "tags") {
            newState.condition.comparator = "CONTAINS";
          }

          return newState;
        });

        break;

      case "comparatorSelect":
        setState((prevState) => {
          const newState = { ...prevState };
          newState.condition.comparator = value as Comparator;
          return newState;
        });

        break;
    }
  };

  const handleCreateAndUpdate = () => {
    if (state.currentSearchField.type === "metadata") {
      // metadata case
      const filterField: MetadataKey = `metadata.${state.metadataKey}`;
      let filterValue: string | number | boolean = state.metadataValue;

      if (state.metadataType === "boolean") {
        filterValue = Boolean(state.metadataValue);
      } else if (state.metadataType === "number") {
        filterValue = parseFloat(state.metadataValue);
      }

      const filter: UISimpleCondition = {
        id: filterToUpdate
          ? state.condition.id
          : new Date().getTime().toString(),
        field: filterField,
        comparator: state.condition.comparator,
        value: filterValue,
        searchFieldType: "metadata",
      };

      if (filterToUpdate) {
        onUpdate(filter);
      } else {
        onCreate(filter);
      }
    } else if (state.currentSearchField.type === "date") {
      // date case
      const filterValue = state.dateValue?.toISOString() ?? "";

      const filter: UISimpleCondition = {
        ...state.condition,
        searchFieldType: "date",
      };

      filter.value = filterValue;

      if (filterToUpdate) {
        onUpdate(filter);
      } else {
        onCreate(filter);
      }
    } else {
      // all other cases

      const filter = { ...state.condition };

      if (filterToUpdate) {
        onUpdate(filter);
      } else {
        filter.id = new Date().getTime().toString();
        onCreate(filter);
      }
    }

    handleClose();
    resetState();
  };

  const handleCancel = () => {
    handleClose();
    resetState();
  };

  let isFormValid = false;

  if (state.currentSearchField.type === "metadata") {
    // metadata
    isFormValid =
      state.metadataKey.length > 0 &&
      (state.metadataValue.length > 0 ||
        emptyComparators.includes(state.condition.comparator));
  } else if (state.currentSearchField.type === "date") {
    // dates
    isFormValid =
      Boolean(state.dateValue) ||
      emptyComparators.includes(state.condition.comparator);
  } else {
    // everything else
    isFormValid =
      (state.condition.value as string).length > 0 ||
      emptyComparators.includes(state.condition.comparator);
  }

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={handleCancel}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      <Box
        sx={{
          width: {
            xs: `calc(100vw * 0.9)`,
          },
          maxWidth: "450px",
          minWidth: "300px",
          padding: theme.spacing(2),
        }}
      >
        <RobotoTypography variant="subtitle1">
          {filterToUpdate ? "Update " : "Add "} Filter
        </RobotoTypography>
        <Divider sx={{ mt: theme.spacing(1), mb: theme.spacing(2) }} />
        <Box
          sx={{ padding: theme.spacing(1) }}
          component="form"
          onSubmit={(e) => {
            e.preventDefault();
            handleCreateAndUpdate();
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: theme.spacing(2),
            }}
          >
            <RobotoTypography
              variant="body2"
              sx={{ marginRight: theme.spacing(2) }}
            >
              Field
            </RobotoTypography>
            <Select
              data-cy={"fieldSelect"}
              sx={{ flex: 1, maxWidth: "300px" }}
              value={state.condition.field}
              size="small"
              name={"fieldSelect"}
              // handleChange will work, MUI type is too restrictive
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              onChange={handleChange}
            >
              {searchFields.map((field) => {
                return (
                  <MenuItem key={field.name} value={field.name}>
                    {formatFieldName(field.name)}
                  </MenuItem>
                );
              })}
            </Select>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: theme.spacing(2),
            }}
          >
            <RobotoTypography
              variant="body2"
              sx={{ marginRight: theme.spacing(2) }}
            >
              Comparator
            </RobotoTypography>
            <Select
              sx={{
                flex: 1,
                maxWidth: "300px",
              }}
              name={"comparatorSelect"}
              size="small"
              value={state.condition.comparator}
              // handleChange will work, MUI type too restrictive
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              onChange={handleChange}
            >
              {comparators
                .filter((value) => {
                  return filterComparatorsAndValues(
                    value,
                    state.currentSearchField,
                  );
                })
                .map((comparator) => (
                  <MenuItem key={comparator} value={comparator}>
                    {comparator}
                  </MenuItem>
                ))}
            </Select>
          </Box>

          <InnerContent
            availableMetadataKeys={availableMetadataKeys}
            comparator={state.condition.comparator}
            searchField={state.currentSearchField}
            dateValue={state.dateValue}
            condition={state.condition}
            metadataKey={state.metadataKey}
            metadataValue={state.metadataValue}
            metadataType={state.metadataType}
            handleChange={handleChange}
            handleDatePickerChange={(dayjsDate) => {
              setState((prevState) => {
                const newState = { ...prevState };
                newState.condition.value = dayjsDate?.toISOString() ?? "";
                newState.dateValue = dayjsDate;
                return newState;
              });
            }}
          />

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              gap: theme.spacing(2),
            }}
          >
            <RobotoButton
              eventName={"DatasetFilterCreated"}
              data-cy={"createAddButton"}
              variant={"contained"}
              disabled={!isFormValid}
              color={"secondary"}
              sx={{ width: "100px" }}
              type="submit"
            >
              {filterToUpdate !== null ? "Update" : "Add"}
            </RobotoButton>
            <RobotoButton
              eventName={"CreateDatasetFilterCanceled"}
              onClick={handleCancel}
              variant={"outlined"}
              color={"secondary"}
              sx={{ width: "100px" }}
            >
              Cancel
            </RobotoButton>
          </Box>
        </Box>
      </Box>
    </Popover>
  );
};
