import { ActionRecord, ActionReference } from "@/shared/domain/actions";
import { RobotoEndpoint } from "@/types";

export function isActionReference(
  record: ActionRecord | ActionReference | null,
): record is ActionReference {
  return (
    record !== null && Object.prototype.hasOwnProperty.call(record, "owner")
  );
}

export const datasetFilesQueryEndpoint: RobotoEndpoint = (pathParams) => {
  const datasetId = pathParams?.datasetId;

  return `/datasets/${datasetId}/files/query`;
};
