import * as React from "react";

import { LoggerService } from "@/shared/services";

interface UseResizableProps {
  // Required to know to check width/height
  axis: "x" | "y";

  // Required to convert absolute viewport sizes to relative container percentage sizes
  containerRef: React.RefObject<HTMLElement>;

  // Required to calculate the layout1 and layout2 sizes without needing their refs
  resizeHandleRef: React.RefObject<HTMLElement>;

  // Initial relative size of top/left resizable layout
  initialLayout1Size: number;

  // Initial relative size of bottom/right resizable layout
  initialLayout2Size: number;

  // Size can't be below 0
  minSize?: number;

  // Can't exceed the constant value of the sum of the layouts. This value won't change
  maxSize?: number;
}

interface AriaProps {
  role: string;
  "aria-valuenow": React.AriaAttributes["aria-valuenow"];
  "aria-orientation": React.AriaAttributes["aria-orientation"];
  "aria-valuemin": React.AriaAttributes["aria-valuemin"];
  "aria-valuemax": React.AriaAttributes["aria-valuemax"];
}

export interface ResizeSeparatorProps extends AriaProps {
  onPointerDown: React.PointerEventHandler;
}

export function useResizable(props: UseResizableProps) {
  const {
    axis,
    containerRef,
    resizeHandleRef,
    initialLayout1Size,
    initialLayout2Size,
    minSize = 0,
    maxSize = initialLayout1Size + initialLayout2Size,
  } = props;

  const abortControllerRef = React.useRef(new AbortController());
  const isResizing = React.useRef(false);
  const layout1SizeRef = React.useRef(initialLayout1Size);
  const layout2SizeRef = React.useRef(initialLayout2Size);

  const [isDragging, setIsDragging] = React.useState(false);
  const [layout1Size, setLayout1Size] = React.useState(initialLayout1Size);
  const [layout2Size, setLayout2Size] = React.useState(initialLayout2Size);

  const ariaProps: AriaProps = React.useMemo(
    () => ({
      role: "separator",
      "aria-valuenow": layout1SizeRef.current,
      "aria-valuemin": minSize,
      "aria-valuemax": maxSize,
      "aria-orientation": axis === "x" ? "vertical" : "horizontal",
    }),
    [axis, minSize, maxSize],
  );

  // If an outside force changes the size of layout 1, update it
  React.useEffect(() => {
    if (isResizing.current === false) {
      layout1SizeRef.current = initialLayout1Size;
    }
  }, [initialLayout1Size]);

  // If an outside force changes the size of layout 2, update it
  React.useEffect(() => {
    if (isResizing.current === false) {
      layout2SizeRef.current = initialLayout2Size;
    }
  }, [initialLayout2Size]);

  const handlePointerMove = React.useCallback(
    (event: PointerEvent) => {
      if (isResizing.current === false) {
        return;
      }

      event.stopPropagation();

      // Prevents text selection
      event.preventDefault();

      const offset = (() => {
        const containerElement = containerRef.current;
        const resizeHandleElement = resizeHandleRef.current;

        if (axis === "x") {
          if (containerElement && resizeHandleElement) {
            const { width: containerWidth } =
              containerElement.getBoundingClientRect();

            const { left, width: resizeHandleWidth } =
              resizeHandleElement.getBoundingClientRect();

            return (
              (event.clientX - left - resizeHandleWidth / 2) / containerWidth
            );
          }

          // Note:(Pratik) This is a fallback that should never be reached
          // because resizeHandle containerElements are required and should be mounted before mousemove is called
          LoggerService.warn(
            "Encountered fallback when resizing layouts in the x-axis",
          );
          return 0;
        } else {
          // Vertical orientation
          if (containerElement && resizeHandleElement) {
            const { height: containerHeight } =
              containerElement.getBoundingClientRect();
            const { top, height: resizeHandleHeight } =
              resizeHandleElement.getBoundingClientRect();

            return (
              (event.clientY - top - resizeHandleHeight / 2) / containerHeight
            );
          }

          // Note:(Pratik) This is a fallback that should never be reached
          // because resizeHandle containerElements are required and should be mounted before mousemove is called
          LoggerService.warn(
            "Encountered fallback when resizing layouts in the y-axis",
          );
          return 0;
        }
      })();

      // Update layout 1
      let nextLayout1Size = layout1SizeRef.current + offset;
      nextLayout1Size = Math.min(Math.max(nextLayout1Size, minSize), maxSize);
      setLayout1Size(nextLayout1Size);
      layout1SizeRef.current = nextLayout1Size;

      // Update layout 2
      const nextLayout2Size = 1 - nextLayout1Size;
      setLayout2Size(nextLayout2Size);
      layout2SizeRef.current = nextLayout2Size;
    },
    [axis, containerRef, resizeHandleRef, minSize, maxSize],
  );

  const handlePointerUp = React.useCallback((event: PointerEvent) => {
    event.stopPropagation();

    isResizing.current = false;
    setIsDragging(false);
    document.body.style.cursor = "default";

    // Clean up of handleMouseMove is done through the abort signal
    abortControllerRef.current.abort();

    // Setup a new abort controller for next time
    abortControllerRef.current = new AbortController();
  }, []);

  const handlePointerDown = React.useCallback<React.PointerEventHandler>(
    (event) => {
      event.stopPropagation();

      isResizing.current = true;
      setIsDragging(true);
      document.body.style.cursor = axis === "x" ? "ew-resize" : "ns-resize";

      document.addEventListener("pointermove", handlePointerMove, {
        signal: abortControllerRef.current.signal,
      });
      document.addEventListener("pointerup", handlePointerUp, { once: true });
    },
    [handlePointerMove, handlePointerUp, axis],
  );

  return {
    isDragging,
    layout1Size,
    layout2Size,
    separatorProps: {
      ...ariaProps,
      onPointerDown: handlePointerDown,
    },
  };
}
