import { Alert } from "@mui/material";
import * as React from "react";

import { MetadataForm, MetadataView } from "@/shared/components/Metadata";
import { useFileMetadata } from "@/shared/domain/files/hooks/useFileMetadata";
import { useMutateFileMetadata } from "@/shared/domain/files/hooks/useMutateFileMetadata";

import { Metadata } from "../Metadata/types";
import { AutocompleteType } from "../TagAndMetadataAutocomplete";

import { MetadataSkeleton } from "./MetadataSkeleton";

interface FileMetadataProps {
  fileId: string;
  readonly?: boolean;
}

export const FileMetadata: React.FC<FileMetadataProps> = ({
  fileId,
  readonly = false,
}) => {
  const metadataQuery = useFileMetadata(fileId);

  const metadata = metadataQuery.data;

  const updateFileMetadataMutation = useMutateFileMetadata();

  const onRemoveMetadata = (data: Metadata, keyDeleted?: string) => {
    if (data && keyDeleted) {
      updateFileMetadataMutation.mutate({
        fileId: fileId,
        removeFields: [keyDeleted],
      });
    }
  };

  const onAddMetadata = (key: string, value: string) => {
    if (key && value) {
      const metadataClone = {
        ...metadata,
        [key]: value,
      };

      updateFileMetadataMutation.mutate({
        fileId: fileId,
        putFields: metadataClone,
      });
    }
  };

  if (metadataQuery.isLoading) {
    return <MetadataSkeleton />;
  }

  return (
    <>
      {readonly && metadata && Object.keys(metadata).length === 0 && <>None</>}
      {updateFileMetadataMutation.isError && (
        <Alert
          onClose={() => updateFileMetadataMutation.reset()}
          severity="error"
          sx={(theme) => ({ marginTop: theme.spacing(1) })}
        >
          {updateFileMetadataMutation.error.message}
        </Alert>
      )}
      <MetadataView
        metadata={metadata || {}}
        onChange={onRemoveMetadata}
        disabled={readonly}
      />
      {readonly === false && (
        <MetadataForm
          metadata={metadata || {}}
          autocompleteType={AutocompleteType.FileMetadataKeys}
          onAddMetadata={onAddMetadata}
          disabled={false}
          isLoading={updateFileMetadataMutation.isPending}
        />
      )}
    </>
  );
};
