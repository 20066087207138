import { useQuery } from "@tanstack/react-query";
import * as React from "react";

import { DomainServicesContext } from "@/providers/DomainServices";

import { TopicRecord } from "../TopicRecord";

/**
 * Fetch TopicRecords associated with files under a particular dataset
 */
export function useDatasetTopics(datasetId: string, isEnabled: boolean) {
  const { topics: topicService } = React.useContext(DomainServicesContext);

  return useQuery<TopicRecord[], Error>({
    queryKey: ["datasetTopics", datasetId],
    queryFn: ({ signal }) => {
      return topicService.getTopicsForDataset(datasetId, {
        abortSignal: signal,
      });
    },
    enabled: isEnabled,
    // Post-ingestion, neither topic nor message path records are expected to be fast-changing.
    staleTime: 300000, // 5 minutes, in milliseconds
  });
}
