import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";

import { useLogPanelContext } from "../panelContext";

export function SearchTool() {
  const { isSearchVisible, toggleSearchVisibility } = useLogPanelContext();

  return (
    <IconButton
      aria-label="search-logs"
      onClick={toggleSearchVisibility}
      size="small"
      title="Search logs"
    >
      <SearchIcon
        color={isSearchVisible ? "primary" : "inherit"}
        fontSize="small"
      />
    </IconButton>
  );
}
