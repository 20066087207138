import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import FlareOutlinedIcon from "@mui/icons-material/FlareOutlined";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SourceOutlinedIcon from "@mui/icons-material/SourceOutlined";
import TimelineIcon from "@mui/icons-material/Timeline";
import { Box, Typography, useTheme } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import * as React from "react";

import { RobotoButton } from "@/shared/components/RobotoButton";
import { RobotoStyledPopoverMenu } from "@/shared/components/RobotoStyledPopoverMenu";
import { QueryTarget, queryTargetToDisplayName } from "@/shared/domain/query";

const descriptionFontStyle = { fontSize: "0.83rem", color: "#777" };

interface SearchTargetSelectorProps {
  initialTarget: QueryTarget;
  onTargetChanged: (arg: QueryTarget) => void;
}

export const SearchTargetSelector: React.FC<SearchTargetSelectorProps> = ({
  initialTarget,
  onTargetChanged,
}) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const changeTarget = (target: QueryTarget) => {
    setTarget(target);
    onTargetChanged(target);
    handleClose();
  };

  const [target, setTarget] = React.useState<QueryTarget>(initialTarget);

  // If the initialTarget changes after first mount, update internal state.
  // This can happen if a queryId is provided to the search page and it lazily
  // fetches the results and target type (e.g. `?queryId=q_5c0dkoke5cxjr0rxwse1`)
  React.useEffect(() => {
    setTarget(initialTarget);
    onTargetChanged(initialTarget);
  }, [initialTarget, onTargetChanged]);

  return (
    <>
      <RobotoButton
        id="search-target-button"
        data-cy={"searchTargetButton"}
        aria-controls={open ? "search-target-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="text"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        eventName={"ChangeSearchTargetClicked"}
        sx={{
          height: "32px",
          whiteSpace: "nowrap",
        }}
        style={{
          textTransform: "capitalize",
        }}
      >
        {queryTargetToDisplayName(target)}
      </RobotoButton>

      <RobotoStyledPopoverMenu
        id="search-target-menu"
        MenuListProps={{
          "aria-labelledby": "search-target-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          disableRipple
          onClick={() => {
            changeTarget(QueryTarget.Datasets);
          }}
          style={{
            paddingTop: theme.spacing(1.25),
            paddingBottom: theme.spacing(1.25),
          }}
          data-cy={"searchTargetDatasetButton"}
        >
          <Box>
            <SourceOutlinedIcon />
          </Box>
          <Box>
            <Typography variant="body2" style={{ fontWeight: 500 }}>
              Datasets
            </Typography>
            <Typography variant="body2" style={descriptionFontStyle}>
              Groups of files organized in a directory structure
            </Typography>
          </Box>
        </MenuItem>
        <MenuItem
          disableRipple
          onClick={() => {
            changeTarget(QueryTarget.Files);
          }}
          data-cy={"searchTargetFilesButton"}
        >
          <Box>
            <InsertDriveFileOutlinedIcon />
          </Box>
          <Box>
            <Typography variant="body2" style={{ fontWeight: 500 }}>
              Files
            </Typography>
            <Typography variant="body2" style={descriptionFontStyle}>
              Individual log files or other uploaded assets
            </Typography>
          </Box>
        </MenuItem>
        <MenuItem
          disableRipple
          onClick={() => {
            changeTarget(QueryTarget.Topics);
          }}
          data-cy={"searchTargetTopicsButton"}
        >
          <Box>
            <AccountTreeOutlinedIcon />
          </Box>
          <Box>
            <Typography variant="body2" style={{ fontWeight: 500 }}>
              Topics
            </Typography>
            <Typography variant="body2" style={descriptionFontStyle}>
              Sequences of time-series data from sensors or systems
            </Typography>
          </Box>
        </MenuItem>
        <MenuItem
          disableRipple
          onClick={() => {
            changeTarget(QueryTarget.TopicMessagePaths);
          }}
          data-cy={"searchTargetTopicMessagePathsButton"}
        >
          <Box>
            <TimelineIcon />
          </Box>
          <Box>
            <Typography variant="body2" style={{ fontWeight: 500 }}>
              Message Paths
            </Typography>
            <Typography variant="body2" style={descriptionFontStyle}>
              Data fields in topics representing individual time-series signals
            </Typography>
          </Box>
        </MenuItem>
        <MenuItem
          disableRipple
          onClick={() => {
            changeTarget(QueryTarget.Events);
          }}
          data-cy={"searchTargetEventsButton"}
        >
          <Box>
            <FlareOutlinedIcon />
          </Box>
          <Box>
            <Typography variant="body2" style={{ fontWeight: 500 }}>
              Events
            </Typography>
            <Typography variant="body2" style={descriptionFontStyle}>
              Points or time ranges of interest within time-series data
            </Typography>
          </Box>
        </MenuItem>
      </RobotoStyledPopoverMenu>
    </>
  );
};
