import { Outlet } from "react-router-dom";

import { NavigationPage } from "@/shared/components";

interface PageLayoutProps {
  title?: string;
}

export function PageLayout({ title }: PageLayoutProps) {
  return (
    <NavigationPage title={title}>
      <Outlet />
    </NavigationPage>
  );
}
