import { useQuery } from "@tanstack/react-query";

import { useDomainServices } from "@/providers/DomainServices";
import { getSignedUrlParams } from "@/utils";

import { FileRecord } from "../FileRecord";

export function useFile(
  fileId: string | undefined,
  onSuccess: (fileRecord: FileRecord) => void = () => {},
) {
  const { files } = useDomainServices();

  return useQuery<FileRecord, Error>({
    queryKey: ["fileRecord", fileId],
    queryFn: ({ signal }) => {
      if (fileId === undefined) {
        return Promise.reject(new Error("Invalid fileId"));
      }

      return files
        .getFileRecord(fileId, {
          abortSignal: signal,
        })
        .then((record) => {
          onSuccess(record);
          return record;
        });
    },
    enabled: fileId !== undefined,

    // 1 minute until considered stale. This value was chosen to satisfy
    // quick navigation between files but small enough to refetch
    // when topics have ingested.
    staleTime: 60000,
  });
}

export function useSignedUrl(fileRecord: FileRecord | undefined) {
  const { files } = useDomainServices();

  return useQuery<URL, Error>({
    queryKey: ["signedUrl", fileRecord?.file_id],
    queryFn: ({ signal }) => {
      if (fileRecord === undefined) {
        return Promise.reject(new Error("Invalid fileRecord"));
      }

      return files.getPresignedDownloadUrl(fileRecord.file_id, {
        searchParams: getSignedUrlParams(fileRecord, false),
        abortSignal: signal,
      });
    },
    enabled: fileRecord !== undefined,
    /* staleTime = 60 minutes (Our urls are signed for an hour. 
      This ensures they become stale when they expire so we refetch immediately).

      If the user leaves a page with this useSignedUrl query, the query will become
      inactive and will be garbage collected after 5 minutes. That's why I think this
      is a sane default.
      **/
    staleTime: 3.6e6,
  });
}
