import * as React from "react";

import { HttpClient } from "@/shared/http";

interface Context {
  httpClient: HttpClient;
}

export const HttpClientContext = React.createContext<Context>({
  get httpClient(): HttpClient {
    throw new Error("HttpClient missing in HttpClientContext");
  },
});
