import * as React from "react";

import {
  useMeasure,
  type Dimensions,
  type OnDimensionsChange,
} from "@/shared/hooks";
import { useDebouncedCallback } from "@/shared/hooks/useDebouncedCallback";
import { ErrorMonitoringService } from "@/shared/services";
import { ImagePanelState, LayoutItem } from "@/shared/state/visualization";

import { useWorkspaceTimer } from "../../WorkspaceCtx";
import { NoDataMessage } from "../NoDataMessage";
import { PanelLayout } from "../PanelLayout";
import { RenderingError } from "../RenderingError";

import styles from "./ImagePanel.module.css";
import { ImageEvent } from "./messaging";
import { Renderer } from "./Renderer";

interface ImagePanelProps {
  layout: LayoutItem;
  state: ImagePanelState;
}

export function ImagePanel({ layout, state }: ImagePanelProps) {
  const timer = useWorkspaceTimer();
  const canvasRef = React.useRef<HTMLCanvasElement | null>(null);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<Error | null>(null);
  const [renderer, setRenderer] = React.useState<Renderer | null>(null);
  const timerHoldRef = React.useRef<symbol | null>(null);

  /**
   * Initialize the renderer and clean up when the component is unmounted
   */
  React.useEffect(
    function init() {
      const canvas = canvasRef.current;
      if (canvas === null || error !== null) {
        return;
      }
      const renderingContext = canvas.getContext("2d");
      if (renderingContext === null) {
        setError(new Error("Failed to get 2D rendering context"));
        return;
      }

      const abortController = new AbortController();

      // Create a new renderer.
      // This is named with an underscore to avoid shadowing the component state variable.
      const _renderer = new Renderer({
        abortController,
        renderingContext,
      });

      _renderer.setEventListener(ImageEvent.Error, (err) => {
        if (abortController.signal.aborted || err.name === "AbortError") {
          return;
        }
        setError(new Error(err.message, { cause: err }));
        setIsLoading(false);
        setRenderer(null);
      });

      setRenderer(_renderer);

      return function abort() {
        abortController.abort();
        _renderer.dispose();
      };
    },
    [error],
  );

  /**
   * Update the renderer's reference to timer as it changes
   */
  React.useEffect(
    function updateTimer() {
      if (renderer === null) {
        return;
      }

      const abortController = new AbortController();
      renderer.setTimer(timer, abortController);

      renderer.setEventListener(
        ImageEvent.LoadingStateChange,
        // isLoading is aliased with an underscore to avoid shadowing the component state variable of the same name.
        ({ isLoading: _isLoading }) => {
          if (abortController.signal.aborted) {
            return;
          }
          if (_isLoading && timerHoldRef.current === null) {
            timerHoldRef.current = timer.hold();
          }
          if (!_isLoading && timerHoldRef.current !== null) {
            timer.holdRelease(timerHoldRef.current);
            timerHoldRef.current = null;
          }

          setIsLoading(_isLoading);
        },
      );

      return function abort() {
        abortController.abort();
        if (timerHoldRef.current !== null) {
          timer.holdRelease(timerHoldRef.current);
          timerHoldRef.current = null;
        }
      };
    },
    [renderer, timer],
  );

  /**
   * Update the renderer's reference to topic data as it changes
   */
  React.useEffect(
    function updateTopicData() {
      if (renderer === null || state.data === null) {
        return;
      }

      const abortController = new AbortController();
      renderer.setData(state.data, abortController.signal).catch((err) => {
        if (abortController.signal.aborted) {
          return;
        }
        const error =
          err instanceof Error
            ? err
            : new Error("Failed to get latest message for ImagePanel", {
                cause: err,
              });
        setError(error);
      });

      return function abort() {
        abortController.abort();
      };
    },
    [renderer, state.data],
  );

  /**
   * Update the renderer's reference to config as it changes
   */
  React.useEffect(
    function updateConfig() {
      if (renderer === null) {
        return;
      }

      renderer.setConfig(state.config);
    },
    [renderer, state.config],
  );

  /**
   * Report errors when they occur
   */
  React.useEffect(
    function reportError() {
      if (error === null) {
        return;
      }
      ErrorMonitoringService.captureError(error);
    },
    [error],
  );

  /**
   * Redraw the image when the canvas is resized
   */
  const onRenderingSurfaceResize = React.useCallback(
    function onResize({ width, height }: Dimensions) {
      if (renderer === null) {
        return;
      }
      renderer.resize({ width, height });
    },
    [renderer],
  );

  const onRenderingSurfaceResizeDebounced =
    useDebouncedCallback<OnDimensionsChange>(onRenderingSurfaceResize, 150);

  const [measured] = useMeasure<HTMLCanvasElement>({
    onDimensionsChange: onRenderingSurfaceResizeDebounced,
  });

  return (
    <PanelLayout isLoading={isLoading} layout={layout} state={state}>
      <div className={styles.imagePanelBody}>
        <canvas
          className={styles.canvas}
          ref={(node) => {
            if (node !== null) {
              measured(node);
            }
            canvasRef.current = node;
          }}
        ></canvas>
        <NoDataMessage panelData={state.data} />
        <RenderingError error={error} onClearError={() => setError(null)} />
      </div>
    </PanelLayout>
  );
}
