import {
  Box,
  Switch,
  Divider,
  useTheme,
  CircularProgress,
} from "@mui/material";
import * as React from "react";

import { NotificationRecord } from "@/shared/domain/notifications";

import { RobotoTypography } from "../../RobotoTypography";

interface NotificationsHeaderProps {
  allNotificationsRead: boolean;
  mostRecentNotification: NotificationRecord | null;
  handleMarkAllAsRead: () => void;
  loadingMarkAllAsRead: boolean;
  handleOnlyShowUnread: () => void;
  lastUpdateTimestamp: number;
}

export const NotificationsHeader: React.FC<NotificationsHeaderProps> = ({
  allNotificationsRead,
  mostRecentNotification,
  handleMarkAllAsRead,
  loadingMarkAllAsRead,
  handleOnlyShowUnread,
  lastUpdateTimestamp,
}) => {
  const [, setTick] = React.useState<number>(0);

  React.useEffect(() => {
    const intervalId = setInterval(() => {
      setTick((tick) => tick + 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const theme = useTheme();

  let subText = "";

  if (mostRecentNotification) {
    const now = new Date();

    const timeDiff = now.getTime() - lastUpdateTimestamp;
    const seconds = Math.floor(timeDiff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const weeks = Math.floor(days / 7);

    if (seconds < 60) {
      subText = `Updated a few moments ago`;
    } else if (minutes < 60) {
      subText = `Updated ${minutes} ${minutes > 1 ? "minutes" : "minute"} ago`;
    } else if (hours < 24) {
      subText = `Updated ${hours} ${hours > 1 ? "hours" : "hour"} ago`;
    } else if (days < 7) {
      subText = `Updated ${days} ${days > 1 ? "days" : "day"} ago`;
    } else {
      subText = `Updated ${weeks} ${weeks > 1 ? "weeks" : "week"} ago`;
    }
  }

  if (!mostRecentNotification) {
    subText = "";
  }

  let markAllReadContent: React.ReactElement | null = (
    <RobotoTypography
      data-cy="mark-all-as-read"
      sx={{
        cursor: "pointer",
        ":hover": {
          textDecoration: "underline",
        },
      }}
      variant={"caption"}
      onClick={() => {
        handleMarkAllAsRead();
      }}
    >
      Mark all as read
    </RobotoTypography>
  );

  if (loadingMarkAllAsRead) {
    markAllReadContent = (
      <Box
        sx={{
          width: "155px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          alignItems: "center",
          marginRight: theme.spacing(1),
        }}
      >
        <CircularProgress size={15} sx={{ marginRight: theme.spacing(1.5) }} />
      </Box>
    );
  } else if (allNotificationsRead) {
    markAllReadContent = null;
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <RobotoTypography variant="subtitle1">Notifications</RobotoTypography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <RobotoTypography
              variant={"caption"}
              sx={{ paddingRight: theme.spacing(1) }}
            >
              Only show unread
            </RobotoTypography>
            <Switch
              inputProps={{
                ["data-cy" as keyof React.InputHTMLAttributes<HTMLInputElement>]:
                  "unread-only",
              }}
              size={"small"}
              onChange={handleOnlyShowUnread}
            />
          </Box>
        </Box>
      </Box>

      <Divider sx={{ mt: theme.spacing(1), mb: theme.spacing(1) }} />

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <RobotoTypography variant={"caption"}>{subText}</RobotoTypography>

        {markAllReadContent}
      </Box>
    </>
  );
};
