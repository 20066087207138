import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useContext } from "react";

import {
  DomainServicesContext,
  useDomainServices,
} from "@/providers/DomainServices";
import { EventRecord } from "@/shared/domain/events";
import { PaginatedResponse } from "@/shared/http";

export function useDatasetEvents(datasetId: string) {
  const { datasetService } = useContext(DomainServicesContext);

  return useQuery<EventRecord[], Error>({
    queryKey: ["datasetEvents", datasetId],
    queryFn: async ({ signal }) => {
      let items: EventRecord[] = [];
      let nextToken: string | undefined = undefined;

      do {
        const data: PaginatedResponse<EventRecord> =
          await datasetService.getEventsForDataset(datasetId, {
            pageToken: nextToken,
            limit: 1000,
            abortSignal: signal,
          });

        items = items.concat(data.items);
        nextToken = data.next_token;
      } while (nextToken);

      return items;
    },
  });
}

export function useDeleteDatasetEvents(
  datasetId: string,
  onDeleteEvents: (events: EventRecord["event_id"][]) => void,
) {
  const queryClient = useQueryClient();
  const { events } = useDomainServices();
  return useMutation<void, Error, EventRecord["event_id"][]>({
    mutationFn: async function deleteEvent(eventIds) {
      for (const eventId of eventIds) {
        await events.deleteEvent(eventId);
      }
    },
    onSuccess: (_, eventIds) => {
      onDeleteEvents(eventIds);
      return queryClient.setQueryData(
        ["datasetEvents", datasetId],
        (oldData: EventRecord[]) => {
          return oldData.filter((event) => !eventIds.includes(event.event_id));
        },
      );
    },
  });
}
