import {
  Alert,
  Link,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
} from "@mui/material";

import { RobotoTableCell, RobotoTableRow } from "@/shared/components";
import { ActionRecord } from "@/shared/domain/actions";
import { OrgRecord } from "@/shared/domain/orgs";

import { ActionRow } from "./ActionRow";

interface ActionTableProps {
  actions: ActionRecord[];
  isLoading: boolean;
  onDelete?: (action: ActionRecord) => void;
  org: OrgRecord | null;
}

/**
 * A table of actions.
 */
export default function ActionTable({
  actions,
  isLoading,
  onDelete,
  org,
}: ActionTableProps) {
  const theme = useTheme();

  let content: JSX.Element[] | JSX.Element | null = null;
  if (isLoading) {
    content = Array.from({ length: 3 }, (_, rowIdx) => (
      <TableRow key={`placeholder-row-${rowIdx}`}>
        {Array.from({ length: 6 }, (_, cellIdx) => (
          <RobotoTableCell key={`placeholder-cell-${cellIdx}`}>
            <Skeleton
              animation="wave"
              variant="rounded"
              height={30}
              sx={{ mt: 1, mb: 1 }}
            />
          </RobotoTableCell>
        ))}
      </TableRow>
    ));
  } else if (actions.length === 0) {
    content = (
      <RobotoTableRow>
        <RobotoTableCell
          style={{
            paddingBottom: theme.spacing(2),
            paddingTop: theme.spacing(2),
          }}
          colSpan={6}
        >
          <Alert severity="info">
            No actions found. You can create one by clicking the Create button
            above, or with the{" "}
            <Link
              underline="hover"
              target="_blank"
              href="https://docs.roboto.ai/getting-started/programmatic-access.html#python-sdk-and-cli"
            >
              Roboto CLI
            </Link>
            :
            <br />
            <br />
            <span
              style={{
                fontFamily: "monospace",
                fontSize: "0.8rem",
              }}
            >
              $ roboto actions create --name {"<actionReference>"} --image{" "}
              {"<imageUri>"}
            </span>
          </Alert>
        </RobotoTableCell>
      </RobotoTableRow>
    );
  } else {
    content = actions.map((action) => {
      return (
        <ActionRow
          key={`${action.org_id}/${action.name}@${action.digest}`}
          action={action}
          onDelete={onDelete}
          org={org}
        />
      );
    });
  }

  return (
    <TableContainer component={Paper} variant="outlined">
      <Table size="small">
        <TableHead>
          <TableRow>
            <RobotoTableCell>Name</RobotoTableCell>
            <RobotoTableCell>Image</RobotoTableCell>
            <RobotoTableCell>Modified</RobotoTableCell>
            <RobotoTableCell>Modified By</RobotoTableCell>
            <RobotoTableCell>Tags</RobotoTableCell>
            <RobotoTableCell>Operations</RobotoTableCell>
          </TableRow>
        </TableHead>
        <TableBody>{content}</TableBody>
      </Table>
    </TableContainer>
  );
}
