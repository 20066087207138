import { Alert, AlertTitle, Box, Paper, useTheme } from "@mui/material";
import * as React from "react";

import { type MessagePathRecord } from "@/shared/domain/topics";
import { SearchQueryBody } from "@/types";

import { MessagePathsDetail } from "../MessagePathsDetail";
import { RobotoDataGrid } from "../RobotoDataGrid";

import { ColumnsModal } from "./ColumnsModal";
import {
  TablePageAction,
  TablePageState,
  tablePageReducer,
} from "./stateReducer";
import { createRows, retrieveMetadataColumns } from "./tableLogic";

const initialQuery: SearchQueryBody = {
  sort_by: "created",
  sort_direction: "DESC",
};

const possibleStandardMsgPathsColumns = [
  "canonical_data_type",
  "created",
  "created_by",
  "data_type",
  "message_path",
  "modified",
  "modified_by",
  "topic_id",
  "message_path_id",
];

const sortableColumns = new Set<string>();

interface TopicMessagePathsTableProps {
  msgPaths: MessagePathRecord[];
  loading: boolean;
  selectedRows?: Set<string>;
  setSelectedRows?: (arg: Set<string>) => void;
  onRowSingleClick?: (msgPath: MessagePathRecord) => void;
  variant?: "elevation" | "outlined";
  containerStyle?: React.CSSProperties;
  fetchMoreData: () => void;
}

export const TopicMessagePathsTable: React.FC<TopicMessagePathsTableProps> = ({
  msgPaths,
  loading,
  selectedRows,
  setSelectedRows = () => {},
  onRowSingleClick = () => {},
  variant,
  containerStyle,
  fetchMoreData,
}) => {
  const theme = useTheme();

  const msgPathsById = new Map<string, MessagePathRecord>(
    msgPaths.map((msgPath) => {
      return [msgPath.message_path_id, msgPath];
    }),
  );

  const [selectedRowsToggle, setSelectedRowsToggle] =
    React.useState<boolean>(false);

  const [state, dispatch] = React.useReducer<
    React.Reducer<TablePageState, TablePageAction>
  >(tablePageReducer, {
    modalOpen: false,
    rightClickedRow: null,
    sidebar: { isOpen: false, dataset: null },
    columns: [
      "message_path_id",
      "message_path",
      "topic_id",
      "data_type",
      "created",
    ],
    tableConfig: { page: 0, rowsPerPage: 25 },
    snackbar: { isOpen: false, message: "" },
    sortColumnIndex: 1,
    sortOrder: "descending",
    lastSearchQuery: initialQuery,
    selectedRows: new Set<string>(),
  });

  const pageData = React.useMemo(() => {
    return msgPaths.slice(
      state.tableConfig.page * state.tableConfig.rowsPerPage,
      state.tableConfig.page * state.tableConfig.rowsPerPage +
        state.tableConfig.rowsPerPage,
    );
  }, [msgPaths, state.tableConfig]);

  const { rows, allPossibleColumns } = React.useMemo(() => {
    const possibleMetadataColumns: string[] =
      retrieveMetadataColumns(pageData) ?? [];

    const rows = createRows(pageData, state.columns, possibleMetadataColumns);

    const allPossibleColumns = possibleStandardMsgPathsColumns
      .concat(possibleMetadataColumns)
      .sort((a, b) => a.localeCompare(b, undefined, { sensitivity: "base" }));

    return { rows, allPossibleColumns };
  }, [pageData, state.columns]);

  const noSearchResults =
    Object.keys(state.lastSearchQuery).length > 0 && msgPaths.length === 0;

  React.useEffect(() => {
    if (selectedRows && selectedRows.size === 0) {
      setSelectedRowsToggle((prevToggle) => !prevToggle);
      dispatch({
        type: "SET_SELECTED_ROWS",
        payload: { selectedRows: new Set<string>() },
      });
    }
  }, [selectedRows]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
      }}
    >
      <Box
        sx={{
          flex: 1,
          maxWidth: "100%",
        }}
      >
        <Paper
          sx={{
            mt: theme.spacing(2),
          }}
          variant={variant}
        >
          {noSearchResults && (
            <Alert severity="info">
              <AlertTitle>Message Paths</AlertTitle>
              <>
                No message paths match your search criteria. Try changing your
                search terms and try again.
              </>
            </Alert>
          )}

          {!noSearchResults && (
            <>
              <RobotoDataGrid
                sortableColumns={sortableColumns}
                sortingOrder={state.sortOrder}
                sortingColumnIndex={state.sortColumnIndex}
                page={state.tableConfig.page}
                rowsPerPage={state.tableConfig.rowsPerPage}
                nextPageButtonDisabled={
                  (state.tableConfig.page + 1) *
                    state.tableConfig.rowsPerPage >=
                  msgPaths.length
                }
                currentRowLength={msgPaths.length}
                containerStyle={containerStyle}
                loading={loading}
                columnNames={state.columns}
                rows={rows}
                onPageChange={(newPage, rowsPerPage) => {
                  if ((newPage + 2) * rowsPerPage >= msgPaths.length) {
                    // Request more pages of data from the backend
                    // as we approach the last page of cached results.
                    // 2 pages prior seemed to work well experimentally.
                    fetchMoreData();
                  }
                  dispatch({
                    type: "SET_TABLE_CONFIG",
                    payload: { page: newPage, rowsPerPage: rowsPerPage },
                  });
                }}
                onRowsPerPageChange={(_page, newRowsPerPage) => {
                  dispatch({
                    type: "SET_TABLE_CONFIG",
                    payload: {
                      page: 0,
                      rowsPerPage: newRowsPerPage as 10 | 25 | 50,
                    },
                  });
                }}
                onColumnSortClicked={() => {
                  /** noop */
                }}
                onAddColumnClick={() => {
                  dispatch({ type: "SET_MODAL_OPEN", payload: true });
                }}
                onRowDoubleClick={() => {
                  /** noop */
                }}
                onRowSingleClick={(rowId: string) => {
                  const msgPathRecord = msgPathsById.get(rowId);
                  if (onRowSingleClick && msgPathRecord) {
                    onRowSingleClick(msgPathRecord);
                  }
                }}
                onSelectedRowsChange={(selectedRows) => {
                  setSelectedRows(selectedRows);
                  dispatch({
                    type: "SET_SELECTED_ROWS",
                    payload: { selectedRows: selectedRows },
                  });
                }}
                isRowSelectable={false}
                isRowExpandable={true}
                clearSelectedRowsToggle={selectedRowsToggle}
                expandableContent={(rowId: string) => {
                  const msgPathRecord = msgPathsById.get(rowId);

                  if (msgPathRecord) {
                    return (
                      <Box
                        sx={{
                          padding: theme.spacing(3, 2),
                        }}
                      >
                        <MessagePathsDetail
                          topicId={msgPathRecord.topic_id}
                          messagePathIds={[msgPathRecord.message_path_id]}
                        />
                      </Box>
                    );
                  }
                }}
              />
              <ColumnsModal
                open={state.modalOpen}
                handleClose={() => {
                  dispatch({ type: "SET_MODAL_OPEN", payload: false });
                }}
                columns={allPossibleColumns}
                currentColumns={state.columns}
                onSave={(updatedColumns) => {
                  dispatch({ type: "SAVE_COLUMNS", payload: updatedColumns });
                }}
              />
            </>
          )}
        </Paper>
      </Box>
    </Box>
  );
};
