import { Route, Routes } from "react-router-dom";

import { Collection } from "./Collection";
import { Collections } from "./ViewCollections";

export function CollectionsRoutes() {
  return (
    <Routes>
      <Route index element={<Collections />} />
      <Route path=":collectionId" element={<Collection />} />
    </Routes>
  );
}
