import {
  Box,
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
} from "@mui/material";
import * as React from "react";

import { RobotoCheckbox, RobotoTableCell } from "@/shared/components";
import { useDatasetEvents } from "@/shared/domain/datasets/hooks/useDatasetEvents";
import { EventRecord } from "@/shared/domain/events";

import { DatasetEventTableControls } from "./controls/DatasetEventTableControls";
import { EventTableBody } from "./EventTableBody";

interface DatasetEventsTableProps {
  datasetId: string;
}

export const DatasetEventsTable = ({ datasetId }: DatasetEventsTableProps) => {
  const events = useDatasetEvents(datasetId);
  const [selectedRows, setSelectedRows] = React.useState<
    EventRecord["event_id"][]
  >([]);

  const theme = useTheme();
  const numSelected = selectedRows.length;
  const rowCount = events.data?.length || 0;

  const onSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = events.data?.map((event) => event.event_id) ?? [];
      setSelectedRows(newSelected);
      return;
    }
    setSelectedRows([]);
  };

  return (
    <Box>
      <DatasetEventTableControls
        datasetId={datasetId}
        selectedEvents={selectedRows}
        onDeleteEvents={(eventIds) =>
          setSelectedRows((prev) => prev.filter((id) => !eventIds.includes(id)))
        }
      />
      <TableContainer component={Paper} variant="outlined">
        <Table size="small" sx={{ minWidth: 650 }} aria-label="events-table">
          <TableHead>
            <TableRow>
              <RobotoTableCell
                sx={{
                  padding: "0px",
                  width: "42.5px",
                  maxWidth: "42.5px",
                }}
              >
                <RobotoCheckbox
                  sx={{
                    ml: theme.spacing(1),
                    display: {
                      xs: "none",
                      sm: "inline-flex",
                    },
                  }}
                  color="default"
                  checked={rowCount > 0 && numSelected === rowCount}
                  onChange={onSelectAllClick}
                  inputProps={{ "aria-label": "checkbox" }}
                />
              </RobotoTableCell>
              <RobotoTableCell>Name</RobotoTableCell>
              <RobotoTableCell>Event ID</RobotoTableCell>
              <RobotoTableCell>Start Time</RobotoTableCell>
              <RobotoTableCell>Created By</RobotoTableCell>
              <RobotoTableCell>Scope</RobotoTableCell>
              <RobotoTableCell>Tags</RobotoTableCell>
            </TableRow>
          </TableHead>
          <EventTableBody
            datasetId={datasetId}
            events={events.data}
            isLoading={events.isPending}
            isError={events.isError}
            numColumns={7}
            onSelect={(event) => {
              if (selectedRows.includes(event.event_id)) {
                setSelectedRows(
                  selectedRows.filter((id) => id !== event.event_id),
                );
                return;
              }
              const newSelected = [...selectedRows, event.event_id];
              setSelectedRows(newSelected);
            }}
            selectedRows={selectedRows}
          />
        </Table>
      </TableContainer>
    </Box>
  );
};
