import { useQuery } from "@tanstack/react-query";
import * as React from "react";

import { DomainServicesContext } from "@/providers/DomainServices";
import { Device } from "@/shared/domain/devices";
import { Token } from "@/shared/domain/tokens";

export function useDevice(
  deviceId: string | undefined,
  onSuccess: (device: Device) => void = () => {},
) {
  const { devices } = React.useContext(DomainServicesContext);

  return useQuery<Device, Error>({
    queryKey: ["device", deviceId],
    queryFn: ({ signal }) => {
      if (deviceId === undefined) {
        return Promise.reject(new Error("Invalid deviceId"));
      }

      return devices
        .getDevice(deviceId, {
          abortSignal: signal,
        })
        .then((record) => {
          onSuccess(record);
          return record;
        });
    },
    enabled: deviceId !== undefined,

    // 1 minute until considered stale.
    staleTime: 60000,
  });
}

export function useDeviceTokens(
  deviceId: string | undefined,
  onSuccess: (tokens: Token[]) => void = () => {},
) {
  const { devices } = React.useContext(DomainServicesContext);

  return useQuery<Token[], Error>({
    queryKey: ["deviceTokens", deviceId],
    queryFn: ({ signal }) => {
      if (deviceId === undefined) {
        return Promise.reject(new Error("Invalid deviceId"));
      }

      return devices
        .getTokensForDevice(deviceId, {
          abortSignal: signal,
        })
        .then((record) => {
          onSuccess(record);
          return record;
        });
    },
    enabled: deviceId !== undefined,

    // 1 minute until considered stale.
    staleTime: 60000,
  });
}
