import FilterListIcon from "@mui/icons-material/FilterList";
import { Box, Popover, TextField, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import * as React from "react";

import { useAuth } from "@/providers";
import { RobotoButton, SelectionButton } from "@/shared/components";
import { AddToCollection } from "@/shared/components/collections";
import {
  FilterForm,
  FiltersBox,
  SearchField,
  UISimpleCondition,
} from "@/shared/components/filters";
import { RoboqlEditor } from "@/shared/components/RoboqlEditor";
import { AutocompleteType } from "@/shared/components/TagAndMetadataAutocomplete";
import { useElementsForAutocompleteType } from "@/shared/components/TagAndMetadataAutocomplete/hook";
import { QueryTarget } from "@/shared/domain/query";
import { ConditionsOperator } from "@/types";

import { SearchModeToggle, SearchTargetSelector } from "./MultiSearch";
import { useRoboqlExternalCtx } from "./MultiSearch/useRoboqlExternalCtx";
import { ModeToggleSearchBarMode } from "./types";

interface MultiSearchBarProps {
  orgId: string;

  // Params used across query types
  target: QueryTarget;
  onTargetChanged: (arg: QueryTarget) => void;
  mode: ModeToggleSearchBarMode;
  onModeChanged: (arg: ModeToggleSearchBarMode) => void;

  // Params used in basic string searches
  placeholder?: string;
  searchTerm: string;
  setSearchTerm: (arg: string) => void;
  onBasicSearchSubmit: (target: QueryTarget, searchTerm: string) => void;

  // Params used in conditional searches with filters
  searchFields: SearchField[];
  onConditionSearchSubmit: (
    conditions: UISimpleCondition[],
    operator: ConditionsOperator,
  ) => void;

  // Params used in RoboQL queries
  roboql: string;
  setRoboql: (arg: string) => void;
  onRoboqlSearchSubmit: (target: QueryTarget, roboql: string) => void;

  // Currently wired in but should probably be handled in a table header instead
  selectedDatasets?: Set<string>;
  clearSelectedDatasets?: () => void;
}

/**
 *
 * @searchFields An array of search fields to be used in the search bar. Should be snake case.
 */

export const MultiSearchBar: React.FC<MultiSearchBarProps> = ({
  orgId,
  target,
  onTargetChanged,
  mode,
  onModeChanged,
  placeholder,
  searchTerm,
  setSearchTerm,
  onBasicSearchSubmit,
  searchFields,
  onConditionSearchSubmit,
  roboql,
  setRoboql,
  onRoboqlSearchSubmit,
  selectedDatasets,
  clearSelectedDatasets,
}) => {
  const theme = useTheme();
  const { currentOrganization } = useAuth();

  const [groupOperator, setGroupOperator] = useState<ConditionsOperator>("AND");
  const [conditions, setConditions] = useState<UISimpleCondition[]>([]);

  // Every time we change which search fields are valid, we should clear existing conditions
  React.useEffect(() => {
    setConditions([]);
  }, [searchFields, searchTerm]);

  const autocompleteType = mapTargetToAutocompleteType(target);

  const { elements: metadataKeys } =
    useElementsForAutocompleteType(autocompleteType);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleRoboqlChange = (value: string | undefined) => {
    setRoboql(value || "");
  };

  const [anchorEl, setAnchorEl] = React.useState<
    HTMLButtonElement | HTMLDivElement | null
  >(null);

  const [filterToUpdate, setFilterToUpdate] =
    useState<UISimpleCondition | null>(null);

  const handleFilterDropdownClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    setFilterToUpdate(null);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setFilterToUpdate(null);
    setAnchorEl(null);
  };

  const processConditionsSearch = (
    conditions: UISimpleCondition[],
    operator: ConditionsOperator,
  ) => {
    // clear the search bar
    setSearchTerm("");
    onConditionSearchSubmit(conditions, operator);
  };

  const [collectionAnchorEl, setCollectionAnchorEl] =
    React.useState<HTMLElement | null>(null);

  const handleCollectionPopoverOpen = (
    event: React.MouseEvent<HTMLElement>,
    overrideAnchorEl?: HTMLElement | null,
  ) => {
    if (overrideAnchorEl) {
      setCollectionAnchorEl(overrideAnchorEl);
    } else {
      setCollectionAnchorEl(event.currentTarget);
    }
  };

  const handleCollectionPopoverClose = () => {
    setCollectionAnchorEl(null);
  };

  // This powers autocomplete suggestions in the RoboQL editor
  const roboqlExternalCtx = useRoboqlExternalCtx(target, orgId);

  return (
    <Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          height: mode === "roboql" ? "88px" : "56px",
          backgroundColor: theme.palette.foreground.main,
          border: 0,
          borderBottomLeftRadius:
            conditions.length > 0 ? "0px" : theme.border.radius,
          borderBottomRightRadius:
            conditions.length > 0 ? "0px" : theme.border.radius,
          borderTopRightRadius: theme.border.radius,
          borderTopLeftRadius: theme.border.radius,
          boxShadow:
            "0px 2px 1px -1px rgba(0,0,0,0.005), 0px 1px 1px 0px rgba(0,0,0,0.05), 0px 1px 3px 0px rgba(0,0,0,0.05)",
        }}
      >
        {/* First box: as large as its content - used for search target selector*/}
        <Box
          sx={{
            display: "flex",
            alignItems: mode === "roboql" ? "start" : "center",
            borderRight: mode === "roboql" ? 0 : "1px solid",
            borderColor:
              theme.palette.mode === "light"
                ? "rgb(221, 221, 221)"
                : "rgb(51, 51, 51)",
            marginTop: theme.spacing(1.5),
            marginBottom: theme.spacing(1.5),
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(1),
          }}
        >
          <SearchTargetSelector
            initialTarget={target}
            onTargetChanged={onTargetChanged}
          />
        </Box>

        {/* Middle box: takes up the remaining space for the search input 
        minWidth: 0 is required for the RoboQL (Monaco) editor to resize responsively
        https://github.com/microsoft/monaco-editor/issues/3393#issuecomment-1317697451 
        */}
        <Box sx={{ flexGrow: 1, minWidth: 0 }}>
          {mode === "roboql" ? (
            <Box
              sx={{
                height: "75px",
                width: "100%",
                marginTop: theme.spacing(0.75),
              }}
            >
              <RoboqlEditor
                initialValue={roboql}
                onChange={handleRoboqlChange}
                target={target}
                triggerCompletionOnEmptyInitialValue={true}
                roboqlExternalCtx={roboqlExternalCtx}
              />
            </Box>
          ) : (
            <TextField
              data-cy={"basicQueryField"}
              sx={{
                width: "100%",
                "& fieldset": { border: "none" },
              }}
              value={searchTerm}
              onChange={handleSearchChange}
              onKeyDown={(e) => {
                if (e.key.toLowerCase() === "enter") {
                  setConditions([]);
                  onBasicSearchSubmit(target, searchTerm);
                  e.preventDefault();
                }
              }}
              variant="outlined"
              placeholder={placeholder}
              inputProps={{
                autoComplete: "off",
              }}
            />
          )}
        </Box>

        {/* Last box: as large as its content - used for extra controls */}

        <Box
          sx={{
            display: "flex",
            marginTop: theme.spacing(1.5),
            marginBottom: theme.spacing(1.5),
            marginLeft: theme.spacing(2),
            gap: theme.spacing(2),
            alignItems: mode === "roboql" ? "start" : "center",
            marginRight: theme.spacing(2),
          }}
        >
          {selectedDatasets !== undefined && selectedDatasets.size > 0 && (
            <SelectionButton
              selectedDatasets={selectedDatasets}
              clearSelected={clearSelectedDatasets}
              currentOrganization={currentOrganization}
              handleCollectionPopoverOpen={handleCollectionPopoverOpen}
              enableDeleteDatasets={true}
            />
          )}

          {mode === "roboql" ? (
            <RobotoButton
              variant="contained"
              sx={{ height: "32px" }}
              onClick={() => onRoboqlSearchSubmit(target, roboql)}
              eventName={"SearchSubmitted"}
              eventProperties={{
                mode: "roboql",
                query: roboql,
              }}
            >
              Execute
            </RobotoButton>
          ) : (
            <RobotoButton
              data-cy={"addFilterButton"}
              eventName={"AddSearchFilterClicked"}
              onClick={handleFilterDropdownClick}
              variant="text"
              disableFocusRipple
              startIcon={<FilterListIcon />}
            >
              <Typography variant="subtitle2">Filters</Typography>
            </RobotoButton>
          )}
          <SearchModeToggle
            mode={mode}
            onModeChanged={(mode) => {
              setConditions([]);
              onModeChanged(mode);
            }}
          />
        </Box>
      </Box>

      {conditions.length > 0 && (
        <FiltersBox
          onGroupOperatorChange={(newOperator) => {
            setGroupOperator(newOperator);
            processConditionsSearch(conditions, newOperator);
          }}
          conditions={conditions}
          conditionsOperator={groupOperator}
          onDeleteCondition={(condition) => {
            const newConditions = conditions.filter(
              (c) => c.id !== condition.id,
            );
            setConditions(newConditions);
            processConditionsSearch(newConditions, groupOperator);
          }}
          onFilterClick={(event, filter) => {
            setFilterToUpdate(filter);
            setAnchorEl(event.currentTarget);
          }}
          editable={true}
        />
      )}
      <FilterForm
        availableMetadataKeys={metadataKeys}
        open={Boolean(anchorEl)}
        searchFields={searchFields}
        filterToUpdate={filterToUpdate}
        anchorEl={anchorEl}
        handleClose={handleClose}
        onCreate={(condition) => {
          const newConditions = [...conditions, condition];

          setConditions(newConditions);
          processConditionsSearch(newConditions, groupOperator);
        }}
        onUpdate={(filter) => {
          const newConditions = conditions.map((condition) => {
            if (condition.id === filter.id) {
              return filter;
            }
            return condition;
          });

          setConditions(newConditions);
          setFilterToUpdate(null);
          setAnchorEl(null);
          processConditionsSearch(newConditions, groupOperator);
        }}
      />

      <Popover
        open={Boolean(collectionAnchorEl)}
        anchorEl={collectionAnchorEl}
        onClose={handleCollectionPopoverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: -8,
          horizontal: "left",
        }}
        marginThreshold={5}
      >
        <AddToCollection
          selectedDatasets={selectedDatasets}
          close={handleCollectionPopoverClose}
        />
      </Popover>
    </Box>
  );
};

const mapTargetToAutocompleteType = (target: QueryTarget): AutocompleteType => {
  let autocompleteType = AutocompleteType.DatasetMetadataKeys;

  if (target === QueryTarget.Datasets) {
    autocompleteType = AutocompleteType.DatasetMetadataKeys;
  } else if (target === QueryTarget.Files) {
    autocompleteType = AutocompleteType.FileMetadataKeys;
  } else if (target === QueryTarget.Topics) {
    autocompleteType = AutocompleteType.TopicMetadataKeys;
  } else if (target === QueryTarget.TopicMessagePaths) {
    autocompleteType = AutocompleteType.TopicMetadataKeys;
  } else if (target === QueryTarget.Events) {
    autocompleteType = AutocompleteType.EventMetadataKeys;
  }

  return autocompleteType;
};
