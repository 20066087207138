import * as React from "react";

import { PlotTool } from "../plotTools/PlotTool";

interface PlotPanelContext {
  activeTool: PlotTool;
  setActiveTool: (tool: PlotTool) => void;
  isViewportSynced: boolean;
  setViewportSynced: (next: boolean) => void;
}

export const PlotPanelContext = React.createContext<PlotPanelContext>({
  activeTool: PlotTool.Move,
  setActiveTool: () => {},
  isViewportSynced: true,
  setViewportSynced: () => {},
});
