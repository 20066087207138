import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Box, IconButton, Paper, Typography, useTheme } from "@mui/material";
import * as React from "react";

import { RobotoTooltip } from "@/shared/components";

interface DashboardSectionProps {
  title?: string;
  onClick?: () => void;
  onClickMore?: () => void;
  linkText?: string;
  colSpan: number;
  rowSpan: number;
  children?: React.ReactNode;
}

export const DashboardSection: React.FC<DashboardSectionProps> = ({
  title,
  onClick,
  onClickMore,
  linkText,
  colSpan,
  rowSpan,
  children,
}) => {
  const theme = useTheme();
  return (
    <Box
      component={Paper}
      variant="outlined"
      onClick={() => onClick && onClick()}
      sx={{
        padding: theme.spacing(2),
        paddingBottom: theme.spacing(0),
        backgroundColor: theme.palette.paper.main,
        gridColumn: "span " + colSpan,
        gridRow: "span " + rowSpan,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="body1">{title}</Typography>
        {onClickMore && (
          <RobotoTooltip title={linkText}>
            <IconButton
              size="small"
              onClick={() => onClickMore && onClickMore()}
            >
              <OpenInNewIcon
                sx={{
                  fontSize: "1rem",
                  opacity: 0.75,
                }}
              />
            </IconButton>
          </RobotoTooltip>
        )}
      </Box>
      <Box>{children}</Box>
    </Box>
  );
};
