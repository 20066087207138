import { SxProps, useTheme } from "@mui/material";
import Box from "@mui/material/Box";

import Tag from "../Tag";

import { assignTagColor } from "./helpers";

interface TagListProps {
  maxDisplayCount?: number;
  onClick?: (tag: string) => void;
  selections?: string[];
  sx?: SxProps;
  tags: string[];
  formatHover?: boolean;
}

export function TagList({
  maxDisplayCount,
  onClick,
  selections,
  sx,
  tags,
  formatHover,
}: TagListProps) {
  const theme = useTheme();

  const sortedTags = [...tags].sort();

  let content = sortedTags.map((tag) => {
    const tagColor = assignTagColor(tag);

    // Background color is defined in rgba format,
    // Text color is defined in  8 digit hex format (#rrggbbaa)
    let backgroundOpacity = 0.35;
    let textOpacity = "ff"; // fully opaque
    if (onClick && formatHover) {
      if (!selections?.includes(tag)) {
        backgroundOpacity = 0.15;
        textOpacity = "80"; // 50% opaque
      }
    }
    const backgroundColor = `rgba(${tagColor.toString()}, ${backgroundOpacity})`;

    return (
      <Tag
        key={tag}
        onTagClick={onClick}
        shrink={true}
        sx={{
          backgroundColor,
          color: `${theme.palette.text.primary}${textOpacity}`,
          ":hover": {
            color: onClick
              ? `${theme.palette.text.primary}`
              : `${theme.palette.text.primary}${textOpacity}`,
            backgroundColor: onClick
              ? `rgba(${tagColor.toString()}, ${backgroundOpacity + 0.2})`
              : backgroundColor,
          },
        }}
        value={tag}
      />
    );
  });
  if (maxDisplayCount !== undefined && tags.length > maxDisplayCount) {
    content = content.slice(0, maxDisplayCount);
    content.push(
      <Tag
        key="more"
        sx={{
          backgroundColor: "inherit",
          color: theme.palette.text.secondary,
          minWidth: "80px",
        }}
        tooltip={tags.slice(maxDisplayCount).join(", ")}
        value={`+ ${tags.length - maxDisplayCount} more`}
        variant={"outlined"}
      />,
    );
  }
  return (
    <Box
      component="ul"
      display="flex"
      gap={theme.spacing(1)}
      justifyContent="flex-start"
      overflow={"hidden"}
      sx={sx}
    >
      {content}
    </Box>
  );
}
