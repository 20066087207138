import DatasetOutlinedIcon from "@mui/icons-material/DatasetOutlined";
import {
  Avatar,
  Box,
  Card,
  CardActions,
  CardContent,
  Typography,
  useTheme,
} from "@mui/material";

import { useNavigation } from "@/providers";
import { RobotoButton, RobotoLinkHoverUnderline } from "@/shared/components";
import Tag from "@/shared/components/Tag";
import { CollectionRecord } from "@/shared/domain/collections";

interface CollectionCardProps {
  collection: CollectionRecord;
}

export default function CollectionCard({ collection }: CollectionCardProps) {
  const theme = useTheme();

  const { goto } = useNavigation();

  const numDatasets = collection.resources["dataset"]?.length || 0;
  const numFiles = collection.resources["file"]?.length || 0;

  return (
    <Card
      variant="outlined"
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "210px",
        width: "1fr",
        cursor: "pointer",
        backgroundColor: theme.palette.paper.main,
        ":hover": {
          backgroundColor: theme.palette.tableHover.light,
        },
      }}
      onClick={() => {
        goto.collection({
          collectionId: collection.collection_id,
        });
      }}
    >
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Box
          alignItems="center"
          justifyContent="space-between"
          display="flex"
          width="100%"
          marginBottom="0.5em"
        >
          <Box component="span" color={theme.palette.primary.main}>
            <RobotoLinkHoverUnderline
              to={`/collections/${collection.collection_id}`}
            >
              <Typography
                component={"abbr"}
                title={collection.name || collection.collection_id}
                sx={{
                  fontSize: "1rem",
                  cursor: "pointer",
                  fontVariant: "none",
                  textDecoration: "none",
                }}
                variant={"subtitle2"}
              >
                {collection.name || collection.collection_id}
              </Typography>
            </RobotoLinkHoverUnderline>

            <Typography variant="body2" color="text.secondary" fontSize="12px">
              By {collection.created_by}
            </Typography>
          </Box>
          <Avatar
            sx={{
              backgroundColor: theme.palette.paper.main,
              boxShadow:
                theme.palette.mode === "light"
                  ? "rgba(140, 149, 159, 0.15) 0px 3px 6px 0px"
                  : "none",
            }}
          >
            <DatasetOutlinedIcon color="disabled" />
          </Avatar>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            flexGrow: "1",
            alignContent: "start",
            paddingTop: theme.spacing(1),
          }}
        >
          <Typography
            variant="body2"
            color="text.secondary"
            flex="0 1 auto"
            sx={{
              display: "-webkit-box",
              WebkitLineClamp: 3,
              WebkitBoxOrient: "vertical",
            }}
            textOverflow="ellipsis"
            overflow="hidden"
          >
            <abbr
              title={collection.description ?? ""}
              style={{
                fontVariant: "none",
                textDecoration: "none",
              }}
            >
              {collection.description || <i>No Description</i>}
            </abbr>
          </Typography>
        </Box>
      </CardContent>

      <CardActions
        sx={{
          display: "flex",
          ml: theme.spacing(1),
          mr: theme.spacing(1),
          mb: theme.spacing(1),
          gap: theme.spacing(1.5),
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <RobotoButton
          eventName={"OpenCollectionClicked"}
          eventProperties={{ collectionId: collection.collection_id }}
          variant={"outlined"}
          size="small"
          color="primary"
          onClick={() =>
            goto.collection({
              collectionId: collection.collection_id,
            })
          }
          sx={{
            minWidth: 80,
          }}
        >
          Open
        </RobotoButton>
        <Box
          sx={{ display: "flex", alignItems: "center", gap: theme.spacing(1) }}
        >
          {numDatasets > 0 && (
            <Tag
              key="num_datasets"
              sx={{
                backgroundColor: "inherit",
                color: theme.palette.text.secondary,
              }}
              value={`${numDatasets} dataset${numDatasets > 1 ? "s" : ""}`}
              variant={"outlined"}
            />
          )}
          {numFiles > 0 && (
            <Tag
              key="num_files"
              sx={{
                backgroundColor: "inherit",
                color: theme.palette.text.secondary,
              }}
              value={`${numFiles} file${numFiles > 1 ? "s" : ""}`}
              variant={"outlined"}
            />
          )}
        </Box>
      </CardActions>
    </Card>
  );
}
