import { Alert, Box, CircularProgress, useTheme } from "@mui/material";
import * as React from "react";

import { useAuth } from "@/providers";
import { RobotoButton, RobotoTypography } from "@/shared/components";
import { AlertDialog } from "@/shared/components/AlertDialog";
import { InviteTeamMembersForm } from "@/shared/components/auth";
import { OrgRecord } from "@/shared/domain/orgs";
import { UserRecord } from "@/shared/domain/users";
import { useAPICall, useLazyAPICall } from "@/shared/services/apiHooks";
import {
  GetOrganizationInvitesResponse,
  GetOrganizationRolesResponse,
  OrganizationRole,
  OrganizationRoleLevel,
} from "@/shared/services/ApiService";
import {
  HTTPMethod,
  RobotoAPICall,
  declineInviteEndpoint,
  orgsEndpoint,
  orgsInvitesEndpoint,
  orgsRolesEndpoint,
  orgsUsersEndpoint,
} from "@/types";

import { UsersTable } from "./UsersTable";

interface OrganizationSettingsProps {
  currentOrg: OrgRecord | null;
  currentUser: UserRecord | null;
}

export const OrganizationSettings: React.FC<OrganizationSettingsProps> = (
  props,
) => {
  const theme = useTheme();

  const { deleteCurrentOrganization } = useAuth();

  const [alertDialogOpen, setAlertDialogOpen] = React.useState<boolean>(false);
  const [alertDialogTitle, setAlertDialogTitle] = React.useState<string>("");
  const [alertDialogText, setAlertDialogText] = React.useState<string>("");
  const [alertDialogAction, setAlertDialogAction] =
    React.useState<() => Promise<void>>();
  const [leavingOrDeletingOrg, setLeavingOrDeletingOrg] =
    React.useState<boolean>(false);

  const { initiateRequest: initiateInviteRequest } = useLazyAPICall();
  const { initiateRequest: removeUserFromOrgRequest } = useLazyAPICall();
  const { initiateRequest: modifyRoleForUserRequest } = useLazyAPICall();
  const { initiateRequest: deleteInviteForUserRequest } = useLazyAPICall();
  const { initiateRequest: deleteOrgRequest } = useLazyAPICall();

  const [currentUserRole, setCurrentUserRole] = React.useState<
    OrganizationRole | undefined
  >();

  const [errMsg, setErrMsg] = React.useState<string | null>(null);

  function wrapActionWithDialog(
    title: string,
    text: string,
    action: () => Promise<void>,
  ) {
    setAlertDialogTitle(title);
    setAlertDialogText(text);
    setAlertDialogAction(() => action);
    setAlertDialogOpen(true);
  }

  // Used to fetch users currently in an org
  const listOrgRoles: RobotoAPICall = {
    endpoint: orgsRolesEndpoint,
    method: "GET",
    orgId: props.currentOrg?.org_id,
  };

  // Used to fetch users that have been invited to an org
  const listInvitedUsers: RobotoAPICall = {
    endpoint: orgsInvitesEndpoint,
    method: "GET",
    orgId: props.currentOrg?.org_id,
  };

  const { data: userListData, refetch: fetchUserListData } =
    useAPICall<GetOrganizationRolesResponse>(listOrgRoles);

  const { data: invitedListData, refetch: fetchInvitedListData } =
    useAPICall<GetOrganizationInvitesResponse>(listInvitedUsers);

  function refreshUserListData() {
    const sleep = (ms: number) =>
      new Promise((resolve) => setTimeout(resolve, ms));
    sleep(1000)
      .then(() => {
        fetchUserListData();
      })
      .catch(() => {
        // do nothing
      });
  }

  function refreshInvitedListData() {
    const sleep = (ms: number) =>
      new Promise((resolve) => setTimeout(resolve, ms));
    sleep(1000)
      .then(() => {
        fetchInvitedListData();
      })
      .catch(() => {
        //do nothing
      });
  }

  async function modifyRoleForUser(
    userId: string,
    role: OrganizationRoleLevel,
    action: HTTPMethod,
  ) {
    await modifyRoleForUserRequest({
      endpoint: orgsRolesEndpoint,
      method: action,
      requestBody: JSON.stringify({
        user_id: userId,
        role_name: role,
      }),
      orgId: props.currentOrg?.org_id,
    });

    refreshUserListData();
  }

  async function removeUserFromOrg(userId: string) {
    setLeavingOrDeletingOrg(true);
    setErrMsg(null);

    const { error } = await removeUserFromOrgRequest({
      endpoint: orgsUsersEndpoint,
      method: "DELETE",
      requestBody: JSON.stringify({
        user_id: userId,
      }),
      orgId: props.currentOrg?.org_id,
    });

    if (error) {
      if (error.message.includes("org owner cannot remove themselves")) {
        setLeavingOrDeletingOrg(false);
        setErrMsg(
          "You cannot leave the organization because you are the owner. Please either make someone else an owner and then leave the organization, or delete the organization.",
        );
        return;
      }

      setLeavingOrDeletingOrg(false);
      setErrMsg(error.message);
      return;
    }

    setLeavingOrDeletingOrg(false);

    // If the user is the current user, delete the org, else refresh the user list
    if (props.currentUser?.user_id === userId) {
      // Note: When the current org is deleted the protected page will redirect to the sign in page
      deleteCurrentOrganization();
    } else {
      refreshUserListData();
    }
  }

  async function deleteInviteForUser(inviteId: string) {
    await deleteInviteForUserRequest({
      endpoint: declineInviteEndpoint,
      method: "POST",
      pathParams: { inviteId },
    });

    refreshInvitedListData();
  }

  async function deleteOrg() {
    //
    setLeavingOrDeletingOrg(true);
    setErrMsg(null);

    const { error } = await deleteOrgRequest({
      endpoint: orgsEndpoint,
      method: "DELETE",
      orgId: props.currentOrg?.org_id,
    });

    if (error) {
      setLeavingOrDeletingOrg(false);
      setErrMsg(error.message);
      return;
    }

    //
    setLeavingOrDeletingOrg(false);

    // Note: When the current org is deleted the protected page will redirect to the sign in page
    deleteCurrentOrganization();
  }

  const initiateInvites = async (emailAddresses: string[]) => {
    for (let i = 0; i < emailAddresses.length; i++) {
      const { error } = await initiateInviteRequest({
        endpoint: orgsInvitesEndpoint,
        method: "POST",
        requestBody: JSON.stringify({
          invited_user_id: emailAddresses[i],
        }),
        orgId: props.currentOrg?.org_id,
      });
      if (error) {
        setErrMsg(error.message);
      }
    }
  };

  React.useEffect(() => {
    const currentRole = userListData?.data?.find(
      (orgRole) => orgRole.user.user_id === props.currentUser?.user_id,
    );
    setCurrentUserRole(currentRole);
  }, [props.currentUser?.user_id, userListData]);

  React.useEffect(() => {
    fetchUserListData({ orgId: props.currentOrg?.org_id });
  }, [props.currentOrg, fetchUserListData]);

  return (
    <Box sx={{ maxWidth: "900px" }}>
      {errMsg && (
        <Box
          sx={{
            marginBottom: theme.spacing(3),
          }}
        >
          <Alert severity="error">{errMsg}</Alert>
        </Box>
      )}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: theme.spacing(3),
          alignItems: "center",
        }}
      >
        <Box>
          <RobotoTypography variant="body1">
            Organization:{" "}
            <span style={{ fontWeight: 500 }}>{props.currentOrg?.name}</span>
          </RobotoTypography>
          <RobotoTypography variant="caption">
            ID: {props.currentOrg?.org_id}
          </RobotoTypography>
        </Box>

        {leavingOrDeletingOrg ? (
          <Box
            sx={{
              display: "flex",
              gap: theme.spacing(2),
              width: "150px",
              justifyContent: "center",
            }}
          >
            <CircularProgress size={20} />
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              gap: theme.spacing(2),
            }}
          >
            <RobotoButton
              eventName={"LeaveOrgClicked"}
              eventProperties={{ orgId: props.currentOrg?.org_id || "" }}
              variant={"outlined"}
              size="small"
              color="secondary"
              onClick={() =>
                wrapActionWithDialog(
                  "Are you sure you want to leave " +
                    props.currentOrg?.name +
                    "?",
                  "Please confirm that you would like to leave " +
                    props.currentOrg?.name,
                  async function () {
                    if (props.currentUser) {
                      await removeUserFromOrg(props.currentUser.user_id);
                    }
                  },
                )
              }
            >
              Leave
            </RobotoButton>
            {currentUserRole?.roles.includes("owner") && (
              <RobotoButton
                eventName={"DeleteOrgClicked"}
                eventProperties={{ orgId: props.currentOrg?.org_id || "" }}
                variant={"outlined"}
                size="small"
                color="error"
                onClick={() =>
                  wrapActionWithDialog(
                    "Are you sure you want to delete " +
                      props.currentOrg?.name +
                      "?",
                    "Please confirm that you would like to delete " +
                      props.currentOrg?.name,
                    deleteOrg,
                  )
                }
              >
                Delete
              </RobotoButton>
            )}
          </Box>
        )}
      </Box>

      <Box>
        {props.currentOrg && (
          <Box sx={{ marginBottom: theme.spacing(4) }}>
            <UsersTable
              userListData={userListData?.data}
              invitedListData={invitedListData?.data}
              removeUserFromOrg={removeUserFromOrg}
              modifyRoleForUser={modifyRoleForUser}
              deleteInviteForUser={deleteInviteForUser}
              currentUser={props.currentUser}
              currentUserRole={currentUserRole}
              actionDialog={wrapActionWithDialog}
            />
          </Box>
        )}
        <InviteTeamMembersForm
          isVisible={true}
          settingsPage={true}
          organizationName={props.currentOrg?.name}
          inviteAndCompletePressed={(emailAddresses) => {
            void initiateInvites(emailAddresses);
            refreshInvitedListData();
          }}
          skipForNowPressed={() => {
            /* do nothing */
          }}
        />
      </Box>
      <AlertDialog
        dialogOpen={alertDialogOpen}
        handleClose={() => {
          setAlertDialogOpen(false);
        }}
        dialogTitle={alertDialogTitle}
        dialogText={alertDialogText}
        dialogAction={alertDialogAction}
      />
    </Box>
  );
};
