import { useDebouncedCallback } from "@/shared/hooks";
import {
  type TopicData,
  type PlotSeries,
  actions,
  useVizDispatch,
} from "@/shared/state/visualization";

import { colorForSeries } from "../../../colorScale";
import { formatMessagePath } from "../../../message";
import { default as sectionStyles } from "../../SettingsSection.module.css";

import { ConfigItem } from "./ConfigItem";
import { MessagePath } from "./MessagePath";
import { MessagePathHelp } from "./MessagePathHelp";
import { PlotSeriesHeader } from "./PlotSeriesHeader";

interface PlotSeriesConfigProps {
  panelId: string;
  plotSeries: PlotSeries;
}

export function PlotSeriesConfig({
  panelId,
  plotSeries,
}: PlotSeriesConfigProps) {
  const vizStateDispatch = useVizDispatch();

  const changeSeriesColor = useDebouncedCallback(function setSeriesColor(
    lineColor: string,
  ) {
    vizStateDispatch(
      actions.setSeriesConfig(panelId, plotSeries.id, {
        lineColor,
      }),
    );
  }, 15);

  const lineColor = colorForSeries(plotSeries);

  const formattedMessagePath = formatMessagePath(
    plotSeries.data.messagePath.parts,
  );
  const title = `${plotSeries.data.topic.name}.${formattedMessagePath}`;

  return (
    <div className={sectionStyles.setting}>
      <PlotSeriesHeader panelId={panelId} plotSeries={plotSeries} />
      <ConfigItem
        label={
          <>
            Message Path{" "}
            <MessagePathHelp messagePath={plotSeries.data.messagePath} />
          </>
        }
      >
        <MessagePath
          messagePath={plotSeries.data.messagePath}
          onChange={(messagePath: TopicData["messagePath"]) => {
            vizStateDispatch(
              actions.setMessagePath({
                messagePath,
                panelId,
                seriesId: plotSeries.id,
              }),
            );
          }}
        />
      </ConfigItem>
      <ConfigItem label="Line Color">
        <input
          aria-label={`Change line color for ${title}`}
          type="color"
          value={lineColor}
          name={plotSeries.id}
          onChange={(event) => {
            changeSeriesColor(event.target.value);
          }}
        />
      </ConfigItem>
    </div>
  );
}
