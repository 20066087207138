import { Alert, AlertTitle } from "@mui/material";

import { PanelState } from "@/shared/state/visualization";

import styles from "./NoDataMessage.module.css";

interface EmptyDataNoticeProps {
  panelData: PanelState["data"];
}

export function NoDataMessage({ panelData }: EmptyDataNoticeProps) {
  if (
    panelData === null ||
    (typeof panelData === "object" && Object.keys(panelData).length === 0)
  ) {
    return (
      <Alert className={styles.center} severity="info">
        <AlertTitle>No data</AlertTitle>
        Drag and drop a message path from the list of topics onto this panel to
        visualize it.
      </Alert>
    );
  }

  return null;
}
