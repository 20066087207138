import { createContext } from "react";

import { EventName, EventProps } from "@/shared/services/analytics";

import { LoggerService } from "../../shared/services";

export type AnalyticsService = {
  trackEvent: <Name extends EventName>(
    eventName: Name,
    ...props: EventProps<Name> extends undefined ? [] : [EventProps<Name>]
  ) => void;
};

export const AnalyticsContext = createContext<AnalyticsService>({
  trackEvent: () => {
    LoggerService.log("TODO: WRAP IN VALID ANALYTICS PROVIDER");
  },
});
