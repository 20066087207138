import { LoadingButton } from "@mui/lab";
import { Alert, Box, DialogActions, TextField } from "@mui/material";
import { useState } from "react";
import * as React from "react";

import { collectionCreationEndpoint } from "@/Collections/api";
import { useAuth, useNavigation } from "@/providers";
import { CollectionRecord } from "@/shared/domain/collections";
import { useLazyAPICall } from "@/shared/services/apiHooks";
import { FileNode } from "@/types";

interface CreateCollectionProps {
  selectedDatasets?: Set<string>;
  selectedFiles?: FileNode[];
  close: () => void;
}

export const CreateCollection: React.FC<CreateCollectionProps> = ({
  selectedDatasets,
  selectedFiles,
  close,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [newCollectionName, setNewCollectionName] = useState<string>("");
  const [newCollectionDesc, setNewCollectionDesc] = useState<string>("");
  const [error, setError] = React.useState<Error | null>(null);
  const { currentOrganization } = useAuth();

  const { goto } = useNavigation();

  const { initiateRequest: updateCollectionReq } = useLazyAPICall<{
    data: CollectionRecord;
  }>();

  const createCollection = async () => {
    if (!newCollectionName || !currentOrganization?.org_id) {
      return;
    }

    setIsLoading(true);

    let requestBody = {} as BodyInit;

    const datasets =
      (selectedDatasets &&
        Array.from(selectedDatasets).map((dataset) => {
          return {
            resource_type: "dataset",
            resource_id: dataset,
          };
        })) ||
      [];

    const files =
      (selectedFiles &&
        selectedFiles.map((item) => {
          return {
            resource_type: "file",
            resource_id: item.file?.file_id || "",
          };
        })) ||
      [];

    if (
      (selectedDatasets && selectedDatasets.size > 0) ||
      (selectedFiles && selectedFiles.length > 0)
    ) {
      requestBody = JSON.stringify({
        name: newCollectionName,
        description: newCollectionDesc,
        resources: files.length ? files : datasets,
      });
    } else {
      requestBody = JSON.stringify({
        name: newCollectionName,
        description: newCollectionDesc,
      });
    }

    // Create the Collection
    const { error, data } = await updateCollectionReq({
      method: "POST",
      endpoint: collectionCreationEndpoint,
      requestBody: requestBody,
      orgId: currentOrganization?.org_id,
    });

    if (error) {
      setError(error);
    }

    setIsLoading(false);

    if (!error && data?.data) {
      close();
      if (!selectedDatasets) {
        goto.collection({
          collectionId: data.data.collection_id,
        });
      }
    }
  };

  let errorDisplay = null;
  if (error) {
    errorDisplay = (
      <Alert severity="error" onClose={() => setError(null)}>
        {error.message}
      </Alert>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        gap: "0.5rem",
        flexDirection: "column",
        justifyContent: "center",
        padding: "0.5rem",
        width: "300px",
      }}
    >
      {errorDisplay}
      <TextField
        autoFocus
        size="small"
        id="collection-name-text"
        placeholder="Name"
        variant="outlined"
        inputProps={{
          autoComplete: "off",
        }}
        value={newCollectionName}
        onChange={(e) => setNewCollectionName(e.target.value)}
      />
      <TextField
        size="small"
        id="collection-description-text"
        placeholder="Description (optional)"
        multiline
        minRows={2}
        maxRows={5}
        inputProps={{
          autoComplete: "off",
        }}
        variant="outlined"
        value={newCollectionDesc}
        onChange={(e) => setNewCollectionDesc(e.target.value)}
      />
      <DialogActions sx={{ justifyContent: "center" }}>
        <LoadingButton
          loading={isLoading}
          type="submit"
          disabled={!newCollectionName}
          onClick={() => {
            void createCollection();
          }}
          variant="outlined"
          style={{
            textTransform: "none",
          }}
          size="small"
        >
          Create Collection{" "}
          {selectedFiles && selectedFiles.length
            ? `(${selectedFiles.length})`
            : selectedDatasets && `(${selectedDatasets.size})`}
        </LoadingButton>
      </DialogActions>
    </Box>
  );
};
