import { Box, CircularProgress, TextField, useTheme } from "@mui/material";
import { useState } from "react";
import * as React from "react";

import { useAuth } from "@/providers";
import {
  RobotoLink,
  RobotoButton,
  RobotoTypography,
  RobotoLogo,
} from "@/shared/components";
import { AuthCard } from "@/shared/components/auth/AuthCard";
import { AuthCardTitle } from "@/shared/components/auth/AuthCardTitle";
import { CustomCognitoProviderAuthButton } from "@/shared/components/auth/CustomCognitoProviderAuthButton";
import GoogleOAuthButton from "@/shared/components/auth/GoogleOAuthButton";
import { LegalFooter } from "@/shared/components/auth/LegalFooter";
import { Env } from "@/shared/config";
import { useURLService } from "@/shared/services";

interface SignUpFormProps {
  isVisible: boolean;
  continueWithEmailClicked: (emailAddress: string, password: string) => void;
  inviteId?: string;
}

const buttonWidth = 215;

export const SignUpForm: React.FC<SignUpFormProps> = ({
  isVisible,
  continueWithEmailClicked,
  inviteId,
}) => {
  const [showEmailInput, setShowEmailInput] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [errMsg, setErrMsg] = useState<string>("");

  const theme = useTheme();

  const { signUp } = useAuth();

  if (!isVisible) {
    return null;
  }

  const clearErrMsg = () => {
    setErrMsg("");
  };

  const continueWithEmailClickedHandler = async (
    email: string,
    password: string,
  ) => {
    setLoading(true);
    setEmail(email);
    clearErrMsg();

    const errMsg = await signUp(email, password);

    if (errMsg) {
      setErrMsg(errMsg);
      setLoading(false);
      return;
    }

    setLoading(false);

    continueWithEmailClicked(email, password);
  };

  if (loading) {
    return (
      <SignUpCard>
        <Box
          sx={{
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
          }}
        >
          <CircularProgress size="1.75rem" />
        </Box>
      </SignUpCard>
    );
  }

  return (
    <SignUpCard inviteId={inviteId}>
      {Env.enableGoogleSignIn && (
        <GoogleOAuthButton
          buttonWidth={buttonWidth}
          marginTop={theme.spacing(2)}
          marginBottom={theme.spacing(2)}
          signUp={true}
          onClick={() => {
            clearErrMsg();
          }}
        />
      )}

      {Env.ssoSignInConfig && (
        <CustomCognitoProviderAuthButton signUp={false} onClick={() => {}} />
      )}

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        {showEmailInput ? (
          <EmailInput
            continueWithEmailClicked={(email, password) => {
              void continueWithEmailClickedHandler(email, password);
            }}
            inputEmail={email}
          />
        ) : (
          <RobotoButton
            data-cy={"sign-up-with-email"}
            eventName={"SignUpClicked"}
            eventProperties={{ method: "email" }}
            variant="outlined"
            size="large"
            sx={{
              width: buttonWidth,
              marginBottom: theme.spacing(3),
              border: theme.border.thin,
              ":hover": {
                border: theme.border.thin,
                color: theme.palette.text.primary,
              },
              color: theme.palette.text.secondary,
            }}
            onClick={() => {
              setShowEmailInput(true);
            }}
          >
            Sign up with email
          </RobotoButton>
        )}

        <Box
          sx={{ display: "flex", justifyContent: "center", width: buttonWidth }}
        >
          {errMsg && (
            <RobotoTypography
              data-cy={"error-message"}
              variant={"caption"}
              textAlign={"center"}
              sx={{ color: theme.palette.error.main }}
            >
              {errMsg}
            </RobotoTypography>
          )}
        </Box>
      </Box>
    </SignUpCard>
  );
};

interface SignUpCardProps {
  children: React.ReactNode;
  inviteId?: string;
}

const SignUpCard: React.FC<SignUpCardProps> = ({ children, inviteId }) => {
  const theme = useTheme();

  const { generateSignInURL } = useURLService();

  return (
    <>
      <RobotoLogo sx={{ maxWidth: "250px" }} />

      <AuthCard>
        <AuthCardTitle title={"Sign up"} sx={{ fontWeight: 500 }} />
        {children}
      </AuthCard>

      <Box>
        <RobotoTypography
          variant={"caption"}
          textAlign={"center"}
          sx={{ marginTop: theme.spacing(2) }}
        >
          Already have an account?{" "}
          {inviteId ? (
            <RobotoLink to={generateSignInURL(inviteId)}>
              Sign in here
            </RobotoLink>
          ) : (
            <RobotoLink to={generateSignInURL()}>Sign in here</RobotoLink>
          )}
        </RobotoTypography>
      </Box>

      <LegalFooter />
    </>
  );
};

interface EmailInputProps {
  continueWithEmailClicked: (email: string, password: string) => void;
  inputEmail?: string;
}

const EmailInput: React.FC<EmailInputProps> = ({
  continueWithEmailClicked,
  inputEmail,
}) => {
  const theme = useTheme();

  const [password, setPassword] = useState<string>("");
  const [email, setEmail] = useState<string>(inputEmail || "");

  let buttonDisabled = true;

  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  buttonDisabled = !(password.length >= 8 && emailRegex.test(email));

  return (
    <Box
      component="form"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: buttonWidth,
      }}
      onSubmit={(e) => {
        e.preventDefault();
        continueWithEmailClicked(email, password);
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          marginTop: theme.spacing(1),
        }}
      >
        <TextField
          data-cy={"email-input"}
          label="Email address"
          placeholder="name@domain.com"
          type={"email"}
          sx={{ marginBottom: theme.spacing(2) }}
          size="small"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          autoFocus
          inputProps={{
            autoCapitalize: "none",
          }}
        />

        <TextField
          data-cy={"password-input"}
          label="Password"
          type={"password"}
          size="small"
          helperText="Include both cases and symbols"
          sx={{ marginBottom: theme.spacing(2) }}
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
          inputProps={{
            autoCapitalize: "none",
          }}
        />
      </Box>
      <RobotoButton
        data-cy={"continue-with-email"}
        eventName={"SignUpWithEmailSubmitted"}
        variant={"contained"}
        sx={{ width: buttonWidth, marginBottom: theme.spacing(2) }}
        disabled={buttonDisabled}
        type="submit"
      >
        Continue with email
      </RobotoButton>
    </Box>
  );
};
