import { Alert, Box, useTheme } from "@mui/material";
import classNames from "classnames";
import * as React from "react";

import { useVizState } from "@/shared/state/visualization";

import { DropZone, DropZones, Droppable } from "../DnD";
import { Layout } from "../Layout";
import { ViewportProvider } from "../Panel/PlotPanel/Viewport";
import { PanelResizeHandle } from "../resize-handles";

import styles from "./PanelBoard.module.css";
import { PanelOptionsGrid } from "./PanelOptionsGrid";

interface PanelBoardProps {
  className?: classNames.Argument;
  style?: React.CSSProperties;
  errorMsg?: string;
}

export function PanelBoard(props: PanelBoardProps) {
  const { errorMsg } = props;

  const theme = useTheme();

  const state = useVizState();

  const rootRef = React.useRef<HTMLDivElement>(null);

  const layouts = React.useMemo(() => {
    const rootLayout = state.layout;
    const layouts = [];

    if (rootLayout.children.length > 0) {
      const firstChild = rootLayout.children[0];
      layouts.push(<Layout key={firstChild.id} layout={firstChild} />);
    }

    for (let i = 1; i < rootLayout.children.length; i++) {
      const prevLayout = rootLayout.children[i - 1];
      const currentLayout = rootLayout.children[i];

      layouts.push(
        <PanelResizeHandle
          key={prevLayout.id + currentLayout.id}
          axis={rootLayout.axis}
          containerRef={rootRef}
          layout1={prevLayout}
          layout2={currentLayout}
        />,
      );

      layouts.push(<Layout key={currentLayout.id} layout={currentLayout} />);
    }

    return layouts;
  }, [state]);

  if (errorMsg) {
    return (
      <div className={styles.error}>
        <Alert severity="error">{errorMsg}</Alert>
      </div>
    );
  }

  const rootLayout = state.layout;

  if (rootLayout.children.length === 0) {
    return (
      <Droppable
        dropZone={DropZone.PanelBoard}
        droppableId="panelBoard"
        className={classNames(styles.info, props.className)}
        style={props.style}
      >
        <PanelOptionsGrid />
      </Droppable>
    );
  }

  return (
    <ViewportProvider>
      <Box
        bgcolor={theme.palette.code.main}
        className={classNames(styles.panelBoard, props.className)}
        style={props.style}
      >
        <div
          ref={rootRef}
          className={classNames(styles.flexContainer, {
            [styles.flexCol]: rootLayout.axis === "y",
          })}
        >
          {rootLayout.children.length > 1 && (
            <DropZones
              idPrefix="panelBoard"
              dropZone={DropZone.Layout}
              axis={rootLayout.axis}
              data={rootLayout}
            />
          )}
          {layouts}
        </div>
      </Box>
    </ViewportProvider>
  );
}
