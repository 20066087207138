import {
  Autocomplete,
  Box,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useTheme,
} from "@mui/material";
import * as React from "react";

import {
  Accessibility,
  ActionRecord,
  ActionReference,
} from "@/shared/domain/actions";
import { OrgRecord } from "@/shared/domain/orgs";
import { fetchAllItems } from "@/shared/services/apiHooks";
import { RobotoAPICall } from "@/types";

import { isActionReference } from "../../model";

interface ActionSelectorProps {
  action: ActionRecord | ActionReference | null;
  setAction: (arg: ActionRecord | null) => void;
  currentOrg: OrgRecord | null;
}

const actionToLabel = (
  action: ActionRecord,
  selectedAccessibility: Accessibility,
  org: OrgRecord | null,
): string => {
  if (action.accessibility === Accessibility.Organization) {
    return action.name;
  }
  if (
    selectedAccessibility === Accessibility.ActionHub ||
    org?.org_id !== action.org_id
  ) {
    return `${action.org_id} / ${action.name}`;
  }
  return action.name;
};

const optionIsEqualToValue = (
  option: ActionRecord,
  value: ActionRecord | null,
) => {
  return option.digest === value?.digest;
};

export const ActionSelector: React.FC<ActionSelectorProps> = ({
  action,
  setAction,
  currentOrg,
}) => {
  const [accessibility, setAccessibility] = React.useState<Accessibility>(
    Accessibility.Organization,
  );
  const [orgActions, setOrgActions] = React.useState<ActionRecord[]>([]);
  const [actionHubActions, setActionHubActions] = React.useState<
    ActionRecord[]
  >([]);
  const [error, setError] = React.useState<Error | null>(null);
  const [isLoading, setIsLoading] = React.useState(false);

  const theme = useTheme();

  const value = React.useMemo(() => {
    if (!action) {
      return null;
    }

    if (!isActionReference(action)) {
      return action;
    }

    // Given reference to an action, find its corresponding action record
    // amongst either the actions belonging to the current org,
    // or amongst the actions published to the action hub
    const belongsToCurrentOrg = action.owner === currentOrg?.org_id;
    const actionOptions = belongsToCurrentOrg ? orgActions : actionHubActions;

    const option = actionOptions.find((option) => {
      const nameAndOwnerMatches =
        option.name === action.name && option.org_id === action.owner;
      if (!nameAndOwnerMatches) {
        return false;
      }
      if (!action.digest) {
        return true;
      }
      return option.digest === action.digest;
    });
    if (!option) {
      return null;
    }
    return option;
  }, [action, currentOrg, orgActions, actionHubActions]);

  React.useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      setError(null);

      const orgQuery: RobotoAPICall = {
        endpoint: () => `/actions/query`,
        method: "POST",
        orgId: currentOrg?.org_id,
        requestBody: "{}",
      };
      const actionHubQuery: RobotoAPICall = {
        endpoint: () => `/actions/query/actionhub`,
        method: "POST",
        orgId: currentOrg?.org_id,
        requestBody: "{}",
      };

      const [orgQueryResponse, actionHubQueryResponse] = await Promise.all([
        fetchAllItems<ActionRecord>(orgQuery),
        fetchAllItems<ActionRecord>(actionHubQuery),
      ]);
      if (orgQueryResponse.error) {
        setError(orgQueryResponse.error);
        return;
      }
      if (actionHubQueryResponse.error) {
        setError(actionHubQueryResponse.error);
        return;
      }
      const sortedOrgActions = orgQueryResponse.items?.sort((a, b) => {
        return a.name.localeCompare(b.name);
      });

      const sortedActionHubActions = actionHubQueryResponse.items?.sort(
        (a, b) => {
          const aLabel = actionToLabel(a, Accessibility.ActionHub, currentOrg);
          const bLabel = actionToLabel(b, Accessibility.ActionHub, currentOrg);
          return aLabel.localeCompare(bLabel);
        },
      );
      setOrgActions(sortedOrgActions ?? []);
      setActionHubActions(sortedActionHubActions ?? []);

      setIsLoading(false);
    }

    void fetchData();
  }, [currentOrg]);

  let errorDisplay = null;
  if (error) {
    errorDisplay = (
      <Typography variant="caption" color="error">
        Error fetching Action options. Try refreshing the page. <br />
        Details: {JSON.stringify(error)}
      </Typography>
    );
  }

  return (
    <Box>
      <ToggleButtonGroup
        color="primary"
        exclusive
        onChange={(_, value: Accessibility) => {
          if (value) {
            setAccessibility(value);
          }
        }}
        size="small"
        sx={{ mb: theme.spacing(2), height: 30 }}
        value={accessibility}
      >
        <ToggleButton
          value={Accessibility.Organization}
          sx={{
            textTransform: "none",
          }}
        >
          {currentOrg?.name ?? "Organization"}
        </ToggleButton>
        <ToggleButton
          value={Accessibility.ActionHub}
          sx={{
            textTransform: "none",
          }}
        >
          Action Hub
        </ToggleButton>
      </ToggleButtonGroup>
      <Autocomplete
        disablePortal
        fullWidth
        id="available-actions"
        isOptionEqualToValue={optionIsEqualToValue}
        options={
          accessibility === Accessibility.ActionHub
            ? actionHubActions
            : orgActions
        }
        getOptionLabel={(option) =>
          actionToLabel(option, accessibility, currentOrg)
        }
        value={value}
        size="small"
        loading={isLoading}
        onChange={(_, selection: ActionRecord | null) => {
          if (selection) {
            setAction(selection);
          } else {
            setAction(null);
          }
        }}
        renderInput={(params) => <TextField {...params} placeholder="Action" />}
        componentsProps={{
          paper: {
            sx: {
              fontSize: "0.875rem",
            },
          },
        }}
        sx={{ width: "100%", maxWidth: 400 }}
      />
      {errorDisplay}
    </Box>
  );
};
