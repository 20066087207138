import HelpIcon from "@mui/icons-material/Help";
import { IconButton, Tooltip } from "@mui/material";

import {
  type TopicData,
  messagePathPartIsMessagePathAttr,
} from "@/shared/state/visualization";

import styles from "./MessagePathHelp.module.css";

const HELP_TEXT = (
  <>
    <p>To edit array inputs, specify either a 0-based index, e.g.:</p>
    <ul>
      <li className={styles.example}>
        <code>0</code>
        <span>The first element in the array</span>
      </li>
      <li className={styles.example}>
        <code>4</code>
        <span>The fifth element in the array</span>
      </li>
    </ul>
    <p>or a range in slice notation (exclusive of end), e.g.:</p>
    <ul>
      <li className={styles.example}>
        <code>2:5</code>
        <span>The third through fifth elements in the array</span>
      </li>
      <li className={styles.example}>
        <code>2:&nbsp;</code>
        <span>The third through last elements in the array</span>
      </li>
      <li className={styles.example}>
        <code>&nbsp;:3</code>
        <span>The first through third elements in the array</span>
      </li>
      <li className={styles.example}>
        <code>&nbsp;:&nbsp;</code>
        <span>All elements in the array</span>
      </li>
    </ul>
  </>
);

interface MessagePathHelpProps {
  messagePath: TopicData["messagePath"];
}

/**
 * Help text rendered in a tooltip for the MessagePathEditor.
 */
export function MessagePathHelp({ messagePath }: MessagePathHelpProps) {
  if (messagePath.parts.length === 0) {
    return null;
  }
  // Only show help if there are non-attribute (e.g., slice) parts
  if (messagePath.parts.every(messagePathPartIsMessagePathAttr)) {
    return null;
  }
  return (
    <Tooltip title={HELP_TEXT}>
      <IconButton aria-label="help" color="info">
        <HelpIcon className={styles.icon} />
      </IconButton>
    </Tooltip>
  );
}
