import { Box, Link, useTheme } from "@mui/material";
import * as React from "react";

import { RobotoTypography } from "@/shared/components";

export const LegalFooter: React.FC = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        alignSelf: "end",
        textAlign: "center",
        mt: theme.spacing(0.5),
        mb: theme.spacing(1),
      }}
    >
      <RobotoTypography
        variant={"caption"}
        textAlign={"center"}
        sx={{
          fontSize: {
            xs: "0.58rem",
            sm: "0.65rem",
          },
          color: theme.palette.grey[700],
        }}
      >
        By using Roboto you agree to our{" "}
        <Link href="https://www.roboto.ai/terms" target="_blank">
          Terms of Service
        </Link>{" "}
        and{" "}
        <Link href="https://www.roboto.ai/privacy" target="_blank">
          Privacy Policy
        </Link>
      </RobotoTypography>
    </Box>
  );
};
