import { useQuery } from "@tanstack/react-query";

import { useDomainServices } from "@/providers/DomainServices";
import { TopicRecord } from "@/shared/domain/topics";

export function useTopicById(topicId: string | undefined) {
  const { topics } = useDomainServices();

  return useQuery<TopicRecord, Error>({
    queryKey: ["topicRecordById", topicId],
    queryFn: ({ signal }) => {
      if (topicId === undefined) {
        return Promise.reject(new Error("Invalid topicId"));
      }

      return topics.getTopicById(topicId, { abortSignal: signal });
    },
    enabled: topicId !== undefined,

    // 1 minute until considered stale
    staleTime: 60000,
  });
}
