import { APIService, LoggerService } from "@/shared/services";
import {
  DatasetsCredentialsResponse,
  datasetsCredentialsEndpoint,
} from "@/types";

export const getFileUploadCredentials = async (
  datasetId: string,
  orgId: string,
  transactionId: string,
) => {
  const queryParamsObject: { [key: string]: string } = {
    mode: "ReadWrite",
    transaction_id: transactionId,
  };

  const { response, error } =
    await APIService.authorizedRequest<DatasetsCredentialsResponse>({
      method: "GET",
      endpoint: datasetsCredentialsEndpoint,
      pathParams: {
        datasetId,
      },
      queryParams: new URLSearchParams(queryParamsObject),
      orgId,
    });

  if (error) {
    LoggerService.error("Error getting credentials for dataset upload", error);
    return { error };
  }

  if (!response?.data) {
    LoggerService.error("No data returned from credentials endpoint");
    return { error: new Error("No data returned from credentials endpoint") };
  }

  return { response };
};
