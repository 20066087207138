import { schemaValidator } from "@/shared/SchemaValidator";
import { LoggerService } from "@/shared/services";
import {
  type File,
  type State,
  CURRENT_VIZ_SCHEMA_VERSION,
} from "@/shared/state/visualization";

import { InvalidVizConfig } from "./exception";

/**
 * Representation of visualization state responsible for serialization and deserializing
 * to and from a URL-safe value.
 */
export class VizConfig {
  #state: State;

  public static from_obj(obj: unknown) {
    if (VizConfig.isValid(obj)) {
      return new VizConfig(obj);
    }

    LoggerService.error("Unable to instantiate VizConfig:", obj);

    throw new InvalidVizConfig("Unable to instantiate VizConfig");
  }

  public static isValid(state: unknown): state is State {
    return schemaValidator.validate<State>(CURRENT_VIZ_SCHEMA_VERSION, state);
  }

  public constructor(state?: State) {
    if (!state) {
      this.#state = {
        version: CURRENT_VIZ_SCHEMA_VERSION,
        files: [] as File[],
        layout: {
          id: "root",
          axis: "x",
          relativeSize: 1,
          children: [],
        },
      };
      return;
    }

    this.#state = state;
  }

  public get files(): File[] {
    return this.#state.files;
  }

  public toObject = () => {
    return this.#state;
  };

  public toString = () => {
    return JSON.stringify(this.#state, null, 2);
  };
}
