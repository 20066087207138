import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import TimelineIcon from "@mui/icons-material/Timeline";
import { Skeleton } from "@mui/material";

import { RobotoTypography } from "@/shared/components";
import { useMessagePathById } from "@/shared/domain/topics/hooks/useMessagePath";
import { useTopicById } from "@/shared/domain/topics/hooks/useTopic";

import styles from "./Scopes.module.css";

interface AssociatedMessagePathProps {
  messagePathId: string;
}

export function AssociatedMessagePath({
  messagePathId,
}: AssociatedMessagePathProps) {
  const messagePathQuery = useMessagePathById(messagePathId);
  const topicQuery = useTopicById(messagePathQuery.data?.topic_id);

  if (messagePathQuery.isPending || topicQuery.isPending) {
    return <Skeleton variant="text" width="150px" />;
  }

  if (messagePathQuery.isError || topicQuery.isError) {
    return (
      <span className={styles.container}>
        <ErrorOutlineIcon color="error" className={styles.icon} />
        <RobotoTypography component={"span"} className={styles.text}>
          Failed to load message path
        </RobotoTypography>
      </span>
    );
  }

  const msgPathName = `${topicQuery.data.topic_name}.${messagePathQuery.data.message_path}`;

  return (
    <span className={styles.container}>
      <TimelineIcon className={styles.icon} />
      <RobotoTypography
        component={"span"}
        className={styles.text}
        title={msgPathName}
      >
        {msgPathName}
      </RobotoTypography>
    </span>
  );
}
