import { Box, Skeleton, useTheme } from "@mui/material";
import * as React from "react";

export const TagsSkeleton: React.FC = () => {
  const theme = useTheme();

  const numSkeletons = 3;

  return (
    <Box sx={{ display: "flex", flexDirection: "row" }}>
      {new Array<number>(numSkeletons).fill(0).map((_, index) => {
        return (
          <Skeleton
            width={100}
            height={50}
            sx={{ marginRight: theme.spacing(1) }}
            key={index}
          />
        );
      })}
    </Box>
  );
};
