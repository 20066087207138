import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import {
  Box,
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import { RobotoTableCell, RobotoTooltip } from "@/shared/components";

import { DatasetTopicTableBody } from "./DatasetTopicTableBody";

interface DatasetTopicsTableProps {
  datasetId: string;
}

export const DatasetTopicTable = ({ datasetId }: DatasetTopicsTableProps) => {
  return (
    <Box>
      <TableContainer component={Paper} variant="outlined">
        <Table size="small" sx={{ minWidth: 650 }} aria-label="events-table">
          <TableHead>
            <TableRow>
              <RobotoTableCell>Topic</RobotoTableCell>
              <RobotoTableCell>Schema</RobotoTableCell>
              <RobotoTableCell>Messages</RobotoTableCell>
              <RobotoTableCell>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  Active Duration (sec)
                  <RobotoTooltip title="The sum of (last_message_time - first_message_time) for all files which contain this topic.">
                    <HelpOutlineIcon
                      sx={{
                        fontSize: "1rem",
                        marginLeft: "4px",
                        color: "primary.main",
                      }}
                    />
                  </RobotoTooltip>
                </Box>
              </RobotoTableCell>
              <RobotoTableCell>Active Frequency (Hz)</RobotoTableCell>
              <RobotoTableCell>File Count</RobotoTableCell>
            </TableRow>
          </TableHead>
          <DatasetTopicTableBody datasetId={datasetId} numColumns={6} />
        </Table>
      </TableContainer>
    </Box>
  );
};
