import { XMLParser } from "fast-xml-parser";

import { FileRecord, FileService } from "@/shared/domain/files";
import { HttpClient, HttpResponse } from "@/shared/http";

import { AsyncEvent } from "./AsyncEvent";
import { InMemoryFile } from "./InMemoryFile";
import { type Readable } from "./ReadableFile";

/**
 * Determined experimentally
 */
interface S3Error {
  Error: {
    Code: string;
    Message: string;
    Expires: string;
    ServerTime: string;
    RequestId: string;
    HostId: string;
  };
}

export class RemoteFile implements Readable {
  #abortSignal: AbortSignal | undefined;
  #fileRecord: FileRecord;
  #fileService: FileService;
  #httpClient: HttpClient;
  #isPresignedUrlLoading = false;
  #presignedUrl: URL | undefined;
  #presignedUrlLoadedEvent = new AsyncEvent();

  constructor(
    fileRecord: FileRecord,
    fileService: FileService,
    httpClient?: HttpClient,
  ) {
    this.#fileRecord = fileRecord;
    this.#fileService = fileService;
    this.#httpClient = httpClient ?? new HttpClient();
  }

  async read(offset: bigint, size: bigint): Promise<Uint8Array> {
    const url = await this.getPresignedUrl();
    const response = await this.#httpClient.get(url, {
      ranges: [
        {
          unit: "bytes",
          start: Number(offset),
          end: Number(offset + size) - 1, // Range requests are inclusive of bounds
        },
      ],
      signal: this.#abortSignal,
    });

    if (await this.presignedUrlExpired(response)) {
      if (this.#presignedUrl === undefined || this.#isPresignedUrlLoading) {
        // If a concurrent request has already determined that the presigned URL has expired,
        // avoid stepping on its toes.
        // This AsyncEvent is set when the presigned URL has been successfully refreshed.
        await this.#presignedUrlLoadedEvent.wait(this.#abortSignal);
      } else {
        // When RemoteFile::getPresignedUrl is called on the next call to RemoteFile::read,
        // it will refresh the presigned URL and set the AsyncEvent.
        this.#presignedUrl = undefined;
        this.#presignedUrlLoadedEvent.clear();
      }
      return this.read(offset, size);
    }
    const buffer = await response.arrayBuffer();
    return new Uint8Array(buffer);
  }

  public setAbortSignal(abortSignal?: AbortSignal) {
    this.#abortSignal = abortSignal;
  }

  public size(): Promise<bigint> {
    return Promise.resolve(BigInt(this.#fileRecord.size));
  }

  /**
   * Request all bytes of the file in batches no larger than 1MB
   * and return a LocalFile to provide a reader interface for those bytes.
   *
   * This was experimentally determined to be the fatest way to load an entire MCAP file,
   * which is necessary for certain types of visualizations, like plots.
   * This was benchmarked against reading the entire file in a single request,
   * and also against using Foxglove's `McapIndexedReader` class to drive remote reads,
   * which reads the file one chunk at a time, interleaving requests for message indices.
   */
  public toInMemoryFile(): Promise<InMemoryFile> {
    const MAX_CHUNK_SIZE = 1024 * 1024; // 1MiB
    const numParts = Math.max(
      Math.ceil(this.#fileRecord.size / MAX_CHUNK_SIZE),
      1,
    );
    const ranges: { start: number; end: number }[] = [];
    for (let i = 0; i < numParts; i++) {
      ranges.push({
        start: i * MAX_CHUNK_SIZE,
        end: Math.min((i + 1) * MAX_CHUNK_SIZE, this.#fileRecord.size),
      });
    }
    const bytes = new Uint8Array(this.#fileRecord.size);
    return Promise.all(
      ranges.map(async (range) => {
        const buffer = await this.read(
          BigInt(range.start),
          BigInt(range.end - range.start),
        );
        bytes.set(buffer, range.start);
      }),
    ).then(() => {
      return new InMemoryFile(bytes);
    });
  }

  private async getPresignedUrl(): Promise<URL> {
    if (this.#isPresignedUrlLoading) {
      await this.#presignedUrlLoadedEvent.wait(this.#abortSignal);
    }
    if (this.#presignedUrl === undefined) {
      this.#isPresignedUrlLoading = true;
      try {
        const url = await this.#fileService.getPresignedDownloadUrl(
          this.#fileRecord.file_id,
          {
            abortSignal: this.#abortSignal,
          },
        );
        this.#presignedUrl = url;
      } finally {
        this.#isPresignedUrlLoading = false;
        this.#presignedUrlLoadedEvent.set();
      }
    }

    return this.#presignedUrl;
  }

  /**
   * Reading of remote files relies on having a presigned url that has not expired.
   * When the URLs expire, AWS returns a 403 with an XML document.
   */
  private async presignedUrlExpired(response: HttpResponse): Promise<boolean> {
    if (response.status !== 403) {
      return false;
    }

    // The signed URL might be expired.
    // AWS returns an XML document that looks like:
    // <Error>
    //    <Code>AccessDenied</Code>
    //    <Message>Request has expired</Message>
    //    <X-Amz-Expires>60</X-Amz-Expires>
    //    <Expires>2024-02-29T18:46:34Z</Expires>
    //    <ServerTime>2024-02-29T18:46:38Z</ServerTime>
    //    <RequestId>BNZ7FV4AWSPFW125</RequestId>
    //    <HostId>...</HostId>
    // </Error>
    const responseText = await response.text();
    const xmlParser = new XMLParser();
    const responseXML = xmlParser.parse(responseText) as S3Error;
    const error = responseXML["Error"];
    if (error === undefined) {
      return false;
    }

    const serverTime = Date.parse(error["ServerTime"] ?? "");
    const expirationTime = Date.parse(error["Expires"] ?? "");
    if (Number.isNaN(serverTime) || Number.isNaN(expirationTime)) {
      throw new Error("Failed to read file content", { cause: responseText });
    }
    return serverTime > expirationTime;
  }
}
