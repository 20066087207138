import CancelIcon from "@mui/icons-material/Cancel";
import FolderRoundedIcon from "@mui/icons-material/FolderRounded";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import { Box, CircularProgress, useTheme } from "@mui/material";
import React from "react";

import { UploadType, UIUploadItem } from "@/providers/FileUpload/types";

import { RobotoTypography } from "../../../shared/components/RobotoTypography";

import { LinkableRow } from "./LinkableRow";
import { SuccessIcon } from "./SuccessIcon";

interface UploadRowProps {
  item: UIUploadItem;
}

export const UploadRow: React.FC<UploadRowProps> = ({ item }) => {
  const [isHovered, setIsHovered] = React.useState(false);

  const theme = useTheme();

  let uploadProgress = (item.bytesUploaded / item.totalBytes) * 100;

  if (isNaN(uploadProgress)) {
    uploadProgress = 0;
  }

  let progressIndicator = (
    <CircularProgress
      size={20}
      sx={{ color: "lightgrey", marginRight: theme.spacing(0.25) }}
      variant={"determinate"}
      value={100}
      thickness={4}
    />
  );

  if (item.isUploadStarted) {
    progressIndicator = (
      <CircularProgress
        size={20}
        thickness={4}
        sx={{ marginRight: theme.spacing(0.25) }}
      />
    );
  }

  if (uploadProgress > 0) {
    progressIndicator = (
      <Box
        lineHeight={0}
        sx={{ position: "relative", marginRight: theme.spacing(0.25) }}
      >
        <CircularProgress
          variant="determinate"
          sx={(theme) => ({
            color: theme.palette.grey[200],
            ...theme.applyStyles("dark", {
              color: theme.palette.grey[800],
            }),
          })}
          size={20}
          thickness={4}
          value={100}
        />
        <CircularProgress
          size={20}
          sx={{
            position: "absolute",
            left: 0,
          }}
          value={uploadProgress}
          variant={"determinate"}
          thickness={4}
        />
      </Box>
    );
  }

  if (item.isUploadCompleted) {
    progressIndicator = <SuccessIcon isHovered={isHovered} />;
  }

  if (item.isCancelling) {
    progressIndicator = (
      <CircularProgress
        size={20}
        thickness={4}
        sx={{ marginRight: theme.spacing(0.25) }}
      />
    );
  }

  if (item.isCancelled) {
    progressIndicator = <CancelIcon color={"error"} />;
  }

  if (item.uploadError) {
    progressIndicator = <CancelIcon color={"error"} />;
  }

  return (
    <LinkableRow
      isHovering={isHovered}
      item={item}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        {item.type === UploadType.Folder ? (
          <FolderRoundedIcon color="info" />
        ) : (
          <InsertDriveFileOutlinedIcon color="info" />
        )}
        <RobotoTypography
          noWrap={true}
          sx={{
            maxWidth: 260,
            marginLeft: theme.spacing(1),
            fontSize: "0.92rem",
          }}
        >
          {item.uploadError ? item.uploadError.message : item.name}
        </RobotoTypography>
      </Box>
      {progressIndicator}
    </LinkableRow>
  );
};
