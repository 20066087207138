import { LoggerService } from "@/shared/services";

import {
  fileFromFileSystemFileEntry,
  fileSystemEntryIsDirectory,
  fileSystemEntryIsFile,
  getFileEntries,
} from "./helpers";
import { UploadItem, UploadType } from "./types";
import { UploadableFile } from "./UploadableFile";

const ONE_GB = 1000 * 1000 * 1000;
const MAX_UPLOAD_SIZE = 5 * ONE_GB;
const OS_FILES = [".DS_Store", "Thumbs.db", "desktop.ini", ".directory"];

export const getUploadItems = async (
  nativeEvent: DragEvent | Event,
  orgId: string,
  datasetId: string,
  prefix?: string,
) => {
  const uploadItems: UploadItem[] = [];

  // Need to get the files before any async calls, otherwise the browser will clear the files
  if (eventIsDragEvent(nativeEvent) && nativeEvent.dataTransfer !== null) {
    // Ref: File and Directories API
    const entries = Array.from(nativeEvent.dataTransfer.items).map(
      (item): [FileSystemEntry | null, DataTransferItem] => [
        item.webkitGetAsEntry(),
        item,
      ],
    );
    for (const [fileSystemEntry, dataTransferItem] of entries) {
      if (fileSystemEntry === null) {
        LoggerService.error(
          "Failed to get file system entry",
          dataTransferItem,
        );
        continue;
      }

      if (
        fileSystemEntryIsFile(fileSystemEntry) &&
        !OS_FILES.includes(fileSystemEntry.name)
      ) {
        const uploadableFile =
          await fileFromFileSystemFileEntry(fileSystemEntry);

        checkFileSize(uploadableFile.size, uploadableFile.relativePath);

        uploadItems.push(
          new UploadItem(
            uploadableFile.name,
            uploadableFile.size,
            fileSystemEntry.fullPath,
            UploadType.File,
            [uploadableFile],
            orgId,
            datasetId,
            prefix,
          ),
        );
      } else if (fileSystemEntryIsDirectory(fileSystemEntry)) {
        let directorySize = 0;
        let emptyDirectory = true;

        const directoryFiles: UploadableFile[] = [];

        for await (const fileEntry of getFileEntries(fileSystemEntry)) {
          const uploadableFile = await fileFromFileSystemFileEntry(
            fileEntry,
            fileSystemEntry.fullPath,
          );

          checkFileSize(uploadableFile.size, uploadableFile.relativePath);

          if (!OS_FILES.includes(uploadableFile.name)) {
            directorySize += uploadableFile.size;
            emptyDirectory = false;
            directoryFiles.push(uploadableFile);
          }
        }

        if (!emptyDirectory) {
          uploadItems.push(
            new UploadItem(
              fileSystemEntry.name,
              directorySize,
              fileSystemEntry.fullPath,
              UploadType.Folder,
              directoryFiles,
              orgId,
              datasetId,
              prefix,
            ),
          );
        }
      }
    }
  } else if (eventIsChangeEvent(nativeEvent) && nativeEvent.target !== null) {
    // An input event
    const target = nativeEvent.target as HTMLInputElement;

    Array.from(target.files || []).forEach((file) => {
      let relativePath = file.webkitRelativePath.endsWith(file.name)
        ? file.webkitRelativePath
        : `${file.webkitRelativePath}/${file.name}`;
      relativePath = relativePath.startsWith("/")
        ? relativePath.slice(1)
        : relativePath;

      checkFileSize(file.size, file.name);

      const uploadableFile = new UploadableFile(
        file,
        relativePath,
        file.webkitRelativePath,
      );

      uploadItems.push(
        new UploadItem(
          file.name,
          file.size,
          file.webkitRelativePath,
          UploadType.File,
          [uploadableFile],
          orgId,
          datasetId,
          prefix,
        ),
      );
    });
  }

  return uploadItems;
};

function eventIsDragEvent(event: Event): event is DragEvent {
  return "dataTransfer" in event && event.dataTransfer !== null;
}

function eventIsChangeEvent(event: Event): event is InputEvent {
  return !eventIsDragEvent(event);
}

function checkFileSize(fileSize: number, relativePath: string) {
  if (fileSize > MAX_UPLOAD_SIZE) {
    const sizeInGB = fileSize / ONE_GB;
    const msg = [
      "Files over 5 GB must be uploaded with the CLI.",
      `${relativePath} is ${sizeInGB.toFixed(2)} GB.`,
    ].join(" ");
    throw new Error(msg);
  }
}
