import { RobotoDomainException } from "@/shared/http";

export enum NotificationType {
  CommentMention = "comment_mention",
  CommentOnThread = "comment_on_thread",
  CommentOnAuthoredAction = "comment_on_authored_action",
}

export enum NotificationChannel {
  Email = "email",
  WebUi = "web_ui",
}

export enum ReadStatus {
  Read = "read",
  Unread = "unread",
}

export interface NotificationBatchResponse {
  responses: NotificationBatchItem[];
}

interface NotificationBatchItem {
  data: NotificationRecord;
  error: RobotoDomainException;
}

export interface NotificationRecord {
  notification_id: string;
  org_id: string;
  user_id: string;
  notifier_id: string;
  notification_type: NotificationType;
  channels: NotificationChannel[];
  lifecycle_status: Partial<Record<NotificationChannel, string>>;
  read_status: ReadStatus;
  created: string;
  modified: string;
}
