import * as React from "react";

import { VizConfig } from "@/shared/config";
import {
  VizStateContext,
  VizStateDispatchContext,
  reducer,
} from "@/shared/state/visualization";

export function VisualizationStateProvider({
  children,
}: React.PropsWithChildren) {
  const [state, dispatch] = React.useReducer(
    reducer,
    new VizConfig().toObject(),
  );

  return (
    <VizStateContext.Provider value={state}>
      <VizStateDispatchContext.Provider value={dispatch}>
        {children}
      </VizStateDispatchContext.Provider>
    </VizStateContext.Provider>
  );
}
