import DownloadIcon from "@mui/icons-material/Download";
import { MenuItem } from "@mui/material";
import * as React from "react";

import { useAnalytics } from "@/providers";
import { FileRecord } from "@/shared/domain/files";
import { ErrorMonitoringService } from "@/shared/services/ErrorMonitoringService";
import { downloadFile } from "@/utils";

import { useGetFileContents } from "../hooks";

import styles from "./MenuItemError.module.css";

interface DownloadFileProps {
  fileRecord: FileRecord;
  onClick: () => void;
}

export function DownloadFile({ fileRecord, onClick }: DownloadFileProps) {
  const getFileContentsAsUrl = useGetFileContents();
  const { trackEvent } = useAnalytics();

  const [error, setError] = React.useState<string | null>(null);

  const download = () => {
    getFileContentsAsUrl(fileRecord, {
      forDownload: true,
    })
      .then((signedUrl) => {
        trackEvent("FileDownloaded", { fileId: fileRecord.file_id });
        downloadFile(fileRecord.relative_path, signedUrl.toString());
        onClick();
      })
      .catch((error) => {
        ErrorMonitoringService.captureError(error);
        setError("Failed to download. Try again.");
      });
  };

  return (
    <>
      <MenuItem onClick={download}>
        <DownloadIcon fontSize="small" />
        Download file
      </MenuItem>
      <div className={styles.errorText}>{error}</div>
    </>
  );
}
