import CloseIcon from "@mui/icons-material/Close";
import { Box, Snackbar, useTheme } from "@mui/material";
import { useEffect, useRef, useReducer } from "react";
import * as React from "react";
import {
  useNavigate,
  useSearchParams,
  useLocation,
  useParams,
} from "react-router-dom";

import { useFileUpload } from "@/providers/FileUpload";
import { AlertDialog, RobotoIconButton } from "@/shared/components";
import { useDataset } from "@/shared/domain/datasets/hooks";
import {
  useAnnotationData,
  useAnnotationFiles,
  useDeleteDirectoryMutation,
  useDeleteFileMutation,
  useDirectoryContents,
  useDirectoryExtensions,
} from "@/shared/domain/files/hooks";
import { ErrorMonitoringService } from "@/shared/services";
import { FileSystemNode, isDirectory, isFile } from "@/types";

import { FileBrowserActionTypes, FileBrowserAction } from "./actions";
import { DatasetBreadcrumbs } from "./DatasetBreadcrumbs";
import { DatasetOperationBar } from "./DatasetOperationBar";
import { DragAndDropDisplay } from "./DragAndDropDisplay";
import { computeExtensionsArray } from "./helper";
import { fileBrowserReducer } from "./reducer";
import { FileBrowserState } from "./state";

const sliderValueFormat = (value: number) => {
  return `Zoom: ${value}`;
};

interface DatasetFileBrowserProps {
  datasetId: string;
}

export const DatasetFileBrowser: React.FC<DatasetFileBrowserProps> = ({
  datasetId,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { "*": filePaths } = useParams();

  const datasetQuery = useDataset(datasetId, { throwOnError: true });

  const fileInputRef = useRef<HTMLInputElement>(null);
  const { beginFileUpload } = useFileUpload();

  const [fileBrowserState, dispatch] = useReducer<
    React.Reducer<FileBrowserState, FileBrowserAction>
  >(fileBrowserReducer, {
    alert: {
      isOpen: false,
      title: "",
      text: "",
      action: async () => {},
    },
    annotationFile: undefined,
    annotationViewFilters: {
      confidenceThreshold: { percent: 0, show: false },
      labels: { show: true },
      boundingBox: { show: true },
      segmentation: { show: false },
    },
    activeToggle: "all",
    displayMode: (searchParams.get("display") as string) ?? "table",
    error: null,
    fileTypes: [],
    gridItems: 3,
    searchTerm: "",
    selectedItems: [],
    showHiddenFiles: false,
    snackbarState: {
      isOpen: false,
      message: "",
    },
    tableConfig: {
      page: 0,
      rowsPerPage: 25,
      pageTokens: { 0: undefined },
    },
  });

  const annotationFilesQuery = useAnnotationFiles(datasetId, filePaths);

  useEffect(() => {
    if (annotationFilesQuery.isSuccess) {
      dispatch({
        type: FileBrowserActionTypes.SetAnnotationFile,
        payload: annotationFilesQuery.data[0],
      });
    }
  }, [annotationFilesQuery.data, annotationFilesQuery.isSuccess]);

  const annotationDataQuery = useAnnotationData(
    fileBrowserState.annotationFile?.file,
  );

  const currentToken =
    fileBrowserState.tableConfig?.pageTokens[fileBrowserState.tableConfig.page];

  const deleteFileMutation = useDeleteFileMutation();
  const deleteDirectoryMutation = useDeleteDirectoryMutation();

  const directoryExtensionsQuery = useDirectoryExtensions(
    filePaths ?? "",
    datasetId,
  );

  const directoryContentsQuery = useDirectoryContents(
    filePaths ?? "",
    datasetId,
    datasetQuery.data?.org_id,
    fileBrowserState.tableConfig.rowsPerPage,
    currentToken,
    fileBrowserState.searchTerm,
    fileBrowserState.showHiddenFiles,
    fileBrowserState.fileTypes,
  );
  const currentPage = directoryContentsQuery.data?.items;
  const nextToken = directoryContentsQuery.data?.nextToken;
  React.useEffect(() => {
    if (directoryContentsQuery.error) {
      dispatch({
        type: FileBrowserActionTypes.SetError,
        payload: directoryContentsQuery.error,
      });
    }
  }, [directoryContentsQuery.error]);

  const extensions = computeExtensionsArray(directoryExtensionsQuery.data);

  const handleSnackbarClose = (
    _event: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === "clickaway") {
      return;
    }
  };

  const handleRowDoubleClick = (row: FileSystemNode) => {
    if (row.type === "directory") {
      let newPath = `${filePaths ? filePaths + "/" : ""}${row.name}`;
      newPath =
        "/datasets/" +
        datasetId +
        "/files/" +
        newPath +
        `?display=${fileBrowserState.displayMode}`;

      navigate(newPath);
    }
  };

  useEffect(() => {
    dispatch({
      type: FileBrowserActionTypes.SetTablePageToZero,
      payload: null,
    });
  }, [pathname]);

  const filePrefix = filePaths;

  const handleGridSliderChange = (
    _event: Event,
    newValue: number | number[],
  ) => {
    if (typeof newValue === "number") {
      dispatch({
        type: FileBrowserActionTypes.SetGridItems,
        payload: newValue,
      });
    }
  };

  function handleActionConfirmation(
    title: string,
    text: string,
    action: () => Promise<void>,
  ) {
    dispatch({
      type: FileBrowserActionTypes.SetAlertState,
      payload: {
        isOpen: true,
        title: title,
        text: text,
        action,
      },
    });
  }

  const handleFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (!datasetId || !datasetQuery.data) {
      return;
    }

    await beginFileUpload(
      event,
      datasetQuery.data.org_id,
      datasetId,
      filePrefix,
    );
    await directoryContentsQuery.refetch();
  };

  function summarizeItems(items: FileSystemNode[]): string {
    const hasFiles = items.some(isFile);
    const hasDirectories = items.some(isDirectory);

    if (items.length > 1) {
      if (hasFiles && hasDirectories) {
        // Mixed case, using a general term
        return "Items";
      }
      return hasFiles ? "Files" : "Directories";
    }

    return isFile(items[0]) ? "File" : "Directory";
  }

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          mt: theme.spacing(0),
          mb: theme.spacing(1.5),
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <DatasetOperationBar
          datasetId={datasetId}
          extensions={extensions}
          fileTypes={fileBrowserState.fileTypes}
          display={fileBrowserState.displayMode}
          activeToggle={fileBrowserState.activeToggle}
          handleViewToggle={(display, fileTypes, activeToggle) => {
            dispatch({
              type: FileBrowserActionTypes.HandleViewToggle,
              payload: { display, fileTypes, activeToggle },
            });

            setSearchParams({
              display: display,
            });
          }}
          handleDeleteItems={(items) => {
            if (items.length === 0) {
              return;
            }

            const deleteTerm = summarizeItems(items);
            const deleteTitle = `Delete ${deleteTerm}`;
            handleActionConfirmation(
              `${deleteTitle}`,
              `Are you sure you want to delete ${items.length} ${deleteTerm.toLowerCase()}?`,
              async function () {
                try {
                  const deletePromises = [];

                  const directoryPaths = items
                    .filter(isDirectory)
                    .map((item) => item.directory.relative_path);

                  if (directoryPaths.length > 0) {
                    deletePromises.push(
                      deleteDirectoryMutation.mutateAsync({
                        datasetId: datasetId,
                        directoryPaths,
                      }),
                    );
                  }

                  const fileDeletePromises = items
                    .filter(isFile)
                    .map((item) => {
                      return deleteFileMutation.mutateAsync({
                        file: item.file,
                      });
                    });

                  deletePromises.push(...fileDeletePromises);

                  await Promise.all(deletePromises);

                  await directoryContentsQuery.refetch();

                  dispatch({
                    type: FileBrowserActionTypes.DeleteFiles,
                    payload: items,
                  });
                } catch (e) {
                  ErrorMonitoringService.captureError(e);
                }
              },
            );
          }}
          handleDeselectAll={() => {
            dispatch({
              type: FileBrowserActionTypes.SetSelectedItems,
              payload: [],
            });
          }}
          handleFileTypeSelection={(fileTypes) => {
            dispatch({
              type: FileBrowserActionTypes.SetFileTypes,
              payload: fileTypes,
            });
          }}
          handleFileSearch={(searchTerm) => {
            dispatch({
              type: FileBrowserActionTypes.SetSearchTerm,
              payload: searchTerm,
            });
          }}
          handleShowHiddenFilesToggle={() => {
            dispatch({
              type: FileBrowserActionTypes.SetShowHiddenFiles,
              payload: !fileBrowserState.showHiddenFiles,
            });
          }}
          showHiddenFiles={fileBrowserState.showHiddenFiles}
          annotationFiles={annotationFilesQuery.data}
          annotationFile={fileBrowserState.annotationFile}
          annotationDataLoading={annotationDataQuery.isLoading}
          annotationDataError={annotationDataQuery.error ?? undefined}
          onAnnotationFileSelected={(file) => {
            if (!file) {
              dispatch({
                type: FileBrowserActionTypes.ClearAnnotationFile,
                payload: null,
              });
            } else {
              dispatch({
                type: FileBrowserActionTypes.SetAnnotationFile,
                payload: file,
              });
            }
          }}
          annotationViewFilters={fileBrowserState.annotationViewFilters}
          onViewFiltersChanged={(filters) =>
            dispatch({
              type: FileBrowserActionTypes.SetAnnotationViewFilters,
              payload: filters,
            })
          }
          uploadButtonPressed={() => fileInputRef?.current?.click()}
          selectedItems={fileBrowserState.selectedItems}
        />
      </Box>
      <div>
        <DatasetBreadcrumbs
          datasetId={datasetId}
          filePaths={filePaths}
          displayMode={fileBrowserState.displayMode}
          gridItems={fileBrowserState.gridItems}
          handleDisplayToggle={(mode) => {
            if (mode !== null) {
              dispatch({
                type: FileBrowserActionTypes.SetDisplayMode,
                payload: mode,
              });
              setSearchParams({
                display: mode,
              });
            }
          }}
          handleGridSliderChange={handleGridSliderChange}
          sliderValueFormat={sliderValueFormat}
        />

        <DragAndDropDisplay
          annotationData={annotationDataQuery.data}
          annotationViewFilters={fileBrowserState.annotationViewFilters}
          currentPage={currentPage}
          datasetId={datasetId}
          displayMode={fileBrowserState.displayMode}
          error={fileBrowserState.error}
          fileInputRef={fileInputRef}
          filePrefix={filePrefix}
          gridItems={fileBrowserState.gridItems}
          handleDeleteRow={(row) => {
            void directoryContentsQuery.refetch();

            dispatch({
              type: FileBrowserActionTypes.DeleteFiles,
              payload: [row],
            });
          }}
          handleRowDoubleClick={handleRowDoubleClick}
          handleSelectRows={(
            newRows: FileSystemNode[],
            isSelected: boolean,
          ) => {
            const selectedItemsMap: Map<string, FileSystemNode> = new Map();

            // Helper function to generate unique keys based on file or directory
            const getKey = (node: FileSystemNode) => {
              if (isFile(node)) {
                return `${node.file.file_id}`;
              } else if (isDirectory(node)) {
                return `${node.directory.relative_path}`;
              }
              return `${node.type}${node.name}`;
            };

            // Add all existing selected items to the map
            fileBrowserState.selectedItems.forEach((item) => {
              selectedItemsMap.set(getKey(item), item);
            });

            if (isSelected) {
              newRows.forEach((row) => selectedItemsMap.set(getKey(row), row));
            } else {
              newRows.forEach((row) => selectedItemsMap.delete(getKey(row)));
            }

            const newItems = [...selectedItemsMap.values()];

            dispatch({
              type: FileBrowserActionTypes.SetSelectedItems,
              payload: newItems,
            });
          }}
          isLoading={directoryContentsQuery.isPending}
          isSearching={Boolean(
            fileBrowserState.searchTerm ||
              fileBrowserState.fileTypes.length > 0,
          )}
          nextToken={nextToken}
          onClearError={() => {
            dispatch({
              type: FileBrowserActionTypes.SetError,
              payload: null,
            });
          }}
          onChangeInput={handleFileUpload}
          onDropComplete={async () => {
            await directoryContentsQuery.refetch();
          }}
          onPageChange={(_event, newPage) => {
            if (newPage > fileBrowserState.tableConfig.page) {
              dispatch({
                type: FileBrowserActionTypes.IncrementTablePage,
                payload: { newPage: newPage, nextToken: nextToken },
              });
            } else {
              dispatch({
                type: FileBrowserActionTypes.DecrementTablePage,
                payload: { newPage: newPage },
              });
            }
          }}
          onRowsPerPageChange={(event) => {
            dispatch({
              type: FileBrowserActionTypes.UpdateTableRowsPerPage,
              payload: parseInt(event?.target.value || "", 10) as 10 | 25 | 50,
            });
          }}
          selectedItems={fileBrowserState.selectedItems}
          tableConfig={fileBrowserState.tableConfig}
          handleActionConfirmation={handleActionConfirmation}
        />
      </div>

      <Snackbar
        open={fileBrowserState.snackbarState.isOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={fileBrowserState.snackbarState.message}
        action={
          <RobotoIconButton
            size="small"
            color="inherit"
            eventName={"DatasetExplorerSnackbarClosed"}
            eventProperties={{ datasetId }}
            onClick={handleSnackbarClose}
          >
            <CloseIcon fontSize="small" />
          </RobotoIconButton>
        }
      />

      <AlertDialog
        dialogOpen={fileBrowserState.alert.isOpen}
        handleClose={() => {
          dispatch({
            type: FileBrowserActionTypes.SetAlertState,
            payload: {
              isOpen: false,
              title: "",
              text: "",
              action: async () => {},
            },
          });
        }}
        dialogTitle={fileBrowserState.alert.title}
        dialogText={fileBrowserState.alert.text}
        dialogAction={fileBrowserState.alert.action}
      />
    </div>
  );
};
