import {
  TableBody,
  TableRow,
  Skeleton,
  Alert,
  AlertTitle,
} from "@mui/material";

import { RobotoCheckbox, RobotoTableCell } from "@/shared/components";
import { EventRecord } from "@/shared/domain/events";

import { EventRow } from "./EventRow";
import styles from "./EventTableBody.module.css";

interface EventTableBodyProps {
  datasetId: string;
  events: EventRecord[] | undefined;
  isLoading: boolean;
  isError: boolean;
  numColumns: number;
  selectedRows: EventRecord["event_id"][];
  onSelect: (event: EventRecord) => void;
}

export function EventTableBody({
  datasetId,
  events,
  isError,
  isLoading,
  numColumns,
  onSelect,
  selectedRows,
}: EventTableBodyProps) {
  if (isLoading) {
    return (
      <TableBody>
        {Array.from({ length: 3 }, (_, rowIndex) => (
          <TableRow key={`skeleton-row-${rowIndex}`}>
            <RobotoTableCell
              sx={{
                padding: "0 8px",
              }}
            >
              <RobotoCheckbox disabled />
            </RobotoTableCell>
            {Array.from({ length: numColumns - 1 }, (_, colIndex) => (
              <RobotoTableCell key={`skeleton-cell-${rowIndex}-${colIndex}`}>
                <Skeleton animation="pulse" variant="text" />
              </RobotoTableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    );
  }

  if (isError) {
    return (
      <TableBody>
        <TableRow>
          <RobotoTableCell colSpan={numColumns}>
            <Alert severity="error" className={styles.alert}>
              <AlertTitle>Failed to fetch events</AlertTitle>
              Please contact{" "}
              <a href="mailto:support@roboto.ai">support@roboto.ai</a> if this
              problem persists.
            </Alert>
          </RobotoTableCell>
        </TableRow>
      </TableBody>
    );
  }

  if (events === undefined || events.length === 0) {
    return (
      <TableBody>
        <TableRow>
          <RobotoTableCell colSpan={numColumns}>
            <Alert severity="warning" className={styles.alert}>
              No events have been created for this dataset yet.
            </Alert>
          </RobotoTableCell>
        </TableRow>
      </TableBody>
    );
  }

  return (
    <TableBody>
      {events.map((event) => {
        const isSelected = selectedRows.includes(event.event_id);
        return (
          <EventRow
            key={event.event_id}
            datasetId={datasetId}
            event={event}
            isSelected={isSelected}
            numColumns={numColumns}
            onSelect={onSelect}
          />
        );
      })}
    </TableBody>
  );
}
