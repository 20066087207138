import {
  Box,
  ListItem,
  ListItemText,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import * as React from "react";

import { EditFieldButton } from "@/shared/components";
import { useLazyAPICall } from "@/shared/services/apiHooks";
import { GetUserResponse, RobotoAPICall, usersEndpoint } from "@/types";

interface EditableProfileFieldProps {
  title: string;
  field: string;
  value: string | undefined;
  maxLength?: number;
  setErrorText?: (arg: string) => void;
  editable: boolean;
  userId: string;
}

export const EditableProfileField: React.FC<EditableProfileFieldProps> = ({
  title,
  field,
  value,
  maxLength,
  setErrorText,
  editable,
  userId,
}) => {
  const theme = useTheme();
  const [showEditableIcon, setShowEditableIcon] = React.useState(false);
  const [editMode, setEditMode] = React.useState(false);
  const [currentValue, setCurrentValue] = React.useState<string | undefined>(
    value,
  );
  const fieldRef = React.createRef<HTMLInputElement>();
  const { initiateRequest } = useLazyAPICall<GetUserResponse>();

  const updateUser = async (updatedVal: string) => {
    let requestBody = "";

    requestBody = JSON.stringify({
      [field]: updatedVal,
    });

    // Important to provide as system users might be making
    // changes on behalf of a different user
    const headers = { "X-Roboto-User-Id": userId };

    const updateUserCall: RobotoAPICall = {
      endpoint: usersEndpoint,
      method: "PUT",
      requestBody: requestBody,
      headers,
    };

    const { error } = await initiateRequest(updateUserCall);

    if (error) {
      if (setErrorText) {
        setErrorText(error.message);
      }
      return false;
    }

    if (setErrorText) {
      setErrorText("");
    }

    return true;
  };

  const onClickEditFieldButton = async () => {
    if (editMode) {
      const val = (fieldRef.current && fieldRef.current.value) ?? "";
      const res = await updateUser(val);
      if (res) {
        // Update succeeded, change cached state
        setCurrentValue(val);
        setEditMode(false);
      }
    }
    setEditMode(!editMode);
  };

  return (
    <ListItem
      onMouseOver={() => {
        if (editable) {
          setShowEditableIcon(true);
        }
      }}
      onMouseLeave={() => {
        if (editable) {
          setShowEditableIcon(false);
        }
      }}
      sx={{
        pl: 0,
      }}
    >
      <ListItemText
        primaryTypographyProps={{
          component: "span",
        }}
        sx={{
          mt: theme.spacing(0.5),
        }}
        primary={
          <>
            <span>{title}</span>

            {editable && (
              <EditFieldButton
                editable={editMode}
                showEditableIcon={showEditableIcon}
                onClick={() => {
                  void onClickEditFieldButton();
                }}
              />
            )}

            <Box>
              {editMode === false ? (
                <Typography
                  component="span"
                  variant="body2"
                  style={{
                    whiteSpace: "pre-wrap",
                    color: theme.palette.text.secondary,
                  }}
                >
                  {currentValue}
                </Typography>
              ) : (
                <TextField
                  sx={{ mt: theme.spacing(1) }}
                  autoFocus
                  fullWidth
                  multiline
                  size="small"
                  defaultValue={currentValue}
                  variant="outlined"
                  inputProps={{
                    ref: fieldRef,
                    style: {
                      fontSize: "0.875rem",
                    },
                    maxLength: maxLength,
                  }}
                />
              )}
            </Box>
          </>
        }
      />
    </ListItem>
  );
};
