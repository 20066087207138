import { SearchField, SearchFieldType } from "@/shared/components/filters";
import {
  RoboqlFieldDescription,
  roboqlFields,
} from "@/shared/components/RoboqlEditor/roboqlFields";
import { QueryTarget } from "@/shared/domain/query";
import { SortDirection } from "@/types";

const newFieldToOldStyle = (field: RoboqlFieldDescription): SearchField => {
  let fieldType: SearchFieldType = "other";

  if (field.label === "metadata") {
    fieldType = "metadata";
  } else if (field.label === "tags") {
    fieldType = "tags";
  } else if (field.label === "created" || field.label === "modified") {
    fieldType = "date";
  }

  return {
    name: field.label,
    type: fieldType,
  };
};

const DATASET_SEARCH_FIELDS: SearchField[] =
  roboqlFields.datasets.map(newFieldToOldStyle);

const FILE_SEARCH_FIELDS: SearchField[] =
  roboqlFields.files.map(newFieldToOldStyle);

const TOPIC_SEARCH_FIELDS: SearchField[] =
  roboqlFields.topics.map(newFieldToOldStyle);

const TOPIC_MESSAGE_PATH_FIELDS: SearchField[] =
  roboqlFields.topic_message_paths.map(newFieldToOldStyle);

const EVENT_SEARCH_FIELDS: SearchField[] =
  roboqlFields.events.map(newFieldToOldStyle);

export function sortFieldAndOrderForTarget(
  target: QueryTarget,
): [string, SortDirection] {
  // For now (11/2024), all search results are ordered by
  // descending creation date, but we can configure
  // that per query target in the future if needed.
  switch (target) {
    default:
      return ["created", "DESC"];
  }
}

export function searchFieldsForTarget(target: QueryTarget): SearchField[] {
  if (target === QueryTarget.Datasets) {
    return DATASET_SEARCH_FIELDS;
  } else if (target === QueryTarget.Files) {
    return FILE_SEARCH_FIELDS;
  } else if (target === QueryTarget.Topics) {
    return TOPIC_SEARCH_FIELDS;
  } else if (target === QueryTarget.TopicMessagePaths) {
    return TOPIC_MESSAGE_PATH_FIELDS;
  } else if (target === QueryTarget.Events) {
    return EVENT_SEARCH_FIELDS;
  } else {
    return [];
  }
}
