import { Box, useTheme } from "@mui/material";
import classNames from "classnames";
import * as React from "react";

import { useResizable } from "@/shared/hooks";
import { actions, useVizDispatch } from "@/shared/state/visualization";
import { Layout, LayoutItem } from "@/shared/state/visualization/schema/v1";

import styles from "./PanelResizeHandle.module.css";

interface PanelResizeHandleProps {
  axis: "x" | "y";
  containerRef: React.RefObject<HTMLDivElement>;
  layout1: Layout | LayoutItem;
  layout2: Layout | LayoutItem;
}

export function PanelResizeHandle(props: PanelResizeHandleProps) {
  const { axis, containerRef, layout1, layout2 } = props;

  const theme = useTheme();
  const dispatch = useVizDispatch();
  const resizeHandlerRef = React.useRef<HTMLDivElement>(null);

  const { isDragging, layout1Size, layout2Size, separatorProps } = useResizable(
    {
      axis: axis,
      initialLayout1Size: layout1.relativeSize,
      initialLayout2Size: layout2.relativeSize,
      containerRef: containerRef,
      resizeHandleRef: resizeHandlerRef,
    },
  );

  React.useEffect(() => {
    dispatch(actions.setLayoutResizing([layout1.id, layout2.id], isDragging));
  }, [isDragging, dispatch, layout1.id, layout2.id]);

  React.useEffect(() => {
    dispatch(
      actions.resizeLayouts(
        {
          id: layout1.id,
          relativeSize: layout1Size,
        },
        {
          id: layout2.id,
          relativeSize: layout2Size,
        },
      ),
    );
  }, [layout1.id, layout2.id, layout1Size, layout2Size, dispatch]);

  return (
    <Box
      ref={resizeHandlerRef}
      bgcolor={theme.palette.code.main}
      className={classNames(styles.dragHandle, {
        [styles.dragBgColorLight]: isDragging && theme.palette.mode === "light",
        [styles.dragBgColorDark]: isDragging && theme.palette.mode === "dark",
        [styles.dragHandleDark]: theme.palette.mode === "dark",
        [styles.dragHandleLight]: theme.palette.mode === "light",
        [styles.dragHorizontal]: axis === "x",
        [styles.dragVertical]: axis === "y",
      })}
      {...separatorProps}
    />
  );
}
