import { RobotoTypography } from "@/shared/components";
import {
  DatasetLink,
  FileVizLink,
  MessagePathLinkFromId,
  TopicVizLink,
} from "@/shared/components/links";
import { AssociationType } from "@/shared/domain/association.ts";
import { EventRecord, eventByAssociationType } from "@/shared/domain/events";
import { pluralize } from "@/utils";

interface EventScopeProps {
  event: EventRecord;
}

/**
 * A single line representation of an event's scope, intended to be rendered in a table cell or a
 * list view. This may be static text, or may be a link to the visualization page.
 */
export const EventScope = ({ event }: EventScopeProps) => {
  if (event.associations.length === 0) {
    return <RobotoTypography variant="body2">No Associations</RobotoTypography>;
  }

  if (event.associations.length === 1) {
    const association = event.associations[0];
    switch (association.association_type) {
      case AssociationType.Dataset:
        return <DatasetLink datasetId={association.association_id} />;

      case AssociationType.File:
        return <FileVizLink fileId={association.association_id} />;

      case AssociationType.Topic:
        return <TopicVizLink topicId={association.association_id} />;

      case AssociationType.MessagePath:
        return (
          <MessagePathLinkFromId messagePathId={association.association_id} />
        );
    }
  }

  const associationsByType = eventByAssociationType(event);
  const eventScopes = Object.entries(associationsByType)
    .filter(([, associations]) => associations.length > 0)
    .map(([associationType, associations]) => {
      const formattedType = associationType.replace(/_/g, " ");
      return `${associations.length} ${pluralize(
        formattedType,
        associations.length,
      )}`;
    });

  return (
    <RobotoTypography variant="body2">
      {eventScopes.join(", ")}
    </RobotoTypography>
  );
};
