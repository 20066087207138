import * as React from "react";

interface LogPanelContext {
  isSearchVisible: boolean;
  toggleSearchVisibility: () => void;
}

export const LogPanelContext = React.createContext<LogPanelContext>({
  isSearchVisible: false,
  toggleSearchVisibility: () => {},
});
