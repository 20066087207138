import InfoIcon from "@mui/icons-material/Info";
import {
  Box,
  IconButton,
  ListItem,
  ListItemText,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import * as React from "react";

import { EditFieldButton } from "@/shared/components/EditFieldButton";
import { ActionRecord } from "@/shared/domain/actions";
import { OrgRecord } from "@/shared/domain/orgs";
import { useLazyAPICall } from "@/shared/services/apiHooks";
import {
  APIResponse,
  RobotoAPICall,
  RobotoEndpoint,
  actionEndpoint,
} from "@/types";

import { ActionMarkdown } from "./ActionMarkdown";

interface EditableListItemProps {
  title: string;
  field: string;
  value: string | undefined;
  endpoint: RobotoEndpoint;
  actionName: string;
  currentOrg: OrgRecord | null;
  setErrorText: (arg: string) => void;
  renderMarkdown?: boolean;
  disablePadding?: boolean;
  onHelperClick?: () => void;
  onUpdate?: (arg: APIResponse<ActionRecord>) => void;
  maxLength?: number;
}

export const EditableListItem: React.FC<EditableListItemProps> = ({
  title,
  field,
  value,
  endpoint,
  actionName,
  currentOrg,
  setErrorText,
  renderMarkdown,
  disablePadding,
  onHelperClick,
  onUpdate,
  maxLength,
}) => {
  const theme = useTheme();
  const [showEditableIcon, setShowEditableIcon] = React.useState(false);
  const [editable, setEditable] = React.useState(false);
  const [currentValue, setCurrentValue] = React.useState<string | undefined>(
    value,
  );
  const fieldRef = React.createRef<HTMLInputElement>();
  const { initiateRequest } = useLazyAPICall<APIResponse<ActionRecord>>();

  const updateAction = async (updatedVal: string | undefined) => {
    let requestBody = "";
    // For actionEndpoint usage, create updates struct
    if (endpoint === actionEndpoint) {
      requestBody = JSON.stringify({
        [field]: updatedVal ? updatedVal : null, // N.b.: won't work with numbers (i.e., 0)
      });
    }

    const updateActionCall: RobotoAPICall = {
      endpoint: endpoint,
      orgId: currentOrg?.org_id,
      pathParams: { name: actionName },
      method: "PUT",
      requestBody: requestBody,
    };

    const { data, error } = await initiateRequest(updateActionCall);

    if (error) {
      setErrorText(error.message);
      return false;
    }

    if (data && onUpdate) {
      onUpdate(data);
    }

    setErrorText("");

    return true;
  };

  const onClick = async () => {
    if (editable) {
      const val = fieldRef.current?.value;
      const res = await updateAction(val);
      if (res) {
        // Update succeeded, change cached state
        setCurrentValue(val);
        setEditable(false);
      }
    }
    setEditable(!editable);
  };

  let actionMarkdownContent;

  if (currentValue) {
    if (renderMarkdown) {
      actionMarkdownContent = <ActionMarkdown text={currentValue} />;
    } else {
      actionMarkdownContent = currentValue;
    }
  } else {
    actionMarkdownContent = "None";
  }

  return (
    <ListItem
      onMouseOver={() => {
        if (onUpdate) {
          setShowEditableIcon(true);
        }
      }}
      onMouseLeave={() => {
        setShowEditableIcon(false);
      }}
      disablePadding={disablePadding}
    >
      <ListItemText
        primaryTypographyProps={{
          component: "span",
        }}
        sx={{
          mt: disablePadding ? 0 : theme.spacing(0.5),
        }}
        primary={
          <>
            <span>{title}</span>

            {onHelperClick && (
              <IconButton
                sx={{
                  p: "5px",
                  verticalAlign: "middle",
                  ml: theme.spacing(0.25),
                }}
                onClick={() => onHelperClick()}
              >
                <InfoIcon
                  color="primary"
                  sx={{
                    fontSize: "1.1rem",
                    cursor: "pointer",
                  }}
                />
              </IconButton>
            )}

            <EditFieldButton
              editable={editable}
              showEditableIcon={showEditableIcon}
              onClick={() => {
                void onClick();
              }}
            />

            <Box>
              {editable === false ? (
                <Typography
                  component="span"
                  variant="body2"
                  style={{
                    whiteSpace: "pre-wrap",
                    color: theme.palette.text.secondary,
                    wordBreak: "break-word",
                  }}
                >
                  {actionMarkdownContent}
                </Typography>
              ) : (
                <TextField
                  sx={{ mt: theme.spacing(1) }}
                  autoFocus
                  fullWidth
                  multiline
                  size="small"
                  defaultValue={currentValue}
                  variant="outlined"
                  inputProps={{
                    ref: fieldRef,
                    style: {
                      fontSize: "0.875rem",
                    },
                    maxLength: maxLength,
                  }}
                />
              )}
            </Box>
          </>
        }
      />
    </ListItem>
  );
};
