import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {
  Alert,
  Box,
  Breadcrumbs,
  CircularProgress,
  Divider,
  Typography,
  useTheme,
} from "@mui/material";
import * as React from "react";
import { Link, useSearchParams } from "react-router-dom";

import { useAuth } from "@/providers";
import {
  CommentsBox,
  RobotoStyledTab,
  RobotoStyledTabs,
  RobotoTabPanel,
} from "@/shared/components";
import { InvocationRecord, InvocationStatus } from "@/shared/domain/actions";
import { APIServiceError } from "@/types";

import { InvocationDetails } from "../components/invocation/InvocationDetails";
import InvocationLogs from "../components/invocation/InvocationLogs";
import { getInvocation } from "../components/invocation/service";

interface InvocationPageProps {
  invocationId: string | undefined;
}

export const Invocation: React.FC<InvocationPageProps> = ({ invocationId }) => {
  const [invocation, setInvocation] = React.useState<InvocationRecord | null>(
    null,
  );
  const [loading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState<Error | null>(null);
  const { currentOrganization } = useAuth();
  const theme = useTheme();
  const [searchParams, setSearchParams] = useSearchParams();

  const tabSearchParam = searchParams.get("tab");
  const tabValue = tabSearchParam !== null ? parseInt(tabSearchParam) : 0;

  const loadInvocationRecord = React.useCallback(
    (signal?: AbortSignal) => {
      if (!invocationId || !currentOrganization?.org_id) {
        return;
      }

      setIsLoading(true);
      getInvocation(invocationId, currentOrganization.org_id, signal)
        .then((invocation) => {
          setInvocation(invocation);
        })
        .catch((err: APIServiceError | null) => {
          if (err?.name !== "AbortError") {
            setError(err);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [invocationId, currentOrganization?.org_id],
  );

  // Fetch invocation on mount (and whenever the invocation changes)
  React.useEffect(() => {
    const abortController = new AbortController();
    loadInvocationRecord(abortController.signal);

    return () => {
      abortController.abort();
    };
  }, [loadInvocationRecord]);

  // Only show loading indicator if the invocation has yet to load.
  // Otherwise, the loading indicator will flash just perciptibly fast enough to be confusing.
  if (loading && !invocation) {
    return (
      <Box
        sx={{
          mt: theme.spacing(5),
          mb: theme.spacing(3),
          display: "flex",
          justifyContent: "center",
        }}
      >
        <CircularProgress size="1.5rem" />
      </Box>
    );
  }

  let content = null;
  if (invocation !== null) {
    content = (
      <>
        <RobotoTabPanel index={0} value={tabValue}>
          <InvocationDetails
            currentOrg={currentOrganization}
            invocation={invocation}
            refetchRecord={loadInvocationRecord}
          />
        </RobotoTabPanel>
        <RobotoTabPanel index={1} value={tabValue}>
          <Box
            sx={{
              mt: 1,
              mb: 3,
            }}
          >
            <InvocationLogs
              currentOrg={currentOrganization}
              invocation={invocation}
              showStatus
            />
          </Box>
        </RobotoTabPanel>
      </>
    );
  }

  return (
    <>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        sx={{
          fontSize: "1.125rem",
          fontWeight: "500",
          borderBottom: "unset",
          "& a": {
            color: theme.palette.text.secondary,
            textDecoration: "none",
          },
          "& a:hover": {
            textDecoration: "underline",
          },
        }}
      >
        <Link to="/actions?tab=2">Invocations</Link>,
        <Typography
          sx={{ fontSize: "1.125rem", fontWeight: "500" }}
          color="text.primary"
          component="span"
        >
          {invocationId}
        </Typography>
      </Breadcrumbs>

      {!error ? (
        <>
          <Box
            sx={{
              paddingTop: theme.spacing(3),
            }}
          >
            <RobotoStyledTabs
              value={tabValue}
              onChange={(_event, newValue) => {
                setSearchParams({ tab: String(newValue) });
              }}
              aria-label="invocation tabs"
              variant="scrollable"
            >
              <RobotoStyledTab label="Details" />
              <RobotoStyledTab
                label="Logs"
                icon={
                  invocation &&
                  tabValue !== 1 && // not on this tab, avoid showing double spinner
                  invocation?.last_status < InvocationStatus.Completed ? (
                    <CircularProgress color="inherit" size="0.75rem" />
                  ) : undefined
                }
                iconPosition="end"
              />
            </RobotoStyledTabs>

            <Divider
              sx={{
                borderColor: theme.border.color,
              }}
            />
          </Box>
          <Box
            sx={{
              padding: theme.spacing(1, 0),
              paddingBottom: theme.spacing(1),
            }}
          >
            {content}
          </Box>

          <Box
            sx={{
              padding: theme.spacing(1, 2),
              paddingBottom: theme.spacing(1),
            }}
          >
            <CommentsBox entityType="invocation" entityId={invocationId} />
          </Box>
        </>
      ) : (
        <Box
          sx={{
            mt: theme.spacing(2),
            mb: theme.spacing(1),
          }}
        >
          <Alert severity="error">{error.message}</Alert>
        </Box>
      )}
    </>
  );
};
