import { State } from "@/shared/state/visualization";

import { HttpClient, robotoHeaders } from "../../http";

import { WorkspaceRecord } from "./WorkspaceRecord";

export class WorkspaceService {
  #httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.#httpClient = httpClient;
  }

  public async createWorkspace(
    vizState: State,
    orgId: string,
  ): Promise<WorkspaceRecord> {
    const requestUrl = this.#httpClient.constructUrl("v1/viz/workspaces");

    const body = JSON.stringify({
      config: vizState,
    });

    const response = await this.#httpClient.post(requestUrl, {
      headers: robotoHeaders({ orgId }),
      body,
    });

    return response.json<WorkspaceRecord>();
  }

  public async getWorkspace(
    orgId: string,
    signal: AbortSignal,
    workspaceId?: string,
  ): Promise<WorkspaceRecord | null> {
    if (!workspaceId) {
      return null;
    }

    const requestUrl = this.#httpClient.constructUrl(
      `v1/viz/workspaces/id/${workspaceId}`,
    );

    const response = await this.#httpClient.get(requestUrl, {
      headers: robotoHeaders({ orgId }),
      signal,
    });

    return await response.json<WorkspaceRecord>();
  }
}
