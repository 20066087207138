import {
  type PlotPanelState,
  type PlotSeries,
} from "@/shared/state/visualization";

import { SettingsSection } from "../SettingsSection";

import { PlotSeriesConfig } from "./PlotSeriesConfig";

interface PlotPanelSettingsProps {
  state: PlotPanelState;
}

export function PlotPanelSettings({ state }: PlotPanelSettingsProps) {
  return (
    <>
      <SettingsSection title="Series">
        {state.data.map((plotSeries: PlotSeries) => (
          <PlotSeriesConfig
            key={plotSeries.id}
            panelId={state.id}
            plotSeries={plotSeries}
          />
        ))}
      </SettingsSection>
    </>
  );
}
