import { IFileService } from "@/shared/domain/files";

import {
  UploadItem,
  OnUploadItemProgress,
  OnUploadItemBegin,
  OnUploadItemComplete,
  OnUploadItemError,
  OnUploadItemFirstBytes,
  OnUploadItemBeginCancellation,
  OnUploadItemCancelled,
} from "./types";
import { UploadItemTask } from "./UploadItemTask";

export class UploadCoordinator {
  /*
    This class coordinates all uploads for the entire app. It makes sure that
    no more than 5 UploadItems are in progress at any one time. It also contains
    callback methods that update users of the class on a particular UploadItem
    */

  private _fileService: IFileService;
  private ongoingUploadTasks: UploadItemTask[];
  private pendingUploadTasks: UploadItemTask[];

  private _onUploadItemBegin: OnUploadItemBegin;
  private _onUploadItemBeginCancellation: OnUploadItemBeginCancellation;
  private _onUploadItemCancelled: OnUploadItemCancelled;
  private _onUploadItemComplete: OnUploadItemComplete;
  private _onUploadItemError: OnUploadItemError;
  private _onUploadItemFirstBytes: OnUploadItemFirstBytes;
  private _onUploadItemProgress: OnUploadItemProgress;

  private static readonly MAX_UPLOAD_POOL_SIZE = 5;

  constructor(
    fileService: IFileService,
    onUploadItemBegin: OnUploadItemBegin,
    onUploadItemBeginCancellation: OnUploadItemBeginCancellation,
    onUploadItemCancelled: OnUploadItemCancelled,
    onUploadItemComplete: OnUploadItemComplete,
    onUploadItemError: OnUploadItemError,
    onUploadItemFirstBytes: OnUploadItemFirstBytes,
    onUploadItemProgress: OnUploadItemProgress,
  ) {
    this.ongoingUploadTasks = [];
    this.pendingUploadTasks = [];

    this._fileService = fileService;

    this._onUploadItemBegin = onUploadItemBegin;
    this._onUploadItemBeginCancellation = onUploadItemBeginCancellation;
    this._onUploadItemCancelled = onUploadItemCancelled;
    this._onUploadItemComplete = onUploadItemComplete;
    this._onUploadItemError = onUploadItemError;
    this._onUploadItemFirstBytes = onUploadItemFirstBytes;
    this._onUploadItemProgress = onUploadItemProgress;
  }

  set fileService(fileService: IFileService) {
    this._fileService = fileService;

    this.ongoingUploadTasks.forEach((task) => {
      task.fileService = fileService;
    });

    this.pendingUploadTasks.forEach((task) => {
      task.fileService = fileService;
    });
  }

  get fileService() {
    return this._fileService;
  }

  set onUploadItemBegin(fnc: OnUploadItemBegin) {
    this._onUploadItemBegin = fnc;
  }

  get onUploadItemBegin() {
    return this._onUploadItemBegin;
  }

  set onUploadItemComplete(fnc: OnUploadItemComplete) {
    this._onUploadItemComplete = fnc;
  }

  get onUploadItemComplete() {
    return this._onUploadItemComplete;
  }

  set onUploadItemError(fnc: OnUploadItemError) {
    this._onUploadItemError = fnc;
  }

  get onUploadItemError() {
    return this._onUploadItemError;
  }

  set onUploadItemFirstBytes(fnc: OnUploadItemFirstBytes) {
    this._onUploadItemFirstBytes = fnc;

    this.ongoingUploadTasks.forEach((task) => {
      task.onUploadItemFirstBytes = this._onUploadItemFirstBytes;
    });

    this.pendingUploadTasks.forEach((task) => {
      task.onUploadItemFirstBytes = this._onUploadItemFirstBytes;
    });
  }

  get onUploadItemFirstBytes() {
    return this._onUploadItemFirstBytes;
  }

  set onUploadItemProgress(fnc: OnUploadItemProgress) {
    this._onUploadItemProgress = fnc;

    this.ongoingUploadTasks.forEach((task) => {
      task.onUploadItemProgress = this._onUploadItemProgress;
    });

    this.pendingUploadTasks.forEach((task) => {
      task.onUploadItemProgress = this._onUploadItemProgress;
    });
  }

  get onUploadItemProgress() {
    return this._onUploadItemProgress;
  }

  set onUploadItemBeginCancellation(fnc: OnUploadItemBeginCancellation) {
    this._onUploadItemBeginCancellation = fnc;
  }

  get onUploadItemBeginCancellation() {
    return this._onUploadItemBeginCancellation;
  }

  set onUploadItemCancelled(fnc: OnUploadItemCancelled) {
    this._onUploadItemCancelled = fnc;
  }

  get onUploadItemCancelled() {
    return this._onUploadItemCancelled;
  }

  async cancelInProgressUploads() {
    const localOngoingUploadTasks = this.ongoingUploadTasks.slice();
    const localPendingUploadTasks = this.pendingUploadTasks.slice();
    this.ongoingUploadTasks = [];
    this.pendingUploadTasks = [];

    const allTasks = [...localOngoingUploadTasks, ...localPendingUploadTasks];

    const results = await Promise.allSettled(
      allTasks.map((task) => {
        this._onUploadItemBeginCancellation(task.uploadItem.id);
        return task.cancelUploadTask();
      }),
    );

    results.forEach((result, index) => {
      if (result.status === "rejected") {
        this._onUploadItemError(
          allTasks[index].uploadItem.id,
          result.reason instanceof Error
            ? result.reason
            : new Error("Unknown error cancelling item upload"),
        );
      } else {
        this._onUploadItemCancelled(allTasks[index].uploadItem.id);
      }
    });
  }

  async uploadItems(uploadItems: UploadItem[]) {
    // Make and manage a pool of uploads. There should be no more than 5 in progress at one time
    // The pool should be maximally utilized at all times

    uploadItems.forEach((item) => {
      const newTask = new UploadItemTask(
        this.fileService,
        item,
        this._onUploadItemFirstBytes,
        this._onUploadItemProgress,
      );
      this.pendingUploadTasks.push(newTask);
    });

    await this.processUploadPool();
  }

  private async processUploadPool() {
    while (
      this.pendingUploadTasks.length > 0 ||
      this.ongoingUploadTasks.length > 0
    ) {
      if (
        this.ongoingUploadTasks.length >= UploadCoordinator.MAX_UPLOAD_POOL_SIZE
      ) {
        await new Promise((resolve) => setTimeout(resolve, 400));
        continue;
      }

      const nextTask = this.pendingUploadTasks.shift();
      if (nextTask === undefined) {
        await new Promise((resolve) => setTimeout(resolve, 400));
        continue;
      }

      void this.processUploadPoolItem(nextTask);
    }
  }

  private async processUploadPoolItem(nextTask: UploadItemTask) {
    this.ongoingUploadTasks.push(nextTask);
    this._onUploadItemBegin(nextTask.uploadItem.id);

    try {
      await nextTask.executeUploadTask();
      this._onUploadItemComplete(nextTask.uploadItem.id);
    } catch (reason) {
      const error =
        reason instanceof Error
          ? reason
          : new Error("Unknown error uploading item");
      this._onUploadItemError(nextTask.uploadItem.id, error);
    } finally {
      this.ongoingUploadTasks = this.ongoingUploadTasks.filter(
        (task) => task !== nextTask,
      );
    }
  }
}
