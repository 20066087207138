import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import MenuBookRoundedIcon from "@mui/icons-material/MenuBookRounded";
import SettingsIcon from "@mui/icons-material/Settings";
import { Box, Divider, useTheme } from "@mui/material";
import * as React from "react";

import { PageRoute } from "@/types";

import { RobotoLink } from "../RobotoLink";
import { SystemUserOnly } from "../SystemUserOnly";

import { NavItem } from "./NavItem";
import { RobotoCommunity } from "./RobotoCommunity";
import { StyledSidebar } from "./StyledSidebar";
import { Alignment, NavItemData, RobotoNavigationProps, Title } from "./types";

interface RobotoSidebarProps extends RobotoNavigationProps {
  sideBarItems: NavItemData[];
}

export const RobotoSidebar: React.FC<RobotoSidebarProps> = ({
  sideBarWidth,
  topBarHeight,
  sideBarItems,
}) => {
  const theme = useTheme();

  return (
    <StyledSidebar topBarHeight={sideBarWidth} sideBarWidth={topBarHeight}>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ width: "100%" }}>
          {sideBarItems.map((item) => {
            return <NavItem key={item.title} {...item} />;
          })}
        </Box>
        <Divider sx={{ marginBottom: theme.spacing(1.5) }} />
        <Box
          sx={{
            width: "100%",
          }}
        >
          <NavItem
            key={"settings"}
            title={Title.Settings}
            link={PageRoute.Settings}
            alignment={Alignment.Left}
            Icon={SettingsIcon}
          />
          <NavItem
            key={"documentation"}
            title={Title.Docs}
            target="_blank"
            link={"https://docs.roboto.ai/"}
            alignment={Alignment.Left}
            Icon={MenuBookRoundedIcon}
          />
        </Box>

        <SystemUserOnly>
          <Divider sx={{ marginBottom: theme.spacing(1.5) }} />
          <NavItem
            key={"admin"}
            title={Title.Admin}
            link={PageRoute.Admin}
            alignment={Alignment.Left}
            Icon={AdminPanelSettingsIcon}
          />
        </SystemUserOnly>
      </Box>

      <Box
        sx={{
          paddingLeft: theme.spacing(1.75),
          paddingBottom: theme.spacing(1),
          display: "flex",
          alignItems: "center",
        }}
      >
        <RobotoLink
          to={"https://www.roboto.ai"}
          target="_blank"
          sx={{
            textDecoration: "none",
            display: "flex",
            alignItems: "center",
            marginRight: theme.spacing(1),
            opacity: 0.85,
            ":hover": {
              opacity: 1,
            },
            cursor: "pointer",
          }}
        >
          <img
            src={
              theme.palette.mode === "dark"
                ? "/iconBlack.svg"
                : "/iconWhite.svg"
            }
            width={25}
            height={25}
            alt="Logo"
          />
        </RobotoLink>

        <RobotoCommunity />
      </Box>
    </StyledSidebar>
  );
};
