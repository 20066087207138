import * as React from "react";

import { type Message } from "@/shared/mcap";
import { useMcapReader } from "@/shared/mcap/useMcapReader";
import { ErrorMonitoringService } from "@/shared/services";
import { LayoutItem, RawMessagePanelState } from "@/shared/state/visualization";
import { NS_PER_S_BIGINT } from "@/utils/time";

import { useWorkspaceTimer } from "../../WorkspaceCtx";
import { NoDataMessage } from "../NoDataMessage";
import { PanelLayout } from "../PanelLayout";

import { FormattedMessage } from "./FormattedMessage";

interface RawMessagePanelProps {
  layout: LayoutItem;
  state: RawMessagePanelState;
}

export function RawMessagePanel({ layout, state }: RawMessagePanelProps) {
  const timer = useWorkspaceTimer();

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<Error | null>(null);
  const [message, setMessage] = React.useState<Message | null>(null);

  const mcapReader = useMcapReader({
    fileId: state.data?.representation.association.association_id,
    onError: setError,
    onLoading: setIsLoading,
  });

  const abortControllerRef = React.useRef(new AbortController());
  const timerHoldRef = React.useRef<symbol | null>(null);
  const updateMessage = React.useCallback(
    function update() {
      if (mcapReader === null) {
        return;
      }

      const currentTime = timer.currentTime;
      if (currentTime < (mcapReader.startTime ?? 0n)) {
        setMessage(null);
        setError(null);
        return;
      }

      // Attempt to get the latest message synchronously
      const message = mcapReader.getLatestMessageAsOfTime(currentTime);
      if (message) {
        setMessage(message);
        setError(null);
        return;
      }

      // Otherwise, load the message asynchronously
      abortControllerRef.current.abort();
      const abortController = new AbortController();
      abortControllerRef.current = abortController;
      if (timerHoldRef.current === null) {
        timerHoldRef.current = timer.hold();
      }
      setIsLoading(true);
      mcapReader
        .loadMessageAsOfTime(currentTime, {
          bufferDurationNs: 10n * NS_PER_S_BIGINT, // And load about 10 seconds of messages into the future
        })
        .catch((err) => {
          if (abortController.signal.aborted) {
            return;
          }
          const error =
            err instanceof Error
              ? err
              : new Error("Failed to get latest message for RawMessagePanel", {
                  cause: err,
                });
          setError(error);
        })
        .finally(() => {
          if (abortController.signal.aborted) {
            return;
          }
          // Get the latest message again after loading.
          const message = mcapReader.getLatestMessageAsOfTime(currentTime);
          if (message) {
            setMessage(message);
            setError(null);
          }

          setIsLoading(false);
          if (timerHoldRef.current !== null) {
            timer.holdRelease(timerHoldRef.current);
            timerHoldRef.current = null;
          }
        });
    },
    [mcapReader, timer],
  );

  React.useEffect(
    function setTimerCallbacks() {
      const abortController = new AbortController();

      timer.addListener("tick", updateMessage, {
        signal: abortController.signal,
      });
      timer.addListener("seek", updateMessage, {
        signal: abortController.signal,
      });
      timer.addListener("start", updateMessage, {
        signal: abortController.signal,
      });
      timer.addListener("stop", updateMessage, {
        signal: abortController.signal,
      });

      updateMessage();

      return function abort() {
        abortController.abort();
        if (timerHoldRef.current !== null) {
          timer.holdRelease(timerHoldRef.current);
          timerHoldRef.current = null;
        }
      };
    },
    [timer, updateMessage],
  );

  /**
   * Report errors when they occur
   */
  React.useEffect(
    function reportError() {
      if (error === null) {
        return;
      }
      ErrorMonitoringService.captureError(error);
    },
    [error],
  );

  return (
    <PanelLayout isLoading={isLoading} state={state} layout={layout}>
      <NoDataMessage panelData={state.data} />
      <FormattedMessage error={error} message={message} />
    </PanelLayout>
  );
}
