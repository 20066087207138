import { ListItem, ListItemText, Skeleton } from "@mui/material";
import * as React from "react";

import { AuthZPermissionCategory } from "@/shared/components/AuthZPermissionCategory";
import { AuthZTuple, RobotoEndpoint } from "@/types";

interface AuthZCategoryListItemProps {
  allowEdit: boolean;
  displayName: string;
  editAccessEndpoint: RobotoEndpoint;
  editAccessEndpointPathParams?: Record<string, string | number>;
  onChange?: () => void;
  relationName: string;
  relations: AuthZTuple[];
  resourceId: string;
  resourceType: "dataset" | "collection";
  loading: boolean;
}

export const AuthZCategoryListItem: React.FC<AuthZCategoryListItemProps> = (
  props,
) => {
  return (
    <ListItem key={`${props.resourceType}-${props.resourceId}`}>
      <ListItemText
        primary={props.displayName}
        secondaryTypographyProps={{
          component: "span",
        }}
        secondary={
          <>
            {props.loading ? (
              <Skeleton width={500} height={25} />
            ) : (
              <AuthZPermissionCategory
                allowEdit={props.allowEdit}
                editAccessEndpoint={props.editAccessEndpoint}
                editAccessEndpointPathParams={
                  props.editAccessEndpointPathParams
                }
                onChange={props.onChange}
                relationName={props.relationName}
                relations={props.relations}
                resourceId={props.resourceId}
                resourceType={props.resourceType}
              />
            )}
          </>
        }
      />
    </ListItem>
  );
};
