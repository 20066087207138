/**
 * A serializable representation of a @link Viewport.
 */
export type ViewportParams = Readonly<{
  x: { min: number; max: number };
  y: { min: number; max: number };
  debounceId?: number;
}>;

// Helper instance of the default viewport, used for comparisons.
const defaultViewportParams: ViewportParams = Object.freeze({
  x: { min: 0, max: 1 },
  y: { min: 0, max: 1 },
});

/**
 * A normalized viewport onto plot data.
 *
 * The full domain and range of any data shown is mapped to 0-1 here.
 *
 * The default viewport, {x: {min: 0, max: 1}, y: {min: 0, max 1}}, maps to a view
 * that includes all plot data along both the x and y axes.
 *
 * The debounceId field is intended to be a monotonically increasing identifier
 * used for debouncing viewport updates.  Defaults to the value of `performance.now()`.
 *
 * As an object with function members, instacnes of Viewport can't be shared with
 * render workers directly. To serialize it and send to a web worker, use @link getParams().
 */
export class Viewport {
  private readonly debounceId: number;
  readonly x: { min: number; max: number };
  readonly y: { min: number; max: number };

  constructor({ x, y, debounceId }: ViewportParams) {
    this.debounceId = debounceId ?? Viewport.latestDebounceId();
    this.x = x;
    this.y = y;
  }

  static default(debounceId?: number): Viewport {
    return new Viewport({ ...defaultViewportParams, debounceId });
  }

  static fromDomainAndRange(params: {
    viewportDomain: { min: number; max: number };
    viewportRange: { min: number; max: number };
    dataDomain: { min: number; max: number };
    dataRange: { min: number; max: number };
    debounceId?: number;
  }) {
    const throwIfBoundsInvalid = (bounds: { min: number; max: number }) => {
      if (bounds.max <= bounds.min) {
        throw Error(`Invalid bounds, max <= min: ${JSON.stringify(bounds)}`);
      }
    };
    throwIfBoundsInvalid(params.viewportDomain);
    throwIfBoundsInvalid(params.viewportRange);
    throwIfBoundsInvalid(params.dataDomain);
    throwIfBoundsInvalid(params.dataRange);

    const normalize = (
      value: number,
      { min, max }: { min: number; max: number },
    ) => {
      return (value - min) / (max - min);
    };
    return new Viewport({
      x: {
        min: normalize(params.viewportDomain.min, params.dataDomain),
        max: normalize(params.viewportDomain.max, params.dataDomain),
      },
      y: {
        min: normalize(params.viewportRange.min, params.dataRange),
        max: normalize(params.viewportRange.max, params.dataRange),
      },
      debounceId: params.debounceId,
    });
  }

  static latestDebounceId(): number {
    return performance.now() + performance.timeOrigin;
  }

  getParams(): ViewportParams {
    return { x: this.x, y: this.y, debounceId: this.debounceId };
  }

  /**
   * Checks if the x and y bounds of this Viewport match the {@link Viewport.default}.
   */
  matchesDefaultViewport(): boolean {
    return (
      this.x.min === defaultViewportParams.x.min &&
      this.x.max === defaultViewportParams.x.max &&
      this.y.min === defaultViewportParams.y.min &&
      this.y.max === defaultViewportParams.y.max
    );
  }

  isOlderThan(other: Viewport): boolean {
    return this.debounceId < other.debounceId;
  }
}
