import {
  TableBody,
  TableRow,
  Skeleton,
  Alert,
  AlertTitle,
} from "@mui/material";

import { RobotoTableCell } from "@/shared/components";

import {
  type EvaluationColumn,
  DEFAULT_EVALUATION_COLUMNS,
} from "./tableColumns";
import { TriggerEvaluationsRow } from "./TriggerEvaluationsRow";
import { useTriggerEvaluations } from "./useTriggerEvaluations";

interface TriggerEvaluationsTableBodyProps {
  columns?: EvaluationColumn[];
  filter: { datasetId?: string; triggerName?: string };
}

export function TriggerEvaluationsTableBody({
  columns,
  filter,
}: TriggerEvaluationsTableBodyProps) {
  const evaluations = useTriggerEvaluations({ filter });
  const numColumns = columns?.length ?? DEFAULT_EVALUATION_COLUMNS.length;

  if (evaluations.isPending) {
    return (
      <TableBody>
        <TableRow>
          {Array.from({ length: numColumns }, (_, i) => (
            <RobotoTableCell key={`loading-cell-${i}`}>
              <Skeleton animation="pulse" variant="text" />
            </RobotoTableCell>
          ))}
        </TableRow>
      </TableBody>
    );
  }

  if (evaluations.error) {
    return (
      <TableBody>
        <TableRow>
          <RobotoTableCell colSpan={numColumns}>
            <Alert severity="error">
              <AlertTitle>Failed to fetch trigger evaluations</AlertTitle>
              Please contact{" "}
              <a href="mailto:support@roboto.ai">support@roboto.ai</a> if this
              problem persists.
            </Alert>
          </RobotoTableCell>
        </TableRow>
      </TableBody>
    );
  }

  if (evaluations.data === undefined || evaluations.data.length === 0) {
    return (
      <TableBody>
        <TableRow>
          <RobotoTableCell colSpan={numColumns}>
            <Alert severity="info">No trigger evaluations yet.</Alert>
          </RobotoTableCell>
        </TableRow>
      </TableBody>
    );
  }

  return (
    <TableBody>
      {evaluations.data.map((evaluation) => (
        <TriggerEvaluationsRow
          key={evaluation.trigger_evaluation_id}
          columns={columns}
          evaluation={evaluation}
        />
      ))}
    </TableBody>
  );
}
