import { DotLottieWorkerReactProps } from "@lottiefiles/dotlottie-react";

import { PanelType } from "@/shared/state/visualization";

import ImagePanelIcon from "./PanelOptionsGrid/assets/image-panel.lottie";
import LogPanelIcon from "./PanelOptionsGrid/assets/log-panel.lottie";
import MapPanelIcon from "./PanelOptionsGrid/assets/map-panel.lottie";
import PlotPanelIcon from "./PanelOptionsGrid/assets/plot-panel.lottie";
import RawMessagePanelIcon from "./PanelOptionsGrid/assets/raw-message-panel.lottie";

export interface PanelTypeDetails {
  title: string;
  description: string;
  icon: string;
  options?: DotLottieWorkerReactProps;
  padding?: string;
}

export const panelTypeToDetails: Record<PanelType, PanelTypeDetails> = {
  [PanelType.Image]: {
    title: "Image",
    description: "Display camera data from image topics",
    icon: ImagePanelIcon,
    options: {
      autoplay: true,
      loop: false,
    },
  },
  [PanelType.Log]: {
    title: "Log",
    description: "Display topic data in a tabular format",
    icon: LogPanelIcon,
    options: {
      loop: false,
    },
    padding: "1.5rem",
  },
  [PanelType.Map]: {
    title: "Map",
    description: "Visualize geographic data on a map",
    icon: MapPanelIcon,
    options: {
      loop: false,
    },
  },
  [PanelType.Plot]: {
    title: "Plot",
    description: "Visualize numeric topic data in a graph",
    icon: PlotPanelIcon,
    options: {
      autoplay: true,
      loop: false,
      segment: [1, 20],
    },
    padding: "1.5rem",
  },
  [PanelType.RawMessage]: {
    title: "Raw",
    description: "View topic data as raw message content",
    icon: RawMessagePanelIcon,
    options: {
      loop: false,
    },
  },
};
