import { RoboqlExternalCtx } from "@/shared/components/RoboqlEditor/monaco";
import {
  useDatasetMetadataKeys,
  useDatasetTags,
} from "@/shared/domain/datasets/hooks/";
import {
  useEventMetadataKeys,
  useEventNames,
  useEventTags,
} from "@/shared/domain/events/hooks";
import {
  useFileMetadataKeys,
  useOrgFileTags,
} from "@/shared/domain/files/hooks";
import { QueryTarget } from "@/shared/domain/query";
import useAllTopicKeys from "@/shared/domain/topics/hooks/useAllTopicKeys.ts";

export const useRoboqlExternalCtx = (
  target: QueryTarget,
  orgId: string,
): RoboqlExternalCtx => {
  const fileTagsQuery = useOrgFileTags(orgId);
  const fileMetadataKeysQuery = useFileMetadataKeys(orgId);
  const datasetTagsQuery = useDatasetTags(orgId);
  const datasetMetadataKeysQuery = useDatasetMetadataKeys(orgId);
  const allTopicKeysQuery = useAllTopicKeys(orgId);
  const eventNamesQuery = useEventNames(orgId);
  const eventTagsQuery = useEventTags(orgId);
  const eventMetadataKeysQuery = useEventMetadataKeys(orgId);

  const ret: RoboqlExternalCtx = {
    target: target,
    datasets: {
      tags: datasetTagsQuery,
      metadataKeys: datasetMetadataKeysQuery,
    },
    files: {
      tags: fileTagsQuery,
      metadataKeys: fileMetadataKeysQuery,
    },
    topics: allTopicKeysQuery,
    events: {
      names: eventNamesQuery,
      tags: eventTagsQuery,
      metadataKeys: eventMetadataKeysQuery,
    },
  };

  return ret;
};
