import { DragOverlay } from "@dnd-kit/core";
import { Box } from "@mui/material";

import { LayoutItem, usePanelState } from "@/shared/state/visualization";

import { DragItem } from "../../DnD";
import { PanelHeader } from "../../Panel/PanelHeader";

import { DragOverlayProps } from "./base";
import styles from "./DragOverlays.module.css";

interface PanelDragOverlayProps extends DragOverlayProps {}

export function PanelDragOverlay({ active }: PanelDragOverlayProps) {
  const isPanel = active?.type === DragItem.Panel;
  const layoutItem = active?.data as LayoutItem | undefined;

  const draggedPanel = usePanelState(layoutItem?.id || "");

  return (
    <DragOverlay className={styles.dragOverlay} dropAnimation={null}>
      {isPanel && draggedPanel && (
        <Box
          bgcolor={(theme) => theme.palette.paper.main}
          padding="0.5rem"
          border={"1px solid"}
          borderRadius={"4px"}
        >
          <PanelHeader state={draggedPanel} />
        </Box>
      )}
    </DragOverlay>
  );
}
