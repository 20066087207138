import CloseIcon from "@mui/icons-material/Close";
import { TableHead, TableRow, TableCell, IconButton } from "@mui/material";

import { actions, useVizDispatch } from "@/shared/state/visualization";

import { ColumnDefinition } from "./columnDefinition";
import styles from "./TableHeader.module.css";

interface LogTableHeader {
  columnDefinitions: ColumnDefinition[];
  panelId: string;
}

export function TableHeader({ columnDefinitions, panelId }: LogTableHeader) {
  const dispatch = useVizDispatch();

  return (
    <TableHead>
      <TableRow>
        <TableCell className={styles.fixedWidth} component="th" scope="col">
          Time (s)
        </TableCell>
        {columnDefinitions.map((column) => {
          return (
            <TableCell
              key={column.messagePathId}
              className={styles.flexibleWidth}
              component="th"
              scope="col"
            >
              <span className={styles.header}>
                <span className={styles.headerText}>{column.name}</span>
                <IconButton
                  aria-label="remove column"
                  onClick={() => {
                    dispatch(
                      actions.removeMessagePathFromLogPanel(
                        panelId,
                        column.messagePathId,
                      ),
                    );
                  }}
                  size="small"
                  sx={{ ml: "0.25rem" }}
                  title="Remove column"
                >
                  <CloseIcon
                    fontSize="small"
                    sx={{
                      width: "0.9em",
                      height: "0.9em",
                    }}
                  />
                </IconButton>
              </span>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}
