import StarIcon from "@mui/icons-material/Star";
import * as React from "react";

import { useDomainServices } from "@/providers/DomainServices";
import { RobotoButton } from "@/shared/components";
import { OrgRecord, OrganizationTier } from "@/shared/domain/orgs";
import { ErrorMonitoringService } from "@/shared/services";

interface OrgUpdateTierButtonProps {
  orgId: string;
  currentOrgTier: OrganizationTier;
  onSuccess?: (updatedOrg: OrgRecord) => void;
  onError?: (error: unknown) => void;
}

export const OrgUpdateTierButton: React.FC<OrgUpdateTierButtonProps> = ({
  orgId,
  currentOrgTier,
  onSuccess,
  onError,
}) => {
  const { orgService } = useDomainServices();

  const differentTier: OrganizationTier =
    currentOrgTier === OrganizationTier.Free
      ? OrganizationTier.Premium
      : OrganizationTier.Free;

  const buttonText =
    currentOrgTier === OrganizationTier.Free
      ? "Upgrade to Premium"
      : "Downgrade to Free";

  async function updateOrgTier() {
    try {
      const updatedOrg = await orgService.updateOrgTier(orgId, differentTier);
      onSuccess?.(updatedOrg);
    } catch (e: unknown) {
      ErrorMonitoringService.captureError(e);
      onError?.(e);
    }
  }

  return (
    <RobotoButton
      eventName={"UpdateOrgTierClicked"}
      eventProperties={{
        orgId,
        tier: differentTier,
      }}
      onClick={() => {
        void updateOrgTier();
      }}
      startIcon={<StarIcon fontSize="small" />}
      variant="outlined"
    >
      {buttonText}
    </RobotoButton>
  );
};
