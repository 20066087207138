import { Box, CircularProgress, TextField, useTheme } from "@mui/material";
import { useState } from "react";
import * as React from "react";

import { useAuth } from "@/providers";
import {
  RobotoButton,
  RobotoTypography,
  RobotoLogo,
  RobotoLink,
} from "@/shared/components";
import { AuthCard } from "@/shared/components/auth/AuthCard";
import { AuthCardTitle } from "@/shared/components/auth/AuthCardTitle";
import { LegalFooter } from "@/shared/components/auth/LegalFooter";

const buttonWidth = 215;

interface ForgotPasswordFormProps {
  isVisible: boolean;
  onSuccess: (emailAddress: string) => void;
  goBackClicked: () => void;
}

export const ForgotPasswordForm: React.FC<ForgotPasswordFormProps> = ({
  isVisible,
  onSuccess,
  goBackClicked,
}) => {
  const theme = useTheme();

  if (!isVisible) {
    return null;
  }

  return (
    <>
      <RobotoLogo sx={{ maxWidth: "250px" }} />

      <AuthCard>
        <AuthCardTitle title={"Find your account"} />
        <Box sx={{ marginTop: theme.spacing(2) }}>
          <EmailInput onSuccess={onSuccess} goBackClicked={goBackClicked} />
        </Box>
      </AuthCard>
      <Box>
        <RobotoTypography
          variant={"caption"}
          textAlign={"center"}
          sx={{ marginTop: theme.spacing(2) }}
        >
          Need an account? <RobotoLink to={"/signup"}>Sign up here</RobotoLink>
        </RobotoTypography>
      </Box>
      <LegalFooter />
    </>
  );
};

interface EmailInputProps {
  onSuccess: (emailAddress: string) => void;
  goBackClicked: () => void;
}

const EmailInput: React.FC<EmailInputProps> = ({
  onSuccess,
  goBackClicked,
}) => {
  const theme = useTheme();

  const [email, setEmail] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>(undefined);

  const { forgotPasswordInitiate } = useAuth();

  let buttonDisabled = true;

  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  buttonDisabled = !emailRegex.test(email);

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

    const err = await forgotPasswordInitiate(email);

    if (err) {
      setError(err);
      setLoading(false);
      return;
    }

    setLoading(false);
    onSuccess(email);
  };

  return (
    <Box
      component="form"
      sx={{
        display: "flex",
        flexDirection: "column",
        width: buttonWidth,
      }}
      onSubmit={(e) => {
        void onSubmit(e);
      }}
    >
      <TextField
        label="Email address"
        placeholder="name@domain.com"
        sx={{ marginBottom: theme.spacing(2) }}
        value={email}
        size="small"
        onChange={(e) => {
          setError(undefined);
          setEmail(e.target.value);
        }}
        inputProps={{
          autoCapitalize: "none",
        }}
      />

      {loading ? (
        <Box
          sx={{
            width: buttonWidth,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: theme.spacing(2),
          }}
        >
          <CircularProgress size="1.75rem" />
        </Box>
      ) : (
        <RobotoButton
          eventName={"GoToNextStep"}
          eventProperties={{ currentStep: "ForgotPassword" }}
          variant={"contained"}
          sx={{ width: buttonWidth, marginBottom: theme.spacing(2) }}
          disabled={buttonDisabled}
          type="submit"
        >
          Continue
        </RobotoButton>
      )}

      {error && (
        <Box sx={{ marginBottom: theme.spacing(1), textAlign: "center" }}>
          <RobotoTypography variant={"caption"} textAlign={"center"}>
            {error}
          </RobotoTypography>
        </Box>
      )}

      <RobotoButton
        eventName={"GoToPreviousStep"}
        eventProperties={{ currentStep: "ForgotPassword" }}
        variant={"text"}
        sx={{ width: buttonWidth }}
        onClick={goBackClicked}
      >
        Go back
      </RobotoButton>
    </Box>
  );
};
