import {
  Box,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import * as React from "react";

import { RobotoTableCell } from "@/shared/components";
import { OrgRecord } from "@/shared/domain/orgs";
import { usePaginatedAPICall } from "@/shared/services/apiHooks";
import { Image, Repository, RobotoAPICall, listImagesEndpoint } from "@/types";

import { ImageRow } from "./ImageRow";

type RowsPerPage = 10 | 25 | 50;
const initialRowsPerPage = 10;

interface ImageTableProps {
  repo: Repository;
  currentOrg: OrgRecord | null;
  listImages: boolean;
  setAlertDialogText: (arg: string) => void;
  setAlertDialogAction: (
    value: React.SetStateAction<(() => Promise<void>) | undefined>,
  ) => void;
  setAlertDialogOpen: (arg: boolean) => void;
  setErrorText: (arg: string) => void;
}

export const ImageTable: React.FC<ImageTableProps> = ({
  repo,
  currentOrg,
  listImages,
  setAlertDialogText,
  setAlertDialogAction,
  setAlertDialogOpen,
  setErrorText,
}) => {
  const [imgReloadToggle, setImgReloadToggle] = React.useState<boolean>(false);

  const [imagesLoading, setImagesLoading] = React.useState<boolean>(false);

  const [page, setPage] = React.useState<number>(0);
  const [rowsPerPage, setRowsPerPage] =
    React.useState<number>(initialRowsPerPage);

  const {
    getFirstPage,
    fetchNextPage,
    fetchPreviousPage,
    onRowsPerPageChange,
    pageData,
    isNextPageAvailable,
    cacheLength,
  } = usePaginatedAPICall<Image>();

  React.useEffect(() => {
    const fetchData = async () => {
      if (!listImages) {
        return;
      }

      if (!currentOrg?.org_id) {
        return;
      }

      setImagesLoading(true);

      const apiCall: RobotoAPICall = {
        endpoint: listImagesEndpoint,
        method: "GET",
        orgId: currentOrg?.org_id,
        queryParams: new URLSearchParams({
          repository_name: repo.repository_name,
        }),
      };

      await getFirstPage(apiCall, initialRowsPerPage);

      setImagesLoading(false);
    };

    void fetchData();
  }, [
    getFirstPage,
    currentOrg?.org_id,
    listImages,
    repo.repository_name,
    imgReloadToggle,
  ]);

  const onPageChangeHandler = async (newPage: number) => {
    //
    if (newPage > page) {
      setPage(page + 1);
      await fetchNextPage(newPage, rowsPerPage);
    } else {
      setPage(page - 1);
      fetchPreviousPage(newPage, rowsPerPage);
    }
  };

  const onRowsPerPageChangeHandler = async (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const val = parseInt(e?.target?.value) ?? 10;
    setPage(0);
    setRowsPerPage(val);
    await onRowsPerPageChange(val as RowsPerPage);
  };

  return (
    <>
      {imagesLoading ? (
        <Box sx={{ display: "flex", justifyContent: "center", padding: 2 }}>
          <CircularProgress size="1.5rem" />
        </Box>
      ) : (
        <>
          {pageData.length > 0 ? (
            <>
              <TableContainer component={Paper} variant="outlined">
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <RobotoTableCell>Image</RobotoTableCell>
                      <RobotoTableCell>Operations</RobotoTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {pageData.map((image, idx) => {
                      return (
                        <ImageRow
                          key={idx}
                          image={image}
                          currentOrg={currentOrg}
                          imgReloadToggle={imgReloadToggle}
                          setImgReloadToggle={setImgReloadToggle}
                          setAlertDialogText={setAlertDialogText}
                          setAlertDialogAction={setAlertDialogAction}
                          setAlertDialogOpen={setAlertDialogOpen}
                          setErrorText={setErrorText}
                        />
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>

              <TablePagination
                component="div"
                count={isNextPageAvailable ? -1 : cacheLength}
                nextIconButtonProps={{
                  disabled: !isNextPageAvailable,
                }}
                rowsPerPageOptions={[10, 25, 50]}
                page={page}
                onPageChange={(_e, newPage) => {
                  void onPageChangeHandler(newPage);
                }}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={(e) => {
                  void onRowsPerPageChangeHandler(e);
                }}
                labelRowsPerPage={"Images per page:"}
                sx={{}}
              />
            </>
          ) : (
            <Typography
              variant="body2"
              sx={{
                p: 0.5,
                fontFamily: "monospace",
                fontSize: "0.8rem",
                wordBreak: "break-all",
              }}
            >
              No images found.
            </Typography>
          )}
        </>
      )}
    </>
  );
};
