import { ListItem, ListItemText, useTheme } from "@mui/material";

import { RobotoSwitch } from "@/shared/components";

interface ExperienceToggleProps {
  displayText: string;
  description: string;
  enabled: boolean;
  onChange: () => void;
}

/**
 * A toggle button for enabling/disabling an org provisioning experience.
 */
export function ExperienceToggle({
  displayText,
  description,
  enabled,
  onChange,
}: ExperienceToggleProps) {
  const theme = useTheme();
  return (
    <ListItem
      disableGutters
      sx={{
        ".MuiListItemSecondaryAction-root": {
          position: "relative",
        },
        gap: theme.spacing(4),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
      }}
      secondaryAction={
        <RobotoSwitch checked={enabled} onChange={onChange} color="success" />
      }
    >
      <ListItemText primary={displayText} secondary={description} />
    </ListItem>
  );
}
