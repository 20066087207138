import classNames from "classnames";

import { actions, useVizDispatch } from "@/shared/state/visualization";

import { RobotoSwitch } from "../../../RobotoSwitch";
import { default as sectionStyles } from "../SettingsSection.module.css";

import styles from "./StretchImageToFit.module.css";

interface StretchImageToFitProps {
  panelId: string;
  stretchImageToFit: boolean;
}

export function StretchImageToFit({
  panelId,
  stretchImageToFit,
}: StretchImageToFitProps) {
  const dispatch = useVizDispatch();

  const onStretchImageToFitChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    dispatch(
      actions.setImagePanelConfig(panelId, {
        stretchToFitCanvas: event.target.checked,
      }),
    );
  };

  return (
    <label className={classNames(sectionStyles.setting, styles.label)}>
      Stretch to fit canvas
      <RobotoSwitch
        checked={stretchImageToFit}
        onChange={onStretchImageToFitChange}
        color="success"
      />
    </label>
  );
}
