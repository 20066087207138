import { useMutation, useQueryClient } from "@tanstack/react-query";
import React from "react";

import { DomainServicesContext } from "@/providers/DomainServices";

import { DatasetRecord, UpdateDatasetRequest } from "../DatasetRecord";

interface UpdateDataset {
  datasetId: string;
  resourceOwnerId: string;
  updates: UpdateDatasetRequest;
}

export function useUpdateDataset() {
  const { datasetService } = React.useContext(DomainServicesContext);

  const queryClient = useQueryClient();

  return useMutation<DatasetRecord, Error, UpdateDataset>({
    mutationFn: async ({ datasetId, resourceOwnerId, updates }) => {
      return await datasetService.updateDataset(datasetId, updates, {
        resourceOwnerId,
      });
    },

    onSuccess: (datasetRecord, updateRequest) => {
      if (
        updateRequest.updates.metadata_changeset?.put_tags !== undefined ||
        updateRequest.updates.metadata_changeset?.remove_tags !== undefined
      ) {
        const requestBody = updateRequest.updates.metadata_changeset;

        queryClient.setQueriesData(
          {
            queryKey: ["queryResults"],
            exact: false,
          },

          (oldData: { items: DatasetRecord[] } | undefined) => {
            if (!oldData) {
              return;
            }

            const newData = {
              ...oldData,
              items: oldData.items.map((item) => {
                if (item.dataset_id === datasetRecord.dataset_id) {
                  let newTags: string[] = item.tags;

                  if (requestBody.put_tags && requestBody.put_tags.length > 0) {
                    newTags = newTags.concat(requestBody.put_tags);
                  }

                  if (requestBody.remove_tags) {
                    newTags = newTags.filter((oldTag) => {
                      return !requestBody?.remove_tags?.includes(oldTag);
                    });
                  }

                  return {
                    ...item,
                    tags: newTags,
                  };
                }

                return item;
              }),
            };

            return newData;
          },
        );
      }

      queryClient.setQueryData(
        ["datasetRecord", datasetRecord.dataset_id],
        datasetRecord,
      );
    },
  });
}
