import { Alert } from "@mui/material";
import * as React from "react";
import { useRouteError } from "react-router";

import { InvalidVizConfig } from "@/shared/config";
import { ErrorMonitoringService } from "@/shared/services";

/**
 * Top-most error boundary for the visualization workspace.
 *
 * Reference: https://reactrouter.com/en/main/route/error-element
 */
export function ErrorBoundary() {
  const error = useRouteError();

  React.useEffect(() => {
    ErrorMonitoringService.captureError(error);
  }, [error]);

  if (error instanceof InvalidVizConfig) {
    return (
      <Alert
        severity="error"
        sx={{
          fontSize: "1.125rem",
          margin: "1em 0",
        }}
      >
        Failed to parse visualization configuration. Please check the URL and
        try again.
      </Alert>
    );
  }

  return (
    <Alert
      severity="error"
      sx={{
        fontSize: "1.125rem",
        margin: "1em 0",
      }}
    >
      Something went wrong loading the visualization workspace. Please try
      reloading the page.
    </Alert>
  );
}
