import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Link, Skeleton, useTheme } from "@mui/material";

import { EventDuration } from "@/shared/components/events";
import { EventRecord } from "@/shared/domain/events";
import { nanoSecToSec } from "@/utils/time";

import { RobotoTypography } from "../../RobotoTypography";
import { colorForAnnotation } from "../colorScale";
import { useWorkspaceEvent, useWorkspaceTimer } from "../WorkspaceCtx";

import styles from "./EventTime.module.css";

interface EventTimeProps {
  eventId: EventRecord["event_id"];
}

export function EventTime({ eventId }: EventTimeProps) {
  const timer = useWorkspaceTimer();
  const eventQuery = useWorkspaceEvent(eventId);

  const theme = useTheme();

  if (eventQuery.isLoading) {
    return (
      <section>
        <RobotoTypography component={"h3"} className={styles.title}>
          Duration
        </RobotoTypography>
        <Skeleton variant="rounded" height="40px" />
      </section>
    );
  }

  if (eventQuery.isError) {
    return (
      <section>
        <RobotoTypography component={"h3"} className={styles.title}>
          Duration
        </RobotoTypography>
        <div className={styles.errorContainer}>
          <ErrorOutlineIcon color="error" />
          <RobotoTypography>Failed to load event time</RobotoTypography>
        </div>
      </section>
    );
  }

  const { start_time, end_time } = eventQuery.data;

  return (
    <section>
      <RobotoTypography component={"h3"} className={styles.title}>
        Duration
      </RobotoTypography>
      <EventDuration
        durationInSeconds={nanoSecToSec(end_time - start_time)}
        style={{
          borderColor: colorForAnnotation(
            eventQuery.data.name,
            1,
            eventQuery.data.display_options?.color,
          ),
          backgroundColor: colorForAnnotation(
            eventQuery.data.name,
            0.15,
            eventQuery.data.display_options?.color,
          ),
          height: "36px",
        }}
      />
      <div className={styles.timeContainer}>
        <Link
          underline="hover"
          className={styles.time}
          color={theme.palette.text.primary}
          component={"button"}
          onClick={function seekToEventStart() {
            timer.seekTo(start_time);
          }}
        >
          {nanoSecToSec(start_time).toFixed(3)}s
        </Link>
        <Link
          underline="hover"
          className={styles.time}
          color={theme.palette.text.primary}
          component={"button"}
          onClick={function seekToEventEnd() {
            timer.seekTo(end_time);
          }}
        >
          {nanoSecToSec(end_time).toFixed(3)}s
        </Link>
      </div>
    </section>
  );
}
