import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { IconButton } from "@mui/material";
import classNames from "classnames";

interface VisibilityToggleProps {
  className?: classNames.Argument;
  on: boolean;
  onToggle: (on: boolean) => void;
}

export function VisibilityToggle({
  className,
  on,
  onToggle,
}: VisibilityToggleProps) {
  return (
    <IconButton
      className={classNames(className)}
      onClick={() => onToggle(!on)}
      title="Toggle visibility"
      aria-label="Toggle visibility"
      aria-checked={on}
    >
      {on ? (
        <VisibilityIcon fontSize="inherit" />
      ) : (
        <VisibilityOffIcon
          fontSize="inherit"
          style={{
            opacity: 0.5,
          }}
        />
      )}
    </IconButton>
  );
}
