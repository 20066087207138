import {
  Box,
  SxProps,
  Typography,
  TypographyVariant,
  useTheme,
} from "@mui/material";
import * as React from "react";
import { Link } from "react-router-dom";

import { useAuth } from "@/providers";
import { ActionRecord, ActionReference } from "@/shared/domain/actions";

import { isActionReference } from "../../model";

interface ActionLinkProps {
  action: ActionRecord | ActionReference | null;
  component?: React.ElementType;
  linkSx?: SxProps;
  variant?: TypographyVariant;
  wrapperSx?: SxProps;
  hideOwner?: boolean;
}

const TEXT_BASE_SX = {
  cursor: "pointer",
  fontVariant: "none",
  textDecoration: "none",
};

export function ActionLink({
  action,
  component = "span",
  linkSx,
  variant = "body2",
  wrapperSx,
  hideOwner,
}: ActionLinkProps) {
  const theme = useTheme();
  const { currentOrganization: org } = useAuth();

  if (!action) {
    return null;
  }

  let displayName = action.name;
  let href = `/actions/${action.name}`;
  const owner = isActionReference(action) ? action.owner : action.org_id;

  if (owner && owner !== org?.org_id) {
    href = `/actions/${owner}/${action.name}`;

    if (!hideOwner) {
      displayName = `${owner} / ${action.name}`;
    }
  }

  const wrapperStyle = {
    "& a": {
      textDecorationColor: theme.palette.primary.main,
    },
    "& a:not(:hover)": {
      textDecoration: "none",
    },
    ...wrapperSx,
  };

  const textStyle = linkSx ? { ...TEXT_BASE_SX, ...linkSx } : TEXT_BASE_SX;

  return (
    <Box component="span" color={theme.palette.primary.main} sx={wrapperStyle}>
      <Link to={href} onClick={(e) => e.stopPropagation()}>
        <Typography
          component={component}
          title={component === "abbr" ? action.name : undefined}
          color={theme.palette.primary.main}
          fontSize={theme.typography.body2.fontSize}
          sx={textStyle}
          variant={variant}
        >
          {displayName}
        </Typography>
      </Link>
    </Box>
  );
}
