import DeleteIcon from "@mui/icons-material/Delete";
import {
  Alert,
  Box,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography,
  useTheme,
} from "@mui/material";
import * as React from "react";
import { Link } from "react-router-dom";

import { useNavigation } from "@/providers";
import {
  AlertDialog,
  RobotoButton,
  RobotoLinkHoverUnderline,
} from "@/shared/components";
import { SystemUserOnly } from "@/shared/components/SystemUserOnly";
import {
  ActionRecord,
  ComputeRequirements,
  ContainerParameters,
} from "@/shared/domain/actions";
import { OrganizationTier, OrgRecord } from "@/shared/domain/orgs";
import { useLazyAPICall } from "@/shared/services/apiHooks";
import { APIResponse, actionEndpoint } from "@/types";

import ChangeActionAccessibilityButton from "./ChangeActionAccessibilityButton";
import EditableActionParams from "./EditableActionParams";
import EditableComputeReqs from "./EditableComputeReqs";
import EditableContainerParams from "./EditableContainerParams";
import { EditableListItem } from "./EditableListItem";
import { EditableTimeout } from "./EditableTimeout";
import { ImageGuidance } from "./ImageGuidance";
import InvokeActionButton from "./InvokeActionButton";
import { UpdateActionMetadata } from "./UpdateActionMetadata";
import { UpdateActionTags } from "./UpdateActionTags";

interface ActionDetailsProps {
  action: ActionRecord;
  currentOrg: OrgRecord | null;
  onUpdate?: (arg: APIResponse<ActionRecord>) => void;
}

export const ActionDetails: React.FC<ActionDetailsProps> = ({
  action,
  currentOrg,
  onUpdate,
}) => {
  const [errorText, setErrorText] = React.useState<string | null>(null);
  const [alertDialogOpen, setAlertDialogOpen] = React.useState<boolean>(false);
  const [alertDialogTitle, setAlertDialogTitle] = React.useState<string>("");
  const [alertDialogText, setAlertDialogText] = React.useState<string>("");
  const [alertDialogContent, setAlertDialogContent] =
    React.useState<React.ReactNode>(undefined);

  const [alertDialogAction, setAlertDialogAction] =
    React.useState<() => Promise<void>>();

  const { initiateRequest: deleteActionRequest } = useLazyAPICall();

  const { goto } = useNavigation();

  const theme = useTheme();

  function wrapActionWithDialog(
    title: string,
    text: string,
    action: () => Promise<void>,
  ) {
    setAlertDialogTitle(title);
    setAlertDialogText(text);
    setAlertDialogContent(undefined);
    setAlertDialogAction(() => action);
    setAlertDialogOpen(true);
  }

  const getRepoIdentifier = (url: string) => {
    const parts = url.split("/");
    // https://github.com/account/repo/...
    // extract "account/repo"
    return parts[3] + "/" + parts[4];
  };

  const githubUrl =
    typeof action.metadata?.github_url === "string" &&
    action.metadata.github_url.length
      ? action.metadata.github_url
      : null;

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          maxWidth: "2000px",
        }}
      >
        <Box
          sx={{
            width: {
              xs: "100%",
              sm: "50%",
            },
          }}
        >
          {errorText && (
            <Box sx={{ mt: theme.spacing(1) }}>
              <Alert severity="error">{errorText}</Alert>
            </Box>
          )}

          <List>
            <ListItem>
              <ListItemText primary={"Name"} secondary={action.name} />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={"Created By"}
                secondary={
                  <RobotoLinkHoverUnderline to={`/users/${action.created_by}`}>
                    {action.created_by}
                  </RobotoLinkHoverUnderline>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={"Modified"}
                secondary={
                  <>
                    <Typography variant="body2">
                      {new Date(action.modified || "").toLocaleString() +
                        " by "}
                      <RobotoLinkHoverUnderline
                        to={`/users/${action.modified_by}`}
                      >
                        {action.modified_by}
                      </RobotoLinkHoverUnderline>
                    </Typography>
                  </>
                }
              />
            </ListItem>

            <Box
              sx={{
                display: {
                  sm: "block",
                  md: "none",
                },
              }}
            >
              <EditableListItem
                title="Summary"
                field="short_description"
                value={action.short_description}
                endpoint={actionEndpoint}
                actionName={action.name}
                currentOrg={currentOrg}
                setErrorText={setErrorText}
                renderMarkdown={false}
                onUpdate={onUpdate}
                maxLength={140}
              />
              <EditableListItem
                title="Description"
                field="description"
                value={action.description}
                endpoint={actionEndpoint}
                actionName={action.name}
                currentOrg={currentOrg}
                setErrorText={setErrorText}
                renderMarkdown={true}
                onUpdate={onUpdate}
              />
            </Box>

            <EditableListItem
              title="Image"
              field="uri"
              value={action.uri}
              endpoint={actionEndpoint}
              actionName={action.name}
              currentOrg={currentOrg}
              setErrorText={setErrorText}
              onHelperClick={() => {
                setAlertDialogOpen(true);
                setAlertDialogTitle("Container image");
                setAlertDialogText("");
                setAlertDialogContent(<ImageGuidance />);
              }}
              onUpdate={onUpdate}
            />

            {currentOrg?.tier === OrganizationTier.Premium ? (
              <EditableTimeout<ActionRecord>
                title="Timeout"
                field="timeout"
                value={action.timeout}
                endpoint={actionEndpoint}
                name={action.name}
                currentOrg={currentOrg}
                setErrorText={setErrorText}
                renderMarkdown={false}
                onUpdate={onUpdate}
              />
            ) : (
              <ListItem>
                <ListItemText
                  primary={"Timeout"}
                  secondaryTypographyProps={{
                    component: "span",
                  }}
                  secondary={
                    action.timeout ? `${action.timeout} minutes` : "None"
                  }
                />
              </ListItem>
            )}

            <EditableActionParams
              title="Parameters"
              value={action.parameters || []}
              pathParams={{ name: action.name }}
              endpoint={actionEndpoint}
              currentOrg={currentOrg}
              setErrorText={setErrorText}
              onUpdate={onUpdate}
            />

            <EditableContainerParams
              title="Container Parameters"
              field="container_parameters"
              value={action.container_parameters || ({} as ContainerParameters)}
              pathParams={{ name: action.name }}
              endpoint={actionEndpoint}
              currentOrg={currentOrg}
              setErrorText={setErrorText}
              onUpdate={onUpdate}
            />

            <EditableComputeReqs
              title="Compute Requirements"
              field="compute_requirements"
              value={action.compute_requirements || ({} as ComputeRequirements)}
              pathParams={{ name: action.name }}
              endpoint={actionEndpoint}
              currentOrg={currentOrg}
              setErrorText={setErrorText}
              onUpdate={onUpdate}
            />

            <ListItem>
              <ListItemText
                primary={"Tags"}
                secondaryTypographyProps={{
                  component: "span",
                  mt: theme.spacing(0.5),
                }}
                secondary={
                  <UpdateActionTags
                    initialTags={action.tags || []}
                    currentOrg={currentOrg}
                    actionName={action.name}
                    enableUpdates={onUpdate !== undefined}
                    onTagsUpdated={onUpdate}
                  />
                }
              />
            </ListItem>

            <ListItem>
              <ListItemText
                primary={"Metadata"}
                secondaryTypographyProps={{
                  component: "span",
                }}
                secondary={
                  <UpdateActionMetadata
                    initialMetadata={action.metadata || {}}
                    currentOrg={currentOrg}
                    actionName={action.name}
                    onMetadataUpdated={onUpdate}
                  />
                }
              />
            </ListItem>
          </List>
        </Box>
        <Box
          component={Paper}
          variant="outlined"
          sx={{
            mt: theme.spacing(1.5),
            padding: theme.spacing(1),
            pt: 0,
            background: "none",
            width: "48%",
            height: "fit-content",
            display: {
              xs: "none",
              md: "block",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              gap: theme.spacing(2),
              borderBottom: "1px solid " + theme.palette.divider,
              ml: theme.spacing(1),
              mr: theme.spacing(1),
              p: theme.spacing(1),
              pt: theme.spacing(2),
              pb: theme.spacing(2),
              overflow: "hidden",
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: theme.spacing(2),
              }}
            >
              <InvokeActionButton action={action} />
              {action.org_id === currentOrg?.org_id && (
                <RobotoButton
                  disabled={onUpdate === undefined}
                  eventName={"ActionDeleted"}
                  eventProperties={{
                    name: action.name,
                  }}
                  variant={"outlined"}
                  color="error"
                  size="small"
                  startIcon={<DeleteIcon />}
                  onClick={() =>
                    wrapActionWithDialog(
                      "Delete Action",
                      `Are you sure you want to delete ${action.name}?`,
                      async function () {
                        const { error } = await deleteActionRequest({
                          endpoint: actionEndpoint,
                          method: "DELETE",
                          orgId: currentOrg?.org_id,
                          pathParams: { name: action.name },
                        });

                        if (!error) {
                          goto.actions();
                        } else {
                          setErrorText(error.message);
                        }
                      },
                    )
                  }
                >
                  Delete
                </RobotoButton>
              )}
              <SystemUserOnly>
                <ChangeActionAccessibilityButton
                  action={action}
                  onSuccess={() => {
                    window.location.reload();
                  }}
                  onError={(error) => {
                    setErrorText(error.message);
                  }}
                />
              </SystemUserOnly>
            </Box>

            {githubUrl !== null && (
              <Box
                sx={{
                  display: "flex",
                  gap: theme.spacing(1.5),
                  alignItems: "center",
                }}
              >
                <Link
                  target="_blank"
                  style={{ display: "flex" }}
                  to={githubUrl}
                >
                  <img
                    alt="GitHub Logo"
                    style={{ width: "28px", height: "28px" }}
                    src={
                      theme.palette.mode === "dark"
                        ? "/github-mark-white.svg"
                        : "/github-mark.svg"
                    }
                  />
                </Link>
                <Link
                  target="_blank"
                  style={{ display: "flex" }}
                  to={githubUrl}
                >
                  <img
                    src={
                      "https://img.shields.io/github/stars/" +
                      getRepoIdentifier(action.metadata["github_url"])
                    }
                    height={20}
                    alt="GitHub Stars"
                  />
                </Link>
              </Box>
            )}
          </Box>

          <List>
            <EditableListItem
              title="Summary"
              field="short_description"
              value={action.short_description}
              endpoint={actionEndpoint}
              actionName={action.name}
              currentOrg={currentOrg}
              setErrorText={setErrorText}
              renderMarkdown={false}
              onUpdate={onUpdate}
              maxLength={140}
            />
            <EditableListItem
              title="Description"
              field="description"
              value={action.description}
              endpoint={actionEndpoint}
              actionName={action.name}
              currentOrg={currentOrg}
              setErrorText={setErrorText}
              renderMarkdown={true}
              onUpdate={onUpdate}
            />
          </List>
        </Box>
      </Box>

      <AlertDialog
        dialogOpen={alertDialogOpen}
        handleClose={() => {
          setAlertDialogOpen(false);
        }}
        dialogTitle={alertDialogTitle}
        dialogText={alertDialogText}
        dialogContent={alertDialogContent}
        dialogAction={alertDialogAction}
      />
    </>
  );
};
