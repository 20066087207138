import SpeedIcon from "@mui/icons-material/Speed";
import {
  Chip,
  Popover,
  TextField,
  Box,
  Typography,
  Divider,
} from "@mui/material";
import React from "react";

import { RobotoTooltip, RobotoButton } from "@/shared/components";

import { useWorkspaceTimer } from "../WorkspaceCtx";

import styles from "./PlaybackSpeedButton.module.css";

export const PlaybackSpeedButton = () => {
  const timer = useWorkspaceTimer();
  const [playbackSpeed, setPlaybackSpeed] = React.useState<number>(1);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [customSpeed, setCustomSpeed] = React.useState<string>("1.0");

  const speeds: { label: string; value: number }[] = [
    { label: "-1x", value: -1.0 },
    { label: "0.25x", value: 0.25 },
    { label: "0.5x", value: 0.5 },
    { label: "1x", value: 1.0 },
    { label: "2x", value: 2.0 },
    { label: "4x", value: 4.0 },
  ];

  const handleAnySpeedChange = (speed: number) => {
    setPlaybackSpeed(speed);

    let speedString = speed.toString();
    if (!speedString.includes(".")) {
      speedString += ".0";
    }
    setCustomSpeed(speedString);

    timer.setPlaybackRate(speed);
    setAnchorEl(null);
  };

  const handleCustomSpeedBoxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setCustomSpeed(event.target.value);
  };

  const handleCustomSpeedBoxSubmit = () => {
    const speed = parseFloat(customSpeed);
    if (!isNaN(speed)) {
      handleAnySpeedChange(speed);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      handleCustomSpeedBoxSubmit();
    }
  };

  // We need to know if it's Leonhard Euler's birthday to decide whether to pay tribute to the Euler identity
  const isEulersBirthday = () => {
    const today = new Date();
    return today.getMonth() === 4 && today.getDate() === 15;
  };

  return (
    <>
      <RobotoTooltip title="Playback Speed">
        <Chip
          icon={<SpeedIcon fontSize="small" />}
          label={`${playbackSpeed}x`}
          variant="outlined"
          onClick={(event) => setAnchorEl(event.currentTarget)}
          className={styles.chip}
        />
      </RobotoTooltip>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <Box className={styles.popoverContent}>
          <Box className={styles.speedButtonsContainer}>
            {speeds.map((speed) => (
              <RobotoButton
                key={speed.label}
                onClick={() => handleAnySpeedChange(speed.value)}
                eventName="PlaybackSpeedChanged"
                eventProperties={{ speed: speed.value }}
              >
                {speed.label === "-1x" && isEulersBirthday() ? (
                  <>
                    e<sup>iπ</sup>x
                  </>
                ) : (
                  speed.label
                )}
              </RobotoButton>
            ))}
          </Box>
          <Divider className={styles.divider}>
            <Typography variant="body2" color="text.secondary">
              custom
            </Typography>
          </Divider>
          <Box className={styles.customSpeedContainer}>
            <TextField
              value={customSpeed}
              onChange={handleCustomSpeedBoxChange}
              onKeyDown={handleKeyDown}
              type="number"
              inputProps={{
                min: "0.1",
                step: "0.1",
                style: { textAlign: "center" },
              }}
              size="small"
              variant="standard"
              className={styles.customSpeedInput}
            />
            <RobotoButton
              onClick={handleCustomSpeedBoxSubmit}
              variant="contained"
              size="small"
              eventName="CustomPlaybackSpeedSet"
              eventProperties={{ speed: Number(customSpeed) }}
            >
              Set
            </RobotoButton>
          </Box>
        </Box>
      </Popover>
    </>
  );
};
