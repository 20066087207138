import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import * as React from "react";

import { FileRecord } from "@/shared/domain/files";
import { useFile } from "@/shared/domain/files/hooks/useFile.ts";
import { LoggerService } from "@/shared/services";

import { RobotoLinkHoverUnderline } from "../RobotoLinkHoverUnderline";

function defaultLinkName(fileRecord: FileRecord) {
  return fileRecord.relative_path;
}

interface FileVizLinkProps {
  fileId: string;
  getLinkName?: (fileRecord: FileRecord) => string;
  topicName?: string;

  /**
   * Optional text content to display below the link
   **/
  subtitle?: React.ReactNode;
}

export const FileVizLink: React.FC<FileVizLinkProps> = ({
  fileId,
  getLinkName = defaultLinkName,
  topicName,
  subtitle,
}) => {
  const fileQuery = useFile(fileId);

  if (fileQuery.isLoading) {
    return (
      <Typography variant="body2">
        <Skeleton variant="text" width="200px" />
      </Typography>
    );
  }

  if (fileQuery.isError || fileQuery.data === undefined) {
    LoggerService.error(fileQuery.error?.message, fileQuery.error);
    return (
      <Typography variant="body2" color="error">
        Failed to load event scope
      </Typography>
    );
  }

  let route = `/files/${fileId}`;
  if (topicName) {
    route += `?filterTerm=${encodeURIComponent(topicName)}`;
  }

  return (
    <>
      <RobotoLinkHoverUnderline to={route} variant="body2">
        {getLinkName(fileQuery.data)}
      </RobotoLinkHoverUnderline>
      {subtitle}
    </>
  );
};
