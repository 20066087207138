import { LogPanelState } from "@/shared/state/visualization";
import { MessagePayload, WorkerManager } from "@/shared/webworker";
import { clamp } from "@/utils/math";

import { Log } from "./log";
import {
  isAllLogsLoadedEvent,
  isErrorEvent,
  isInitializedEvent,
  LogCommand,
  LogCommandPayloadMap,
  LogCommandResponse,
  LogCommandResponsePayloadMap,
  LogEvent,
  LogEventPayloadMap,
  SOURCE,
} from "./messaging";
import { SearchMode } from "./search/search";

export type OnInitializedCallback = (
  error: LogEventPayloadMap[LogEvent.Initialized],
) => void;

export type OnErrorCallback = (
  error: LogEventPayloadMap[LogEvent.Error],
) => void;

export type OnLoadingStateChangedCallback = (
  data: LogEventPayloadMap[LogEvent.LoadingStateChange],
) => void;

export type OnNumLogsChangedCallback = (
  data: LogEventPayloadMap[LogEvent.NumLogsChanged],
) => void;

const noop = () => {};

export class LogManager {
  #abortController: AbortController;

  // Callback for when the worker is initialized
  #onInitialized: OnInitializedCallback = noop;

  // Callback for when an error has occurred
  #onError: OnErrorCallback = noop;

  // Callback for when we're loading logs
  #onLoadingStateChanged: OnLoadingStateChangedCallback = noop;

  #onNumLogsChanged: OnNumLogsChangedCallback = noop;

  // Sorted in ascending order
  #timestamps: BigUint64Array = new BigUint64Array(0);

  #workerManager: WorkerManager;

  constructor() {
    this.#abortController = new AbortController();

    const worker = new Worker(new URL("./LogService.worker", import.meta.url), {
      type: "module",
    });

    this.#workerManager = new WorkerManager({
      worker,
      onError: this.#onError,
      onMessage: this.#onMessage,
      signal: this.#abortController.signal,
    });

    this.#workerManager.sendCommand({
      data: undefined,
      source: SOURCE,
      type: LogCommand.Init,
    });
  }

  public dispose(): void {
    this.#abortController.abort();
    this.#workerManager.dispose();
  }

  public async filterLogs(
    searchParams: { query: string; mode: SearchMode },
    signal?: AbortSignal,
  ): Promise<void> {
    const timestampsBuffer = await this.#workerManager.sendCommandAwaitResponse<
      LogCommand,
      LogCommandPayloadMap[LogCommand.GetFilteredTimestamps],
      LogCommandResponsePayloadMap[LogCommandResponse.TimestampsFiltered]
    >({
      type: LogCommand.GetFilteredTimestamps,
      data: searchParams,
      signal,
      source: SOURCE,
    });

    const timestamps = new BigUint64Array(timestampsBuffer);

    this.#timestamps = timestamps;
    this.#onNumLogsChanged({ numLogs: timestamps.length });
  }

  public findLogIndexForTime(time: bigint): number {
    let low = 0;
    let high = this.#timestamps.length - 1;
    while (low <= high) {
      const mid = Math.floor((low + high) / 2);
      const logTime = this.#timestamps[mid];
      if (logTime === time) {
        return mid;
      } else if (logTime < time) {
        low = mid + 1;
      } else {
        high = mid - 1;
      }
    }
    // Use the index of the closest log before the given time otherwise
    return Math.max(low - 1, 0);
  }

  public async getLogs(
    pagination: { start: number; end: number },
    signal?: AbortSignal,
  ): Promise<Map<bigint, Log>> {
    if (this.#timestamps.length === 0) {
      return new Map();
    }

    const startIndex = clamp(
      pagination.start,
      0,
      Math.max(this.#timestamps.length - 1, 0),
    );
    const endIndex = clamp(
      pagination.end,
      0,
      Math.max(this.#timestamps.length - 1, 0),
    );

    const startTime = this.#timestamps[startIndex];
    const endTime = this.#timestamps[endIndex];

    return this.#workerManager.sendCommandAwaitResponse<
      LogCommand,
      LogCommandPayloadMap[LogCommand.GetLogs],
      LogCommandResponsePayloadMap[LogCommandResponse.LogsLoaded]
    >({
      type: LogCommand.GetLogs,
      data: { startTime, endTime },
      signal,
      source: SOURCE,
    });
  }

  /**
   * @param index Position in the full list of logs
   * @returns The unique timestamp for that log or undefined if we don't have it
   */
  public getTimeForIndex(index: number): bigint | undefined {
    return this.#timestamps[index];
  }

  public setEventListener<K extends keyof LogEventPayloadMap>(
    type: K,
    listener: (arg0: LogEventPayloadMap[K]) => void,
  ): void {
    if (type === LogEvent.Initialized) {
      this.#onInitialized = listener as OnInitializedCallback;
      return;
    }

    if (type === LogEvent.Error) {
      this.#onError = listener as OnErrorCallback;
      return;
    }

    if (type === LogEvent.LoadingStateChange) {
      this.#onLoadingStateChanged = listener as OnLoadingStateChangedCallback;
      return;
    }

    if (type === LogEvent.NumLogsChanged) {
      this.#onNumLogsChanged = listener as OnNumLogsChangedCallback;
      return;
    }
  }

  public setState(state: LogPanelState["data"]): void {
    this.#onLoadingStateChanged({ isLoading: true });
    this.#workerManager.sendCommand({
      data: state,
      source: SOURCE,
      type: LogCommand.SetState,
    });
  }

  #onMessage = (event: MessageEvent<MessagePayload>) => {
    if (isAllLogsLoadedEvent(event)) {
      const timestamps = new BigUint64Array(event.data.data.timestamps);

      this.#timestamps = timestamps;
      this.#onLoadingStateChanged({ isLoading: false });
      this.#onNumLogsChanged({ numLogs: timestamps.length });
      return;
    }

    if (isErrorEvent(event) && event.data.error !== undefined) {
      this.#onError(event.data.error);
      return;
    }

    if (isInitializedEvent(event)) {
      this.#onInitialized(event.data.data);
      return;
    }
  };
}
