import * as React from "react";

import { LoggerService } from "@/shared/services";

interface IFileUploadContext {
  // Parse the event data for files, and upload the files to the backend
  beginFileUpload: (
    event: React.DragEvent<HTMLElement> | React.ChangeEvent<HTMLInputElement>,
    orgId: string,
    datasetId: string,
    prefix?: string,
  ) => Promise<void>;
  isUploading: boolean;
}

export const FileUploadContext = React.createContext<IFileUploadContext>({
  beginFileUpload() {
    LoggerService.log("TODO: WRAP IN VALID FILE UPLOAD PROVIDER");
    return Promise.resolve();
  },
  isUploading: false,
});

export const useFileUpload = (): IFileUploadContext => {
  return React.useContext(FileUploadContext);
};
