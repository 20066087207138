import { Alert, Box, useTheme } from "@mui/material";
import { ErrorBoundary } from "@sentry/react";
import { useParams } from "react-router-dom";

import { CommentsBox, NavigationPage } from "@/shared/components";

import { DatasetHeader } from "./DatasetHeader";
import { DatasetTabRoutes } from "./DatasetTabRoutes";
import { DatasetTabs } from "./DatasetTabs";

export function Dataset() {
  const { datasetPath: datasetId } = useParams();
  const theme = useTheme();

  if (!datasetId) {
    return (
      <NavigationPage title={`Roboto - Dataset - ${datasetId}`}>
        <Alert severity="error">Missing dataset id in path</Alert>
      </NavigationPage>
    );
  }

  return (
    <NavigationPage title={`Roboto - Dataset - ${datasetId}`}>
      <ErrorBoundary
        fallback={(props) => {
          if (props.error instanceof Error) {
            return <Alert severity="error">{props.error.message}</Alert>;
          }

          return (
            <Alert severity="error">
              Something went wrong. Please contact{" "}
              <a href="mailto:support@roboto.ai">support@roboto.ai</a> if this
              problem persists.
            </Alert>
          );
        }}
      >
        <section
          style={{
            display: "flex",
            flexDirection: "column",
            gap: theme.spacing(2),
          }}
        >
          <DatasetHeader datasetId={datasetId} showTags />
          <DatasetTabs datasetId={datasetId} />
          <DatasetTabRoutes datasetId={datasetId} />
          <Box sx={{ mt: theme.spacing(2) }}>
            <CommentsBox entityType={"dataset"} entityId={datasetId} />
          </Box>
        </section>
      </ErrorBoundary>
    </NavigationPage>
  );
}
