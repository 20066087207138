import { Box, Typography, useTheme } from "@mui/material";
import * as React from "react";

import CopyToClipboardButton from "@/shared/components/CopyToClipboardButton";
import { FileRecord } from "@/shared/domain/files";
import { basename } from "@/utils";

const FILENAME_FONT_SIZE = "0.92rem";

interface FileHeaderPathProps {
  fileRecord?: FileRecord;
}

export const FileHeaderPath: React.FC<FileHeaderPathProps> = ({
  fileRecord,
}) => {
  const theme = useTheme();

  const [filenameHovering, setFilenameHovering] =
    React.useState<boolean>(false);

  return (
    fileRecord && (
      <Box
        sx={{
          color: theme.palette.text.primary,
          display: "flex",
          gap: theme.spacing(0.75),
          alignItems: "center",
        }}
        title={fileRecord.relative_path}
        onMouseEnter={() => setFilenameHovering(true)}
        onMouseLeave={() => setFilenameHovering(false)}
      >
        <Typography
          component={"span"}
          sx={{
            fontSize: FILENAME_FONT_SIZE,
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {basename(fileRecord.relative_path)}
        </Typography>

        <CopyToClipboardButton
          text={basename(fileRecord.relative_path)}
          sx={{
            fontSize: FILENAME_FONT_SIZE,
            opacity: filenameHovering ? 1 : 0,
            transition: "opacity 0.2s",
          }}
        />
      </Box>
    )
  );
};
