import { Comparator } from "@/types";

import { SearchField } from "./fields";

export const filterComparatorsAndValues = (
  value: Comparator,
  currentSearchField: SearchField,
): boolean => {
  if (currentSearchField.type === "date") {
    if (
      value === "CONTAINS" ||
      value === "NOT_CONTAINS" ||
      value === "BEGINS_WITH" ||
      // TODO: Add support for EQUALS and NOT_EQUALS on the backend: https://roboto.atlassian.net/browse/ROBO-743
      value === "EQUALS" ||
      value === "NOT_EQUALS" ||
      value === "IS_NOT_NULL" ||
      value === "IS_NULL" ||
      value === "NOT_EXISTS" ||
      value === "EXISTS"
    ) {
      return false;
    }
  }

  if (currentSearchField.type === "tags") {
    if (
      value === "EQUALS" ||
      value === "NOT_EQUALS" ||
      value === "GREATER_THAN" ||
      value === "LESS_THAN" ||
      value === "GREATER_THAN_OR_EQUAL" ||
      value === "LESS_THAN_OR_EQUAL" ||
      value === "BEGINS_WITH" ||
      value === "IS_NULL" ||
      value === "IS_NOT_NULL" ||
      value === "EXISTS" ||
      value === "NOT_EXISTS"
    ) {
      return false;
    }
  }

  if (currentSearchField.name === "dataset_id") {
    if (
      value === "IS_NOT_NULL" ||
      value === "IS_NULL" ||
      value === "NOT_EXISTS" ||
      value === "EXISTS" ||
      value === "GREATER_THAN" ||
      value === "LESS_THAN" ||
      value === "GREATER_THAN_OR_EQUAL" ||
      value === "LESS_THAN_OR_EQUAL"
    ) {
      return false;
    }
  }

  if (
    currentSearchField.name === "created_by" ||
    currentSearchField.name === "modified_by"
  ) {
    if (
      value === "IS_NOT_NULL" ||
      value === "IS_NULL" ||
      value === "NOT_EXISTS" ||
      value === "EXISTS"
    ) {
      return false;
    }
  }

  return true;
};
