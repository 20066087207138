import { useQuery } from "@tanstack/react-query";

import { useDomainServices } from "@/providers/DomainServices";
import { DatasetRecord } from "@/shared/domain/datasets/DatasetRecord";

interface UseDatasetOptions {
  throwOnError: boolean;
}

export function useDataset(
  datasetId: string | undefined,
  options?: Partial<UseDatasetOptions>,
) {
  const { datasetService } = useDomainServices();

  return useQuery<DatasetRecord, Error>({
    queryKey: ["datasetRecord", datasetId],
    queryFn({ signal }) {
      if (datasetId === undefined) {
        return Promise.reject(new Error("Invalid datasetId"));
      }

      return datasetService.getDataset(datasetId, {
        abortSignal: signal,
      });
    },
    enabled: datasetId !== undefined,
    // Our HttpClient already retries requests that need retrying
    retry: false,
    // 1 Minute until stale
    staleTime: 60000,
    throwOnError: options?.throwOnError ?? false,
  });
}
