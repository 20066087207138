import { Chip, ChipProps } from "@mui/material";
import * as React from "react";

import { InvocationStatus } from "@/shared/domain/actions";
import { invocationStatusToChipColor, invocationStatusToString } from "@/utils";

const noop = () => {
  /** noop */
};

// Taken from
// https://overreacted.io/making-setinterval-declarative-with-react-hooks/
function useInterval(callback = noop, delay: number | null = 1000) {
  const savedCallback = React.useRef<() => void>(callback);

  // Remember the latest callback.
  React.useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  React.useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

interface InvocationStatusProps {
  onPollForStatus?: () => void;
  pollInterval?: number | null; // in milliseconds
  status: InvocationStatus;
}

export default function InvocationStatusChip(
  props: InvocationStatusProps & ChipProps,
) {
  const { onPollForStatus, pollInterval = 2000, status, ...rest } = props;

  useInterval(
    onPollForStatus,
    status < InvocationStatus.Completed && Number.isFinite(pollInterval)
      ? pollInterval
      : null,
  );

  return (
    <Chip
      color={invocationStatusToChipColor(status)}
      label={invocationStatusToString(status)}
      style={{
        borderRadius: "8px",
      }}
      {...rest}
    />
  );
}
