import {
  InfiniteData,
  QueryKey,
  useInfiniteQuery,
} from "@tanstack/react-query";
import * as React from "react";

import { DomainServicesContext } from "@/providers/DomainServices";
import { PaginatedResponse } from "@/shared/http";

/**
 * Custom hook to retrieve query results with pagination.
 *
 * @template T - The type of the query results.
 * @param {string | undefined} queryId - The unique identifier for the query. If undefined, no query will be performed.
 * @param {number} [pageSize=250] - The number of results per page retrieved from the backend.
 *                                Default is set to 250, which is  higher than typical frontend pagination
 *                                defaults (e.g., 10, 25, or 50). This provides an upfront buffer capable
 *                                of handling multiple frontend pages worth of results, improving performance
 *                                and reducing the need for frequent backend requests.
 */
export function useQueryResults<T>(
  queryId: string | undefined,
  pageSize: number = 250,
) {
  const { queryService } = React.useContext(DomainServicesContext);

  return useInfiniteQuery<
    PaginatedResponse<T>,
    Error,
    InfiniteData<PaginatedResponse<T>, string | undefined>,
    QueryKey,
    string | undefined
  >({
    queryKey: ["queryResults", queryId],
    queryFn: ({ pageParam, signal }) => {
      if (queryId === undefined) {
        return Promise.reject(new Error("Invalid queryId"));
      }

      return queryService.getQueryResults(queryId, {
        abortSignal: signal,
        nextPageToken: pageParam,
        pageSize: pageSize,
      });
    },
    initialPageParam: undefined,
    getNextPageParam: (lastPage) => lastPage.next_token,
    enabled: queryId !== undefined,
    staleTime: Infinity,
  });
}
