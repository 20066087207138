import FlareOutlinedIcon from "@mui/icons-material/FlareOutlined";

import { useNavigation } from "@/providers";
import { RobotoButton } from "@/shared/components";

interface CreateDatasetEventProps {
  datasetId: string;
}

export function CreateDatasetEvent({ datasetId }: CreateDatasetEventProps) {
  const { goto } = useNavigation();
  return (
    <RobotoButton
      eventName={"CreateDatasetEventClicked"}
      eventProperties={{ datasetId }}
      onClick={() => goto.createDatasetEvent({ datasetId })}
      size="small"
      startIcon={<FlareOutlinedIcon />}
      variant="outlined"
    >
      Create Event
    </RobotoButton>
  );
}
