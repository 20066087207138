import * as React from "react";

import { Dimensions, usePopupState } from "@/shared/hooks";
import { PlotPanelState } from "@/shared/state/visualization";

import { usePlotPanelContext } from "../panelContext";
import { Extents } from "../PlotRenderer";
import { PlotTool } from "../plotTools";

import { pixelRegionToTimeRange } from "./chartRegionToTimeRange";
import { SelectedRegionActionsMenu } from "./SelectedRegionActionsMenu";

interface SelectRegionProps {
  canvas: HTMLCanvasElement | null;
  chartExtents: Extents | null;
  onDragChanged: (isDragging: boolean) => void;
  renderingSurface: HTMLDivElement | null;
  state: PlotPanelState;
}

interface SelectedDomain {
  firstPoint: number;
  secondPoint: number;
}

function isElementOnCanvas(
  renderingSurface: HTMLDivElement | null,
  eventTarget: EventTarget,
) {
  return (
    eventTarget instanceof Node &&
    renderingSurface?.contains(eventTarget) === true
  );
}

function normalizeRegionToChartSpace(
  selectedDomain: SelectedDomain,
  containerDims: Dimensions,
  chartExtents: Extents,
): SelectedDomain {
  return {
    firstPoint:
      (selectedDomain.firstPoint -
        containerDims.left -
        chartExtents.pixel.left) /
      chartExtents.pixel.width,
    secondPoint:
      (selectedDomain.secondPoint -
        containerDims.left -
        chartExtents.pixel.left) /
      chartExtents.pixel.width,
  };
}

/**
 *
 * @param selectedDomain selectedDomain should be a ratio relative to the chart
 */
function calculateRegionDimensions(
  selectedDomain: SelectedDomain,
  chartExtents: Extents,
) {
  const regionLeft =
    Math.min(selectedDomain.firstPoint, selectedDomain.secondPoint) *
    chartExtents.pixel.width;

  const regionWidth =
    Math.abs(selectedDomain.secondPoint - selectedDomain.firstPoint) *
    chartExtents.pixel.width;

  return {
    left: regionLeft,
    top: chartExtents.pixel.top,
    width: regionWidth,
    height: chartExtents.pixel.height,
  };
}

export function SelectRegion(props: SelectRegionProps) {
  const { canvas, chartExtents, onDragChanged, renderingSurface, state } =
    props;
  const { activeTool } = usePlotPanelContext();

  const [selectedRegion, setSelectedRegion] =
    React.useState<SelectedDomain | null>(null);

  const contextMenu = usePopupState();
  const { open: openContextMenu } = contextMenu;

  React.useEffect(
    function addSelectRegionEventsToCanvas() {
      const shouldEnablePointerEvents =
        activeTool === PlotTool.SelectRegion &&
        canvas !== null &&
        renderingSurface !== null &&
        chartExtents !== null &&
        chartExtents.pixel.width !== 0;

      if (shouldEnablePointerEvents === false) {
        return;
      }

      const abortController = new AbortController();

      canvas.addEventListener(
        "pointerdown",
        function startSelectingRegion(event: PointerEvent) {
          const eventAbortController = new AbortController();

          const firstPoint = event.clientX;

          canvas.addEventListener(
            "pointermove",
            function selectingRegion(event: PointerEvent) {
              const secondPoint = event.clientX;

              const region = normalizeRegionToChartSpace(
                { firstPoint, secondPoint },
                renderingSurface.getBoundingClientRect(),
                chartExtents,
              );

              setSelectedRegion(region);
              onDragChanged(true);
            },
            { signal: eventAbortController.signal },
          );

          canvas.addEventListener(
            "pointerup",
            function finishSelectingRegion() {
              openContextMenu();

              /* 
                Schedules setting isDragging to false on the next iteration of the event loop
                after all the immediate listeners have been called. This is to prevent
                subsequent click events from occurring at the same time as when the drag completes.
              */
              setTimeout(function resetDragging() {
                onDragChanged(false);
              }, 10);

              eventAbortController.abort();
            },
            { once: true },
          );

          const cancelSelectingRegion = (event: PointerEvent) => {
            if (
              event.relatedTarget === null ||
              isElementOnCanvas(renderingSurface, event.relatedTarget) === false
            ) {
              eventAbortController.abort();
              onDragChanged(false);
            }
          };

          canvas.addEventListener("pointercancel", cancelSelectingRegion, {
            once: true,
          });
          canvas.addEventListener("pointerleave", cancelSelectingRegion, {
            once: true,
          });
        },
        { signal: abortController.signal },
      );
      return function dispose() {
        abortController.abort();
      };
    },
    [
      activeTool,
      canvas,
      onDragChanged,
      openContextMenu,
      renderingSurface,
      chartExtents,
    ],
  );

  React.useEffect(
    function resetSelectedRegion() {
      setSelectedRegion(null);
    },
    [activeTool],
  );

  const shouldDisplaySelectedRegion =
    activeTool === PlotTool.SelectRegion &&
    chartExtents !== null &&
    selectedRegion !== null &&
    chartExtents.pixel.width !== 0;

  if (shouldDisplaySelectedRegion === false) {
    return null;
  }

  const region = calculateRegionDimensions(selectedRegion, chartExtents);
  const timeRange = pixelRegionToTimeRange(region, chartExtents);

  // Need to convert the region's left relative to the rendering surface
  // because the selected region is rendered relative to the rendering surface
  const leftRelativeToRenderingSurface = region.left + chartExtents.pixel.left;

  return (
    <>
      <div
        style={{
          position: "absolute",
          backgroundColor: "rgba(0,0,255,0.20)",
          pointerEvents: "none",
          left: `${leftRelativeToRenderingSurface}px`,
          height: `${region.height}px`,
          top: `${region.top}px`,
          width: `${region.width}px`,
          borderLeft: "1px solid blue",
          borderRight: "1px solid blue",
        }}
      />
      {contextMenu.isOpen && (
        <SelectedRegionActionsMenu
          onClose={function resetSelectedRegion() {
            setSelectedRegion(null);
            contextMenu.close();
          }}
          panelId={state.id}
          timeRange={timeRange}
        />
      )}
    </>
  );
}
