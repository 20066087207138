import * as React from "react";

import { LogPanelContext } from "./LogPanelContext";

export function LogPanelProvider({ children }: React.PropsWithChildren) {
  const [isSearchVisible, setSearchVisibility] = React.useState<boolean>(false);

  const toggleSearchVisibility = () => {
    setSearchVisibility((prev) => !prev);
  };

  return (
    <LogPanelContext.Provider
      value={{
        isSearchVisible,
        toggleSearchVisibility,
      }}
    >
      {children}
    </LogPanelContext.Provider>
  );
}
