import { RobotoButton } from "@/shared/components";
import { useDeleteDatasetEvents } from "@/shared/domain/datasets/hooks/useDatasetEvents";
import { EventRecord } from "@/shared/domain/events";

interface DeleteDatasetEventsProps {
  datasetId: string;
  selectedEvents: EventRecord["event_id"][];
  onDeleteEvents: (events: EventRecord["event_id"][]) => void;
}

export function DeleteDatasetEvents({
  datasetId,
  onDeleteEvents,
  selectedEvents,
}: DeleteDatasetEventsProps) {
  const deleteMutation = useDeleteDatasetEvents(datasetId, onDeleteEvents);
  const onDelete = () => {
    deleteMutation.mutate(selectedEvents);
  };

  return (
    <RobotoButton
      eventName="DeleteDatasetEventsClicked"
      eventProperties={{
        datasetId,
        eventIds: selectedEvents,
      }}
      onClick={onDelete}
      variant="outlined"
      color="error"
      size="small"
      loading={deleteMutation.isPending}
      disabled={deleteMutation.isPending || selectedEvents.length === 0}
    >
      Delete {selectedEvents.length > 0 ? `(${selectedEvents.length})` : ""}
    </RobotoButton>
  );
}
