import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import {
  Avatar,
  Box,
  Card,
  CardActions,
  CardContent,
  Typography,
  useTheme,
} from "@mui/material";
import * as React from "react";

import { useNavigation } from "@/providers";
import { RobotoTooltip, TagList } from "@/shared/components";
import {
  Accessibility,
  ActionRecord,
  ActionReference,
} from "@/shared/domain/actions";
import { OrgRecord } from "@/shared/domain/orgs";

import { ActionLink } from "./ActionLink";
import DeleteActionIconButton from "./DeleteActionIconButton";
import InvokeActionButton from "./InvokeActionButton";
import InvokeActionIconButton from "./InvokeActionIconButton";

interface ActionCardProps {
  action: ActionRecord;
  onDelete?: (action: ActionRecord) => void;
  org: OrgRecord | null;
  accessibility: Accessibility;
}

const TEXT_ABBREV_STYLE = {
  fontVariant: "none",
  textDecoration: "none",
};

export default function ActionCard({
  action,
  onDelete,
  org,
  accessibility,
}: ActionCardProps) {
  const theme = useTheme();

  const [avatarHover, setAvatarHover] = React.useState<boolean>(false);

  const { goto } = useNavigation();

  const operations = [<InvokeActionIconButton key="invoke" action={action} />];
  if (onDelete !== undefined && action.org_id === org?.org_id) {
    operations.push(
      <DeleteActionIconButton
        key="delete"
        action={action}
        onDelete={onDelete}
        org={org}
      />,
    );
  }

  const owner =
    accessibility === Accessibility.Organization
      ? action.created_by
      : action.org_id;

  return (
    <Card
      variant="outlined"
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "210px",
        width: "1fr",
        backgroundColor: theme.palette.paper.main,
        cursor: "pointer",
        ":hover": {
          backgroundColor: theme.palette.tableHover.light,
        },
      }}
      onClick={() => {
        let ref = {} as ActionReference;

        if (action.org_id && action.org_id !== org?.org_id) {
          ref = {
            name: action.name,
            owner: action.org_id,
          };
        } else {
          ref = {
            name: action.name,
          };
        }

        goto.action({
          action: ref,
        });
      }}
    >
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Box
          alignItems="center"
          justifyContent="space-between"
          display="flex"
          width="100%"
          marginBottom="0.5em"
        >
          <Box>
            <ActionLink
              action={action}
              hideOwner={true} // shown below it instead
              component="abbr"
              wrapperSx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                //width: "calc(100% - 50px)",
                whiteSpace: "nowrap",
              }}
              linkSx={{
                fontSize: "1rem",
              }}
              variant="subtitle2"
            />
            <Typography variant="body2" color="text.secondary" fontSize="12px">
              By {owner}
            </Typography>
          </Box>
          <Avatar
            alt={org?.name}
            sx={{
              backgroundColor: theme.palette.paper.main,

              boxShadow:
                theme.palette.mode === "light"
                  ? "rgba(140, 149, 159, 0.15) 0px 3px 6px 0px"
                  : "none",
            }}
          >
            {action.org_id === "roboto-public" ? (
              <Box
                onMouseOver={() => setAvatarHover(true)}
                onMouseLeave={() => setAvatarHover(false)}
                sx={{ opacity: avatarHover ? 1 : 0.6 }}
              >
                <RobotoTooltip title="Published by Roboto">
                  <img
                    src="/iconWhite.svg"
                    style={{ width: "40px", height: "40px" }}
                    alt="Roboto Logo"
                  />
                </RobotoTooltip>
              </Box>
            ) : (
              <AutoAwesomeIcon color="disabled" />
            )}
          </Avatar>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            flexGrow: "1",
            alignContent: "start",
            paddingTop: theme.spacing(1),
          }}
        >
          <Typography
            variant="body2"
            color="text.secondary"
            flex="0 1 auto"
            sx={{
              display: "-webkit-box",
              WebkitLineClamp: 3,
              WebkitBoxOrient: "vertical",
            }}
            textOverflow="ellipsis"
            overflow="hidden"
          >
            <abbr title={action.short_description} style={TEXT_ABBREV_STYLE}>
              {action.short_description || action.description || (
                <i>No Description</i>
              )}
            </abbr>
          </Typography>
        </Box>
      </CardContent>

      <CardActions
        sx={{
          display: "flex",
          ml: theme.spacing(1),
          mr: theme.spacing(1),
          mb: theme.spacing(1),
          gap: theme.spacing(1.5),
          justifyContent: "space-between",
        }}
      >
        <InvokeActionButton action={action} />

        <TagList
          sx={{
            justifyContent: "flex-end",
          }}
          maxDisplayCount={2}
          tags={action.tags}
        />
      </CardActions>
    </Card>
  );
}
