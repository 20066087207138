import ExpandCircleDownOutlinedIcon from "@mui/icons-material/ExpandCircleDownOutlined";
import { Box, Collapse, useTheme } from "@mui/material";
import * as React from "react";

import {
  RobotoTableCell,
  RobotoTableRow,
  TooltipIconButton,
} from "@/shared/components";
import { TopicSequence } from "@/shared/domain/topics/TopicSequence";

import { TopicSequenceDetails } from "./TopicSequenceDetails";

interface TopicSequenceRowProps {
  topicSequence: TopicSequence;
  numColumns: number;
}

export const TopicSequenceRow: React.FC<TopicSequenceRowProps> = ({
  topicSequence,
  numColumns,
}) => {
  const [expanded, setExpanded] = React.useState(false);
  const theme = useTheme();

  const toggleExpandingContent = () => {
    setExpanded(!expanded);
  };

  return (
    <>
      <RobotoTableRow hover onClick={toggleExpandingContent}>
        <RobotoTableCell>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: theme.spacing(1.5),
            }}
          >
            <TooltipIconButton
              title={expanded ? "Collapse" : "Expand"}
              onClick={toggleExpandingContent}
              sx={{
                padding: theme.spacing(0.25),
              }}
            >
              <ExpandCircleDownOutlinedIcon
                fontSize="small"
                sx={{
                  transform: expanded ? "none" : "rotate(270deg)",
                }}
                color="primary"
              />
            </TooltipIconButton>
            {topicSequence.topic_name}
          </Box>
        </RobotoTableCell>
        <RobotoTableCell>{topicSequence.schema_name}</RobotoTableCell>
        <RobotoTableCell>{topicSequence.message_count}</RobotoTableCell>
        <RobotoTableCell>
          {topicSequence.active_duration_s.toFixed(3)}
        </RobotoTableCell>
        <RobotoTableCell>
          {topicSequence.active_frequency_hz.toFixed(2)}
        </RobotoTableCell>
        <RobotoTableCell>{topicSequence.topics.length}</RobotoTableCell>
      </RobotoTableRow>

      <RobotoTableRow>
        <RobotoTableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            borderWidth: expanded ? "1px" : 0,
          }}
          colSpan={numColumns}
        >
          <Collapse in={expanded} unmountOnExit>
            <TopicSequenceDetails topicSequence={topicSequence} />
          </Collapse>
        </RobotoTableCell>
      </RobotoTableRow>
    </>
  );
};
