import {
  Box,
  ListItem,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import * as React from "react";

import { EditFieldButton } from "@/shared/components/EditFieldButton";
import { TriggerRecord } from "@/shared/domain/actions/TriggerRecord";
import { OrgRecord } from "@/shared/domain/orgs";
import { useLazyAPICall } from "@/shared/services/apiHooks";
import { APIResponse, RobotoAPICall, RobotoEndpoint } from "@/types";

import { TriggerForEachSelector } from "./TriggerForEachSelector";

interface EditableForEachSelectorProps {
  title: string;
  field: string;
  value: string | undefined;
  endpoint: RobotoEndpoint;
  triggerName: string;
  currentOrg: OrgRecord | null;
  setErrorText: (arg: string) => void;
  onUpdate: (arg: APIResponse<TriggerRecord>) => void;
}

export const EditableForEachSelector: React.FC<
  EditableForEachSelectorProps
> = ({
  title,
  field,
  value,
  endpoint,
  triggerName,
  currentOrg,
  setErrorText,
  onUpdate,
}) => {
  const theme = useTheme();
  const [showEditableIcon, setShowEditableIcon] = React.useState(false);
  const [editable, setEditable] = React.useState(false);
  const [currentValue, setCurrentValue] = React.useState<string | null>(
    value || null,
  );
  const { initiateRequest } = useLazyAPICall<APIResponse<TriggerRecord>>();

  const updateTrigger = async (updatedVal: string) => {
    const updateTriggerCall: RobotoAPICall = {
      endpoint: endpoint,
      orgId: currentOrg?.org_id,
      pathParams: { name: triggerName },
      method: "PUT",
      requestBody: JSON.stringify({
        [field]: updatedVal,
      }),
    };

    const { data, error } = await initiateRequest(updateTriggerCall);

    if (error) {
      setErrorText(error.message);
      return false;
    }

    if (data) {
      onUpdate(data);
    }

    setErrorText("");

    return data;
  };

  const onClick = async () => {
    if (editable && currentValue) {
      const res = await updateTrigger(currentValue);
      if (res) {
        // Update succeeded, change cached state
        setCurrentValue(res.data.for_each);
        setEditable(false);
      }
    }
    setEditable(!editable);
  };

  return (
    <ListItem
      onMouseOver={() => {
        setShowEditableIcon(true);
      }}
      onMouseLeave={() => {
        setShowEditableIcon(false);
      }}
    >
      <ListItemText
        primaryTypographyProps={{
          component: "span",
        }}
        sx={{
          mt: theme.spacing(0.5),
        }}
        primary={
          <>
            <span>{title}</span>

            <EditFieldButton
              editable={editable}
              showEditableIcon={showEditableIcon}
              onClick={() => {
                void onClick();
              }}
            />

            <Box>
              {editable === false ? (
                <Typography
                  component="span"
                  variant="body2"
                  style={{
                    whiteSpace: "pre-wrap",
                    color: theme.palette.text.secondary,
                  }}
                >
                  <>{currentValue}</>
                </Typography>
              ) : (
                <Box
                  sx={{
                    mt: theme.spacing(1),
                  }}
                >
                  <TriggerForEachSelector
                    forEach={currentValue || null}
                    setForEach={setCurrentValue}
                  />
                </Box>
              )}
            </Box>
          </>
        }
      />
    </ListItem>
  );
};
