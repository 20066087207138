import {
  Box,
  ListItem,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import { useState } from "react";

import { EditFieldButton } from "@/shared/components/EditFieldButton";
import { ContainerParameters } from "@/shared/domain/actions";
import { OrgRecord } from "@/shared/domain/orgs";
import { useLazyAPICall } from "@/shared/services/apiHooks";
import { APIResponse, RobotoAPICall, RobotoEndpoint } from "@/types";

import { ActionContainerParams } from "./ActionContainerParams";
import { ActionContainerParamsTable } from "./ActionContainerParamsTable";
import { parseContainerParameters, stringifyCommand } from "./actionUtils";
import { ClearFieldButton } from "./ClearFieldButton";

interface EditableContainerParamsProps<T> {
  title: string;
  field: keyof T;
  value: ContainerParameters;
  pathParams: Record<string, string | number> | undefined;
  endpoint: RobotoEndpoint;
  currentOrg: OrgRecord | null;
  setErrorText: (arg: string) => void;
  overrides?: boolean;
  onUpdate?: (arg: APIResponse<T>) => void;
}

export default function EditableContainerParams<T>({
  title,
  field,
  value,
  pathParams,
  endpoint,
  currentOrg,
  setErrorText,
  overrides,
  onUpdate,
}: EditableContainerParamsProps<T>) {
  const theme = useTheme();
  const [showEditableIcon, setShowEditableIcon] = useState(false);
  const [editable, setEditable] = useState(false);
  const [workdir, setWorkdir] = useState<string | undefined>(
    value.workdir === null ? "null" : value.workdir,
  );
  const [command, setCommand] = useState<string | undefined>(
    stringifyCommand(value.command),
  );

  let initialState: string | undefined;
  if (value.entry_point && value.entry_point.length > 0) {
    initialState = value.entry_point[0];
  } else if (value.entry_point === null) {
    initialState = "null";
  }

  const [entrypoint, setEntrypoint] = useState<string | undefined>(
    initialState,
  );
  const [envVars, setEnvVars] = useState<Record<string, string>>(
    value.env_vars ?? {},
  );

  const { initiateRequest } = useLazyAPICall<APIResponse<T>>();

  const updateRecord = async (reset?: boolean) => {
    const containerParameters = parseContainerParameters(
      command,
      entrypoint,
      workdir,
      envVars,
    );

    const updates = {
      [field]: reset ? null : containerParameters,
    };
    const requestBody = JSON.stringify(updates);

    const updateCall: RobotoAPICall = {
      endpoint: endpoint,
      orgId: currentOrg?.org_id,
      pathParams: pathParams,
      method: "PUT",
      requestBody: requestBody,
    };

    const { error, data } = await initiateRequest(updateCall);

    if (error) {
      setErrorText(error.message);
      return false;
    }

    if (data && onUpdate) {
      onUpdate(data);
    }

    setEditable(false);
    setErrorText("");

    return data;
  };

  return (
    <ListItem
      onMouseOver={() => {
        setShowEditableIcon(true);
      }}
      onMouseLeave={() => {
        setShowEditableIcon(false);
      }}
    >
      <ListItemText
        primaryTypographyProps={{
          component: "span",
        }}
        primary={
          <>
            <span>{title}</span>

            <EditFieldButton
              editable={editable}
              disabled={!editable && onUpdate === undefined}
              showEditableIcon={showEditableIcon}
              onClick={() => {
                if (editable) {
                  void updateRecord();
                }
                setEditable(!editable);
              }}
            />

            {overrides && (
              <ClearFieldButton
                disabled={!editable && onUpdate === undefined}
                showIcon={showEditableIcon}
                onClick={() => {
                  void updateRecord(true);
                  setEntrypoint(undefined);
                  setCommand(undefined);
                  setWorkdir(undefined);
                }}
              />
            )}

            <Box>
              {!editable ? (
                <>
                  {value && Object.keys(value).length > 0 ? (
                    <Box
                      sx={{
                        mt: theme.spacing(1),
                      }}
                    >
                      <ActionContainerParamsTable
                        workdir={workdir}
                        command={command}
                        entrypoint={entrypoint}
                        envVars={envVars}
                      />
                    </Box>
                  ) : (
                    <Typography
                      component="span"
                      variant="body2"
                      style={{
                        whiteSpace: "pre-wrap",
                        color: theme.palette.text.secondary,
                      }}
                    >
                      None
                    </Typography>
                  )}
                </>
              ) : (
                <Box sx={{ mt: theme.spacing(0.5) }}>
                  <ActionContainerParams
                    workdir={workdir}
                    setWorkdir={setWorkdir}
                    command={command}
                    setCommand={setCommand}
                    entrypoint={entrypoint}
                    setEntrypoint={setEntrypoint}
                    envVars={envVars}
                    setEnvVars={setEnvVars}
                  />
                </Box>
              )}
            </Box>
          </>
        }
      />
    </ListItem>
  );
}
