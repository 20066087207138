import { Box, Container, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import * as React from "react";
import { useSearchParams } from "react-router-dom";

import { useAuth, useNavigation } from "@/providers";
import { Page } from "@/shared/components";
import {
  ConfigureDataRegionForm,
  ConfigureOrganizationForm,
  HowDoYouWorkForm,
  InviteTeamMembersForm,
  PendingInvitesForm,
  SelectOrCreateOrganizationForm,
} from "@/shared/components/auth";
import { ConfigureExperiences } from "@/shared/components/auth/ConfigureExperiences";
import { RobotoRegion } from "@/shared/domain/orgs/OrgRecord";
import { useLazyAPICall } from "@/shared/services/apiHooks";
import { orgsInvitesEndpoint } from "@/types";

import { InterruptedVerificationCodeForm } from "./InterruptedVerificationForm";
import { SignUpForm } from "./SignUpForm";
import { SignUpFormState } from "./signUpFormState";
import { VerificationCodeForm } from "./VerificationCodeForm";

export const SignUp: React.FC = () => {
  const theme = useTheme();

  const [searchParams] = useSearchParams();

  const inviteId = searchParams.get("inviteId") ?? undefined;
  const interruptedVerificationCode =
    searchParams.get("interruptedVerificationCode") ?? undefined;

  const [formState, setFormState] = useState<SignUpFormState>({
    currentSignUpForm: interruptedVerificationCode
      ? "interruptedVerificationCode"
      : "signUp",
    emailAddress: "",
    individualAccountIdentifier: "",
    password: "",
    currentOrganizations: null,
    workMode: "individual",
    pendingInvites: null,
    robotoRegion: RobotoRegion.UsWest,
  });

  const { getCurrentOrganization, isAuthenticated } = useAuth();

  const { goto } = useNavigation();

  const { initiateRequest: initiateInviteRequest } = useLazyAPICall();

  useEffect(() => {
    // If the user is already authenticated,
    // and they're not in the middle of the sign up process,
    // redirect them to the home page.
    // Home page redirects to signIn?chooseorg=true if the user doesn't have an org
    if (isAuthenticated && formState.currentSignUpForm === "signUp") {
      goto.home();
    }
  }, [formState.currentSignUpForm, isAuthenticated, goto]);

  useEffect(() => {
    setFormState({
      currentSignUpForm: interruptedVerificationCode
        ? "interruptedVerificationCode"
        : "signUp",
      emailAddress: "",
      individualAccountIdentifier: "",
      password: "",
      currentOrganizations: null,
      workMode: "individual",
      pendingInvites: null,
      robotoRegion: RobotoRegion.UsWest,
    });
  }, [interruptedVerificationCode]);

  return (
    <Page title={"Roboto - Sign Up"}>
      <Container
        sx={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "grid",
            rowGap: theme.spacing(4),
            minHeight: "100vh",
            padding: theme.spacing(4, 2),
            marginLeft: "auto",
            marginRight: "auto",
            placeItems: "stretch center",
          }}
        >
          <SignUpForm
            inviteId={inviteId}
            isVisible={formState.currentSignUpForm === "signUp"}
            continueWithEmailClicked={(email: string, password: string) => {
              const newFormState: SignUpFormState = {
                ...formState,
                currentSignUpForm: "verificationCode",
                emailAddress: email,
                password: password,
              };

              setFormState(newFormState);
            }}
          />

          <InterruptedVerificationCodeForm
            isVisible={
              formState.currentSignUpForm === "interruptedVerificationCode"
            }
            onCodeSuccess={(
              nextForm,
              organizations,
              invites,
              inviteId,
              emailAddress,
              password,
            ) => {
              if (inviteId) {
                goto.invite({ inviteId });
                return;
              }

              setFormState({
                ...formState,
                currentSignUpForm: nextForm,
                currentOrganizations:
                  organizations.length > 0 ? organizations : null,
                pendingInvites: invites.length > 0 ? invites : null,
                emailAddress: emailAddress,
                password: password,
              });
            }}
          />

          <VerificationCodeForm
            isVisible={formState.currentSignUpForm === "verificationCode"}
            emailAddress={formState.emailAddress}
            password={formState.password}
            onCodeSuccess={(nextForm, organizations, invites, inviteId) => {
              if (inviteId) {
                goto.invite({ inviteId });
                return;
              }

              setFormState({
                ...formState,
                currentSignUpForm: nextForm,
                currentOrganizations:
                  organizations.length > 0 ? organizations : null,
                pendingInvites: invites.length > 0 ? invites : null,
              });
            }}
          />

          <PendingInvitesForm
            isVisible={formState.currentSignUpForm === "pendingInvites"}
            invites={formState.pendingInvites}
            skipInvites={() => {
              setFormState({
                ...formState,
                currentSignUpForm: "selectOrCreateOrganization",
              });
            }}
          />

          <SelectOrCreateOrganizationForm
            isVisible={
              formState.currentSignUpForm === "selectOrCreateOrganization"
            }
            currentOrganizations={formState.currentOrganizations}
            createNewOrganizationClicked={(individualAccountIdentifier) => {
              const newFormState: SignUpFormState = {
                ...formState,
                currentSignUpForm: "howDoYouWork",
                individualAccountIdentifier: individualAccountIdentifier,
              };

              setFormState(newFormState);
            }}
          />

          <HowDoYouWorkForm
            isVisible={formState.currentSignUpForm === "howDoYouWork"}
            goBackPressed={() => {
              setFormState({
                ...formState,
                currentSignUpForm: "selectOrCreateOrganization",
              });
            }}
            continueClicked={(workMode) => {
              const newFormState: SignUpFormState = {
                ...formState,
                currentSignUpForm: "configureDataRegion",
                workMode: workMode,
              };

              setFormState(newFormState);
            }}
          />

          <ConfigureDataRegionForm
            isVisible={formState.currentSignUpForm === "configureDataRegion"}
            regionChosen={(region) => {
              setFormState({
                ...formState,
                robotoRegion: region,
                currentSignUpForm: "configureOrganization",
              });
            }}
            goBackPressed={() => {
              setFormState({ ...formState, currentSignUpForm: "howDoYouWork" });
            }}
          />

          <ConfigureOrganizationForm
            isVisible={formState.currentSignUpForm === "configureOrganization"}
            onSuccess={(
              allowEmailDomain: boolean,
              organizationName: string,
            ) => {
              const newFormState: SignUpFormState = {
                ...formState,
                currentSignUpForm:
                  formState.workMode === "individual"
                    ? "configureExperiences"
                    : "inviteTeamMembers",
                allowEmailDomainToJoinOrg: allowEmailDomain,
                organizationName: organizationName,
              };

              setFormState(newFormState);
            }}
            goBackPressed={() =>
              setFormState({
                ...formState,
                currentSignUpForm: "configureDataRegion",
              })
            }
            workMode={formState.workMode}
            dataRegion={formState.robotoRegion}
          />

          <InviteTeamMembersForm
            isVisible={formState.currentSignUpForm === "inviteTeamMembers"}
            organizationName={formState.organizationName}
            inviteAndCompletePressed={(emailAddresses) => {
              emailAddresses.forEach((emailAddress) => {
                void initiateInviteRequest({
                  endpoint: orgsInvitesEndpoint,
                  method: "POST",
                  requestBody: JSON.stringify({
                    invited_user_id: emailAddress,
                  }),
                  orgId: getCurrentOrganization()?.org_id,
                });
              });

              const newFormState: SignUpFormState = {
                ...formState,
                currentSignUpForm: "configureExperiences",
              };

              setFormState(newFormState);
            }}
            skipForNowPressed={() => {
              const newFormState: SignUpFormState = {
                ...formState,
                currentSignUpForm: "configureExperiences",
              };

              setFormState(newFormState);
            }}
          />

          <ConfigureExperiences
            isVisible={formState.currentSignUpForm === "configureExperiences"}
            continueClicked={() => {
              goto.home();
            }}
            orgId={getCurrentOrganization()?.org_id}
          />
        </Box>
      </Container>
    </Page>
  );
};
