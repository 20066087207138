import DeleteIcon from "@mui/icons-material/Delete";
import { useTheme } from "@mui/material";
import * as React from "react";

import {
  RobotoIconButton,
  RobotoTableCell,
  RobotoTableRow,
  RobotoTooltip,
} from "@/shared/components";
import { OrgRecord } from "@/shared/domain/orgs";
import { useLazyAPICall } from "@/shared/services/apiHooks";
import { Image, imageEndpoint } from "@/types";

interface ImageRowProps {
  image: Image;
  currentOrg: OrgRecord | null;
  imgReloadToggle: boolean;
  setImgReloadToggle: (arg: boolean) => void;
  setAlertDialogText: (arg: string) => void;
  setAlertDialogAction: (
    value: React.SetStateAction<(() => Promise<void>) | undefined>,
  ) => void;
  setAlertDialogOpen: (arg: boolean) => void;
  setErrorText: (arg: string) => void;
}

export const ImageRow: React.FC<ImageRowProps> = ({
  image,
  currentOrg,
  imgReloadToggle,
  setImgReloadToggle,
  setAlertDialogText,
  setAlertDialogAction,
  setAlertDialogOpen,
  setErrorText,
}) => {
  const theme = useTheme();

  const { initiateRequest: deleteImageRequest } = useLazyAPICall();

  function wrapActionWithDialog(text: string, action: () => Promise<void>) {
    setAlertDialogText(text);
    setAlertDialogAction(() => action);
    setAlertDialogOpen(true);
  }

  return (
    <RobotoTableRow>
      <RobotoTableCell>{image.image_uri}</RobotoTableCell>
      <RobotoTableCell>
        <RobotoTooltip title="Delete Image">
          <span>
            <RobotoIconButton
              eventName={"DeleteImageClicked"}
              eventProperties={{
                imageUri: image.image_uri,
              }}
              onClick={() =>
                wrapActionWithDialog(
                  `Are you sure you want to delete ${image.image_uri}?`,
                  async function () {
                    const { error } = await deleteImageRequest({
                      endpoint: imageEndpoint,
                      method: "DELETE",
                      orgId: currentOrg?.org_id,
                      requestBody: JSON.stringify({
                        image_uri: image.image_uri,
                      }),
                    });

                    if (!error) {
                      setImgReloadToggle(!imgReloadToggle);
                    } else {
                      setErrorText(error.message);
                    }
                  },
                )
              }
            >
              <DeleteIcon
                fontSize="small"
                sx={{
                  color: theme.palette.secondary.main,
                }}
              />
            </RobotoIconButton>
          </span>
        </RobotoTooltip>
      </RobotoTableCell>
    </RobotoTableRow>
  );
};
