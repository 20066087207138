import {
  isImagePanelState,
  isPlotPanelState,
  usePanelState,
} from "@/shared/state/visualization";

import { useSelectedPanelId } from "../WorkspaceCtx";

import { EmptyPanelSettings } from "./EmptyPanelSettings";
import { ImagePanelSettings } from "./ImagePanelSettings";
import { NoSelectedPanelMessage } from "./NoSelectedPanelMessage";
import { PlotPanelSettings } from "./PlotPanelSettings";

export function PanelSettings() {
  const selectedPanelId = useSelectedPanelId();
  const panelState = usePanelState(selectedPanelId);

  if (!panelState) {
    return <NoSelectedPanelMessage />;
  }

  if (isImagePanelState(panelState)) {
    return <ImagePanelSettings state={panelState} />;
  }

  if (isPlotPanelState(panelState)) {
    return <PlotPanelSettings state={panelState} />;
  }

  return <EmptyPanelSettings />;
}
