import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Alert } from "@mui/material";

import { DatasetTopicTable } from "./DatasetTopicTable";

interface DatasetTopicsProps {
  datasetId: string;
}

export function DatasetTopics({ datasetId }: DatasetTopicsProps) {
  return (
    <div>
      <Alert
        severity="info"
        icon={<InfoOutlinedIcon />}
        sx={{ marginBottom: 2 }}
      >
        This tab shows an aggregated view of topics derived from all files in
        the dataset. Expand topics to see their associated source files.
      </Alert>
      <DatasetTopicTable datasetId={datasetId} />
    </div>
  );
}
