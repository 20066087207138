import { HttpClient, robotoHeaders } from "@/shared/http";

import { type ApplyExperiencesRequest } from "./operations";

export class ExperienceService {
  #httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.#httpClient = httpClient;
  }

  public async applyExperiences(
    orgId: string,
    request: ApplyExperiencesRequest,
    options?: Partial<{ abortSignal: AbortSignal }>,
  ): Promise<void> {
    const requestUrl = this.#httpClient.constructUrl(`v1/experiences/apply`);

    await this.#httpClient.post(requestUrl, {
      headers: robotoHeaders({ orgId }),
      body: JSON.stringify(request),
      signal: options?.abortSignal,
    });
  }
}
