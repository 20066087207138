import ExpandCircleDownOutlinedIcon from "@mui/icons-material/ExpandCircleDownOutlined";
import FolderRoundedIcon from "@mui/icons-material/FolderRounded";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import PhotoOutlinedIcon from "@mui/icons-material/PhotoOutlined";
import VideoCameraBackOutlinedIcon from "@mui/icons-material/VideoCameraBackOutlined";
import {
  Box,
  CircularProgress,
  Collapse,
  PopoverPosition,
  useTheme,
} from "@mui/material";
import * as React from "react";

import { useAuth } from "@/providers";
import {
  FileSource,
  RobotoCheckbox,
  RobotoErrorAlertDialog,
  RobotoTableCell,
  RobotoTableRow,
  TooltipIconButton,
} from "@/shared/components";
import { FileMetadataAndTopics } from "@/shared/components/FileMetadataAndTopics";
import { IngestionStatus } from "@/shared/domain/files/FileRecord";
import { useLazyAPICall } from "@/shared/services/apiHooks";
import {
  APIServiceError,
  FileNode,
  FileSignedUrlResponse,
  FileSystemNode,
  fileSignedUrlEndpoint,
  isFile,
} from "@/types";
import {
  basename,
  humanFileSize,
  getSignedUrlParams,
  isImage,
  isVideo,
  canHaveTopics,
} from "@/utils";

import { ActionCell } from "./ActionCell";
import { ActionMenu } from "./ActionMenu";
import { RenameNode } from "./RenameNode";

interface DatasetFileRowProps {
  row: FileSystemNode;
  handleDeleteRow: (row: FileSystemNode) => void;
  handleRowDoubleClick: (row: FileSystemNode) => void;
  handleActionConfirmation: (
    title: string,
    text: string,
    action: () => Promise<void>,
  ) => void;
  handleSelectRows: (rows: FileSystemNode[], isSelected: boolean) => void;
  isChecked: boolean;
  goToFile: () => void;
}

export const DatasetFileRow: React.FC<DatasetFileRowProps> = ({
  row,
  handleDeleteRow,
  handleRowDoubleClick,
  handleActionConfirmation,
  handleSelectRows,
  isChecked,
  goToFile,
}) => {
  const theme = useTheme();

  const { currentOrganization } = useAuth();
  const [alertOpen, setAlertOpen] = React.useState<boolean>(false);
  const [lastError, setLastError] = React.useState<APIServiceError | undefined>(
    undefined,
  );

  const [rowLoading, setRowLoading] = React.useState<boolean>(false);

  const [openExpandableInfo, setOpenExpandableInfo] =
    React.useState<boolean>(false);

  const [anchorPos, setAnchorPos] = React.useState<PopoverPosition>();

  const [isRenaming, setIsRenaming] = React.useState<boolean>(false);

  const handlePopoverMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorPos({ top: event.clientY, left: event.clientX });
  };

  const { initiateRequest: initiateSignedUrlRequest } =
    useLazyAPICall<FileSignedUrlResponse>();

  const handleError = (error: APIServiceError | undefined) => {
    setLastError(error);
    setAlertOpen(true);
  };

  const fetchSignedUrl = async (row: FileNode, download?: boolean) => {
    const file = row.file;
    if (!file) {
      return;
    }

    const { data, error } = await initiateSignedUrlRequest({
      endpoint: fileSignedUrlEndpoint,
      method: "GET",
      orgId: currentOrganization?.org_id,
      queryParams: getSignedUrlParams(file, download),
      pathParams: { fileId: file.file_id },
    });

    if (!error && data?.data) {
      return data.data.url;
    }

    if (error) {
      handleError(error);
    }
  };

  const toggleOpenDetails = () => {
    if (openExpandableInfo === false) {
      setRowLoading(true);
    }

    setOpenExpandableInfo(!openExpandableInfo);
  };

  const handleRenameNode = () => {
    setIsRenaming(true);
  };

  const rowIsFileType = row?.type === "file";

  const isFileIngested =
    isFile(row) && row.file.ingestion_status === IngestionStatus.Ingested;

  const isRoboticsFile = canHaveTopics(row.name);
  const isNotIngestedRoboticsFile = isRoboticsFile && !isFileIngested;

  let iconContent;

  const progressIcon = <CircularProgress size={20} />;

  if (isRoboticsFile) {
    let title = "";

    if (isNotIngestedRoboticsFile) {
      title = "Not Ingested";
    } else {
      title = openExpandableInfo ? "Hide Details" : "Show Details";
    }

    iconContent = (
      <TooltipIconButton
        title={title}
        onClick={() => {
          void toggleOpenDetails();
        }}
        disabled={isNotIngestedRoboticsFile}
        sx={{
          padding: theme.spacing(0.25),
          transform: openExpandableInfo ? "none" : "rotate(270deg)",
        }}
      >
        {rowLoading ? (
          progressIcon
        ) : (
          <ExpandCircleDownOutlinedIcon
            color={isNotIngestedRoboticsFile ? "disabled" : "primary"}
            fontSize="small"
          />
        )}
      </TooltipIconButton>
    );
  } else if (isImage(row.name)) {
    iconContent = rowLoading ? (
      progressIcon
    ) : (
      <PhotoOutlinedIcon color="info" />
    );
  } else if (isVideo(row.name)) {
    iconContent = rowLoading ? (
      progressIcon
    ) : (
      <VideoCameraBackOutlinedIcon color="info" />
    );
  } else {
    iconContent = rowLoading ? (
      progressIcon
    ) : (
      <InsertDriveFileOutlinedIcon color="info" />
    );
  }

  const shouldDisplayMetadata =
    isFile(row) && openExpandableInfo && !rowLoading;

  const shouldFetchMetadata = isFile(row) && openExpandableInfo;

  const itemDoubleClicked = () => {
    if (isRenaming) {
      return;
    }

    if (row.type === "file") {
      goToFile();
    } else if (row.type === "directory") {
      handleRowDoubleClick(row);
    }
  };

  return (
    <>
      <RobotoErrorAlertDialog
        open={alertOpen}
        setOpen={setAlertOpen}
        error={lastError}
      />
      <RobotoTableRow
        hover
        key={row.name}
        onContextMenu={(e) => {
          e.preventDefault();
          handlePopoverMenu(e);
        }}
        sx={{
          cursor: row.type === "directory" ? "pointer" : "default",
          userSelect: row.type !== "file" ? "none" : "auto",
        }}
      >
        <RobotoTableCell
          sx={{
            padding: "0px",
            width: "42.5px",
            maxWidth: "42.5px",
          }}
        >
          <RobotoCheckbox
            sx={{
              ml: theme.spacing(1),
              display: {
                xs: "none",
                sm: "inline-flex",
              },
            }}
            checked={isChecked}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              handleSelectRows([row], e.target.checked);
            }}
            color="default"
            inputProps={{ "aria-label": "checkbox" }}
          />
        </RobotoTableCell>

        <RobotoTableCell onDoubleClick={itemDoubleClicked}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: theme.spacing(1.5),
            }}
          >
            {row.type === "file" ? (
              iconContent
            ) : (
              <FolderRoundedIcon color="info" />
            )}

            <RenameNode
              node={row}
              handleClose={() => setIsRenaming(false)}
              open={isRenaming}
            />

            {basename(row.name)}
          </Box>
        </RobotoTableCell>
        <RobotoTableCell onDoubleClick={itemDoubleClicked}>
          {isFile(row) ? new Date(row.file.modified).toLocaleString() : ""}
        </RobotoTableCell>
        <RobotoTableCell onDoubleClick={itemDoubleClicked}>
          {isFile(row) && <FileSource fileRecord={row.file} />}
        </RobotoTableCell>
        <RobotoTableCell onDoubleClick={itemDoubleClicked}>
          {isFile(row) ? humanFileSize(row.file?.size) : ""}
        </RobotoTableCell>
        <ActionCell
          row={row}
          handleMoreClicked={(e) => {
            e.stopPropagation();
            handlePopoverMenu(e);
          }}
        />
      </RobotoTableRow>
      {isFile(row) && (
        <RobotoTableRow>
          <RobotoTableCell
            style={{
              paddingBottom: 0,
              paddingTop: 0,
              borderWidth: openExpandableInfo ? "1px" : 0,
            }}
            colSpan={6}
          >
            <Collapse in={shouldDisplayMetadata}>
              <FileMetadataAndTopics
                fileId={row.file.file_id}
                fileVersion={row.file.version}
                willBeDisplayed={shouldFetchMetadata}
                onDataReady={() => {
                  setRowLoading(false);
                }}
              />
            </Collapse>
          </RobotoTableCell>
        </RobotoTableRow>
      )}
      <ActionMenu
        row={row}
        onDeleteSuccess={() => {
          // Remove row from UI
          handleDeleteRow(row);
        }}
        anchorPos={anchorPos}
        setAnchorPos={setAnchorPos}
        handleActionConfirmation={handleActionConfirmation}
        fetchSignedUrl={(row) => fetchSignedUrl(row, true)}
        openContent={() => {
          goToFile();
        }}
        checked={isChecked}
        handleSelectRows={handleSelectRows}
        detailsExpandable={true}
        openDetails={toggleOpenDetails}
        handleRenameNode={handleRenameNode}
        rowIsFileType={rowIsFileType}
      />
    </>
  );
};
